import styled, { css } from 'styled-components'
import { theme } from '../../../../../../../../styles/theme'

const bgColor = {
  0: 'transparent',
  25: theme.colors.reddishOrange,
  50: theme.colors.amber,
  100: theme.colors.lemonGreen,
}

export const PasswordStrengthContainer = styled.div`
  margin-top: -10px;

  @media screen and (min-width: 1024px) {
    padding-top: 30px;
    margin-top: 0px;
  }
`

export const PasswordStrength = styled.div`
  height: 11px;
  position: relative;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
  border: 3px solid;
  margin-bottom: 8px;
  border-radius: 19px;
  padding: 0px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

  ${({ strength }) =>
    css`
      span {
        display: block;
        height: 5px;
        width: ${strength}%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: ${bgColor[strength]};
        position: relative;
        z-index: 9;
        overflow: hidden;
        transition: all 1s;
      }
    `}
`

export const LabelPasswordStrength = styled.div`
  width: 100%;
  height: 19px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  margin: 4px 15px 0 15px;

  ${({ strength, theme }) =>
    css`
      span {
        font-weight: bolder;
        color: ${bgColor[strength]};
      }
    `}
`

export const PasswordRules = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.05px;
  padding: 10px 0 0 20px;
  gap: 10px;
`

export const PasswordRule = styled.p`
  ${({ theme, check }) =>
    check &&
    css`
      color: ${theme.colors.EmeraldGreen};
    `}
`
