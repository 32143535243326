import React from 'react'

import { VucaLogoWithDescription } from '../../assets/img'
import { FormattedMessage } from 'react-intl'
import { Container } from './styles'
import PropTypes from 'prop-types'

const Logo = ({ className = '', width, height, hiddenText } = {}) => (
  <Container className={className}>
    <span>
      <VucaLogoWithDescription style={{ width, height }} />
      {!hiddenText && (
        <small className="hcxOjX">
          <FormattedMessage id="logo" />
        </small>
      )}
    </span>
  </Container>
)

Logo.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  hiddenText: PropTypes.bool,
}

export default Logo
