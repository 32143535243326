import styled, { css } from 'styled-components'

import { setColorInProgressBar } from './utils'

export const ComponentContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ isHome }) =>
    !isHome &&
    css`
      @media screen and (max-width: 1024px) {
        flex-direction: row-reverse;
      }
    `}
`

export const CharacterPictureContainer = styled.div`
  position: relative;

  .jobIcon {
    position: absolute;
    right: 0;
    top: 3rem;
  }
`

export const BarFrame = styled.div`
  border: solid 3px ${({ theme }) => theme.colors.black};
  border-radius: 1.2rem;
  height: 17px;
  width: 100%;
  overflow: hidden;
  background-color: white;

  @media screen and (min-width: 1024px) {
    height: 2rem;
    width: 284px;
  }
`
export const StageTitle = styled.div`
  margin-bottom: 10px;
  text-align: end;

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  
  @media screen and (min-width: 1024px) {
    margin-bottom: 0.4rem;
    text-align: start;
  }
`

export const BarProgress = styled.div`
  transition: 1s ease;
  transition-delay: 0.5s;
  height: 100%;
  ${({ progress }) =>
    css`
      width: ${progress * 100}%;
    `}
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`

export const SvgDivider = styled.div`
  ${({ percentMobile, percentDesktop, progressWidth }) =>
    progressWidth &&
    css`
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: -13px;
      padding-left: calc(${progressWidth}px / 100 * ${percentMobile});

      @media screen and (min-width: 1024px) {
        margin-left: calc(284px / 100 * ${percentDesktop - 1.5});
        padding-left: 0;
      }

      svg:nth-child(2) {
        margin-top: 9px;

        @media screen and (max-width: 1024px) {
          width: 16px;
          height: 16px;
        }
      }

      svg:nth-child(3) {
        margin-top: 9px;

        @media screen and (max-width: 1024px) {
          width: 24px;
          height: 17.5px;
        }
      }
    `}
`

export const Divider = styled.div`
  width: 4px;
  height: 33px;
  margin: 4px 1px 0;
  background-color: #000000;
  position: absolute;
  margin-top: -27px;

  @media screen and (max-width: 1024px) {
    height: 23px;
    margin-top: -13px;
  }
`

function mediaQuery(width, percent) {
  return css`
    @media screen and (max-width: ${width}px) {
      width: ${percent}%;
    }
  `
}

export const FlagPortalZ = styled.div`
  margin-top: 15px;
  width: 20%;

  @media screen and (min-width: 1024px) {
    position: absolute;
    margin-top: 21px;
    margin-left: 332px;
  }

  @media screen and (max-width: 1024px) {
    width: 7%;

    svg:first-child {
      width: 20px;
      height: 25.8px;
    }
  }

  ${mediaQuery(900, 6)}
  ${mediaQuery(680, 7)}
  ${mediaQuery(580, 8)}
  ${mediaQuery(480, 10)}
  ${mediaQuery(440, 11)}
  ${mediaQuery(380, 14)}

`

export const Tooltip = styled.div`
  position: absolute;

  padding: 20px 20px 0;
  border-radius: 3px;
  border: solid 3px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 88;
  box-shadow: -2.5px 3px rgb(29, 29, 27);

  ${({ withCloseButton }) =>
    !withCloseButton &&
    css`
      svg {
        display: none;
      }
    `}

  ${({ styles }) =>
    styles &&
    css`
      width: ${styles.widthSize}px;
      height: ${styles.heightSize}px;

      margin-left: ${styles.marginLeft}px;
      margin-top: ${styles.marginTop}px;
      background-color: ${({ theme }) => theme.colors.amber};

      @media screen and (max-width: 1024px) {
        margin-left: ${styles.marginLeftMobile}px;
        margin-top: ${styles.marginTopMobile}px;

        svg {
          display: block;
        }
      }
    `}

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${({ theme }) => theme.colors[bgColor]};
    `}

  b {
    font-family: MilkyNice;
    letter-spacing: -0.08px;
  }

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: -0.06px;
    margin-top: 15px;
  }

  &::after {
    content: '';
    position: relative;
    width: 0;
    height: 0;
    z-index: 9999;
    border-left: 5px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid rgba(0, 0, 0, 1);

    ${({ styles }) =>
      styles &&
      css`
        left: ${styles.afterLeft}px;
        top: ${styles.afterTop}px;

        @media screen and (max-width: 1024px) {
          left: ${styles.afterLeftMobile}px;
          top: ${styles.afterTopMobile}px;
        }
      `}
  }
`

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 60%;

  @media screen and (max-width: 1024px) {
    justify-content: end;
  }

  @media screen and (max-width: 650px) {
    width: 80%;
  }
`

export const TitleBarContainer = styled.div`
  margin: 0 2rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: MilkyNice;
  letter-spacing: 1.9px;
  font-size: 1.2rem;

  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 0.7rem;
    margin: 0 0.8rem;
    width: 80%;
  }
`

export const CharacterPicture = styled.div`
  height: 67px;
  width: 67px;
  border: solid 3px ${({ theme }) => theme.colors.black};
  border-radius: 50px;
  ${({ portrait }) =>
    portrait &&
    css`
      background-image: url("${portrait}");
      background-size: 120px;
      background-position: center;
    `}

  @media screen and (max-width: 1024px) {
    height: 3.5rem;
    width: 3.5rem;
  }
`

export const ExpTagContainer = styled.div`
  letter-spacing: -0.06px;
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;

  div {
    transition: 1s ease;
    font-size: 14px;
    margin-top: 6px;
  }
`

export const LevelOfProgress = styled.div`
  background-color: ${setColorInProgressBar};
  color: white;
  display: grid;
  font-weight: bolder;
  height: calc(100% / 3);
  place-items: center;
`
export const MarkProgress = styled.div`
  position: absolute;
  padding: 2rem;
  color: black;
  /* top: calc(13.5rem * 0.5); */
  ${({ percent }) =>
    css`
      top: calc(13.5rem * ${percent});
    `}

  span {
    color: orange;
    font-weight: bolder;
    display: none;
  }
`

export const CounterHome = styled.div`
  width: 100%;
  position: relative;
  top: 15px;
  left: -4px;

  @media screen and (max-width: 1024px) {
    top: 8px;
    left: -8px;
  }
`
