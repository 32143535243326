import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '..'
import { Container, BackgroundBlur, ContentCard, ContainerButton } from './styles'

import { useContentOfTextOnStage } from './useContentOfTextOnStage'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

export function StageCompleteAlertCp({
  characterName,
  nameOfCompleteLevel,
  confirmCompleteStage,
  intl,
}) {
  const { title, message, Icon } = useContentOfTextOnStage(
    characterName,
    nameOfCompleteLevel,
  )
  return (
    <>
      <Container data-test="containerFeedbackCard">
        <Card isSmall data_test="feedbackCard">
          <ContentCard>
            <figure>
              <Icon width="100%" height="10rem" data-test="iconFeedBackCard" />
            </figure>

            <section>

              <h1 data-test="titleFeedBackCard">
                {intl.formatMessage({ id: title }, { name: characterName })}
              </h1>
              <p data-test="paragraphFeedBackCard">
                {intl.formatMessage({ id: message }, { name: characterName })}
              </p>

              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  onClick={confirmCompleteStage(nameOfCompleteLevel)}
                  data_test="buttonFeedBackCard"
                  text={intl.formatMessage({ id: 'continue' })}
                />
              </ContainerButton>
            </section>
          </ContentCard>
        </Card>
      </Container>
      <BackgroundBlur />
    </>
  )
}

StageCompleteAlertCp.propTypes = {
  characterName: PropTypes.string.isRequired,
  nameOfCompleteLevel: PropTypes.string.isRequired,
  confirmCompleteStage: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const StageCompleteAlert = injectIntl(StageCompleteAlertCp)
