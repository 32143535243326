import { Api } from './index'
import { types } from '../../store'
import { clearAll } from '../../services/storage'

export async function login(dataRequest) {
  try {
    const response = await Api.post('/login', dataRequest)
    return response.data
  } catch {
    return undefined
  }
}

export function loginFacebook() {
  window.location = process.env.REACT_APP_API_GAME + '/auth/facebook'
}

export async function callbackFacebook(code) {
  try {
    const response = await Api.get(`/auth/facebook/callback?code=${code}`)
    return response.data
  } catch {
    return undefined
  }
}

export function playWithoutAccount(dispatch) {
  const defaultUser = {
    name: 'Default User',
  }

  dispatch({ type: types.SET_USER_DETAILS, payload: defaultUser })
}

export function logout(dispatch) {
  dispatch({ type: types.SET_USER_DETAILS, payload: null })
  dispatch({ type: types.SET_USER_DETAILS, payload: null })
  clearAll()
}
