export default {
  logo: 'The game of big decisions',
  download: 'Download',
  worldVuca: 'The world of VUCA',
  email: 'Email address',
  emailPlaceholder: 'Enter your email address',
  emailAlreadySignedUp: 'This email address is already registered',
  password: 'Password',
  remember: 'Remember me',
  return: 'Back',
  doLogin: 'Log in',
  send: 'Submit',
  ok: 'Ok',
  select: 'Select',
  yes: 'Yes',
  no: 'No',
  edit: 'Edit',
  play: 'Play',
  exit: 'Exit',
  cancel: 'Cancel',
  loading: 'Loading',
  newGame: 'New game',
  saveGame: 'Save game',
  'saveGame.success': 'Game saved successfully.',
  'saveGame.error': 'An error occurred while saving the game. Try again later.',
  or: 'Or',
  choose: 'Select',
  jump: 'Skip',
  start: 'Start',
  next: 'next',
  save: 'Save',
  seeMore: 'View more',
  backToHome: 'Go back to start',
  deleteGame: 'Delete game',
  continue: 'Continue',
  savedGame: 'Game saved',
  and: 'and',
  copy: 'Copy',
  copied: 'Copied!',
  turnCounter: 'That is the number of times you can roll the dice',
  notResult: 'Nenhum resultado',
  shared: 'Share',
  resetPassword: 'New password',
  portalZ: 'This is Portal Z.',
  'language.pt': 'Portuguese',
  'language.es': 'Spanish',
  'language.en': 'English',
  'portalZ.info':
    'After graduating from higher education {name} you will arrive at Portal Z and be ready for new opportunities.',
  'deleteGame.info':
    'Are you sure you want to delete this match? <br/> <br/> After deleting the game you will have to start a new game.',
  'saveGame.confirm':
    'You already have a saved and unfinished game in your account. If you start and save a new game, you will lose the previous game. <br/> <br/> Do you want to start a new game?',
}
