import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  place-items: center;

  .choiceCharacterCard {
    max-width: 1360px;
    margin: 1.4rem;
    align-self: center;

    @media screen and (max-width: 1400px) {
      margin: 0;
    }
  }
`

export const CardBody = styled.main`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 1rem 10px;
    `}
`

export const ContainerCharactersNButtons = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  padding: 0 0 0.5rem;
`
export const CardFooter = styled.footer`
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.3rem;
  border-top: 0.2rem solid;
  display: grid;
  grid-template-columns: 2fr 1.5fr 3fr 1.5fr 3fr 1.5fr 7fr;
  overflow: hidden;
  width: calc(100% + 0.5px);

  div {
    height: 1rem;
  }

  ${({ theme }) => css`
    div:nth-child(1) {
      background-color: ${theme.colors.reddishOrange};
    }
    div:nth-child(2) {
      background-color: ${theme.colors.pistachioGreen};
    }
    div:nth-child(3) {
      background-color: ${theme.colors.lemonGreen};
    }
    div:nth-child(4) {
      background-color: ${theme.colors.amber};
    }
    div:nth-child(5) {
      background-color: ${theme.colors.EmeraldGreen};
    }
    div:nth-child(6) {
      background-color: ${theme.colors.pistachioGreen};
    }
    div:nth-child(7) {
      background-color: ${theme.colors.orange};
    }
  `}
`

export const BalloonOfDescriptionCharacter = styled.div`
  border-radius: 0.3rem;
  border: solid 0.2rem ${({ theme }) => theme.colors.black};
  box-shadow: -2.5px 3px rgb(29, 29, 27);

  padding: 1rem;
  min-height: 11rem;

  position: relative;
  margin: 0.5rem 0 0;

  &::before {
    content: '';
    position: absolute;
    left: calc(60% - 20px);
    top: -12px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 1);
  }

  p {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: -0.05px;
    line-height: 1.43;
  }
`

export const ContainerChooseButton = styled.div`
  bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0;
`
