export default {
  'game.skills.middle.questions.1':
    'Um atleta olímpico vai visitar a escola e o diretor está procurando um estudante para ajudar no tour da escola. O que {name} faz?',
  'game.skills.middle.questions.1.option.1':
    '{name} se candidata e se empolga com a oportunidade.',
  'game.skills.middle.questions.1.option.1.feedback':
    '{name} surpreendeu o atleta com uma apresentação especial. Usou a criatividade e desenvolveu sua postura e oratória.',
  'game.skills.middle.questions.1.option.2':
    'Fica com medo, e prefere deixar a missão para outro colega.',
  'game.skills.middle.questions.1.option.2.feedback':
    '{name} não conseguiu driblar o medo e ainda precisa melhorar sua autoconfiança!.​',

  'game.skills.middle.questions.2':
    'Chegou o período de eleição do grêmio estudantil! {name} resolve:',
  'game.skills.middle.questions.2.option.1':
    'Apoiar a chapa que tem a melhor proposta na sua opinião.',
  'game.skills.middle.questions.2.option.1.feedback':
    '{name} se envolveu ativamente. Teve que liderar equipes e convencer seus colegas. A experiência garantiu novas habilidades e um estoque de XP para chegar ao Portal Z.',
  'game.skills.middle.questions.2.option.2': 'Não se envolver nas eleições.',
  'game.skills.middle.questions.2.option.2.feedback':
    '{name} não teve iniciativa e perdeu a oportunidade de participar de decisões importantes para escola e seus colegas.​',

  'game.skills.middle.questions.3':
    'A escola fez parceria com uma ONG para oferecer um curso de empreendedorismo para estudantes. O que {name} faz?',
  'game.skills.middle.questions.3.option.1':
    '{name} se inscreve no curso mesmo não sendo obrigatório.',
  'game.skills.middle.questions.3.option.1.feedback':
    'Boa iniciativa! {name} criou uma empresa estudantil com os colegas e descobriu habilidades que nem sabia que tinha! A experiência vai ajudar {name} nas decisões futuras!!!',
  'game.skills.middle.questions.3.option.2':
    'Não se inscreve porque não tem interesse em abrir seu negócio.',
  'game.skills.middle.questions.3.option.2.feedback':
    '{name} perdeu a chance de explorar seus próprios talentos e descobrir novas opções de carreira.',

  'game.skills.middle.questions.4':
    '{name} assumiu a coordenação do Festival de Dança, mas sua equipe não tem experiência em eventos e os membros discordam em tudo. O que fazer?',
  'game.skills.middle.questions.4.option.1':
    'Passar o cargo para uma pessoa mais paciente.',
  'game.skills.middle.questions.4.option.1.feedback':
    'Poxa! {name} perdeu a  chance de praticar uma habilidade essencial no Mundo Vuca: liderar pessoas e projetos.',
  'game.skills.middle.questions.4.option.2':
    'Conversar com a equipe para identificar os interesses e habilidades de cada um.',
  'game.skills.middle.questions.4.option.2.feedback':
    'Boa! Pontos para {name}, que aprendeu muito sobre liderar pessoas e coordenar projetos!',

  'game.skills.middle.questions.5':
    '{name} descobriu pelas redes sociais que sua melhor amiga mentiu sobre o final de semana. {name} resolve:',
  'game.skills.middle.questions.5.option.1':
    'Publicar um texto nas redes sociais xingando a amiga.',
  'game.skills.middle.questions.5.option.1.feedback':
    '{name} agiu por impulso e atacou a amiga publicamente. Faltou autocontrole e diálogo, elementos fundamentais no Mundo Vuca.',
  'game.skills.middle.questions.5.option.2': 'Conversar com a amiga.',
  'game.skills.middle.questions.5.option.2.feedback':
    'Boa! {name} se sentiu mal, mas não se deixou levar pelo impulso. Pontos para {name} pela iniciativa e pelo autocontrole! ',

  'game.skills.middle.questions.6':
    'Uma amiga de {name} não consegue entender por que a biblioteca da escola só abre uma vez por mês. Ela comenta com {name}, que responde:',
  'game.skills.middle.questions.6.option.1': '"Sempre foi assim!"',
  'game.skills.middle.questions.6.option.1.feedback':
    'Opa! Esta frase está proibida no Mundo VUCA.  Não é porque as coisas são assim que não podem ser melhoradas, não é mesmo?',
  'game.skills.middle.questions.6.option.2':
    '"Nunca tinha pensado nisso, o que será que a gente pode fazer?"',
  'game.skills.middle.questions.6.option.2.feedback':
    'Show! Não é porque as coisas são assim que não podem ser melhoradas, não é mesmo? A maior parte das inovações - pequenas ou grandes - começam assim.',
}
