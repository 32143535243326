import { Api } from './index'

export async function getGame(store) {
  try {
    const response = await Api.get('/game/save')
    return response
  } catch (err) {
    return err.response
  }
}

export async function setGame(dataRequest) {
  try {
    const response = await Api.post(`/game/save`, dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}
