import styled, { css } from 'styled-components'

const removeOutline = '&:focus {outline: none; }'

export const Container = styled.div`
  min-width: 90px;
  position: relative;
`

export const Label = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  height: 19px;
  margin: 0 25px 4px 25px;
  font-family: MilkyNice;
  font-size: 14px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
`

export const SelectContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  position: absolute;
  max-height: 130px;
  min-height: 37px;
  border-radius: 19px;
  border: solid 4px ${({ theme }) => theme.colors.black};
  padding-left: 20px;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 500ms;

  z-index: 999999;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const InputText = styled.input`
  width: 100%;
  height: 38px;
  border-radius: 19px;
  border: solid 4px ${({ theme }) => theme.colors.black};
  padding-left: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};

  &:focus,
  &:hover {
    border: solid 4px ${({ theme }) => theme.colors.azure};
  }

  ${removeOutline}

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueyGrey};
  }

  ${({ borderColor }) =>
    borderColor &&
    css`
      border: solid 4px ${borderColor};
    `}

  ${({ showRequiredBorder, theme }) =>
    showRequiredBorder &&
    css`
      border: solid 4px ${theme.colors.reddishOrange};
    `}
`

export const ArrowSelect = styled.span`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 13px;
  border-radius: 1px;
  right: 12px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 11px solid black;
`

export const Info = styled.div`
  font-family: Montserrat;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.67;
  letter-spacing: -0.05px;
`
export const Option = styled.input`
  outline: none;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 0px;
  letter-spacing: -0.06px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 500ms;

  z-index: 9;
`
