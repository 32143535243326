export default {
  'game.knowledge.superior.questions.1': 'Qual item abaixo é um exemplo de metáfora?',
  'game.knowledge.superior.questions.1.option.1': 'A vida deles é um conto de fadas.',
  'game.knowledge.superior.questions.1.option.1.feedback':
    'Boa! Metáfora pode ser um recurso poderoso de comunicação.',
  'game.knowledge.superior.questions.1.option.2': 'Esta flor se parece com o sol.​',
  'game.knowledge.superior.questions.1.option.2.feedback':
    'Metáfora é uma figura de linguagem que produz sentidos figurados por meio de comparações, como “conto de fadas”. Saber usar isso é mais útil do que você pensa!',

  'game.knowledge.superior.questions.2': 'O que é plágio?',
  'game.knowledge.superior.questions.2.option.1':
    'Se apropriar de ideias ou textos de outras pessoas sem dar os devidos créditos.',
  'game.knowledge.superior.questions.2.option.1.feedback':
    'Boa! E não se esqueça: plágio é crime!',
  'game.knowledge.superior.questions.2.option.2':
    'Publicar uma foto de uma pessoa sem sua autorização.​',
  'game.knowledge.superior.questions.2.option.2.feedback':
    'Plágio é o ato de usar ideias e textos de outras pessoas sem dar os devidos créditos. E não se esqueça: plágio é crime!',

  'game.knowledge.superior.questions.3':
    'Por que analisar dados e informações é tão importante no mundo atual?',
  'game.knowledge.superior.questions.3.option.1':
    'Porque selecionar e analisar as informações disponíveis ajuda a tomar decisões de forma mais eficiente',
  'game.knowledge.superior.questions.3.option.1.feedback':
    'Boa! Você já sabe que tomar decisões simplesmente na base da intuição não é o caminho mais eficiente. E isso vale na vida e no trabalho!',
  'game.knowledge.superior.questions.3.option.2':
    'Porque a análise de dados te ajuda a programar e desenvolver sites​',
  'game.knowledge.superior.questions.3.option.2.feedback':
    'Análise de dados é um termo técnico usado para descrever o ato de selecionar e analisar as informações disponíveis.',

  'game.knowledge.superior.questions.4':
    'Qual item abaixo não é uma extensão de formato de imagem?',
  'game.knowledge.superior.questions.4.option.1': '.png',
  'game.knowledge.superior.questions.4.option.1.feedback':
    'Estas extensões podem parecer estranhas, mas formam a base do nosso mundo digital. HTML não é um tipo de imagem.',
  'game.knowledge.superior.questions.4.option.2': '.html​',
  'game.knowledge.superior.questions.4.option.2.feedback':
    'Parabéns! Você sabe que o mundo está ficando cada vez mais digital e não quer ficar para trás!',

  'game.knowledge.superior.questions.5': 'O que é realidade virtual?',
  'game.knowledge.superior.questions.5.option.1':
    'Robôs e computadores controlados por máquinas.',
  'game.knowledge.superior.questions.5.option.1.feedback':
    'Resposta incorreta.Realidade virtual é um ambiente criado por computador, no qual o usuário pode interagir como se fosse um mundo real.',
  'game.knowledge.superior.questions.5.option.2':
    'Um mundo artificial e tridimensional criado por um computador.',
  'game.knowledge.superior.questions.5.option.2.feedback':
    'Resposta Correta. Realidade virtual é um ambiente criado por computador, no qual o usuário pode interagir como se fosse um mundo real.',

  'game.knowledge.superior.questions.6':
    'Inteligência artificial é um ramo da ciência da computação que foca na:',
  'game.knowledge.superior.questions.6.option.1':
    'Construção de computadores e máquinas capazes de simular comportamento inteligente.',
  'game.knowledge.superior.questions.6.option.1.feedback':
    'Resposta correta! A inteligência artificial (IA) possibilita que máquinas aprendam com experiências e executem tarefas como seres humanos.',
  'game.knowledge.superior.questions.6.option.2':
    'Democratização do conhecimento sobre informática.',
  'game.knowledge.superior.questions.6.option.2.feedback':
    'Resposta incorreta! Inteligência artificial é um ramo da ciência da computação que desenvolve máquinas  capazes de simular comportamento inteligente.',

  'game.knowledge.superior.questions.7':
    'A palavra inglesa NETWORKING é utilizada no Brasil para descrever:',
  'game.knowledge.superior.questions.7.option.1': 'A habilidade digital de uma pessoa.',
  'game.knowledge.superior.questions.7.option.1.feedback':
    'Resposta incorreta. Networking é o processo de interagir com pessoas para ampliar sua rede de relacionamento. ',
  'game.knowledge.superior.questions.7.option.2':
    'O processo de interagir com pessoas para trocar informações e desenvolver contatos pessoais ou profissionais.',
  'game.knowledge.superior.questions.7.option.2.feedback': 'Resposta correta!',
}
