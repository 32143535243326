export default {
  'signUp.registration': 'Register',
  'signUp.editAccount': 'Change user data',
  'signUp.personalData': 'Personal information',
  'signUp.name': 'Full name*',
  'signUp.namePlaceholder': 'Enter your full name',
  'signUp.email': 'E-mail*',
  'signUp.dateOfBirth': 'Date of birth*',
  'signUp.dateOfBirth.tooltip':
    'VUCA is a game suitable for children over the age of 11, this is why we record your date of birth.',
  'signUp.birthDate.day': 'Day',
  'signUp.birthDate.mounth': 'Month',
  'signUp.birthDate.mounth.january': 'Jan',
  'signUp.birthDate.mounth.february': 'Feb',
  'signUp.birthDate.mounth.march': 'Mar',
  'signUp.birthDate.mounth.april': 'Apr',
  'signUp.birthDate.mounth.may': 'May',
  'signUp.birthDate.mounth.june': 'Jun',
  'signUp.birthDate.mounth.july': 'Jul',
  'signUp.birthDate.mounth.august': 'Aug',
  'signUp.birthDate.mounth.september': 'Sept',
  'signUp.birthDate.mounth.october': 'Oct',
  'signUp.birthDate.mounth.november': 'Nov',
  'signUp.birthDate.mounth.december': 'Dec',
  'signUp.year': 'Year',
  'signUp.gender': 'Gender',
  'signUp.gender.feminine': 'Female',
  'signUp.gender.male': 'Male',
  'signUp.gender.notBinary': 'Non-binary',
  'signUp.gender.other': 'Other',
  'signUp.gender.preferNotToSay': "I'd rather not say",
  'signUp.tooltip':
    'These fields are optional. We do not need any data related to gender, race, city, location and education. We will only use this data for internal statistics purposes.',
  'signUp.ethnicity': 'Race',
  'signUp.ethnicity.white': 'White',
  'signUp.ethnicity.parde': 'Brown',
  'signUp.ethnicity.black': 'Black',
  'signUp.ethnicity.yellow': 'Asian',
  'signUp.ethnicity.indie': 'Native',
  'signUp.state': 'State',
  'signUp.city': 'City',
  'signUp.areYouStudying': 'Are you currently studying?',
  'signUp.accountSettings': 'Account settings',
  'signUp.termsSignUp': 'Terms of use and privacy',
  'signUp.agreeTerms': 'I agree with the',
  'signUp.acceptTerms':
    'To proceed, you must agree to the VUCA Game Terms of Use and Privacy.',
  'signUp.schooling': 'Education',
  'signUp.schooling.juniorStage': 'Elementary/High school',
  'signUp.schooling.middleStage': 'Upper middle school',
  'signUp.schooling.superiorStage': 'College',
  'Ensino Fundamental': 'Elementary/High school',
  'Ensino Médio': 'Upper middle school',
  'Ensino Superior': 'College',
  'signUp.schooling.year': 'School year',
  'signUp.schooling.year.stage': '{val} year',
  'signUp.schooling.year.period': '{val} period',
  'signUp.schooling.type': 'Type of school',
  'signUp.schooling.type.public': 'Public',
  'signUp.schooling.type.private': 'Private',
  'signUp.password.strenght': 'Password strength',
  'signUp.password.strenght.caracters':
    'Your password must have between 8 and 12 characters.',
  'signUp.password.strenght.upper': 'At least one uppercase letter.',
  'signUp.password.strenght.lower': 'At least one lowercase letter.',
  'signUp.password.strenght.number': 'At least one number.',
  'signUp.password.strenght.special': 'At least one symbol (! @ # $% ˆ &).',
  'signUp.password.create': 'Create password*',
  'signUp.password.new': 'New password',
  'signUp.password.confirm': 'Confirm the password',
  'signUp.password.weak': 'Weak',
  'signUp.password.moderate': 'Moderate',
  'signUp.password.strong': 'Strong',
  'signUp.confirmLeaveWithoutSave':
    'Some fields with important information have been edited.',
  'signUp.confirmLeaveWithoutSave.description':
    'If you do not save the changes, the edited data will be canceled and we will consider the data previously filled in .',
  'signUp.confirm.emailInfo':
    'You are changing your email to {email}. Are you sure you want to change your email?',
  'signUp.account.updateError': 'There was an error updating the data',
  'signUp.account.updateSuccess': 'Successfully changed data.',
}
