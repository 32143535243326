export default {
  'signUp.registration': 'Registrarse',
  'signUp.editAccount': 'Cambiar datos',
  'signUp.personalData': 'Datos personales',
  'signUp.name': 'Nombre*',
  'signUp.namePlaceholder': 'Intrpoduzca su nombre completo',
  'signUp.email': 'Correo electrónico*',
  'signUp.dateOfBirth': 'Fecha de nacimiento*',
  'signUp.dateOfBirth.tooltip':
    'El juego VUCA es adecuado a personas mayores de 11 años, por lo que almacenamos su fecha de nacimiento.',
  'signUp.birthDate.day': 'Día',
  'signUp.birthDate.mounth': 'Mes',
  'signUp.birthDate.mounth.january': 'Ene',
  'signUp.birthDate.mounth.february': 'Feb',
  'signUp.birthDate.mounth.march': 'Mar',
  'signUp.birthDate.mounth.april': 'Abr',
  'signUp.birthDate.mounth.may': 'May',
  'signUp.birthDate.mounth.june': 'Jun',
  'signUp.birthDate.mounth.july': 'Jul',
  'signUp.birthDate.mounth.august': 'Ago',
  'signUp.birthDate.mounth.september': 'Sep',
  'signUp.birthDate.mounth.october': 'Oct',
  'signUp.birthDate.mounth.november': 'Nov',
  'signUp.birthDate.mounth.december': 'Dic',
  'signUp.year': 'Año',
  'signUp.gender': 'Género',
  'signUp.gender.feminine': 'Femenino',
  'signUp.gender.male': 'Masculino',
  'signUp.gender.notBinary': 'No binario',
  'signUp.gender.other': 'Otro',
  'signUp.gender.preferNotToSay': 'Prefiero no decirlo',
  'signUp.tooltip':
    'Datos opcionales, no necesitamos datos sobre género, raza, ciudad, ubicación y educación, pero solicitamos estos datos para estadísticas internas.',
  'signUp.ethnicity': 'Raza',
  'signUp.ethnicity.white': 'Blanco',
  'signUp.ethnicity.parde': 'Pardo',
  'signUp.ethnicity.black': 'Negro',
  'signUp.ethnicity.yellow': 'Amarillo',
  'signUp.ethnicity.indie': 'Indigena',
  'signUp.state': 'Estado',
  'signUp.city': 'Ciudad',
  'signUp.areYouStudying': '¿Estudia actualmente?',
  'signUp.accountSettings': 'Configuraciones de cuenta',
  'signUp.termsSignUp': 'Términos de uso y privacidad',
  'signUp.agreeTerms': 'Estoy de acuerdo con los',
  'signUp.acceptTerms':
    'Para continuar, debe aceptar los Términos de uso y Privacidad del juego',
  'signUp.schooling': 'Educación',
  'signUp.schooling.juniorStage': 'Escuela Primaria/Secundaria',
  'signUp.schooling.middleStage': 'Medio Superior',
  'signUp.schooling.superiorStage': 'Universidad',
  'Ensino Fundamental': 'Escuela Primaria/Secundaria',
  'Ensino Médio': 'Medio Superior',
  'Ensino Superior': 'Universidad',
  'signUp.schooling.year': 'Año escolar',
  'signUp.schooling.year.stage': '{val} año',
  'signUp.schooling.year.period': '{val} período',
  'signUp.schooling.type': 'Tipo de escuela',
  'signUp.schooling.type.public': 'Pública',
  'signUp.schooling.type.private': 'Privada',
  'signUp.password.strenght': 'Seguridad de la contraseña',
  'signUp.password.strenght.caracters':
    'La contraseña debe tener un mínimo de 8 y un máximo de 12 caracteres.',
  'signUp.password.strenght.upper': 'Al menos una mayúscula.',
  'signUp.password.strenght.lower': 'Al menos una letra minúscula.',
  'signUp.password.strenght.number': 'Al menos un número.',
  'signUp.password.strenght.special': 'Al menos un carácter especial (! @ # $% ˆ &).',
  'signUp.password.create': 'Crear contraseña*',
  'signUp.password.new': 'Nueva contraseña',
  'signUp.password.confirm': 'Confirma la contraseña',
  'signUp.password.weak': 'Débiles',
  'signUp.password.moderate': 'Moderar',
  'signUp.password.strong': 'Fuerte',
  'signUp.confirmLeaveWithoutSave':
    'Se han editado algunos campos con información importante.',
  'signUp.confirmLeaveWithoutSave.description':
    'Si no guarda los cambios, los datos editados serán cancelados y consideraremos los datos previamente rellenados.',
  'signUp.confirm.emailInfo':
    'Está cambiando su correo electrónico a {email}. ¿Estás seguro de que deseas cambiar tu correo electrónico?',
  'signUp.account.updateError': 'Hubo un error al actualizar los datos.',
  'signUp.account.updateSuccess': 'Datos modificados con éxito.',
}
