export default {
  'game.challenge.junior.questions.1':
    '{name} tuvo un año difícil, con muchos problemas familiares y terminó fracasando en la escuela. Los problemas pueden afectar a cualquier persona, pero son parte de la vida. {name} dejó de ganar XP, pero ¡está listo para enfrentar el nuevo año enfocado y con determinación!',

  'game.challenge.junior.questions.2':
    'Un grupo de estudiantes criticó el cabello de {name} e ir a la escuela se convirtió en un drama.¿Y ahora? ¿Qué hacer?',
  'game.challenge.junior.questions.2.option.1':
    'Se mantiene firme y sin queja hasta que termine el año escolar.',
  'game.challenge.junior.questions.2.option.1.feedback':
    'Quedarse callado no ayudó en nada. El semestre fue bastante difícil y {name} ganó poco XP.',
  'game.challenge.junior.questions.2.option.2':
    'Comenta el problema con sus padres y con los directores de la escuela.',
  'game.challenge.junior.questions.2.option.2.feedback':
    '¡Felicitaciones por su coraje! ¡Excelente! La escuela ha descubierto que {name} no era la única víctima de este grupo de estudiantes. Gracias a su actitud, ¡la escuela ha organizado una campaña anti-bullying y el clima escolar ha mejorado para todos!',

  'game.challenge.junior.questions.3':
    'Los padres de {name} se divorciaron y quien pagaba las cuentas en la casa era el padre. Con la separación de ellos, {name} piensa si....',
  'game.challenge.junior.questions.3.option.1': 'Comienza a vender dulces con su mamá.',
  'game.challenge.junior.questions.3.option.1.feedback':
    '¿Situacion difícil, no? Esta será la primera experiencia profesional de {name}, y ahora tendrá que equilibrar sus estudios con las nuevas obligaciones para ganar XP y llegar al Portal Z.',
  'game.challenge.junior.questions.3.option.2':
    'Continua enfocado exlusivamente en los estudios, aún sabiendo que el presupuesto de la familia va a disminuir.​',
  'game.challenge.junior.questions.3.option.2.feedback':
    'La decisión fue difícil, pero {name} ha entendido que la falta de educación primaria limitó las opciones de su familia, entonces ha decidido enfocarse en los estudios para llegar más preparado al Portal Z.',

  'game.challenge.junior.questions.4':
    '{name} no esta consiguiendo seguir y entender el contenido de los cursos de matemática y castellano. {name} siente la necesidad de:',
  'game.challenge.junior.questions.4.option.1':
    'Dejar la escuela y volver el año entrante.',
  'game.challenge.junior.questions.4.option.1.feedback':
    'Desistir de los estudiso nunca es una buena opción. {name} sigue estudiante, pero siente dificultades.',
  'game.challenge.junior.questions.4.option.2': 'Hacer un esfuerzo extra y pedir ayuda.',
  'game.challenge.junior.questions.4.option.2.feedback':
    '¡Muy bien! Los esfuerzos de {name} no fueron suficientes para ser aprobad@en matemática, pero por otro lado {name} desarrolló su capacidad de resiliciencia y eso fue muy importante para otros desafíos que la vida le presente en el futuro.',

  'game.challenge.junior.questions.5':
    '{name} participó en una conversación en la que sus compañeros de escuela comentaban que la escuela es una pérdida de tiempo, y terminó dedicando poco tiempo a los estudios.',
  'game.challenge.junior.questions.5.feedback':
    'Ahora, {name} está teniendo dificultades para terminar las materias.',

  'game.challenge.junior.questions.6':
    'La escuela de {name} está promoviendo debates sobre emprendimiento y educación financiera como actividades extra curriculares.',
  'game.challenge.junior.questions.6.option.1': 'Se inscribe para participar.',
  'game.challenge.junior.questions.6.option.1.feedback':
    'A pesar de no disfrutar de estos temas, {name} participó de los debates y percibió que puede aprender y que le gusten de nuevos temas. ¡Genial!',
  'game.challenge.junior.questions.6.option.2': 'No disfruta de estos temas.',
  'game.challenge.junior.questions.6.option.2.feedback':
    '¡Vamos! Estos temas pueden ayudar bastante en la vida de {name}. ¿Quién sabe, quizás en la próxima oportunidad participe, cierto?',
}
