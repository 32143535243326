import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
`

export const BackgroundBlur = styled.div`
  background-color: #606060;
  height: 100vh;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;
`
export const ContentCard = styled.div`
  padding: 2rem;

  h1,
  p {
    letter-spacing: -0.05px;
  }

  h1 {
    font-family: MilkyNice;
    font-size: 1.28rem;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  p {
    font-family: Montserrat;
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1024px) {
    h1,
    p {
      text-align: center;
    }
  }
`

export const ContainerButton = styled.div`
  display: inline-block;
  text-align: right;
  width: 100%;
`
