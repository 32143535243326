import React from 'react'
import PropTypes from 'prop-types'

import { ButtonWithRadiusBorderComponent } from '../../../../components/Buttons/buttonWithRadiusBorder/'

import { ContainerButtons } from './styles'
import { goToGameClicked } from '../../../../services/analytics'
import { injectIntl } from 'react-intl'

const ContainerButtonsOfPlayNDownloadCp = ({ buttonPlayDescrition, history, intl }) => {
  const clickPlayGame = () => {
    history.push('/login')
    goToGameClicked(buttonPlayDescrition)
  }

  return (
    <ContainerButtons>
      <ButtonWithRadiusBorderComponent
        data-test="goToGameInSections"
        onClick={clickPlayGame}
        text={intl.formatMessage({ id: 'play' })}
      />
    </ContainerButtons>
  )
}

ContainerButtonsOfPlayNDownloadCp.propTypes = {
  buttonPlayDescrition: PropTypes.string,
  intl: PropTypes.object,
  history: PropTypes.object,
}

export const ContainerButtonsOfPlayNDownload = injectIntl(
  ContainerButtonsOfPlayNDownloadCp,
)
