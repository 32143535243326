export const set = (key, value) => localStorage.setItem(key, JSON.stringify(value))
export const clear = (key) => localStorage.setItem(key, null)
export const clearAll = () => localStorage.clear()
export function get(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (err) {
    return undefined
  }
}
