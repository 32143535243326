import { createGlobalStyle } from 'styled-components'

export const Animations = createGlobalStyle`
  .slide-in-bottom {
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  }

  .fade-in {
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in-half {
    animation: fade-in-half 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @keyframes fade-in-half {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }

  /* Delays */
  .anim-delay-half {
    animation-delay: 0.5s
  }

  .anim-delay-1s {
    animation-delay: 1s;
  }
`
