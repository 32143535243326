import styled from 'styled-components'

export const DiceContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
  margin: 0 0 1rem;

  /* position: absolute;
  left: 0;
  bottom: 0; */
`
