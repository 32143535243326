import gameChallenge from './game-challenge/'
import gameInspiration from './game-inspiration/'
import gameJob from './game-job/'
import gameKnowledge from './game-knowledge/'
import gameSkills from './game-skills/'

export default {
  'game.dice.role': 'Roll the dice to play',
  'game.shared': 'Share the game',
  'game.shared.description':
    'Do you like Game VUCA? Share with your friends via the link:',
  'game.leaveJob': 'Leave work',
  'game.leaveJob.description':
    'You will only be able to return to work when you fall into a new opportunity in the Situation house',
  'game.leaveJob.question': 'Are you sure you want to quit your job now?',
  'game.leaveJob.info':
    'From now on you can stop working, focus on your studies and think about your future.',
  'game.nowWorthHalfThePoints': 'are now worth half the points',
  'game.startJob.init': 'You have started working',
  'game.startJob':
    '{name} has entered the labor market, so {name} will have to balance education with professional life now.',
  'game.startJob.tooltip': '{name} is working!',
  'game.theTilesOf': 'The houses of',
  'game.question.empty': ' ',
  'game.success.nextStage.middle': 'You are going to middle school!',
  'game.success.nextStage.middle.message':
    '{name} has graduated from elementary school and taken the first steps towards Portal Z. Are you ready to go on with the journey?',
  'game.success.nextStage.superior': 'You are going to college!',
  'game.success.nextStage.superior.message':
    'College is a hard journey, full of highs and lows, and {name} made it up to here. Congratulations! A little more and you will have reached the final goal!',
  'game.success.nextStage.superior.finish': 'Você terminou o Ensino Superior!',
  'game.success.nextStage.superior.finish.message':
    '{name} has finished the last stage of the educational journey! Congratulations! Your participation was critical for this achievement and you made it up to here together!',
  'game.start.newgame': 'Shall we start a new game?',
  'game.toEnd': 'End',
  'game.toThank': 'Thank you for your participation.',
  'game.playerWin': '{name} has reached Portal Z!  ',
  'game.playerWin.description':
    'Portal Z will open the doors to big and new opportunities. You may go on. After Portal Z, there are other portals with infinite possibilities, your journey has only just begun. Have fun!',
  'game.player.LoserFist': '{name} did not gain <br /> {maxPoints} on time.',
  'game.player.LoserSecond': 'Do not feel bad, you may try again.',
  'game.player.LoserThird': 'Well? Do you accept the challenge?',
  'game.player.again': 'Play again',
  ...gameChallenge,
  ...gameInspiration,
  ...gameJob,
  ...gameKnowledge,
  ...gameSkills,
}
