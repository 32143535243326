export default {
  'landingpage.menu.init': 'Início',
  'landingpage.menu.about': 'Sobre a JA',
  'landingpage.choiceYourCharacter': 'Escolha seu personagem',
  'landingpage.jobXStudies': 'Trabalho x Estudos',
  'landingpage.acceptChallenge': 'Aceita o desafio?',
  'landingpage.copyright': 'Game VUCA Ⓒ. Todos os direitos reservados.',
  'landingpage.welcome': 'Bem-vindo(a) ao',
  'landingpage.youGoTo': 'Você chegou ao',
  'landingpage.introParagraphs.1':
    'Cris, Milena, Brenda e Jonatan estão em um período decisivo e precisam pensar no futuro.',
  'landingpage.introParagraphs.2':
    'Ajude essa galera a se formar na escola e a decidir o seu futuro chegando até o Portal Z: O portal das oportunidades.',
  'landingpage.introParagraphs.3':
    'Enfrente dilemas, adversidades, conte com um pouco de inspiração para ganhar esse desafio.',
  'landingpage.introParagraphs.4': ':) Vamos lá?',
  'landingpage.card.1':
    'Escola, trabalho, família, tudo isso faz parte do mundo VUCA (volátil, incerto, complexo e ambíguo). Um mundo cheio de incertezas em que tudo pode mudar de uma hora pra outra e não existe certo ou errado. Lide com desafios reais, teste seus conhecimentos e a sua sorte. VUCA significa volátil, incerto, complexo e ambíguo.',
  'landingpage.card.2':
    'Cada pessoa tem sua história, assim como os quatro personagens desse jogo. Eles vivem em um mundo hiperconectado, acelerado e se sentem confusos e inseguros em relação ao seu futuro. Ajude um deles a alcançar sua melhor versão utilizando seu poder de decisão, pensamento crítico e conhecimentos gerais. Muitos desafios virão, bem vindos ao mundo VUCA!',
  'landingpage.card.3':
    'Trabalhar e ganhar dinheiro agora ou estudar e ter uma profissão no futuro? Enfrente os desafios do mercado de trabalho e tome suas próprias decisões.',
}
