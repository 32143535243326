import styled, { css } from 'styled-components'

import { setColorOfButton } from './validateColorForButton'

export const SelectContainer = styled.div`
  ${setColorOfButton};
  ${({ theme, withoutMarginTop }) => css`
    cursor: pointer;
    border: solid 0.2rem ${theme.colors.black};
    border-radius: 2rem;
    box-shadow: -2.5px 3px rgb(29, 29, 27);
    color: black;
    font-family: MilkyNice;
    padding: 0.5rem;
    margin-top: ${withoutMarginTop ? `0px` : `30px`};
    text-decoration: none;
    position: relative;
    text-align: center;

    .current-opt {
      position: relative;
      text-align: center;
    }

    ul {
      margin: 5px 0;
      position: relative;
      left: -3px;
      min-width: 160px;
      text-align: left;

      li {
        padding: 10px 20px;

        &:hover,
        &.opt-active {
          color: ${theme.colors.azure};
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      padding: 0.7rem;
      min-width: 60px;
    }

    @media screen and (max-width: 1024px) {
      margin-top: ${withoutMarginTop ? `5px` : `20px`};

      .current-opt {
        left: -1px;
      }

      ul {
        li {
          padding: 10px 20px;

          &:last-child {
            padding-bottom: 10px;
          }
        }
      }
    }

    &:focus,
    &:hover {
      background: none;
      background-color: ${theme.colors.orangeButtonFocused};
    }

    &.active {
      top: 10px;
      align-self: end;
      ${setColorOfButton};
    }

    &:disabled {
      background: none;
      background-color: ${theme.colors.veryLightPink};
      border: solid 0.2rem ${theme.colors.brownGrey};
      box-shadow: -2.5px 3px ${theme.colors.brownGrey};
      color: ${theme.colors.brownGrey};
    }

    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
    `}
  `}
`
