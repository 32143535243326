import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { BackgroundBlur, Container } from '../GameCards/styles'
import { CardWithHeader } from '../Cards'

import { DesktopRules } from './desktopRules'
import { MobileRules } from './mobileRules'
import { RulesContentCard } from './styles'

export const RulesCardCp = ({ onStartButtonClick, intl }) => (
  <>
    <Container>
      <CardWithHeader
        titleCard={intl.formatMessage({ id: 'introduction.title' })}
        backgroundColorHeader="pistachioGreen"
        withCloseButton={true}
        handleClickCloseButton={onStartButtonClick}
        data_test="RulesCard">
        <RulesContentCard>
          <DesktopRules onStartButtonClick={onStartButtonClick} />
          <MobileRules onStartButtonClick={onStartButtonClick} />
        </RulesContentCard>
      </CardWithHeader>
    </Container>
    <BackgroundBlur />
  </>
)

RulesCardCp.propTypes = {
  onStartButtonClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const RulesCard = injectIntl(RulesCardCp)
