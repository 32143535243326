import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useStore } from '../store/hooks'
import { get } from '../services/storage'
import { setToken } from '../services/API'

export default function RouteWrapper({ component: Component, isPrivate, ...rest }) {
  const { store } = useStore()
  const { user_details: userContext } = store
  const { path } = rest

  const token = get('token')
  const userStorage = get('user')
  const userLogged = userStorage || userContext?.name

  if (!userLogged && isPrivate) {
    return <Redirect to="/" />
  } else if (userLogged && (path === '/login' || path === '/sign-up')) {
    return <Redirect to="/home" />
  }

  setToken(token)

  return <Route {...rest} component={Component} />
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}
