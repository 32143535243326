import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { isMobileSize } from '../../common/utils'
import { CardTerms } from '../../components/Cards'

import {
  IcDeleteAccount,
  IcEditAccount,
  IcEditPassword,
  IcMedalha,
  IcTermsPrivacity,
} from '../../assets/img/myAccount'

import { IcBack } from '../../assets/img'

import { SignUpCard } from '../../pages/SignUp/SignUpCard'

import { DeleteAccountCard } from './components/deleteAccountCard'
import { ChangePasswordCard } from './components/changePassword'

import {
  Container,
  AccountBody,
  Title,
  ButtonAccountContainer,
  ButtonAccount,
  SubItem,
  PopButton,
} from './styles'

import { Background } from '../../styles/backgroud'
import { get } from '../../services/storage'
import { CircularButtonComponent } from '../Buttons/circularButton'
import { CardMedals } from './components/medalsCard'
import { CardMedal } from './components/medalCard'
import { injectIntl, FormattedMessage } from 'react-intl'

export const AccountCp = ({ closeAccount, intl }) => {
  const isMobile = useMemo(isMobileSize, [])

  const [showEditAccount, setShowEditAccount] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [showMedals, setShowMedals] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showCardMedal, setShowCardMedal] = useState(false)

  const [currentMedal, setCurrentMedal] = useState({
    text: '',
    data_test: '',
    Icon: '',
    date: '',
    username: '',
  })

  const hasSomeCardOpened = useMemo(isSomeCardOpen, [
    showChangePassword,
    showEditAccount,
    showDeleteAccount,
    showTerms,
    showMedals,
    showCardMedal,
  ])

  function handleCardMedalClick(medal) {
    if (medal) setCurrentMedal(medal)

    setShowCardMedal(!showCardMedal)
    setShowMedals(!showMedals)
  }

  function isSomeCardOpen() {
    return (
      showChangePassword ||
      showEditAccount ||
      showDeleteAccount ||
      showTerms ||
      showMedals ||
      showCardMedal
    )
  }

  function isFacebookUser() {
    return !!get('user')?.authentications
  }

  function handleShowMedalsClick() {
    setShowMedals(!showMedals)
  }

  const memoizedDataOfButton = useMemo(dataOfButtons, [])
  function dataOfButtons() {
    return [
      {
        text: intl.formatMessage({ id: 'account.medals.my' }),
        data_test: 'myAccountMyMedalsButton',
        Icon: IcMedalha,
        onClick: handleShowMedalsClick,
        hide: false,
      },
      {
        text: intl.formatMessage({ id: 'account.password.edit' }),
        data_test: 'myAccountEditPasswordButton',
        Icon: IcEditPassword,
        onClick: openChangePasswordCard,
        hide: isFacebookUser(),
      },
      {
        text: intl.formatMessage({ id: 'account.edit' }),
        data_test: 'myAccountEditButton',
        Icon: IcEditAccount,
        onClick: openSignUpCard,
        hide: false,
      },
      {
        text: intl.formatMessage({ id: 'account.terms' }),
        data_test: 'myAccountSeeTermsButton',
        Icon: IcTermsPrivacity,
        onClick: openCardTerms,
        hide: false,
      },
      {
        text: intl.formatMessage({ id: 'account.delete' }),
        data_test: 'myAccountDeleteAccountButton',
        Icon: IcDeleteAccount,
        onClick: openCardDeleteAccount,
        hide: false,
      },
    ]
  }

  const buttonMobile = (Icon) => {
    return (
      <PopButton>
        <CircularButtonComponent
          to="#"
          id="mobileMenuButton"
          color="white"
          data_test="menuButtonMobile"
          onClick={closeCards}>
          <Icon width="15" height="15" />
        </CircularButtonComponent>
      </PopButton>
    )
  }

  function closeCards() {
    closeChangePasswordCard()
    closeCardDeleteAccount()
    closeCardTerms()
    closeSignUpCard()
  }

  function closeChangePasswordCard() {
    setShowChangePassword(false)
  }

  function openChangePasswordCard() {
    setShowChangePassword(true)
  }

  function closeCardDeleteAccount(deleted) {
    if (deleted) {
      closeAccount(true)
    }

    setShowDeleteAccount(false)
  }

  function openCardDeleteAccount() {
    setShowDeleteAccount(true)
  }

  function closeCardTerms() {
    setShowTerms(false)
  }

  function openCardTerms() {
    setShowTerms(true)
  }

  function closeSignUpCard() {
    setShowEditAccount(false)
  }

  function openSignUpCard() {
    setShowEditAccount(true)
  }

  return (
    <>
      {hasSomeCardOpened && (
        <Background isMobile={isMobile}>
          {isMobile && buttonMobile(IcBack)}

          {showChangePassword && (
            <ChangePasswordCard closeCardChangePassword={closeChangePasswordCard} />
          )}

          {showEditAccount && (
            <SignUpCard isEdit={true} closeSignUpCard={closeSignUpCard} />
          )}

          {showDeleteAccount && (
            <DeleteAccountCard closeCardDeleteAccount={closeCardDeleteAccount} />
          )}

          {showTerms && <CardTerms closeCardTerms={closeCardTerms} />}

          {showMedals && (
            <CardMedals
              closeMedalsCard={handleShowMedalsClick}
              handleCardMedalClick={handleCardMedalClick}
            />
          )}

          {showCardMedal && (
            <CardMedal
              closeMedalCard={handleCardMedalClick}
              currentMedal={currentMedal}
              Icon={currentMedal.Icon}
            />
          )}
        </Background>
      )}

      {isMobile &&
        memoizedDataOfButton.map(
          ({ text, data_test, hide, onClick }) =>
            !hide && (
              <SubItem key={text} onClick={onClick || (() => {})} data-test={data_test}>
                {text}
              </SubItem>
            ),
        )}

      {!isMobile && (
        <Container>
          <AccountBody>
            <Title data-test="MyCount">
              <FormattedMessage id="account.my" />
            </Title>
            <ButtonAccountContainer>
              {memoizedDataOfButton.map(
                ({ text, data_test, Icon, onClick, hide }) =>
                  !hide && (
                    <ButtonAccount
                      data-test={data_test}
                      className="myAccountItem"
                      type="button"
                      onClick={onClick || (() => {})}
                      key={text}>
                      <Icon className="iconButton" />
                      <p className="labelButton">{text}</p>
                    </ButtonAccount>
                  ),
              )}
            </ButtonAccountContainer>
          </AccountBody>
        </Container>
      )}
    </>
  )
}

AccountCp.propTypes = {
  closeAccount: PropTypes.func,
  intl: PropTypes.object,
}

AccountCp.defaultProps = {
  closeAccount: () => {},
}

export const Account = injectIntl(AccountCp)
