import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'
import { Container, BackgroundBlur, ContentCard, ContainerButton } from './styles'

import { Footer } from '../../pages/ChoiceCharacter/CharactersCard/components/cardFooter'

import { useContentOfTextOnUnderstandingCards } from './useContentOfTextOnUnderstandingCards'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

const CardUnderstandingCp = ({ categoryName, closeUnderstandingCard, intl }) => {
  const { title, message, Icon } = useContentOfTextOnUnderstandingCards(categoryName)

  return (
    <>
      <Container data-test="containerUnderstandingCard">
        <CardWithHeader
          className="slide-in-bottom anim-delay-half"
          isSmall
          backgroundColorHeader="pistachioGreen"
          titleCard={intl.formatMessage({ id: title })}
          data_test="understandingCard">
          <ContentCard>
            <figure>
              <Icon
                width="100%"
                height="7rem"
                data-test="iconCategoryUnderstandingCards"
              />
            </figure>

            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: message }),
                }}
                data-test="paragraphUnderstandingCard"
              />

              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  onClick={closeUnderstandingCard}
                  data_test="buttonUnderstandingCard"
                  text={intl.formatMessage({ id: 'continue' })}
                />
              </ContainerButton>
            </section>
          </ContentCard>
          <Footer />
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardUnderstandingCp.propTypes = {
  categoryName: PropTypes.string.isRequired,
  closeUnderstandingCard: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const CardUnderstanding = injectIntl(CardUnderstandingCp)
