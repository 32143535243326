export default {
  'game.job.superior.questions.1':
    '{name} recibió la tarea de crear una presentación para el lanzamiento de un nuevo producto. ¡Usó su creatividad y sorprendió a la empresa! Afortunadamente tenía las habilidades para llevar a cabo la tarea, ¿verdad?',
  'game.job.superior.questions.1.feedback':
    '¡{name} recibe y avanza con el siguiente movimiento!',

  'game.job.superior.questions.2':
    '{name} está en período de prueba y recibe una propuesta, fuera de su área de interés, pero para trabajar a tiempo completo. ¿Qué decide hacer?',
  'game.job.superior.questions.2.option.1':
    'Continuar en período de prueba, ganando solo la beca asociada.',
  'game.job.superior.questions.2.option.1.feedback':
    'Difícil decidir, ¿verdad? {name} prefiere continuar en su área de interés y tener más tiempo para el estudio.',
  'game.job.superior.questions.2.option.2':
    'Aceptar el empleo, ganando un salario completo.',
  'game.job.superior.questions.2.option.2.feedback':
    'Difícil decidir, ¿verdad?. A pesar de ganar bien, el trabajo disminuye su tiempo de estudio.',

  'game.job.superior.questions.3':
    '{name} necesita mejorar sus ingresos para poder mudarse y vivir más cerca de la universidad. ¿Qué puede hacer?',
  'game.job.superior.questions.3.option.1':
    'Conversar con su jefe sobre la posibilidad de un aumento de salario.',
  'game.job.superior.questions.3.option.1.feedback':
    '{name} intentó negociar, pero lamentablemente el jefe no tenía presupuesto disponible para un aumento de salario. {name} aprendió sobre negociación y resiliencia.',
  'game.job.superior.questions.3.option.2':
    'Vender sandwiches para tener un ingreso extra.',
  'game.job.superior.questions.3.option.2.feedback':
    'Esa es una buena idea para tener ingresos extra, pero {name} tendrá que esforzarse para no perjudicar sus estudios.',

  'game.job.superior.questions.4':
    'La empresa en la cual {name} trabaja hizo un sorteo y ¡{name} ganó una beca para un curso de especialización!',
  'game.job.superior.questions.4.feedback':
    '¡{name} lo recibe y pasemos a la siguiente letra!',

  'game.job.superior.questions.5':
    '{name} está en semana de exámenes en la Universidad y también está con muchas demandas en el trabajo. Esta semana {name} no consiguió dormir ni alimentarse correctamente.',
  'game.job.superior.questions.5.feedback':
    '¡{name} lo recibe y pasemos a la siguiente letra!',

  'game.job.superior.questions.6':
    'Un cliente internacional visitará la empresa este mes y {name} recibió una invitación para ser parte de la reunión.  Sin embargo, no se siente cómodo de participar siendo la misma en otro idioma. ¿Qué hacer?',
  'game.job.superior.questions.6.option.1': 'Participar de la reunión.',
  'game.job.superior.questions.6.option.1.feedback':
    '{name} conversó con su lider y participó como oyente de la reunión. Aunque no comprendió el 100% de los temas tratados, esa experiencia hizo a {name} valorar más las clases de cursos de idioma extranjero.',
  'game.job.superior.questions.6.option.2':
    'Faltar el día de la reunión para no pasar vergüenza.',
  'game.job.superior.questions.6.option.2.feedback':
    'Todos notaron la ausencia de {name} en la reunión y su ausencia injustificada hizo que el lider perdiera confianza en su trabajo.',
}
