export default {
  'introduction.title': 'You have arrived at the world of VUCA',
  'introduction.firstDescription':
    '<span>Chris</span>, <span>Milena</span>, <span>Brenda</span> and <span>Jonathan</span> are going through a turning point: <span>Elementary school</span> is about to start, along with new challenges of the <span>world of VUCA</span>. An amazing place, full of surprises.',
  'introduction.secondDescription':
    'Help them go through the three stages: <span>elementary, middle and high school</span> in order to win 1000 experience points by reaching <span>Portal Z: the portal of opportunities</span>, where they can begin their dreamed career!',
  'introduction.thirdDescription':
    'But do not think this will be easy. <span>Unexpected issues may arise at home at any moment, so you will have to rely on your luck and your knowledge</span> to gain experience points. Shall we begin?',
  'introduction.init': 'How to play',
  'introduction.init.description':
    'Roll the dice to begin. You will move forward according to the number you roll and you may get one of five boxes: <span> Work, Quiz, Skills, Scenario and Inspiration. </span>',
}
