export default {
  'game.knowledge.middle.questions.1':
    'Qual destes itens não caracteriza um sistema democrático?',
  'game.knowledge.middle.questions.1.option.1': 'Acesso à informação.',
  'game.knowledge.middle.questions.1.option.1.feedback':
    'Um sistema democrático não proíbe manifestações políticas. Democracia significa liberdade de expressão, eleições livres, liberdade de associação política e acesso à informação.',
  'game.knowledge.middle.questions.1.option.2': 'Proibição de manifestações políticas.​',
  'game.knowledge.middle.questions.1.option.2.feedback':
    'Boa! {name} já sabe que democracia tem a ver com liberdade.',

  'game.knowledge.middle.questions.2':
    'Qual melhor estratégia para construir um argumento?',
  'game.knowledge.middle.questions.2.option.1':
    'Recorrer a dados concretos e fazer comparações para justificar seu ponto de vista.',
  'game.knowledge.middle.questions.2.option.1.feedback':
    'Boa! Uma boa argumentação é feita de fatos, não de gritos!',
  'game.knowledge.middle.questions.2.option.2':
    'Falar firme e atacar o posicionamento contrário.​',
  'game.knowledge.middle.questions.2.option.2.feedback':
    'Não dá para convencer outra pessoa na base do grito e do ataque. Argumentar é apresentar dados e ideias que sustentem uma opinião.',

  'game.knowledge.middle.questions.3':
    'Qual dos itens abaixo não representa uma reação química?',
  'game.knowledge.middle.questions.3.option.1': 'Fritar um ovo',
  'game.knowledge.middle.questions.3.option.1.feedback':
    'Ops! A resposta certa é a outra opção, pois a água e o óleo não se misturam.',
  'game.knowledge.middle.questions.3.option.2': 'Misturar água com óleo.',
  'game.knowledge.middle.questions.3.option.2.feedback':
    'Boa! Misturar esses dois elementos não representa uma reação química.',

  'game.knowledge.middle.questions.4':
    'Imagine que Maria ganha R$ 1.200 por mês de salário. Seu orçamento inclui R$ 450,00 reais de aluguel, R$ 300,00 de alimentação e R$ 100,00 de contas de luz e água. Quanto sobra do seu orçamento mensal para as demais despesas da família?',
  'game.knowledge.middle.questions.4.option.1': 'R$ 350,00',
  'game.knowledge.middle.questions.4.option.1.feedback':
    'Resposta certa. Calcular corretamente o orçamento familiar pode evitar uma bela dor de cabeça!',
  'game.knowledge.middle.questions.4.option.2': 'R$ 250,00',
  'game.knowledge.middle.questions.4.option.2.feedback':
    'Esta conta não está fechando! A resposta certa é R$ 350,00. Calcular corretamente o orçamento familiar pode evitar uma bela dor de cabeça!',

  'game.knowledge.middle.questions.5':
    'Os antibióticos são medicamentos utilizados para combater infecções causadas por..',
  'game.knowledge.middle.questions.5.option.1': 'Bactéria.',
  'game.knowledge.middle.questions.5.option.1.feedback': 'Boa! Resposta certa.',
  'game.knowledge.middle.questions.5.option.2': 'Vírus.',
  'game.knowledge.middle.questions.5.option.2.feedback':
    'Deu ruim! Os antibióticos são medicamentos que combatem as bactérias, não os vírus.',

  'game.knowledge.middle.questions.6':
    'Cinco bolas numeradas de 1 a 5 são colocadas em uma urna. A probabilidade de sortear um número par é de: ',
  'game.knowledge.middle.questions.6.option.1': '20%',
  'game.knowledge.middle.questions.6.option.1.feedback': 'Ops! A resposta correta é 40%',
  'game.knowledge.middle.questions.6.option.2': '40%',
  'game.knowledge.middle.questions.6.option.2.feedback': 'Resposta correta!',

  'game.knowledge.middle.questions.7': 'Qual frase está correta?',
  'game.knowledge.middle.questions.7.option.1': 'Tinha muitas pessoas no jogo.',
  'game.knowledge.middle.questions.7.option.1.feedback':
    'Resposta correta. Quando o verbo TER é usado com o significado de EXISTIR, ele é conjugado na 3ª pessoa do singular.',
  'game.knowledge.middle.questions.7.option.2': 'Tinham muitas pessoas no jogo.',
  'game.knowledge.middle.questions.7.option.2.feedback':
    'Resposta errada. Quando o verbo TER é usado com o significado de EXISTIR, ele é conjugado na 3ª pessoa do singular: “Tinha muitas pessoas” ',

  'game.knowledge.middle.questions.8':
    'O indiano Mohandas Gandhi ficou famoso mundialmente por: ',
  'game.knowledge.middle.questions.8.option.1':
    'Liderar um movimento não violento pela independência da Índia.',
  'game.knowledge.middle.questions.8.option.1.feedback': 'Resposta correta!',
  'game.knowledge.middle.questions.8.option.2': 'Iniciar uma guerra mundial.',
  'game.knowledge.middle.questions.8.option.2.feedback':
    'Resposta incorreta. Gandhi liderou o movimento de independência da Índia e ficou conhecido pela sua forma de manifestação pacífica.',
}
