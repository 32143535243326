import styled from 'styled-components'

export const CardContent = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  svg {
    display: flex;
    width: 100%;
    align-items: center;
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`
