import styled from 'styled-components'

export const DiceContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
  width: 100%;

  .text-after {
    width: 142px;
    position: absolute;
    right: -245px;
    top: 28px;
    font-family: 'MilkyNice';
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`
