import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};

  @media screen and (min-width: 1024px) {
    gap: 1em;
  }
`
