import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
`

export const BackgroundBlur = styled.div`
  background-color: #606060;
  height: 100vh;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;
`
export const ContentCard = styled.div`
  padding: 16px;

  h1,
  p {
    letter-spacing: -0.05px;
  }

  h1 {
    font-family: MilkyNice;
    font-size: 1.28rem;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  p {
    font-family: Montserrat;
    font-size: 1.1rem 1.42rem;
  }

  @media screen and (min-width: 1024px) {
    h1 {
      text-align: center;
    }
  }

  ${({ playerWin }) =>
    playerWin &&
    css`
      padding: 1rem;
    `}
`
export const Figure = styled.figure`
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: 0 0 1rem;
`

export const Paragraphs = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: -0.06px;

  ${({ withSpace }) =>
    withSpace &&
    css`
      margin: 0 0 1rem;
    `}
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

// export const ContainerButtons = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 10px;

//   @media screen and (max-width: 1024px) {
//     flex-direction: column;

//     button {
//       width: 100%;
//     }
//   }
// `
export const Flag = styled.picture`
  position: relative;
  top: -21px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    svg {
      width: 280px;
    }
  }
`

export const Title = styled.div`
  position: relative;
  font-weight: bold;
  top: -69px;
  display: flex;
  font-family: MilkyNice;
  font-size: 18px;
  height: 0;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`

export const IconPlayWin = styled.div`
  @media screen and (max-width: 1024px) {
    svg {
      width: 191px;
      height: 85px;
    }
  }
`
