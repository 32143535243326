export default {
  'menu.login': 'Login',
  'menu.account': 'Cuenta',
  'menu.account.my': 'Mi cuenta',
  'menu.account.edit': 'Cambiar datos',
  'menu.account.delete': 'Eliminar cuenta',
  'menu.rules': 'Reglas',
  'menu.rules.game': 'Reglas del juego',
  'menu.password.edit': 'Cambiar contraseña',
  'menu.terms': 'Condiciones de uso y privacidad',
  'menu.exit': 'Salir',
  'menu.exit.game': 'Salir del juego',
  'menu.sound': 'Sonidos',
  'menu.sound.on': 'Encendido',
  'menu.sound.off': 'Apagado',
}
