import React from 'react'
import PropTypes from 'prop-types'

import { CardBody } from '../../styles'

import { FormSignUp } from './FormSignUp'

export function Body({ isEdit, isSignUpInsideGame, closeSignUpCard }) {
  return (
    <CardBody>
      <FormSignUp
        isEdit={isEdit}
        isSignUpInsideGame={isSignUpInsideGame}
        closeSignUpCard={closeSignUpCard}
      />
    </CardBody>
  )
}

Body.propTypes = {
  isEdit: PropTypes.bool,
  closeSignUpCard: PropTypes.func.isRequired,
  isSignUpInsideGame: PropTypes.bool,
}

Body.defaultProps = {
  isEdit: false,
  isSignUpInsideGame: false,
}
