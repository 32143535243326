import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  position: absolute;
  z-index: 1000;
`

export const BackgroundBlur = styled.div`
  background-color: #606060;
  height: 100vh;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;
`
export const ContentCard = styled.div`
  padding: 1.2rem 1.875rem;

  h1,
  p {
    letter-spacing: -0.05px;
  }

  h1 {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.05px;
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    font-family: Montserrat;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: -0.06px;
  }
`

export const ContainerButton = styled.div`
  display: inline-block;
  text-align: right;
  width: 100%;
  margin: 2rem 0 0;
`
