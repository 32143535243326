export default {
  'game.job.junior.questions.1':
    'Hoje foi dia de feedback sobre seu trabalho. A mãe de {name} falou sobre suas habilidades no trabalho e fez algumas sugestões de melhoria.',
  'game.job.junior.questions.1.feedback':
    'Conhecer suas habilidades e fraquezas é peça chave para a {name} chegar ao Portal Z!',

  'game.job.junior.questions.2':
    '{name} visitou uma startup que desenvolve sites com a escola, conheceu toda a estrutura e descobriu profissões que não conhecia. Uhuul!!!',
  'game.job.junior.questions.2.feedback':
    '{name} aproveitou a oportunidade e ganhou mais XP.',

  'game.job.junior.questions.3':
    'A mãe de {name} recebeu uma encomenda grande de doces e vai precisar de ajuda extra no final de semana. Mas {name] tem muita coisa para preparar para a escola. O que resolve fazer?',
  'game.job.junior.questions.3.option.1': 'Fazer as tarefas rapidinho para ficar livre.',
  'game.job.junior.questions.3.option.1.feedback':
    'Conciliar trabalho e estudo é um desafio. {name} entregou as tarefas de qualquer jeito e não aprendeu nada. Se quiser chegar ao Portal Z {name} terá que se organizar!',
  'game.job.junior.questions.3.option.2':
    'Organizar seus horários para terminar as tarefas da escola durante a semana.',
  'game.job.junior.questions.3.option.2.feedback':
    '{name} cortou TV e celular durante a semana e focou nas tarefas da escola. Foi uma semana pesada, mas {name} conseguiu ajudar sua mãe e ficou com aquela sensação de dever cumprido!',

  'game.job.junior.questions.4':
    '{name} não prestou atenção na receita que sua mãe lhe ensinou e estragou uma leva de doces.',
  'game.job.junior.questions.4.option.1': 'Mais atenção na próxima!',

  'game.job.junior.questions.5':
    'Um dos clientes não pagou no dia combinado pois está sem dinheiro. E agora?',
  'game.job.junior.questions.5.option.1':
    'Assume o prejuízo, porém não vende mais para esse cliente.',
  'game.job.junior.questions.5.option.1.feedback':
    'Uma pena, mas isso pode acontecer com todo mundo. {name} poderia ter tentado negociar antes de cortar a relação com o cliente.',
  'game.job.junior.questions.5.option.2': 'Negocia com o cliente para receber parcelado.',
  'game.job.junior.questions.5.option.2.feedback':
    'Sua iniciativa garantiu o recebimento do pedido e ainda manteve a boa relação com o cliente!',

  'game.job.junior.questions.6':
    'Um colega da escola também começou a vender doces e agora {name} tem um concorrente. Como lidar com a situação?',
  'game.job.junior.questions.6.option.1':
    'Combina com o colega de vender doces diferentes. ',
  'game.job.junior.questions.6.option.1.feedback':
    'Boa escolha! Agora toda a escola tem mais opções para comprar e {name} garantiu a clientela.',
  'game.job.junior.questions.6.option.2':
    'Espalha boatos negativos sobre os doces do colega.',
  'game.job.junior.questions.6.option.2.feedback':
    'Poxa! As pessoas perceberam que isso não era verdade e as vendas de doces de {name} caíram.',

  'game.job.junior.questions.7':
    'Não está trabalhando. Aproveite para estudar e curtir os desafios dessa fase!',
}
