import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '..'
import {
  ConfirmationButtonContainer,
  Paragraph,
  SimpleButton,
  Flag,
  Title,
} from './styles'
import {
  BackgroundBlur,
  ContentCard,
  Container as CardContainer,
} from '../../GameCards/styles'

import { CardFlag } from '../../../assets/img'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

export const CardWithFlagCp = ({
  isOnlyInfo,
  onConfirmClick,
  onCancelClick,
  title,
  paragraph,
  confirmText,
  exitText,
  intl,
}) => {
  return (
    <>
      <CardContainer>
        <Card isSmall data_test="cardWithFlag">
          <Flag>
            <CardFlag />
          </Flag>
          <ContentCard data-test="cardWithFlagContent" style={{ paddingTop: '0px' }}>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Paragraph
              data-test="cardWithFlagParagraph"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
            <ConfirmationButtonContainer isOnlyInfo={isOnlyInfo}>
              {!isOnlyInfo && (
                <SimpleButton
                  data-test="cancelButton"
                  color="white"
                  type="button"
                  onClick={onCancelClick}>
                  {exitText || intl.formatMessage({ id: 'exit' })}
                </SimpleButton>
              )}

              <ButtonWithRadiusBorderComponent
                data_test="confirmButton"
                type="button"
                onClick={onConfirmClick}
                text={confirmText}
              />
            </ConfirmationButtonContainer>
          </ContentCard>
        </Card>
      </CardContainer>
      <BackgroundBlur />
    </>
  )
}

CardWithFlagCp.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  isOnlyInfo: PropTypes.bool,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  exitText: PropTypes.string,
  intl: PropTypes.object,
}

CardWithFlagCp.defaultProps = {
  onCancelClick: () => {},
  isOnlyInfo: false,
}

export const CardWithFlag = injectIntl(CardWithFlagCp)
