import styled, { css } from 'styled-components'

export const ContainerFooter = styled.footer`
  img {
    height: 34px;
  }

  @media screen and (min-width: 1024px) {
    img {
      height: 52px;
    }
  }
`

export const ContentWrapper = styled.div`
  padding: 0 0 5rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 0 0 8.75rem;
  }
`

export const CompaniesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1.5rem;
  margin: 0 0 3.125rem;

  @media screen and (min-width: 1024px) {
    margin: 0;
    gap: 3.125rem;
  }
`

export const CopyrightSection = styled.section``

const informationCss = css`
  display: block;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: bolder;
  margin: 1.25rem 0;
  letter-spacing: -0.06px;
  text-align: right;
`

export const InformationLink = styled.a`
  ${informationCss};
  color: #5a5a5a;
  text-decoration: none;
`

export const InformationText = styled.p`
  ${informationCss};

  color: #a8a8a8;
`
