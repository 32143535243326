export default {
  'game.job.middle.questions.1':
    '{name} had to write a report about their job life and send it via e-mail. {name} left this until the last minute and forgot to write down some activities. That is too bad!',
  'game.job.middle.questions.1.feedback': ' ',

  'game.job.middle.questions.2':
    '{name} changed of position at the job and has questions about several activities. What should {name} do?',
  'game.job.middle.questions.2.option.1': 'Face the challenge and learn from practice.',
  'game.job.middle.questions.2.option.1.feedback': 'Ask for help to other colleagues.',
  'game.job.middle.questions.2.option.2':
    'Learning from practice is very important, but some mistakes could be avoided by asking other colleagues for help. This way, everyone learns.',
  'game.job.middle.questions.2.option.2.feedback':
    'That is correct! Talking to people and asking for help shows that {name} has a will to learn.',

  'game.job.middle.questions.3':
    '{name} needs to go to the doctor early in the morning, but {name} will be late for work. What should {name} do?',
  'game.job.middle.questions.3.option.1': 'Let their boss know in advance.',
  'game.job.middle.questions.3.option.1.feedback':
    "{name}'s team organizes to cover their hours and everything is in order. You get XP if you thought about your team.",
  'game.job.middle.questions.3.option.2':
    '{name} asks the doctor for a certificate to justify being late.',
  'game.job.middle.questions.3.option.2.feedback':
    '{name} did not let the boss know and let everything in the hands of the team.',

  'game.job.middle.questions.4':
    "{name} is doing very well and {name}'s performance in the workplace is ideal! The manager admires {name}'s effort and will reward {name} with an additional bonus this month!",
  'game.job.middle.questions.4.feedback': ' ',

  'game.job.middle.questions.5':
    'Sales are at a low point in the company and {name} wants to create a website to help increase sales. But {name} does not know how to propose the idea, since that activity is another area’s responsibility. What should {name} do?',
  'game.job.middle.questions.5.option.1': 'Continue working and stay out of that.',
  'game.job.middle.questions.5.option.1.feedback':
    'A colleague talks about the idea of creating a website and it is very well received. {name} could have stood out if they had had more confidence.',
  'game.job.middle.questions.5.option.2': 'Share the idea with the team.',
  'game.job.middle.questions.5.option.2.feedback':
    'The managing team likes the idea very much and plans to implement it. Positive point for {name}, who stood out once again!',

  'game.job.middle.questions.6':
    'New people have been hired and {name} is helping them for the first weeks. The team trusts in{name}’s work!',
  'game.job.middle.questions.6.feedback': ' ',
}
