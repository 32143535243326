export default {
  'game.skills.junior.questions.1':
    'A professora organizou a turma em grupos e {name} terá que fazer o trabalho com colegas que não conhece. {name} resolve:​​',
  'game.skills.junior.questions.1.option.1':
    'Fazer o trabalho individualmente e enviar para o grupo.',
  'game.skills.junior.questions.1.option.1.feedback':
    '{name} perdeu uma ótima oportunidade de trabalhar em equipe. Esta é uma daquelas habilidades essenciais que não se aprende nos livros, mas sim praticando!',
  'game.skills.junior.questions.1.option.2':
    'Fazer uma reunião para organizar o trabalho.',
  'game.skills.junior.questions.1.option.2.feedback':
    'Boa iniciativa. {name} aprendeu muito sobre colaboração e organização. Apesar de algumas dificuldades iniciais, o trabalho ficou ótimo e {name} fez novos amigos.​',

  'game.skills.junior.questions.2':
    'A feira de ciências da escola é mês que vem. {name} resolve…​',
  'game.skills.junior.questions.2.option.1': 'Trabalhar na organização.',
  'game.skills.junior.questions.2.option.1.feedback':
    '{name} mostrou iniciativa e trabalhou pesado para organizar a feira! A lista de coisas que ela aprendeu nem cabe aqui. A feira foi um sucesso, e uma experiência para vida toda!',
  'game.skills.junior.questions.2.option.2': 'Visitar a feira com seus amigos.',
  'game.skills.junior.questions.2.option.2.feedback':
    '{name} não quis organizar, mas aproveitou a experiência e aprendeu mais sobre o mundo!',

  'game.skills.junior.questions.3':
    '{name} saiu confiante do teste de matemática, mas quando a professora entregou as notas descobriu que não tinha ido tão bem. Como reagir?',
  'game.skills.junior.questions.3.option.1':
    'Fica triste, rasga o teste e esquece o assunto.',
  'game.skills.junior.questions.3.option.1.feedback':
    '{name} perdeu uma ótima chance de aprender com os erros. Se tivesse revisado as questões teria percebido que seu problema foi a interpretação das questões. Que pena!',
  'game.skills.junior.questions.3.option.2':
    'Fica triste e procura a professora para falar sobre o teste.',
  'game.skills.junior.questions.3.option.2.feedback':
    '​Depois da conversa com a professora, {name} percebeu que não tinha lido as questões direito. Aprendeu com os erros e já sabe o que precisa melhorar para a próxima.',

  'game.skills.junior.questions.4':
    'O cachorro destruiu a maquete que {name} passou 1 mês preparando para a Feira de Inovação da escola. Faltam 24 horas para a apresentação, o que fazer?',
  'game.skills.junior.questions.4.option.1':
    'Cancelar sua participação e se preparar para a Feira do ano que vem.',
  'game.skills.junior.questions.4.option.1.feedback':
    'Pena, a ideia era perfeita e {name} poderia ter apresentado de outra forma. Flexibilidade e criatividade valem ouro no Mundo VUCA! ',
  'game.skills.junior.questions.4.option.2':
    'Adaptar a apresentação usando outros recursos.',
  'game.skills.junior.questions.4.option.2.feedback':
    '{name} usou desenhos e uma  bela história para apresentar sua ideia. Flexibilidade e criatividade para resolver problemas vão ajudar {name} a chegar ao Portal Z!',

  'game.skills.junior.questions.5':
    '{name} se elegeu como representante de turma, mas está com dificuldades de cumprir suas tarefas. Decide então:',
  'game.skills.junior.questions.5.option.1':
    'Respirar fundo e torcer para o ano terminar logo.',
  'game.skills.junior.questions.5.option.1.feedback':
    'Administrar o tempo é um dos grandes desafios do Mundo VUCA. Há muitas estratégias para isso, como priorizar as tarefas mais urgentes ou pedir ajuda.',
  'game.skills.junior.questions.5.option.2':
    'Escolher as prioridades e definir prazos para cada tarefa.',
  'game.skills.junior.questions.5.option.2.feedback':
    'Administrar o tempo é um dos grandes desafios do Mundo VUCA. Há muitas estratégias para isso, inclusive priorizar as tarefas mais urgentes ou pedir ajuda.',

  'game.skills.junior.questions.6':
    '{name} tem que escrever uma redação para a escola, mas não sabe por onde começar. {name} resolve:',
  'game.skills.junior.questions.6.option.1':
    'Pesquisar na Internet e copiar parte de um texto de um blog.',
  'game.skills.junior.questions.6.option.1.feedback':
    'Opa! Copiar ideias de outras pessoa sem crédito é plágio. E plágio é crime!',
  'game.skills.junior.questions.6.option.2':
    'Pesquisar sobre o tema na Internet para se inspirar.',
  'game.skills.junior.questions.6.option.2.feedback':
    'Boa! {name} levantou dados e encontrou referências para criar sua própria história.',
}
