import { junior } from './junior'
import { middle } from './middle'
import { superior } from './superior'

const defaults = {
  job: {
    category: 'job',
    question: 'game.job.junior.questions.7',
    type_question: 'luck_or_setback',
    options: [
      {
        response_text: 'game.question.empty',
        point: 10,
        feedback: 'game.question.empty',
      },
    ],
    complete: false,
    has_interaction: false,
  },
}

export const cards = {
  junior,
  middle,
  superior,
  defaults,
}
