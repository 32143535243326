import styled from 'styled-components'

import backgroundCloudsDesktop from '../../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Container = styled.div`
  background-image: url(${backgroundCloudsMobile});
  background-color: ${({ theme }) => theme.colors.veryLightBlue};
  object-fit: contain;
  width: 100%;
  padding: 1rem;
  background-size: 102%;
  background-position-x: -3px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundCloudsDesktop});
    height: 100vh;
    padding: 20px 200px;
  }
`
