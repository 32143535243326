import React from 'react'
import PropTypes from 'prop-types'

import { BorderCard } from './styles'

export const Card = ({ children, data_test, isSmall, className, minWidth, maxWidth }) => (
  <BorderCard
    className={className}
    isSmall={isSmall}
    maxWidth={maxWidth}
    minWidth={minWidth}
    data-test={data_test}>
    {children}
  </BorderCard>
)

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  data_test: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  className: PropTypes.string,
}

Card.defaultProps = {
  isSmall: false,
  maxWidth: '',
  minWidth: '',
}
