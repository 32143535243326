export default {
  'menu.login': 'Login',
  'menu.account': 'Conta',
  'menu.account.my': 'Minha Conta',
  'menu.account.edit': 'Alterar dados',
  'menu.account.delete': 'Excluir conta',
  'menu.rules': 'Regras',
  'menu.rules.game': 'Regras do Jogo',
  'menu.password.edit': 'Alterar senha',
  'menu.terms': 'Termos de Uso e Privacidade',
  'menu.exit': 'Sair',
  'menu.exit.game': 'Sair do jogo',
  'menu.sound': 'Sons',
  'menu.sound.on': 'Ligado',
  'menu.sound.off': 'Desligado',
}
