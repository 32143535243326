import { Api } from './index'

export async function getFeedbackForm(dataRequest) {
  try {
    const response = await Api.get('/feedback/form', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function setFeedback(dataRequest) {
  try {
    const response = await Api.post('/user/feedback', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}
