import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { EndGameAsset } from '../../assets/img/endGameCards/index.'
import { isMobileSize } from '../../common/utils'

import { Card } from '..'
import {
  BackgroundBlur,
  Container,
  ContentCard,
  Figure,
  Paragraphs,
  ContainerButtons,
  Title,
  Flag,
  IconPlayWin,
} from './styles'
import { CardFlagLarge, StarsPlayerWin } from '../../assets/img'

import SoundPlayWin from '../../assets/music/player_win.mp3'
import SoundPlayLoser from '../../assets/music/game_over.mp3'

import { Share } from '../Share'
import { useStore } from '../../store/hooks'
import { Sound } from '../Sound'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

export function CardEndGameCp({
  character,
  maxPoints,
  playerWin,
  openWinMedalCard,
  openFeedBack,
  openShareCard,
  intl,
}) {
  const isMobile = useMemo(isMobileSize, [])
  const { store } = useStore()
  const { sound } = store

  return (
    <>
      {sound && (
        <Sound loop={false} mp3Sound={playerWin ? SoundPlayWin : SoundPlayLoser} />
      )}
      <Container>
        <Card isSmall data_test="CardEndGame" maxWidth="505px">
          {playerWin && (
            <Flag>
              <CardFlagLarge />
            </Flag>
          )}
          <ContentCard playerWin={playerWin}>
            {playerWin && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: 'game.playerWin' }, { name: 'Você' }),
                }}
              />
            )}

            <Figure>
              {playerWin ? (
                <IconPlayWin>
                  <StarsPlayerWin />
                </IconPlayWin>
              ) : (
                <EndGameAsset height="36" style={{ marginTop: '20px' }} />
              )}
            </Figure>

            <section data-test="endGameCardContent">
              {playerWin && (
                <>
                  <Paragraphs
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: 'game.success.nextStage.superior.finish.message' },
                        { name: character.name },
                      ),
                    }}
                  />
                  <br />
                  <Paragraphs
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: 'game.playerWin.description' }),
                    }}
                  />
                </>
              )}

              {!playerWin && (
                <>
                  {[
                    'game.player.LoserFist',
                    'game.player.LoserSecond',
                    'game.player.LoserThird',
                  ].map((text, index) => (
                    <Paragraphs
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          { id: text },
                          { name: character.name, maxPoints: maxPoints },
                        ),
                      }}
                      withSpace
                    />
                  ))}
                </>
              )}
            </section>

            <ContainerButtons>
              {playerWin && (
                <ButtonWithRadiusBorderComponent
                  onClick={openWinMedalCard}
                  text={intl.formatMessage({ id: 'next' })}
                />
              )}

              {!playerWin && (
                <>
                  <ButtonWithRadiusBorderComponent
                    onClick={() => openFeedBack(true)}
                    text={intl.formatMessage({ id: 'game.player.again' })}
                  />

                  <ButtonWithRadiusBorderComponent
                    withoutMarginTop={isMobile}
                    color="white"
                    onClick={() => openFeedBack(false)}
                    text={intl.formatMessage({ id: 'exit' })}
                  />
                </>
              )}
            </ContainerButtons>
            <Share openShareCard={() => openShareCard(true)} />
          </ContentCard>
        </Card>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardEndGameCp.propTypes = {
  character: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }),
  maxPoints: PropTypes.number.isRequired,
  playerWin: PropTypes.bool.isRequired,
  openWinMedalCard: PropTypes.func.isRequired,
  openShareCard: PropTypes.func.isRequired,
  openFeedBack: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const CardEndGame = injectIntl(CardEndGameCp)
