import React, { useRef } from 'react'

import { ButtonWithRadiusBorder } from './styles'
import { bool, func, number, object, string } from 'prop-types'
import { useStore } from '../../../store/hooks'

import ClickButton from '../../../assets/music/click_buttons.mp3'

export const ButtonWithRadiusBorderComponent = ({
  color,
  onClick,
  Icon,
  className,
  type,
  data_test,
  text,
  width,
  key,
  disabled,
  withoutMarginTop,
}) => {
  const { store } = useStore()
  const { sound } = store
  const audio = useRef()

  async function sendOnClick(e) {
    if (sound && audio.current !== null) {
      audio.current.play()
    }

    setTimeout(function () {
      onClick(e)
    }, 300)
  }

  return (
    <div key={key}>
      <audio ref={audio}>
        <source src={ClickButton} type="audio/mpeg" />
      </audio>
      <ButtonWithRadiusBorder
        type={type}
        className={className}
        color={color}
        onClick={sendOnClick}
        width={width}
        data-test={data_test}
        disabled={disabled}
        withoutMarginTop={withoutMarginTop}>
        {Icon && <Icon width="24px" height="24px" style={{ marginRight: '10px' }} />}
        {text}
      </ButtonWithRadiusBorder>
    </div>
  )
}

ButtonWithRadiusBorderComponent.propTypes = {
  color: string,
  data_test: string,
  text: string,
  onClick: func.isRequired,
  key: string,
  withoutMarginTop: bool,
  className: string,
  disabled: bool,
  Icon: object,
  width: number,
  type: string,
}

ButtonWithRadiusBorderComponent.defaultProps = {
  color: '',
  key: '',
  withoutMarginTop: false,
  disabled: false,
  className: '',
  data_test: '',
  text: '',
  width: 0,
  type: 'button',
}
