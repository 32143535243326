export default {
  'game.skills.superior.questions.1':
    'Un amigo de {name} está siendo víctima de prejuicios en el campus en el que estudia. {name} resuelve:',
  'game.skills.superior.questions.1.option.1':
    'Aguardar a que sus compañeros den el primer paso para involucrarse.',
  'game.skills.superior.questions.1.option.1.feedback':
    '{name} prefirió guardar silencio frente a la situación y perdió la chance de ser un agente de cambio, valorizando la diversidad.',
  'game.skills.superior.questions.1.option.2': 'Realizar un debate con su curso.',
  'game.skills.superior.questions.1.option.2.feedback':
    '¡Felicitaciones por la iniciativa! {name} hace su parte para disminuir los prejuicios y valorar la diversidad en el campus.',

  'game.skills.superior.questions.2':
    '¡{name} ganó una beca para investigación en la universidad! La experiencia fue increible y {name} aprendió en la práctica como el análisis de datos puede ayudar a tomar decisiones de una manera más inteligente.',
  'game.skills.superior.questions.2.feedback':
    '¡Buenísimo {name}, esta habilidad vale oro en el Portal Z!',

  'game.skills.superior.questions.3':
    '{name} perdió el control de una presentación en vivo y terminó maltratando al presentador en los comentarios. Si quiere llegar al Portal Z, {name} va a tener que aprender a lidiar con sus emociones y a respectar ideas diferentes a las propias.',
  'game.skills.superior.questions.3.feedback': ' ',

  'game.skills.superior.questions.4':
    '{name} creó una propuesta de StartUp para una presentación de ideas de negocio en la Universidad. Los jurados creen que la idea es genial, pero mencionaron algunas fallas en el modelo de negocio. {name} resuelve:',
  'game.skills.superior.questions.4.option.1':
    'Buscar un aliado que tenga conocimientos en el área de finanzas para mejorar su propuesta.',
  'game.skills.superior.questions.4.option.1.feedback':
    '¡Muy bien! {name} analizó la situación e identificó lo que faltaba para mejorar su propuesta. ¡Felicitaciones por la persistencia!',
  'game.skills.superior.questions.4.option.2': 'Desistir de la idea y buscar una nueva.',
  'game.skills.superior.questions.4.option.2.feedback':
    '¡Oh no! La idea de {name} era my buena y perdió la oportunidad de mejorarla. La persistencia y la capacidad de aprender son piezas clave para llegar al Portal Z.',

  'game.skills.superior.questions.5':
    '{name} tiene una entrevista de trabajo y tiene mucho interés en conseguir la posición, pero no cumple con todos los requisitos. ¿Qué puede hacer {name}?',
  'game.skills.superior.questions.5.option.1':
    'Exagera sus capacidades en su CV para poder cumplir con todos los requisitos.',
  'game.skills.superior.questions.5.option.1.feedback':
    '¡Ups! {name} enredó las cosas con las mentiras y acabó perdiendo la posición.',
  'game.skills.superior.questions.5.option.2':
    'Destaca sus habilidades, experiencias y demuestra motivación para aprender cosas nuevas.',
  'game.skills.superior.questions.5.option.2.feedback':
    '¡{name} consigue hacer una buena entrevista! Ahora a esperar que el resultado sea positivo.',

  'game.skills.superior.questions.6':
    '{name} acaba de descubrir que el exámen gratuito de ingreso a la Universidad está detenido por falta de voluntarios. ¿Cuál es su reacción?',
  'game.skills.superior.questions.6.option.1':
    'Se preocupa por los jóvenes que no tienen otra alternativa de estudio.',
  'game.skills.superior.questions.6.option.1.feedback':
    'La empatía es importante, pero {name} podría haber tomado acciones y ayudado a cambiar la historia.',
  'game.skills.superior.questions.6.option.2':
    'Se ofrece a participar y hace un campaña entre sus amigos para aumentar las horas de voluntariado.',
  'game.skills.superior.questions.6.option.2.feedback':
    '¡Muy bien!¡ {name} tuvo empatía e iniciativa, y además ganó más experiencia!',

  'game.skills.superior.questions.7':
    'El semestre está siendo difícil. {name} no consigue terminar los trabajos, pierde una buena oportunidad de exámenes y su proyecto recibe duras críticas de su profesor. ¿Cómo reacciona {name}?',
  'game.skills.superior.questions.7.option.1':
    'Comienza a dudar de si mismo, pero prefiere no comentarle a nadie.',
  'game.skills.superior.questions.7.option.1.feedback':
    '¿Quién nunca tuvo dudas o perdió la confianza en si mismo? No existe una receta única para enfrentar este tipo de situaciones. ¡Pero ignorar el problema no ayuda!',
  'game.skills.superior.questions.7.option.2':
    'Analiza sus errores y aciertos, y pide consejos a sus amigos y profesores.',
  'game.skills.superior.questions.7.option.2.feedback':
    '¿Quién nunca tuvo dudas o perdió la confianza en si mismo? No existe una receta única para enfrentar este tipo de situaciones. ¡Pero {name} decidió enfretar el tema y pedir ayuda, es un buen comienzo!',

  'game.skills.superior.questions.8':
    '{name} está con dudas acerca de su carrera, resuelve conversar con un profesional en el área, quien lo aconseja para pensar en sus objetivos y hacer una lista de CONOCIMIENTOS y HABILIDADES importantes para conquistarlos.',
  'game.skills.superior.questions.8.option.1':
    '¡Puntos para {name], está planeando cómo llegar al Portal Z!',
}
