import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.challenge': 'Scenario',
  'game.challenge.message':
    "Every time you fall on this box you will receive cards with challenges of daily life moments that can affect your character's life.",

  ...junior,
  ...middle,
  ...superior,
}
