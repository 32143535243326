export default {
  'game.skills.middle.questions.1':
    'Un atleta olímpico va a visitar la escuela y el director está buscando un estudiante que ayude en el tour de la escuela. ¿Qué hace {name}?',
  'game.skills.middle.questions.1.option.1':
    '{name} se presenta como candidato y se entusiasma con la oportunidad.',
  'game.skills.middle.questions.1.option.1.feedback':
    '{name} sorprendió al atleta con una presentación especial. Usó su creatividad y desarrolló su postura y oratoria.',
  'game.skills.middle.questions.1.option.2':
    'Tiene miedo y prefiere dejarle la misión a un compañero.',
  'game.skills.middle.questions.1.option.2.feedback':
    '¡{name} no logró superar el miedo y aún necesita mejorar su autoconfianza!',

  'game.skills.middle.questions.2':
    '¡Llegó el período de elección del centro de estudiantes! {name} decide:',
  'game.skills.middle.questions.2.option.1':
    'Apoyar al grupo de estudiantes que tiene la mejor propuesta, según su opinión.',
  'game.skills.middle.questions.2.option.1.feedback':
    '{name} se involucró activamente. Tuvo que liderar equipos y convencer a sus compañeros. La experiencia le aseguró nuevas habilidades y una suma de XP para llegar al Portal Z.',
  'game.skills.middle.questions.2.option.2': 'No involucrarse en las elecciones.',
  'game.skills.middle.questions.2.option.2.feedback':
    '{name} no tuvo iniciativa y perdió la oportunidad de participar de decisiones importantes para la escuela y sus compañeros.​',

  'game.skills.middle.questions.3':
    'La escuela hizo una alianza con una ONG para ofrecer un curso de emprendimiento para estudiantes. ¿Qué hace {name}?',
  'game.skills.middle.questions.3.option.1':
    '{name} se inscribe en el curso aunque no sea obligatorio.',
  'game.skills.middle.questions.3.option.1.feedback':
    '¡Buena iniciativa!¡{name} creó una empresa estudiantil con sus compañeros y descubrió habilidades que ni sabía que tenía!¡La experiencia va a ayudará a {name} en sus decisiones futuras!',
  'game.skills.middle.questions.3.option.2':
    'No se inscribe porque no tiene interés en abrir su negocio.',
  'game.skills.middle.questions.3.option.2.feedback':
    '{name} perdió la oportunidad de explorar sus propios talentos y descubrir nuevas opciones de carrera.',

  'game.skills.middle.questions.4':
    '{name} asumió la coordinación del Festival de Danza, pero su equipo no tiene experiencia en eventos y los miembros discuten por todo. ¿Qué hacer?',
  'game.skills.middle.questions.4.option.1': 'Pasar el cargo a una persona más paciente.',
  'game.skills.middle.questions.4.option.1.feedback':
    '¡No es buena decisión! {name} pierde la oportunidad de practicar una habilidad escencial del Mundo VUCA: liderar personas y proyectos.',
  'game.skills.middle.questions.4.option.2':
    'Conversar con el equipo para identificar los intereses y habilidades de cada uno.',
  'game.skills.middle.questions.4.option.2.feedback':
    '¡Muy bien! ¡Puntos para {name}, que aprendió sobre liderar personas y coordinar proyectos!',

  'game.skills.middle.questions.5':
    '{name} descubrió a través de las redes sociales que su mejor amiga le mintió acerca de sus actividades del fin de semana. {name} decide:',
  'game.skills.middle.questions.5.option.1':
    'Publicar un texto en las redes sociales exponiendo a su amiga.',
  'game.skills.middle.questions.5.option.1.feedback':
    '{name} actuó por impulso y atacó a su amiga públicamente.  Le faltó autocontrol y diálogo, elementos fundamentales en el Mundo VUCA.',
  'game.skills.middle.questions.5.option.2': 'Conversar con su amiga.',
  'game.skills.middle.questions.5.option.2.feedback':
    '¡Muy bien! {name} se sintió mal, pero no se dejó llevar por el impulso. ¡Puntos para {name} por la iniciativa y por el autocontrol!',

  'game.skills.middle.questions.6':
    'Una amiga de {name} no consigue entender por qué la bivlioteca de la escuela sólo abre una vez por mes. Ella comenta con {name}, quién responde:',
  'game.skills.middle.questions.6.option.1': '"¡Siempre fue así!"',
  'game.skills.middle.questions.6.option.1.feedback':
    '¡Ups! Esta frase está prohibida en el Mundo VUCA. No es porque las cosas "son así" que no pueden ser mejoradas, ¿correcto?',
  'game.skills.middle.questions.6.option.2':
    '"Nunca había pensado en eso, ¿será que podemos hacer algo al respecto?',
  'game.skills.middle.questions.6.option.2.feedback':
    '¡Genial! No es porque las cosas "son así" que no pueden ser mejoradas, ¿correcto?. La mayor parte de las innovaciones - pequeñas o grandes - comienzan de esta manera.',
}
