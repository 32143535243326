export default {
  'game.job.junior.questions.1':
    'Hoy fue día de retroalimentación en su trabajo. La madre de {name} habló sobre sus habilidades en el trabajo e hizo algunas sugerencias de mejora.',
  'game.job.junior.questions.1.feedback':
    '¡Conocer sus habilidades y debilidades es una pieza clave para que {name} pueda llegar al Portal Z!',

  'game.job.junior.questions.2':
    '{name} visitó con la escuela, una startup que desarrolla sitios web, conoció toda la estructura de la empresa y descubrió profesiones que no conocía. ¡Uhuuuu!',
  'game.job.junior.questions.2.feedback':
    '{name} aprovechó la oportunidad y ganó más XP.',

  'game.job.junior.questions.3':
    'La madre de {name} recibió un gran paquete de dulces y va a necesitar ayuda extra hacia el final de la semana. Pero {name} tiene tareas que resolver para la escuela. ¿Qué resuelve hacer?',
  'game.job.junior.questions.3.option.1':
    'Hacer las tareas rápido para poder estar libre.',
  'game.job.junior.questions.3.option.1.feedback':
    'Conciliar trabajo y estudio es un desafío. {name} entregó las tareas de cualquier forma y no aprendió nada. Si quierellegar al Portal Z, {name} tiene que organizarse mejor.',
  'game.job.junior.questions.3.option.2':
    'Organizar sus horarios para terminar las tareas de la escuela durante la semana.',
  'game.job.junior.questions.3.option.2.feedback':
    '{name} cortó el uso de la televisión y el teléfono celular durante la semana y se enfocó en resolver las tareas de la escuela. Fue una semana pesada, pero {name} consigió ayudar a su madre y ¡finalizó con una sensación positiva de deber cumplido!!',

  'game.job.junior.questions.4':
    '{name} no prestó atención a la receta que su mamá le enseñó y estropeó un lote de dulces.',
  'game.job.junior.questions.4.option.1': '¡Más atención la próxima vez!',

  'game.job.junior.questions.5':
    'Uno de los clientes no pagó en el día acordado porque no tenía dinero. ¿Y ahora?',
  'game.job.junior.questions.5.option.1':
    'Acepta el perjuicio, pero le no vende más a ese cliente.',
  'game.job.junior.questions.5.option.1.feedback':
    'Una pena, pero eso que puede sucederle a cualquier persona. {name} podría haber tratado de negociar antes de cortar la relación con el cliente.',
  'game.job.junior.questions.5.option.2':
    'Negocia con el cliente para recibir el pago en cuotas.',
  'game.job.junior.questions.5.option.2.feedback':
    'Su iniciativa aseguró recibir el pedido además de mantener una buena relación con el cliente.',

  'game.job.junior.questions.6':
    'Un compañero de escuela también comenzó a vender dulces y ahora {name} tiene un competidor. ¿Cómo afrontar la situación?',
  'game.job.junior.questions.6.option.1':
    'Acuerda con el compañero vender dulces diferentes.',
  'game.job.junior.questions.6.option.1.feedback':
    '¡Buena decisión! Ahora toda la escuela tiene más opciones para comprar y {name} asegura su clientela.',
  'game.job.junior.questions.6.option.2':
    'Disemina comentarios negativos acerca de los dulces de su compañero.',
  'game.job.junior.questions.6.option.2.feedback':
    '¡Qué feo! Las personas se dieron cuenta de que eso no era verdad y las ventas de dulces de {name} cayeron.',

  'game.job.junior.questions.7':
    'No esta trabajando. ¡Aprovecha para estudiar y disfrutar de los retos de esta fase!',
}
