import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentCard,
  ContainerButton,
  MedalContainer,
  TextMedal,
  CenterFlex,
} from './styles'

import { CardWithHeader } from '../../../Cards'
import { isMobileSize } from '../../../../common/utils'
import { ButtonWithRadiusBorderComponent } from '../../../Buttons'
import { BackgroundBlur } from '../../../GameCards/styles'
import { dataOfMedals } from './dataOfMedals'
import { Loading } from '../../../Loading'
import { getMedal } from '../../../../services/API/medals'
import { injectIntl } from 'react-intl'

export const CardMedalsCp = ({ closeMedalsCard, handleCardMedalClick, intl }) => {
  const [showLoading, setShowLoading] = useState(true)
  const isMobile = useMemo(isMobileSize, [])
  const memoizedDataOfMedals = useMemo(dataOfMedals, [])
  const [characterMedals, setCharacterMedals] = useState(memoizedDataOfMedals)

  useEffect(handleUserMedals, [])
  function handleUserMedals() {
    getUserMedals()
  }

  function capitalize(name) {
    return name.replace(/^\w/, (c) => c.toUpperCase())
  }

  async function getUserMedals() {
    setShowLoading(true)
    const response = await getMedal()

    if (response && response.data && response.data.medals.length > 0) {
      setArrayMedals(response.data)
    }

    setShowLoading(false)
  }

  function setArrayMedals(data) {
    const characterMedalsAux = characterMedals

    characterMedalsAux.forEach((item) => {
      data.medals.forEach((medal) => {
        const { object_medal, date } = medal
        const character = Object.keys(object_medal)
        const aux = capitalize(character[0])

        if (aux === item.character) {
          item.active = true
          item.date = date
          item.username = data.name
        }
      })
    })

    setCharacterMedals(characterMedalsAux)
  }

  function openMedal(active, index) {
    if (active) {
      handleCardMedalClick(characterMedals[index])
    }
  }

  return (
    <div>
      {showLoading && <Loading />}
      <Container>
        <CardWithHeader
          titleCard={intl.formatMessage({ id: 'account.medals.my' })}
          backgroundColorHeader="pistachioGreen"
          isSmall={isMobile}
          data_test="myMedalsCard">
          <ContentCard>
            <MedalContainer>
              {memoizedDataOfMedals.map(
                ({ character, data_test, Icon, active }, index) => (
                  <CenterFlex active={active} key={index}>
                    <Icon onClick={() => openMedal(active, index)} />
                    <TextMedal
                      active={active}
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          { id: 'account.medals.of' },
                          { name: character },
                        ),
                      }}
                    />
                    <ButtonWithRadiusBorderComponent
                      data_test={data_test}
                      text={intl.formatMessage({ id: 'seeMore' })}
                      disabled={!active}
                      onClick={() => openMedal(active, index)}
                    />
                  </CenterFlex>
                ),
              )}
            </MedalContainer>

            {closeMedalsCard && (
              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  type="button"
                  color={isMobile ? '' : 'white'}
                  data_test="goToGame"
                  onClick={closeMedalsCard}
                  text={intl.formatMessage({ id: 'return' })}
                />
              </ContainerButton>
            )}
          </ContentCard>
        </CardWithHeader>
      </Container>
      {!isMobile && <BackgroundBlur />}
    </div>
  )
}

CardMedalsCp.propTypes = {
  closeMedalsCard: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.bool.isRequired,
  ]),
  handleCardMedalClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const CardMedals = injectIntl(CardMedalsCp)
