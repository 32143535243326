import styled, { css } from 'styled-components'

export const ContainerMenuMobile = styled.div`
  position: relative;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const MenuContentMobile = styled.nav`
  background-color: #8fc440;
  display: none;
  left: 0;
  opacity: 0;
  padding: 1.5625rem 1.875rem;
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100vw;

  ${({ showMenu }) =>
    showMenu &&
    css`
      display: block;
      opacity: 1;
      z-index: 100;
    `};
`

export const ListOptionsMenuMobile = styled.ul`
  margin: 2.625rem 0;
  display: inline-block;
  text-align: center;
  width: 100%;

  &:nth-child(1) {
    margin: 1.875rem 0;
  }

  li {
    margin: 0 0 1.875rem;
  }

  a,
  span {
    color: #1d1d1b;
    font-family: MilkyNice;
    font-size: 1.125rem;
    text-decoration: none;
    -webkit-text-decoration: none;
  }
`
