import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.skills': 'Habilidades',
  'game.skills.message':
    'Se encuentra en una casilla de Habilidades ¡Aqui va a recibir desafíos que lo ayudarán a desarrollar habilidades en los personajes o no!',

  ...junior,
  ...middle,
  ...superior,
}
