import React from 'react'
import PropTypes from 'prop-types'

export const PawnContainer = (props) => {
  const { CharacterPawn } = props
  return <CharacterPawn {...props} />
}

PawnContainer.propTypes = {
  CharacterPawn: PropTypes.func.isRequired,
}
