export default {
  'account.my': 'My account',
  'account.medals': 'Medals',
  'account.medals.view': 'View Medal',
  'account.medals.win': 'You won the {name} medal!',
  'account.medals.win.description':
    'Medals are rewards for reaching Portal Z and winning the VUCA Game. The medals won will be saved and you can consult them in the game menu if you are logged in.',
  'account.medals.my': 'My medals',
  'account.medals.of': 'Medal {name}',
  'account.password.edit': 'Change password',
  'account.password.success.update': 'Your password has been changed successfully.',
  'account.password.error.update':
    'An error occurred while changing the password. Please try again.',
  'account.password.current': 'Current password',
  'account.password.current.placeholder': 'Enter password password',
  'account.password.new': 'New password',
  'account.password.new.placeholder': 'Enter your new password',
  'account.password.new.confirm': 'Confirm new password',
  'account.password.create': 'Create password',
  'account.password.invalid': '<b>Incorrect password</>',
  'account.edit': 'Change user data',
  'account.terms': 'View Terms of Use and Privacy',
  'account.delete': 'Delete account',
  'account.delete.info':
    "It's a shame to see you go! <br/> <br/> Are you sure you want to close your account at Game VUCA? If you cancel your account you will no longer be able to: <br/> - Save games <br/> - Access the match history <br/> - Access the medals received <br/> <br/> You want to delete your account?",
  'account.deleted': 'Account deleted',
  'account.delete.error':
    'There was an error deleting the account. Please try again later.',
  'account.delete.success': 'Your account has been deleted',
}
