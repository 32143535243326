import React, { useEffect, useMemo, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Logo } from '../../components'

import { LoginEmailCollunm } from './components/LoginEmail'
import { LoginSocialMediaCollunm } from './components/LoginSocialMedia'

import {
  Container,
  LoginContainer,
  LoginEmail,
  LoginSocialMedia,
  LoginTitle,
  Side,
} from './styles'

import { callbackFacebook } from '../../services/API/login'
import { useStore } from '../../store/hooks'
import { clearAll, get, set } from '../../services/storage'
import { types } from '../../store'
import { Loading } from '../../components/Loading'
import { getLoginStrings } from './loginStrings'
import { setToken } from '../../services/API'
import { reportCompleteRegistration } from '../../services/analytics'

export const Login = () => {
  const { dispatch } = useStore()
  const [showLoading, setShowLoading] = useState(false)
  const loginStrings = useMemo(getLoginStrings, [])

  function getUrlParams() {
    const url = window.location.href
    const completeParam = url.split('?code=')
    let currentUrl = ''
    let codeParam = ''

    if (completeParam.length > 1) {
      currentUrl = completeParam[0]
      const stringWithCode = completeParam[1]

      if (stringWithCode) {
        codeParam = stringWithCode.split('#/auth')[0]
      }
    }

    return {
      url: currentUrl,
      codeParam: codeParam,
    }
  }

  useEffect(getTokenParam, [])
  function getTokenParam() {
    const { url, codeParam } = getUrlParams()

    if (codeParam) {
      setShowLoading(true)
      loginFacebook(codeParam, url)
      setShowLoading(false)
    } else {
      clearAll()
    }
  }

  async function loginFacebook(code, url) {
    const response = await callbackFacebook(code)
    if (response) {
      dispatch({ type: types.SET_USER_DETAILS, payload: response.user })
      dispatch({ type: types.SET_AUTH_TOKEN, payload: response.token })
      set('user', response.user)
      set('token', response.token)
      setToken(response.token)

      reportCompleteRegistration()

      const gameState = get('gameState')
      const hasSavedGame = gameState && gameState.G
      const setNewGame = hasSavedGame && get('isLoginFacebook')

      const route = setNewGame ? '#/game' : '#/home'
      window.location.replace(url + route)
    }
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container data-test="LoginContainer">
        <Logo className="logo" />

        <LoginContainer>
          <Side />
          <LoginEmail>
            <LoginTitle data-test="msgLogin">
              <FormattedMessage id="login.title" />
            </LoginTitle>

            <LoginEmailCollunm
              handleLoading={setShowLoading}
              loginStrings={loginStrings}
            />
          </LoginEmail>

          <LoginSocialMedia>
            <LoginTitle data-test="msgLoginMedia">
              <FormattedMessage id="login.socialMedia" />
            </LoginTitle>

            <LoginSocialMediaCollunm loginStrings={loginStrings} />
          </LoginSocialMedia>
          <Side />
        </LoginContainer>
      </Container>
    </>
  )
}
