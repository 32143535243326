import styled from 'styled-components'

export const TopBarContainer = styled.header`
  background-color: #8fc440;
  box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  z-index: 100000;

  .container {
    padding: 0;

    .logo {
      width: 30%;
      padding-left: 2rem;
    }
  }

  @media (max-width: 1024px) {
    .logo {
      width: 70%;
      flex: 1 100%;
    }
  }
`

export const TopBarContentWrapper = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;

  .containerSelect {
    position: relative;
    margin: 0 2rem 0 10px;
    width: 60px;
    height: 47px;

    > div {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px;
    }
  }
  @media (min-width: 1024px) {
    .containerSelect {
      margin: 0 2rem 0 20px;
      width: 120px;
      height: 38px;
    }
  }
`

export const ListOptionsMenu = styled.ul`
  align-items: center;
  display: flex;
  gap: 1rem;
  height: 100%;

  li a {
    color: #fff;
    text-decoration: none;
  }

  li:hover {
    font-weight: bolder;
    text-decoration: underline;
  }
`
