export default {
  terms: 'Consent form',
  'terms.title': 'For the use and proccessing of personal data',
  'terms.description.1':
    'This document is intended to register the informed, unequivocal and freely given authorization provided by the' +
    ' Subject for the anonymous treatment and release of their personal data for specific purposes, in compliance ' +
    'with the Brazilian General Data Protection Law (Ley N.º 13.709 - Ley General de Protección de Datos Personales, ' +
    'LGPD).',

  'terms.description.2': 'Subject',
  'terms.description.2.info': 'The person to which the used personal data refers.',

  'terms.description.3':
    'By agreeing to this form, the Subject agrees to and allows the company Associação Junior Achievement do ' +
    'Brasil, CNPJ nº 04.906.381.001-23, endereço comercial, Avenida Ipiranga 6681 – Prédio96E – sala 210 – ' +
    'Tecnopuc, Bairro Partenon, CEP 90.160-091, Porto Alegre- RS, hereby known as the Controller, to make ' +
    `decisions in relation to the treatment of the Subject's personal data, as well as the processing of such ` +
    'personal data, which involves procedures such as those related to the collection, production, reception, ' +
    'classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, ' +
    'deletion, assessment, control, modification, communication, transference, disclosure, or extraction of ' +
    'such information.',

  'terms.description.4': 'Personal Data',
  'terms.description.4.info':
    'The Controller is hereby authorized to make decisions related to the treatment and to the performance of ' +
    'the treatment of the following personal data from the Subject:',

  'terms.description.4.list.1': 'Full name',
  'terms.description.4.list.2': 'Date of birth',
  'terms.description.4.list.3': 'ID number and picture',
  'terms.description.4.list.4': 'Unique Labor Identification Code number and picture',
  'terms.description.4.list.5': 'Driver’s license number and picture',
  'terms.description.4.list.6': 'Marital status',
  'terms.description.4.list.7': 'Level of education',
  'terms.description.4.list.8': 'Full address',
  'terms.description.4.list.9': 'Telephone number, WhatsApp number and email addresses',
  'terms.description.4.list.10':
    'User name and password specific to the use of the Controller’s services',
  'terms.description.4.list.11':
    'Communication, written and oral, between the Subject and the Controller',

  'terms.description.5': 'Scope of Data Treatment',
  'terms.description.5.info':
    'The personal data hereby mentioned will be shared anonymously with third parties in order to perform a ' +
    'follow-up of Junior Achievement’s activities within its social aim of promoting entrepreneurial education, ' +
    'and it has the following goals:',
  'terms.description.5.list.1':
    'To allow the Controller to send or provide the Subject with products or services for free',
  'terms.description.5.list.2':
    'To allow the Controller to send notices, electronic messages via WhatsApp and email for institutional relationship purposes',
  'terms.description.5.list.3':
    'To allow the Controller to identify and contact the Subject for commercial relationship purposes',
  'terms.description.5.list.4':
    'To allow the Controller to design, test, promote and advertise services and products, tailored or not, according to the Subject’s profile',
  'terms.description.5.list.5':
    'To allow the Controller to share the data anonymously with third party partners with the purpose of monitoring the activities carried out by the Controller',

  'terms.description.6': 'Data Sharing',
  'terms.description.6.info':
    'The Controller is authorized to share the Subject’s personal data with other data treatment agents, if ' +
    'necessary, for the purposes mentioned in this form, subject to the principles and guarantees established ' +
    'by Brazilian Law No. 13.709.',

  'terms.description.7': 'Data Security',
  'terms.description.7.info':
    'The Controller is responsible for the preservation of the technical, executive and security measures that ' +
    'protect personal data from unauthorized access and accidental or illegal cases of destruction, loss, ' +
    'alteration, release or any form of inadequate or illegal treatment.' +
    '<br />' +
    'In compliance with article 48 of Brazilian Law No. 13.709, the Controller will inform the Subject and the ' +
    'National Data Protection Authority (Autoridad Nacional de Protección de Datos, ANPD) of the occurrence of ' +
    'any security incident that may result in a risk or significant damage to the Subject.',

  'terms.description.8': 'End of Data Treatment',
  'terms.description.8.info':
    'The Controller may keep and process the Subject’s personal data during the whole period for which they are ' +
    'relevant to the scope of the purposes listed in this term. Anonymous personal data, which cannot be ' +
    'associated with the Subject, may be kept for an indefinite amount of time.' +
    '<br />' +
    'The Subject may ask the Controller at any time, by means of an email or letter, to delete any personal data ' +
    'that is not anonymous and related to the Subject. The Subject acknowledges that the Controller may not be ' +
    'able to continue providing products or services to the Subject after the deletion of such personal data.',

  'terms.description.9': 'Subject’s Rights',
  'terms.description.9.info':
    'The Subject has the right to obtain from the Controller, in relation to the data used by the Controller, at ' +
    'any time and with prior request: I - Confirmation of the existence of treatment; II - Access to the data; ' +
    'III - Amendment of incomplete, inaccurate, or outdated data; IV - Anonymity, obstruction, or deletion of any ' +
    'unnecessary, excessive data or treatment that is not in compliance with Brazilian Law No. 13.709; V - Data ' +
    'transference to any other product or service provider, with prior express request, in accordance with the ' +
    'rules of the national authorities, in due compliance with industrial and trade secrets; VI - Deletion of ' +
    'treated personal data with the Subject’s consent, with the exception of those cases contemplated by article ' +
    'no. 16 of Brazilian Law No. 13.709; VII - Information about public and private entities to which the ' +
    'Controller released the data; VIII - Information about the possibility of denial of consent and related ' +
    'consequences; IX - Withdrawal of consent, in compliance with the terms of § 5, article 8, of Brazilian Law ' +
    'No. 13.709.',

  'terms.description.10': 'Right to withdraw of consent',
  'terms.description.10.info':
    'The Subject may withdraw their consent at any time, subject to prior request sent by email or postal ' +
    'service to the Controller.',
}
