import styled, { css } from 'styled-components'

export const BandJob = styled.div`
  width: 58px;
  height: 58px;

  border: solid 4px #db6a05;
  border-radius: 8px;
  background-color: #fd8204;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 36px;
    height: 36px;
  }
`

export const JobButtonContainer = styled.div`
  margin-top: 30px;
  margin-left: 5px;
  position: absolute;
  z-index: 99;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1024px) {
    margin-top: 80px;
    margin-left: -44px;
    flex-direction: row-reverse;

    ${({ showLeaveJobButton }) =>
      showLeaveJobButton &&
      css`
        margin-left: -72px;
      `}
  }
`

export const LatchLeaveJob = styled.div`
  width: 43px;
  height: 38.4px;

  border: solid 4px #db6a05;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
  background-color: #fd8204;

  padding: 10px;

  cursor: pointer;

  display: flex;
  justify-content: space-around;
  align-items: center;

  font-family: MilkyNice;
  font-size: 13.8px;
  color: #914613;

  transition: width 1s, background-color 1.2s ease;

  @media screen and (max-width: 1024px) {
    border-right: none;
    border-left: solid 4px #db6a05;
    border-radius: 8px 0 0 8px;
    height: 27px;
    width: 27px;
    padding: 0px;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  b {
    display: none;
    min-width: 120px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;
  }

  &:hover {
    width: 177.4px;
    background-color: #ea7c18;

    b {
      display: block;
      min-width: 120px;
      visibility: visible;
      opacity: 1;
      padding-right: 10px;
    }
  }
`
