import styled, { css } from 'styled-components'

import backgroundNewGameDesktop from '../../assets/img/backgrounds/backgroundNewGameDesktop.svg'
import backgroundNewGameMobile from '../../assets/img/backgrounds/backgroundNewGameMobile.svg'
import backgroundCloudsDesktop from '../../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Container = styled.div`
  background-image: url(${backgroundNewGameMobile});
  object-fit: contain;
  height: 100vh;
  width: 100%;
  padding: 1rem;
  background-repeat: no-repeat;
  background-color: #69bc32;

  position: absolute;

  svg {
    font-family: MilkyNice;
  }

  ${({ hasSavedGame }) =>
    hasSavedGame &&
    css`
      background-image: url(${backgroundCloudsMobile});
    `}

  @media screen and (min-width: 1000px) {
    background-image: url(${backgroundNewGameDesktop});
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position-x: -2px;

    ${({ hasSavedGame }) =>
      hasSavedGame &&
      css`
        background-image: url(${backgroundCloudsDesktop});
      `}
  }

  background-size: 105%;
  height: 100vh;
`

export const ContainerHasntSavedGame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  figure {
    small {
      top: -20px;
      font-size: 22px;
      left: 130px;
    }
  }

  @media screen and (max-width: 1000px) {
    height: 85%;
    justify-content: space-between;
  }

  @media screen and (min-width: 1000px) {
    flex-direction: column;
    width: 322px;
    gap: 30px;
    float: right;
    margin-right: 152px;
  }
`

export const ButtonContainer = styled.div`
  width: 213px;
  display: grid;
  text-align: center;
`

export const ContainerHasSavedGame = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  figure {
    margin-top: 50px;
    svg {
      width: 236px;
      height: 70px;
    }

    small {
      top: -20px;
      font-size: 24px;
      left: 170px;
    }
  }
`

export const CardSavedGame = styled.div`
  margin-top: 60px;

  @media screen and (min-width: 1000px) {
    max-width: 601px;
  }
`

export const ButtonSavedGameContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    gap: 10px;
  }
`

export const Body = styled.div`
  padding: 30px;
  padding-bottom: 50px;
  display: flex;
  background-color: white;

  @media screen and (max-width: 1024px) {
    padding: 20px;
  }
`

export const ContainerButtonNewGame = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
