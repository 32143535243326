import React from 'react'
import { Switch, HashRouter } from 'react-router-dom'

import RouteWrapper from './routeWrapper'

import { Game, ChoiceCharacter, Home, LandingPage } from '../pages'
import { Login } from '../pages/Login'
import { SignUp } from '../pages/SignUp'
import { ResetPassword } from '../pages/ResetPassword'
import { Terms } from '../pages/Terms'

export const Routes = () => (
  <HashRouter>
    <Switch>
      <RouteWrapper path="/start" exact component={ChoiceCharacter} isPrivate />
      <RouteWrapper path="/home" exact component={Home} isPrivate />
      <RouteWrapper path="/auth/facebook/callback" component={Login} />
      <RouteWrapper path="/" exact component={LandingPage} />
      <RouteWrapper path="/login" exact component={Login} />
      <RouteWrapper path="/game" exact component={Game} isPrivate />
      <RouteWrapper path="/reset-password" exact component={ResetPassword} />
      <RouteWrapper path="/terms" exact component={Terms} />
      <RouteWrapper path="/sign-up" exact component={SignUp} />
      <RouteWrapper
        path="*"
        exact
        component={() => <h1>404 - Página não encontrada</h1>}
      />
    </Switch>
  </HashRouter>
)
