export default {
  login: 'Login',
  'login.notLogin': '¡No has iniciado sesión!',
  'login.save.alert':
    'Para guardar el juego, debes iniciar sesión o registrarte (si aún no has iniciado sesión)',
  'login.save.alert.init': 'Para guardar, debe iniciar un nuevo juego.<br/>',
  'login.progress.not': 'No hay juegos en curso',
  'login.title': 'Iniciar sesión',
  'login.doTitle': 'Hacer login',
  'login.invalid': 'Correo o contraseña incorrectos.',
  'login.play': 'Jugar',
  'login.signUp': 'Registrarse',
  'login.signUp.description': 'Para guardar el juego, inicie sesión o regístrese.',
  'login.notAccount': '¿No tiene una cuenta?',
  'login.registration': 'Registrese',
  'login.socialMedia': 'O juegue usando las redes sociales',
  'login.google': 'Iniciar sesión con Google',
  'login.facebook': 'Iniciar sesión con Facebook',
  'login.playWithoutLogin': 'Jugar sin iniciar sesión',
  'login.playWithoutLogin.description':
    'Puedes jugar sin iniciar sesión en una cuenta, pero no podrás guardar tu progreso ni su historial de partidos.',
  'login.playWithoutLogin.description.question': '¿Quieres jugar de todos modos?',
  'login.forgetPassword': '¿Ha olvidado su contraseña?',
  'login.forgetPassword.title': 'He olvidado la contraseña',
  'login.forgetPassword.description':
    'Le enviaremos un correo electrónico con instrucciones para restablecer la contraseña.',
  'login.forgetPassword.description.emailSent':
    'Recibirá un correo electrónico con instrucciones para restablecer su contraseña si su correo electrónico está registrado.',
}
