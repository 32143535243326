import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import {
  ConfirmButtonContainer,
  DesktopContainer,
  ButtonCentered,
  RightArrow,
  LeftArrow,
  CurrentImageContainer,
  ContainerMarkStepRule,
  JustifyContantEnd,
  Paragraphs,
  RulesText,
} from './styles'
import { PortalZ1, PortalZ2, PortalZ3 } from '../../assets/img/portalZ'
import { IcArrowRight } from '../../assets/img'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { CircularButtonComponent } from '../Buttons/circularButton'

const DesktopRulesCp = ({ onStartButtonClick, intl }) => {
  const [indexCurrentRules, setIndexCurrentRules] = useState(0)

  const pageRules = {
    previous: {
      isDisabled: indexCurrentRules <= 0,
      handleClick() {
        setIndexCurrentRules((current) => current - 1)
      },
    },
    next: {
      isDisabled: indexCurrentRules === 2,
      handleClick() {
        setIndexCurrentRules((current) => current + 1)
      },
    },
  }

  const paragraphs = [
    intl.formatMessage({ id: 'introduction.firstDescription' }),
    intl.formatMessage({ id: 'introduction.secondDescription' }),
    intl.formatMessage({ id: 'introduction.thirdDescription' }),
  ]

  const CurrentImage = useMemo(() => [PortalZ1, PortalZ2, PortalZ3][indexCurrentRules], [
    indexCurrentRules,
  ])

  function centerButtonClick() {
    if (indexCurrentRules !== 2) {
      setIndexCurrentRules(2)
    } else {
      onStartButtonClick()
    }
  }

  return (
    <DesktopContainer className="someClassName">
      <JustifyContantEnd>
        <ContainerMarkStepRule currentMarkIndex={indexCurrentRules}>
          <span />
          <span />
          <span />
        </ContainerMarkStepRule>
      </JustifyContantEnd>

      <RulesText>
        <Paragraphs
          className={`texts currentText-${indexCurrentRules}`}
          isLast
          dangerouslySetInnerHTML={{ __html: paragraphs[indexCurrentRules] }}
        />
      </RulesText>

      <CurrentImageContainer>
        <CurrentImage data-test={'PortalZImage' + indexCurrentRules} />
      </CurrentImageContainer>

      <ConfirmButtonContainer>
        <LeftArrow>
          <CircularButtonComponent
            color="lemonGreen"
            isDesktop
            rotateImg
            data_test="previousSlideButton"
            disabled={pageRules.previous.isDisabled}
            onClick={pageRules.previous.handleClick}>
            <IcArrowRight width="33" height="33" />
          </CircularButtonComponent>
        </LeftArrow>

        <ButtonCentered isLeft={indexCurrentRules === 0}>
          <ButtonWithRadiusBorderComponent
            data_test={indexCurrentRules !== 2 ? 'jumpSlideButton' : 'startNewGame'}
            onClick={centerButtonClick}
            text={
              indexCurrentRules !== 2
                ? intl.formatMessage({ id: 'jump' })
                : intl.formatMessage({ id: 'start' })
            }
          />
        </ButtonCentered>

        <RightArrow>
          <CircularButtonComponent
            color="lemonGreen"
            isDesktop
            disabled={pageRules.next.isDisabled}
            data_test="nextSlideButton"
            onClick={pageRules.next.handleClick}>
            <IcArrowRight width="33" height="33" />
          </CircularButtonComponent>
        </RightArrow>
      </ConfirmButtonContainer>
    </DesktopContainer>
  )
}

DesktopRulesCp.propTypes = {
  onStartButtonClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const DesktopRules = injectIntl(DesktopRulesCp)
