import { config } from '../../../game/config'

const { employed } = config.player.conditions

export const superior = {
  challenge: [
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.1',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: 10,
          feedback: 'game.challenge.superior.questions.1.feedback',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.4',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: -30,
          feedback: 'game.challenge.superior.questions.4.feedback',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.5',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.challenge.superior.questions.5.option.1',
          point: 40,
          feedback: 'game.challenge.superior.questions.5.option.1.feedback',
        },
        {
          response_text: 'game.challenge.superior.questions.5.option.2',
          point: -20,
          feedback: 'game.challenge.superior.questions.5.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.6',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.challenge.superior.questions.6.option.1',
          point: -20,
          feedback: 'game.challenge.superior.questions.6.option.1.feedback',
        },
        {
          response_text: 'game.challenge.superior.questions.6.option.2',
          point: 40,
          feedback: 'game.challenge.superior.questions.6.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.2',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.challenge.superior.questions.2.option.1',
          point: 40,
          feedback: 'game.challenge.superior.questions.2.option.1.feedback',
        },
        {
          response_text: 'game.challenge.superior.questions.2.option.2',
          point: 20,
          feedback: 'game.challenge.superior.questions.2.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.challenge.superior.questions.3',
      type_question: 'decision_making',
      job_entry: true,
      options: [
        {
          response_text: 'game.challenge.superior.questions.3.option.1',
          point: 20,
          feedback: 'game.challenge.superior.questions.3.option.1.feedback',
          conditions: [employed],
        },
        {
          response_text: 'game.challenge.superior.questions.3.option.2',
          point: 40,
          feedback: 'game.challenge.superior.questions.3.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  knowledge: [
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.1',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.1.option.1',
          point: 50,
          feedback: 'game.knowledge.superior.questions.1.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.1.option.2',
          point: -40,
          feedback: 'game.knowledge.superior.questions.1.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.5',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.5.option.1',
          point: -30,
          feedback: 'game.knowledge.superior.questions.5.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.5.option.2',
          point: 40,
          feedback: 'game.knowledge.superior.questions.5.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.6',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.6.option.1',
          point: 40,
          feedback: 'game.knowledge.superior.questions.6.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.6.option.2',
          point: -30,
          feedback: 'game.knowledge.superior.questions.6.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.7',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.7.option.1',
          point: -30,
          feedback: 'game.knowledge.superior.questions.7.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.7.option.2',
          point: 40,
          feedback: 'game.knowledge.superior.questions.7.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.2',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.2.option.1',
          point: 50,
          feedback: 'game.knowledge.superior.questions.2.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.2.option.2',
          point: -40,
          feedback: 'game.knowledge.superior.questions.2.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.3',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.3.option.1',
          point: 50,
          feedback: 'game.knowledge.superior.questions.3.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.3.option.2',
          point: -40,
          feedback: 'game.knowledge.superior.questions.3.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.knowledge.superior.questions.4',
      type_question: 'right_or_wrong',
      options: [
        {
          response_text: 'game.knowledge.superior.questions.4.option.1',
          point: -40,
          feedback: 'game.knowledge.superior.questions.4.option.1.feedback',
        },
        {
          response_text: 'game.knowledge.superior.questions.4.option.2',
          point: 50,
          feedback: 'game.knowledge.superior.questions.4.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  skills: [
    {
      level: 'superior',
      question: 'game.skills.superior.questions.1',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.skills.superior.questions.1.option.1',
          point: -20,
          feedback: 'game.skills.superior.questions.1.option.1.feedback',
        },
        {
          response_text: 'game.skills.superior.questions.1.option.2',
          point: 40,
          feedback: 'game.skills.superior.questions.1.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.5',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.skills.superior.questions.5.option.1',
          point: -30,
          feedback: 'game.skills.superior.questions.5.option.1.feedback',
        },
        {
          response_text: 'game.skills.superior.questions.5.option.2',
          point: 40,
          feedback: 'game.skills.superior.questions.5.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.6',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.skills.superior.questions.6.option.1',
          point: 10,
          feedback: 'game.skills.superior.questions.6.option.1.feedback',
        },
        {
          response_text: 'game.skills.superior.questions.6.option.2',
          point: 30,
          feedback: 'game.skills.superior.questions.6.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.7',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.skills.superior.questions.7.option.1',
          point: -20,
          feedback: 'game.skills.superior.questions.7.option.1.feedback',
        },
        {
          response_text: 'game.skills.superior.questions.7.option.2',
          point: 40,
          feedback: 'game.skills.superior.questions.7.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.8',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: 30,
          feedback: 'game.skills.superior.questions.8.option.1',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.2',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: 50,
          feedback: 'game.skills.superior.questions.2.feedback',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.3',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: -40,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.skills.superior.questions.4',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.skills.superior.questions.4.option.1',
          point: 40,
          feedback: 'game.skills.superior.questions.4.option.1.feedback',
        },
        {
          response_text: 'game.skills.superior.questions.4.option.2',
          point: -30,
          feedback: 'game.skills.superior.questions.4.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
  inspiration: [
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.1',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.1.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      has_interaction: false,
      complete: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.2',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.2.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.8',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.8.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.9',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.9.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.10',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.10.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.3',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.3.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.4',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.4.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.5',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.5.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.6',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.6.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.inspiration.superior.questions.7',
      type_question: 'only_good_thing',
      options: [
        {
          response_text: 'game.inspiration.superior.questions.7.option.1',
          point: 50,
          feedback: 'game.question.empty',
        },
      ],
      complete: false,
      has_interaction: false,
    },
  ],
  job: [
    {
      level: 'superior',
      question: 'game.job.superior.questions.1',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: 20,
          feedback: 'game.job.superior.questions.1.feedback',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.job.superior.questions.4',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: 30,
          feedback: 'game.job.superior.questions.4.feedback',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.job.superior.questions.5',
      type_question: 'luck_or_setback',
      options: [
        {
          response_text: 'game.question.empty',
          point: -20,
          feedback: 'game.job.superior.questions.5.feedback',
        },
      ],
      complete: false,
      has_interaction: false,
    },
    {
      level: 'superior',
      question: 'game.job.superior.questions.6',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.job.superior.questions.6.option.1',
          point: 30,
          feedback: 'game.job.superior.questions.6.option.1.feedback',
        },
        {
          response_text: 'game.job.superior.questions.6.option.2',
          point: -30,
          feedback: 'game.job.superior.questions.6.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.job.superior.questions.2',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.job.superior.questions.2.option.1',
          point: 30,
          feedback: 'game.job.superior.questions.2.option.1.feedback',
        },
        {
          response_text: 'game.job.superior.questions.2.option.2',
          point: 10,
          feedback: 'game.job.superior.questions.2.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
    {
      level: 'superior',
      question: 'game.job.superior.questions.3',
      type_question: 'decision_making',
      options: [
        {
          response_text: 'game.job.superior.questions.3.option.1',
          point: 20,
          feedback: 'game.job.superior.questions.3.option.1.feedback',
        },
        {
          response_text: 'game.job.superior.questions.3.option.2',
          point: 20,
          feedback: 'game.job.superior.questions.3.option.2.feedback',
        },
      ],
      complete: false,
      has_interaction: true,
    },
  ],
}
