import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  CompaniesContainer,
  ContainerFooter,
  ContentWrapper,
  CopyrightSection,
  InformationLink,
  InformationText,
} from './styles'
import { injectIntl } from 'react-intl'

import logoJAAM from '../../../../assets/img/companies/logoJAAM.png'
import logoAccenture from '../../../../assets/img/companies/logoAccenture.svg'

export const FooterCp = ({ intl, onRulesClick }) => {
  const logosData = [
    { nameFileLogo: logoJAAM, alt: 'logo JA Americas' },
    { nameFileLogo: logoAccenture, alt: 'logo Accenture' },
  ]

  const renderedLogos = useMemo(makeLogos, [])
  function makeLogos() {
    return logosData.map(({ nameFileLogo, alt }) => (
      <figure key={nameFileLogo} data-test={nameFileLogo}>
        <img src={nameFileLogo} alt={alt} />
      </figure>
    ))
  }

  return (
    <ContainerFooter className="container" data-test="footer">
      <ContentWrapper>
        <CompaniesContainer data-test="CompaniesContainer">
          {renderedLogos}
        </CompaniesContainer>

        <CopyrightSection data-test="CopyrightSection">
          <InformationLink
            data-test="JA link"
            href="https://www.jabrasil.org.br/"
            target="_blank"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'landingpage.menu.about' }),
            }}
          />

          <InformationLink
            data-test="termOfPrivacy"
            onClick={onRulesClick}
            href="#"
            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'terms' }) }}
          />

          <InformationText
            data-test="copyrightText"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'landingpage.copyright' }),
            }}
          />
        </CopyrightSection>
      </ContentWrapper>
    </ContainerFooter>
  )
}

FooterCp.propTypes = {
  intl: PropTypes.object,
  onRulesClick: PropTypes.any,
}

export const Footer = injectIntl(FooterCp)
