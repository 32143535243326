import { useMemo } from 'react'

import {
  ImgQuiz,
  ImgSituation,
  ImgAbility,
  ImgJob,
  ImgInspiration,
} from '../../assets/img/categoriesCards'

export function useContentOfTextOnUnderstandingCards(categoryName) {
  const stringUnderstandingCard = {
    knowledge: {
      title: 'game.knowledge',
      message: 'game.knowledge.message',
    },
    inspiration: {
      title: 'game.inspiration',
      message: 'game.inspiration.message',
    },
    job: {
      title: 'game.job',
      message: 'game.job.message',
    },
    challenge: {
      title: 'game.challenge',
      message: 'game.challenge.message',
    },
    skills: {
      title: 'game.skills',
      message: 'game.skills.message',
    },
  }

  const contentCard = useMemo(
    () => ({
      challenge: {
        ...stringUnderstandingCard.challenge,
        Icon: ImgSituation,
      },
      inspiration: {
        ...stringUnderstandingCard.inspiration,
        Icon: ImgInspiration,
      },
      knowledge: {
        ...stringUnderstandingCard.knowledge,
        Icon: ImgQuiz,
      },
      skills: {
        ...stringUnderstandingCard.skills,
        Icon: ImgAbility,
      },
      job: {
        ...stringUnderstandingCard.job,
        Icon: ImgJob,
      },
    }),
    [stringUnderstandingCard],
  )[categoryName]

  return {
    ...contentCard,
  }
}
