import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;

  @media screen and (max-width: 1084px) {
    top: 45px;
  }

  @media screen and (max-width: 1400px) {
    padding: 0.5rem;
  }

  .SignUpCard {
    max-width: 1360px;
    margin: 1.4rem;
    align-self: center;

    @media screen and (max-width: 1400px) {
      margin: 0;
    }
  }
`

export const CardBody = styled.main`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0.8rem;

  @media screen and (min-width: 1024px) {
    margin: 25px 0 25px 30px;
    margin-right: 12px;
    padding-bottom: 5px;
  }
`

export const ContainerCharactersNButtons = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  padding: 2.7rem 0 0.5rem;
`
export const CardFooter = styled.footer`
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.3rem;
  border-top: 0.2rem solid;
  display: grid;
  grid-template-columns: 2fr 1.5fr 3fr 1.5fr 3fr 1.5fr 7fr;
  overflow: hidden;
  width: calc(100% + 0.5px);

  div {
    height: 1rem;
  }

  div:nth-child(1) {
    background-color: ${({ theme }) => theme.colors.reddishOrange};
  }
  div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.pistachioGreen};
  }
  div:nth-child(3) {
    background-color: ${({ theme }) => theme.colors.lemonGreen};
  }
  div:nth-child(4) {
    background-color: ${({ theme }) => theme.colors.amber};
  }
  div:nth-child(5) {
    background-color: ${({ theme }) => theme.colors.EmeraldGreen};
  }
  div:nth-child(6) {
    background-color: ${({ theme }) => theme.colors.pistachioGreen};
  }
  div:nth-child(7) {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`

export const BalloonOfDescriptionCharacter = styled.div`
  border-radius: 0.3rem;
  border: solid 0.2rem ${({ theme }) => theme.colors.black};
  box-shadow: -2.5px 3px rgb(29, 29, 27);

  padding: 1rem;

  position: relative;
  margin: 0.5rem 0 0;

  &::before {
    content: '';
    position: absolute;
    left: calc(60% - 20px);
    top: -12px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 1);
  }

  p {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: -0.05px;
  }
`

export const ContainerChooseButton = styled.div`
  bottom: 1rem;
  display: flex;
  justify-content: flex-end;
`
