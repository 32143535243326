import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Card } from '..'
import { CardContent, ContainerButtons } from './styles'
import { Paragraph } from '../cardWithFlag/styles'

import { isMobileSize } from '../../../common/utils'
import { Share } from '../../Share'
import { ButtonWithRadiusBorderComponent } from '../../Buttons'
import { TextMedal } from '../../Account/components/medalCard/styles'
import { Container } from '../../CardEndGame/styles'
import { get } from '../../../services/storage'
import { setMedal } from '../../../services/API/medals'
import { gamePropTypes } from '../../../game'
import { setGame } from '../../../services/API/game'
import { useStore } from '../../../store/hooks'
import { types } from '../../../store'
import { injectIntl, FormattedMessage } from 'react-intl'

export const WinMedalCardCp = ({
  G,
  openFeedBack,
  openShareCard,
  openMedalCard,
  character,
  Icon,
  intl,
}) => {
  const { dispatch } = useStore()
  const isMobile = useMemo(isMobileSize, [])
  const isLogged = useMemo(() => {
    return !!get('token')
  }, [])

  useEffect(setNewMedal, [])
  function setNewMedal() {
    if (isLogged) {
      saveGame()
      const object_medal = {
        [character.toLowerCase()]: true,
      }

      setMedal(object_medal)
    }
  }

  const saveGame = async () => {
    const payload = {
      user: get('user').id,
      game: G,
    }

    await setGame(payload)
    dispatch({ type: types.SET_G, payload: payload.game })
  }

  return (
    <>
      <Container>
        <Card data_test="CardWinMedal" isSmall maxWidth="700px">
          <CardContent>
            <Icon />
            <TextMedal
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: 'account.medals.win' },
                  { name: character },
                ),
              }}
            />
            <Paragraph>
              <FormattedMessage id="account.medals.win.description" />
            </Paragraph>
            <ContainerButtons>
              {isLogged && (
                <ButtonWithRadiusBorderComponent
                  onClick={openMedalCard}
                  width={197}
                  text={intl.formatMessage({ id: 'account.medals.view' })}
                />
              )}
              <ButtonWithRadiusBorderComponent
                onClick={() => openFeedBack(true)}
                withoutMarginTop={isMobile}
                width={197}
                text={intl.formatMessage({ id: 'game.player.again' })}
              />
              <ButtonWithRadiusBorderComponent
                withoutMarginTop={isMobile}
                color="white"
                width={197}
                onClick={() => openFeedBack(false)}
                text={intl.formatMessage({ id: 'exit' })}
              />
            </ContainerButtons>
            <Share openShareCard={() => openShareCard(false)} />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

WinMedalCardCp.propTypes = {
  G: gamePropTypes.G,
  openFeedBack: PropTypes.func.isRequired,
  openShareCard: PropTypes.func.isRequired,
  character: PropTypes.string.isRequired,
  openMedalCard: PropTypes.func.isRequired,
  Icon: PropTypes.object.isRequired,
  intl: PropTypes.object,
}

export const WinMedalCard = injectIntl(WinMedalCardCp)
