import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { gamePropTypes } from '../../game'
import { theme } from '../../styles/theme'

import { PointText } from './style'

export const BadgePoints = ({ G, anchorPosition }) => {
  const { colors } = theme
  const [pointsCommitted, setPointsCommitted] = useState()
  const [isNegative, setIsNegative] = useState()
  const [badgeColor, setBadgeColor] = useState()
  const [position, setPosition] = useState()
  const animateOpacity = useRef()

  useEffect(setBadgePoints, [G.pointsCredited])
  function setBadgePoints() {
    if (G.pointsCredited !== 0) {
      const negative = G.pointsCredited <= 0
      setPointsCommitted(G.pointsCredited)
      setIsNegative(negative)

      setBadgeColor(negative ? colors.reddishOrange : colors.pistachioGreen)
      animateOpacity.current.beginElement()
    }
  }

  useEffect(positionBadge, [anchorPosition])
  function positionBadge() {
    setPosition(anchorPosition)
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="31"
      x={position ? position[0] : 0}
      y={position ? position[1] - 35 : 0}
      viewBox="0 0 85 31">
      <g opacity="0" id="badge">
        <g>
          <g transform="translate(-140 -180) translate(140 180)">
            <rect width="85" height="31" fill={badgeColor} rx="5" />
            <path
              fill="#FFF"
              fillOpacity=".2"
              d="M76 6c1.657 0 3 1.343 3 3v9H6V9c0-1.657 1.343-3 3-3h67z"
            />
            <PointText>
              <tspan x="19.431" y="23">{`${
                isNegative ? '' : '+'
              }${pointsCommitted}xp`}</tspan>
            </PointText>
          </g>
        </g>
      </g>

      <animate
        xlinkHref="#badge"
        attributeName="opacity"
        begin="indefinite"
        dur="3s"
        values="0; 1; 1; 0"
        keyTimes="0; 0.1; 0.9; 1"
        ref={animateOpacity}
      />
    </svg>
  )
}

const { G } = gamePropTypes
BadgePoints.propTypes = {
  G,
  anchorPosition: PropTypes.arrayOf(PropTypes.number),
}
