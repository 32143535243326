export default {
  'game.challenge.superior.questions.1':
    '{name} estudió mucho para aprobar una universidad pública, pero desafortunadamente fracasó. La situación desanimó a {name}, que no tiene gasolina para empezar de nuevo.',
  'game.challenge.superior.questions.1.feedback':
    '¡Los problemas son parte de la vida! ¡Concéntrate en el portal Z, {name}!',

  'game.challenge.superior.questions.2':
    '{name} comparte departamento con una amiga. Pero la amiga consiguió una beca en otro estado y tendrá que mudarse. {name} no puede pagar todas las facturas. ¿Qué hacer?',
  'game.challenge.superior.questions.2.option.1':
    'Buscando a alguien con quien compartir el alquiler',
  'game.challenge.superior.questions.2.option.1.feedback':
    '¡Gran idea! Y el amigo seguirá ayudando con la promoción.',
  'game.challenge.superior.questions.2.option.2':
    'Empieza a impartir clases particulares para pagar el alquiler.',
  'game.challenge.superior.questions.2.option.2.feedback':
    '{name} mostró su lado empresarial, pero corre el riesgo de no obtener el valor de cada més.​',

  'game.challenge.superior.questions.3':
    '{name} comenzó la educación superior y se dio cuenta de que necesitaría dinero para pagar sus gastos. ¿Y ahora?',
  'game.challenge.superior.questions.3.option.1':
    'Busca un trabajo para pagar las facturas y seguir estudiando',
  'game.challenge.superior.questions.3.option.1.feedback':
    '{name} obtuvo una pasantía a tiempo parcial. Le fue bien en la universidad y ganó elogios de sus compañeros de trabajo. ¡{name} va al mercado laboral y acumula XP para llegar al Portal Z! ¡Bien!',
  'game.challenge.superior.questions.3.option.2':
    'Pide ayuda familiar y permanece 100% centrado en los estudios',
  'game.challenge.superior.questions.3.option.2.feedback':
    '{name} estudió dos veces y se involucró en varios proyectos. Fue un gran semestre y {name} consiguió más XP para llegar al portal Z. ¡Bien!',

  'game.challenge.superior.questions.4':
    '{name} estaba yendo a su casa de noche, después de clases, cuando le robaron su bolso con la computadora personal y el dinero del mes para transporte. {name} tendrá que buscar ingresos extra para recupera e}l dinero y conseguir comprar un nuevo computador.',
  'game.challenge.superior.questions.4.feedback':
    '¡Los problemas son parte de la vida!¡Haz foco en el portal Z, {name}!',

  'game.challenge.superior.questions.5':
    '{name} creó una cuenta de Instagram para dar consejos sobre el estudio. Sus contenidos han recibido comentatios positivos, sin embargo, {name} vió algunos comentarios maliciosos que mermaron su autoconfianza. ¿Qué hacer?',
  'game.challenge.superior.questions.5.option.1':
    'Enfocarse en los puntos de mejora y en los comentarios positivos.',
  'game.challenge.superior.questions.5.option.1.feedback':
    '¡Buena elección! Es muy importante filtrar los comentarios, enfocar en la mejora del contenido y continuar.',
  'game.challenge.superior.questions.5.option.2':
    'Dejar de postear contenidos en la red social.',
  'game.challenge.superior.questions.5.option.2.feedback':
    '¡Qué pena! {name} tuvo la iniciativa de crear un buen proyecto, pero terminó desanimado debido a algunos comentarios negativos. Faltó perseverancia y autoconfianza para seguir.',

  'game.challenge.superior.questions.6':
    '{name} está enfrentando problemas familiares, y por eso siente angustia cuando piensa en su futuro. ¿Qué hacer?',
  'game.challenge.superior.questions.6.option.1':
    'Sigue su rutina y procura ignorar sus sentimientos.',
  'game.challenge.superior.questions.6.option.1.feedback':
    'Ignorar los sentimientos propios agrava la situación de {name}. ¡Reconocer sus dificultades y buscar ayuda sería un gran comienzo!',
  'game.challenge.superior.questions.6.option.2':
    'Busca ayuda médica para ordenar su rutina de una mejor manera.',
  'game.challenge.superior.questions.6.option.2.feedback':
    '¡Muy bien! ¡{name} reconoció sus dificultades y buscó ayuda!. Felicitaciones por la iniciativa.',
}
