import { css } from 'styled-components'

export function setColorOfButton({ color, theme }) {
  if (checkIfNotIsOrange(color) && colorIsValid(color, theme)) {
    return css`
      background: ${theme.colors[color]};
    `
  }
  return css`
    background: ${theme.colors.orange};
    background: ${theme.linearGradients.orange};
  `
}

function checkIfNotIsOrange(color) {
  return color !== 'orange'
}

function colorIsValid(color, themeObject) {
  return !!themeObject.colors[color]
}
