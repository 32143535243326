export default {
  feedback: 'Assessment',
  'feedback.recommend': 'Yes, I would recommend it',
  'feedback.recommend.not': 'No, I would not recommend it',
  'feedback.recommend.question': 'Would you recommend the VUCA game to a friend?',
  'feedback.info':
    'Check the following phrases according to your opinion after playing VUCA',
  'feedback.disagree': 'I disagree.',
  'feedback.agree': 'I agree.',
}
