import React from 'react'
import PropTypes from 'prop-types'
import { Body, Footer } from './components'

import { CardWithHeader } from '../../../components'

import { Container } from './styles'
import { injectIntl } from 'react-intl'

export const CharactersCardCp = ({ intl }) => (
  <Container>
    <div className="choiceCharacterCard">
      <CardWithHeader
        titleCard={intl.formatMessage({ id: 'characters.choose' })}
        backgroundColorHeader="pistachioGreen"
        handleClickCloseButton={() => {}}
        withFooter={false}
        data_test="CharacterCard">
        <Body />
        <Footer />
      </CardWithHeader>
    </div>
  </Container>
)

CharactersCardCp.propTypes = {
  intl: PropTypes.object,
}

export const CharactersCard = injectIntl(CharactersCardCp)
