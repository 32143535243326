import React from 'react'
import PropTypes from 'prop-types'

import { CheckBox } from '..'
import { OptionCard, OptionTextContainer } from './styles'
import { injectIntl } from 'react-intl'

export const QuestionsCp = ({
  options,
  indexSelectedOptions,
  selectOption,
  intl,
  character,
  stage,
}) =>
  options.map(({ response_text, point }, index) => {
    const check = indexSelectedOptions === index
    const selectWithCurrentIndex = selectOption(index)
    return (
      <OptionCard
        data-test={`option-${index}`}
        key={index}
        onClick={selectWithCurrentIndex}
        checked={check}>
        <div className="checkboxQuestion">
          <CheckBox retrieveCheckOrUnchecked={selectWithCurrentIndex} checked={check} />
        </div>
        <OptionTextContainer data-test={`option-text-${index}`}>
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                { id: response_text },
                { name: character.name, point, stage },
              ),
            }}
          />
        </OptionTextContainer>
      </OptionCard>
    )
  })

QuestionsCp.propTypes = {
  options: PropTypes.arrayOf({
    response_text: PropTypes.string.isRequired,
    point: PropTypes.number.isRequired,
    feedback: PropTypes.string.isRequired,
  }).isRequired,
  indexSelectedOptions: PropTypes.any.isRequired,
  selectOption: PropTypes.func.isRequired,
  intl: PropTypes.object,
  character: PropTypes.object,
  stage: PropTypes.string,
}

export const Questions = injectIntl(QuestionsCp)
