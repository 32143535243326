import { string, object } from 'prop-types'
import React from 'react'

import { injectIntl } from 'react-intl'

import { ToolTip } from './styles'

export const TooltipCp = ({ tooltipMsg, intl }) => (
  <ToolTip tooltipMsg={intl.formatMessage({ id: tooltipMsg })}>
    <b>?</b>
  </ToolTip>
)

TooltipCp.propTypes = {
  tooltipMsg: string,
  intl: object,
}

export const Tooltip = injectIntl(TooltipCp)
