import { createGlobalStyle } from 'styled-components'

import fontMilkyNice from '../assets/fonts/MilkyNice.ttf'

export const Reset = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ol,
ul {
  list-style: none;
}

@font-face {
  font-family: MilkyNice;
  src: url(${fontMilkyNice}) format('truetype');
  font-style: normal;
  font-weight: normal;
}

body {
  height: 100vh;
}
  /* .die {
      width: 40px !important;
      height: 40px !important;
  .face {
      width: 40px !important;
      height: 40px !important;
    }

    .dot {
      width:4px !important;
      height:4px !important;
    }
  } */
`
