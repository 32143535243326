import styled, { css } from 'styled-components'

import InfoGameSectionMobile from '../../assets/img/shapes/InfoGameSectionMobile.svg'
import allCharactersDesktop from '../../assets/img/characters/allCharactersDesktop-landingpage.svg'
import sectionVucaTexts from '../../assets/img/shapes/sectionVucaTexts.svg'
import backgroundChallengeBlock from '../../assets/img/shapes/backgroundChallengeBlock.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    .container {
      margin: 0 auto;
      padding: 0 2rem;
      font-family: Montserrat;

      &--withMarginTop {
        padding-top: 6rem;
      }

      @media (min-width: 768px) {
        width: 750px;
      }
      @media (min-width: 992px) {
        width: 970px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }

      .logo small {
        top: -2px;
        font-size: 16px;
      }
    }
  `}
`

export const MainSection = styled.main`
  @media screen and (min-width: 1024px) {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
  }
`

export const Paragraph = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.06px;
  margin: 1.5rem 0 0;

  @media screen and (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: -0.08px;
  }
`

export const ContainerTextContentMobile = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const ContainerTextContentDesktop = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const JonathanFigure = styled.figure`
  display: grid;
  place-items: center;
  margin: 3rem 0;
  position: relative;
  z-index: 10;
`

export const SectionVucaTexts = styled.section`
  @media screen and (min-width: 1024px) {
    align-items: flex-end;
    background-image: url(${sectionVucaTexts});
    background-size: 100%;
    background-repeat: no-repeat;
    object-fit: contain;

    display: flex;

    margin: -14rem 0 4rem;
    position: relative;
    z-index: 5;

    height: 30rem;
  }
`

export const WelcomeTitleMobile = styled.h2`
  font-family: MilkyNice;
  font-size: 1rem;
  letter-spacing: -0.07px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const WelcomeTitleDesktop = styled.h2`
  font-family: MilkyNice;
  font-size: 1.75rem;
  letter-spacing: -0.12px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const VucaTitle = styled.h3`
  font-family: MilkyNice;
  font-size: 2.5rem;
  letter-spacing: -0.18px;
  margin: 0 0 2rem;

  @media screen and (min-width: 1024px) {
    font-size: 5.625rem;
    letter-spacing: -0.4px;
  }
`

export const InfoGameSection = styled.section`
  background-image: url(${InfoGameSectionMobile});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 4;

  @media screen and (min-width: 1024px) {
    background-image: url(${allCharactersDesktop});
    background-size: 100%;
    background-repeat: no-repeat;
    height: 792px;

    margin: -10rem 0 0;
    padding: 10rem 0 0;
  }

  @media screen and (min-width: 1312px) {
    background-size: 92%;
    margin: -4rem 0 0;
  }
  @media screen and (min-width: 1430px) {
    background-size: 85%;
  }
  @media screen and (min-width: 1550px) {
    background-size: 80%;
  }
  @media screen and (min-width: 1650px) {
    background-size: 75%;
    margin: -2rem 0 0;
  }
  @media screen and (min-width: 1755px) {
    background-size: 70%;
    padding: 5rem 0 0;
  }
  @media screen and (min-width: 1885px) {
    background-size: 65%;
  }
  @media screen and (min-width: 2030px) {
    background-size: 60%;
  }
  @media screen and (min-width: 2200px) {
    background-size: 55%;
  }
  @media screen and (min-width: 2400px) {
    background-size: 50%;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 1.875rem;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 1024px) {
    display: grid;
    justify-content: right;
  }
`

export const WrapperFirstLineCards = styled.div`
  display: grid;
  gap: 1.875rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    gap: 1.875rem;
    grid-template-columns: 1fr 1fr;
  }
`

export const WrapperSecondLineCards = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`

export const AllCharactersMobile = styled.figure`
  margin: 2.75rem 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const ChallengeBlock = styled.section`
  align-items: center;
  display: flex;
  margin: 2rem 0;
  justify-content: center;

  background-image: url(${backgroundChallengeBlock});
  background-size: 100%;
  background-repeat: no-repeat;

  h4 {
    font-family: MilkyNice;
  }

  @media screen and (min-width: 1024px) {
    text-align: center;
    height: 30rem;
    margin: 0;

    h4 {
      font-size: 1.75rem;
      line-height: 0.71;
      letter-spacing: -0.12px;
      margin: 0 0 2.8125rem;
    }
  }
`
