import { Api } from './index'

export async function register(dataRequest) {
  try {
    const response = await Api.post('/user', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function update(dataRequest, id) {
  try {
    const response = await Api.put(`/user/${id}`, dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function getUser() {
  try {
    const response = (await Api.get('/user')).data
    return response
  } catch (err) {
    return undefined
  }
}

export async function changePassword(dataRequest, id) {
  try {
    const response = await Api.patch(`/user/${id}/password`, dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function deactivate(userId) {
  try {
    const response = (await Api.put(`/user/${userId}/deactivate`)).data
    return response
  } catch (err) {
    return undefined
  }
}
