import styled from 'styled-components'

export const ShareContent = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 18px;
  align-items: center;
  margin-top: 30px;

  svg {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 1000px) {
    gap: 10px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`

export const ShareText = styled.div`
  font-family: MilkyNice;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.08px;
`
