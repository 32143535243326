import styled, { css } from 'styled-components'

export const ConfirmationButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;

  ${({ isOnlyInfo }) =>
    isOnlyInfo &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`

export const Paragraph = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.06px;
`
