import {
  IcBrendaMedal,
  IcCrisMedal,
  IcJonathanMedal,
  IcMilenaMedal,
} from '../../../../assets/img/medals'
import {
  femalepersona1,
  femalepersona2,
  malePersona1,
  malePersona2,
} from '../../../../common/strings'

export const dataOfMedals = () => {
  return [
    {
      character: malePersona1,
      date: '',
      username: '',
      data_test: 'crisMedalButton',
      Icon: IcCrisMedal,
      active: false,
    },
    {
      character: femalepersona1,
      date: '',
      username: '',
      data_test: 'milenaMedalButton',
      Icon: IcMilenaMedal,
      active: false,
    },
    {
      character: femalepersona2,
      date: '',
      username: '',
      data_test: 'brendaMedalButton',
      Icon: IcBrendaMedal,
      active: false,
    },
    {
      character: malePersona2,
      date: '',
      username: '',
      data_test: 'jonathanMedalButton',
      Icon: IcJonathanMedal,
      active: false,
    },
  ]
}
