export default {
  'game.challenge.junior.questions.1':
    '{name} teve um ano difícil, com muitos problemas familiares, e acabou sendo reprovada na escola. Problemas podem tirar qualquer um do sério, mas fazem parte da vida. {name} deixou de ganhar alguns xp, mas está pronta para enfrentar o novo ano com foco e determinação!',

  'game.challenge.junior.questions.2':
    'Um grupo de estudantes implicou com o cabelo da {name} e ir à escola virou um drama. E agora? O que fazer?',
  'game.challenge.junior.questions.2.option.1': 'Aguenta firme até o ano terminar.',
  'game.challenge.junior.questions.2.option.1.feedback':
    'Ficar calado não ajudou em nada. O semestre foi bem difícil e {name} ganhou pouco xp.',
  'game.challenge.junior.questions.2.option.2': 'Comunica aos pais e a diretoria.​',
  'game.challenge.junior.questions.2.option.2.feedback':
    'Parabéns pela coragem! Boa! A escola descobriu que {name} não era a única vítima. Graças à sua atitude, a escola organizou uma campanha anti-bullying e o clima melhorou para todos!​',

  'game.challenge.junior.questions.3':
    'Os pais de {name} se separaram e quem pagava as contas era o pai. Com a separação, {name} fica pensando se...',
  'game.challenge.junior.questions.3.option.1': 'Começa a vender doces com a mãe.',
  'game.challenge.junior.questions.3.option.1.feedback':
    'Decisão difícil, né? Esta será a primeira experiência profissional de {name}, e agora terá que equilibrar os estudos com as novas obrigações para conquistar xp e chegar ao Portal Z.',
  'game.challenge.junior.questions.3.option.2':
    'Continua foco exclusivamente nos estudos, mesmo sabendo que o padrão de vida da família irá diminuir.​',
  'game.challenge.junior.questions.3.option.2.feedback':
    'A decisão foi difícil, mas {name} percebeu como a falta do ensino básico limitou as opções da família, e resolveu focar nos estudos pra chegar mais perto do Portal Z.',

  'game.challenge.junior.questions.4':
    '{name} não está conseguindo acompanhar e compreender o conteúdo das aulas de matemática e português. {name} sente vontade de:',
  'game.challenge.junior.questions.4.option.1': 'Largar a escola e voltar ano que vem.',
  'game.challenge.junior.questions.4.option.1.feedback':
    'Desistir dos estudos nunca é uma boa opção. {name} segue estudando, mas ainda sente dificuldade.',
  'game.challenge.junior.questions.4.option.2': 'Fazer um esforço extra e pedir ajuda.​',
  'game.challenge.junior.questions.4.option.2.feedback':
    'Muito bem! Os esforços de {name} não foram suficientes para ser aprovado em matemática, mas por outro lado {name} desenvolveu resiliência e isso foi muito importante nos outros desafios da vida.',

  'game.challenge.junior.questions.5':
    '{name} caiu na conversa de seus colegas de que a escola é uma perda de tempo e acabou se dedicando pouco aos estudos.',
  'game.challenge.junior.questions.5.feedback':
    'Agora {name} está sentindo dificuldade para concluir as matérias.',

  'game.challenge.junior.questions.6':
    'A escola de {name} está promovendo debates sobre empreendedorismo e educação financeira como parte extra curricular.',
  'game.challenge.junior.questions.6.option.1': 'Se inscreve para participar',
  'game.challenge.junior.questions.6.option.1.feedback':
    'Mesmo sem curtir muito esse assunto {name} participou dos debates a percebeu que pode aprender a gostar de novos temas. Boa!',
  'game.challenge.junior.questions.6.option.2': 'Não curte muito esses temas',
  'game.challenge.junior.questions.6.option.2.feedback':
    'Poxa! Esses temas podem ajudar bastante na vida de {name}. Quem sabe na próxima né?!',
}
