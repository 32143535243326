import styled from 'styled-components'

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1.42rem;

  p {
    font-family: Montserrat;
    font-size: 0.875rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2.14rem;
  }
`

export const ContainerButton = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
`

export const LinkIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  svg {
    margin-left: 20px;
  }
`

export const VucaLink = styled.div`
  width: 90%;
  height: 38px;
  border-radius: 19px;
  border: solid 4px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 20px;
  font-family: Montserrat;
  font-size: 14px;
`

export const LinkCopied = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  letter-spacing: -0.05px;
  color: ${({ theme }) => theme.colors.orange};
`
