import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.knowledge': 'Quiz',
  'game.knowledge.message':
    'Time to test your knowledge! In this box you will answer questions to gain experience points.',

  ...junior,
  ...middle,
  ...superior,
}
