export default {
  feedback: 'Evaluación',
  'feedback.recommend': 'Sí, lo recomendaría',
  'feedback.recommend.not': 'No lo recomendaría',
  'feedback.recommend.question': '¿Recomendarías el juego VUCA a un amigo?',
  'feedback.info':
    'Califica las frases a continuación según tu percepción después de jugar el Juego VUCA:',
  'feedback.disagree': 'no estoy de acuerdo',
  'feedback.agree': 'estoy de acuerdo',
}
