import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.inspiration': 'Inspiração',
  'game.inspiration.message':
    'Boa! Essa é uma casa de Inspiração. Aqui você receberá aquela forcinha para seguir em frente e ainda alguns pontos extras de experiência.',

  ...junior,
  ...middle,
  ...superior,
}
