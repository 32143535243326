import styled from 'styled-components'

export const ToolTip = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 2px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.black};
  transition: all 500ms;

  b {
    font-family: MilkyNice;
    font-size: 11px;
    color: white;
    position: relative;
    bottom: 3.5px;
    left: 3px;
  }

  &:hover {
    &::before {
      content: '${({ tooltipMsg }) => tooltipMsg}';
      max-width: 250px;
      font-family: Montserrat;
      background-color: ${({ theme }) => theme.colors.amber};
      border: 3px solid;
      margin: 26px 16px 0 -60px;
      position: absolute;
      padding: 20px;
      border-radius: 4px;
      z-index: 99999;
      font-size: 14px;
      font-height: 1.43;
      font-weight: 500 !important;
    }

    &::after {
      content: '';
      position: relative;
      top: 0px;
      bottom: -16px;
      width: 0;
      height: 0;
      z-index: 9999;
      border-left: 5px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 13px solid rgba(0,0,0,1);
    }
  }
`
