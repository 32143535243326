import { gameConstants } from '../common/strings'

const { DEBUFF } = gameConstants.CONDITION_TYPES
const conditions = {
  employed: {
    type: DEBUFF,
    name: 'Empregado',
    key: 'employed',
    categoriesAffected: {
      onCommitPoints: ['knowledge', 'skills'],
    },
    details:
      'Por estar trabalhando e estudando, você se sente cansado demais para estudar. Pontuação de XP ganha é reduzida pela metade.',
  },
}

export const checkIsCatergoryAffected = (category) => {
  return conditions.employed.categoriesAffected.onCommitPoints.includes(category)
}

export const config = {
  game: {
    lastStageIndex: 2,
  },
  board: {
    tileIconWidth: 35,
    tileIconHeight: 35,
    pawnIconHeight: 97,
    pawnIconWidth: 76,
    size: 400,
    showCentroidDots: false,
    tiles: [
      'challenge',
      'inspiration',
      'job',
      'knowledge',
      'skills',
      'challenge',
      'inspiration',
      'job',
      'knowledge',
      'skills',
      'challenge',
      'inspiration',
      'job',
      'inspiration',
      'knowledge',
      'skills',
    ],
  },
  cardCategories: [
    {
      name: 'challenge',
    },
    {
      name: 'knowledge',
    },
    {
      name: 'inspiration',
    },
    {
      name: 'job',
    },
    {
      name: 'skills',
    },
  ],
  player: {
    conditions,
    conditionsNames: Object.keys(conditions).map((c) => c),
  },
}
