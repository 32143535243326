export default {
  'game.knowledge.superior.questions.1':
    '¿Qué elemento a continuación es un ejemplo de metáfora?',
  'game.knowledge.superior.questions.1.option.1': 'Su vida es un cuento de hadas.',
  'game.knowledge.superior.questions.1.option.1.feedback':
    '¡Bueno! La metáfora puede ser un poderoso recurso de comunicación.',
  'game.knowledge.superior.questions.1.option.2': 'Esta flor se parece al sol.',
  'game.knowledge.superior.questions.1.option.2.feedback':
    'La metáfora es una forma de hablar que produce significados figurativos a través de comparaciones, como “cuento de hadas”. ¡Saber cómo usar esto es más útil de lo que piensas!',

  'game.knowledge.superior.questions.2': '¿Qué es el plagio?',
  'game.knowledge.superior.questions.2.option.1':
    'Asumir las ideas o los textos de otras personas sin darles el debido crédito.',
  'game.knowledge.superior.questions.2.option.1.feedback':
    '¡Muy bien! Y no lo olvide: ¡el plagio es un crimen!',
  'game.knowledge.superior.questions.2.option.2':
    'Publica una foto de una persona sin su permiso.​',
  'game.knowledge.superior.questions.2.option.2.feedback':
    'El plagio es el acto de utilizar las ideas y los textos de otras personas sin dar el debido crédito. Y no lo olvide: ¡el plagio es un crimen!',

  'game.knowledge.superior.questions.3':
    '¿Por qué es tan importante analizar datos e información en el mundo actual?',
  'game.knowledge.superior.questions.3.option.1':
    'Porque seleccionar y analizar la información disponible te ayuda a tomar decisiones de manera más eficiente',
  'game.knowledge.superior.questions.3.option.1.feedback':
    '¡Bien! Ya sabes que tomar decisiones simplemente sobre la base de la intuición no es la forma más eficiente. ¡Y eso vale para la vida y el trabajo!',
  'game.knowledge.superior.questions.3.option.2':
    'Porque el análisis de datos le ayuda a programar y desarrollar sitios web',
  'game.knowledge.superior.questions.3.option.2.feedback':
    'El análisis de datos es un término técnico que se utiliza para describir el acto de seleccionar y analizar la información disponible.',

  'game.knowledge.superior.questions.4':
    '¿Cuál de los items de abajo no es una extensión de formato de imagen?',
  'game.knowledge.superior.questions.4.option.1': '.png',
  'game.knowledge.superior.questions.4.option.1.feedback':
    'Estas extensiones en los nombres de los archivos pueden parecer extrañas, pero forman parte del mundo digital. HTML no es un tipo de imagen.',
  'game.knowledge.superior.questions.4.option.2': '.html​',
  'game.knowledge.superior.questions.4.option.2.feedback':
    '¡Felicidades!¡Ya sabe que el mundo está cada vez más digital y no quiere quedarse fuera!',

  'game.knowledge.superior.questions.5': '¿Qué es la realidad virtual?',
  'game.knowledge.superior.questions.5.option.1':
    'Robots y computadoras controlados por máquinas.',
  'game.knowledge.superior.questions.5.option.1.feedback':
    'Respuesta incorrecta. La realidad virtual es un ambiente creado por la computadora, en el cual un usuario puede interactuar como si estuviera en el mundo real.',
  'game.knowledge.superior.questions.5.option.2':
    'Un mundo artificial y tridimensional creado por una computadora.',
  'game.knowledge.superior.questions.5.option.2.feedback':
    'Respuesta correcta. Realidad virtual es un ambiente creado por la computadora, en el cual un usuario puede interactuar como si estuviera en el mundo real.',

  'game.knowledge.superior.questions.6':
    'La Inteligencia artificial es una rama de las ciencias informáticas que hace foco en:',
  'game.knowledge.superior.questions.6.option.1':
    'La contrucción de computadoras y máquinas capaces de simular comportamiento inteligente.',
  'game.knowledge.superior.questions.6.option.1.feedback':
    '¡Respuestas correcta! La inteligencia artificial (IA) posibilita que las máquinas aprendan a través de experiencias y ejecuten tareas como si fuesen seres humanos.',
  'game.knowledge.superior.questions.6.option.2':
    'La democratización del conocimiento sobre informática.',
  'game.knowledge.superior.questions.6.option.2.feedback':
    '¡Respuesta incorrecta! La inteligencia artificial es una rama de las ciencias informáticas que desarrolla máquinas capaces de simular comportamiento inteligente.',

  'game.knowledge.superior.questions.7':
    'La palabra NETWORKING se utiliza para describir:',
  'game.knowledge.superior.questions.7.option.1': 'La habilidad digital de una persona.',
  'game.knowledge.superior.questions.7.option.1.feedback':
    'Respuesta incorrecta. Networking es el proceso de interactuar con otras personas para ampliar las redes de relacionamiento.',
  'game.knowledge.superior.questions.7.option.2':
    'Es el proceso de interactuar con otras personas para intercambiar informaciones y desarrollar contactos personales o profesionales.',
  'game.knowledge.superior.questions.7.option.2.feedback': '¡Respuesta Correcta!',
}
