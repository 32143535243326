import React from 'react'
import PropTypes from 'prop-types'

import { useStore } from '../store/hooks'
import { IntlProvider } from 'react-intl'
import i18n from './insert'

const Intl = ({ children }) => {
  const { store } = useStore()
  const { language } = store
  const msgI18n = i18n[language] || i18n.pt

  return (
    <IntlProvider locale={language} key={language} messages={msgI18n} onError={() => {}}>
      {children}
    </IntlProvider>
  )
}

Intl.propTypes = {
  children: PropTypes.any,
}

export default Intl
