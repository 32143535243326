import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import { HowToPlayImageCard } from '../../assets/img'
import { CardWithHeader } from '../../components'
import { BackgroundBlur, Container } from '../GameCards/styles'

import { ContentCard, ContainerButton } from './styles'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'

export const CardHowToPlayCp = ({ closeCardHowToPlay, back, intl }) => (
  <>
    <Container>
      <CardWithHeader
        titleCard={intl.formatMessage({ id: 'introduction.init' })}
        backgroundColorHeader="white"
        isSmall
        data_test="howToPlayCard">
        <ContentCard>
          <figure>
            <HowToPlayImageCard width="100%" />
          </figure>

          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'introduction.init.description' }),
            }}
          />

          <ContainerButton>
            <ButtonWithRadiusBorderComponent
              color="white"
              onClick={back}
              data_test="leaveGame"
              text={intl.formatMessage({ id: 'return' })}
            />

            <ButtonWithRadiusBorderComponent
              data_test="goToGame"
              onClick={closeCardHowToPlay}
              text={intl.formatMessage({ id: 'play' })}
            />
          </ContainerButton>
        </ContentCard>
      </CardWithHeader>
    </Container>
    <BackgroundBlur />
  </>
)

CardHowToPlayCp.propTypes = {
  closeCardHowToPlay: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const CardHowToPlay = injectIntl(CardHowToPlayCp)
