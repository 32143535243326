import React from 'react'

import { Container } from './styles'
import { CardTerms } from '../../components/Cards'

export const Terms = () => {
  return (
    <Container data-test="SignUpContainer">
      <CardTerms closeCardTerms={false} />
    </Container>
  )
}
