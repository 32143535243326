export default {
  'introduction.title': 'Ha llegado al mundo VUCA',
  'introduction.firstDescription':
    '<span>Cris</span>, <span>Milena</span>, <span>Brenda</span> y <span>Jonatan</span> están pasando por un periodo decisivo: La Escuela Elemental va a comenzar y los nuevos desafíos <span>del Mundo VUCA</span> también. Un lugar imprevisivel y llenos de sorpresas.',
  'introduction.secondDescription':
    'Ayudalos a pasar por las tres etapas: la <span>Escuela Elemental, Medio y Superior</span> para lograr 1000 puntos de experiencia al llegar al <span>Portal Z: el portal de las oportunidades,</span>¡donde podrán alcanzar la carrera de los sueños!',
  'introduction.thirdDescription':
    'Pero no piense que será fácil. <span>En cualquier momento puede tener un imprevisto en casa, necesita contar con la  suerte y también con sus conocimientos</span> para obtener más puntos de experiencia. ¿Vamos?',
  'introduction.init': 'Como jugar',
  'introduction.init.description':
    'Juegue el dado para comenzar. Vas a avanzar el número de casas que tires y podrás caer en una de las cinco casas: <span> Trabajo, Pregunta, Habilidades, Situación e Inspiración. </span>',
}
