import styled from 'styled-components'

export const Title = styled.div`
  width: 100%;
  height: 22px;
  font-family: MilkyNice;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  margin-right: 22%;
  margin-top: ${({ isFirst }) => (isFirst ? '0' : '20px')};
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const Container = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, 1fr);

  .divGrid {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    margin-right: 22%;

    .divGrid {
      display: block;
    }
  }
`

export const ContainerAceite = styled.div`
  margin-top: 30px;
`

export const FormContainer = styled.div`
  @media screen and (min-width: 1024px) {
    max-height: 600px;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`

export const Label = styled.div`
  display: flex;
  width: 100%;
  height: 19px;
  margin: 0 25px 4px 25px;
  font-family: MilkyNice;
  font-size: 14px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  gap: 6px;
`

export const FlexAround = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
`

export const Info = styled.div`
  font-family: Montserrat;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.67;
  letter-spacing: -0.05px;
`

export const TermsBox = styled.div`
  border-radius: 4px;
  height: 212px;
  background-color: ${({ theme }) => theme.colors.whiteOff};
  overflow: auto;
  padding: 5px;
`

export const ContainerButtons = styled.div`
  bottom: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  gap: 15px;
  margin-right: 14px;

  @media screen and (max-width: 1000px) {
    display: flex;
    margin-right: 0;
    flex-direction: column;
    align-items: center;

    button,
    div {
      width: 100% !important;
    }
  }
`

export const AlertTerms = styled.div`
  width: 78%;
  min-height: 64px;
  margin-right: 22%;
  margin-top: 30px;
  padding: 20px 77px 20px 16px;
  border-radius: 19px;
  border: solid 2px ${({ theme }) => theme.colors.veryLightOrange};
  background-color: ${({ theme }) => theme.colors.ligthOrange};

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.05px;
    color: ${({ theme }) => theme.colors.reddishOrange};

    display: flex;
    align-items: center;
    gap: 14px;
  }
`
