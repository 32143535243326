import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardHeader, CardBody, CardParagraph } from './styles'
import { FormattedMessage } from 'react-intl'

export const CardDetails = ({ iconName, title, textContent }) => (
  <Card data-test={`CardDetails${title}`}>
    <CardHeader data-test={`CardDetailsTitle${title}`}>
      {iconName && <img src={iconName} alt="Icone do card" />}
      <h4>
        <FormattedMessage id={title} />
      </h4>
    </CardHeader>

    <CardBody data-test={`CardDetailsContent${title}`}>
      <CardParagraph>
        <FormattedMessage id={textContent} />
      </CardParagraph>
    </CardBody>
  </Card>
)

CardDetails.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  textContent: PropTypes.string,
}
