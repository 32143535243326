import React from 'react'
import {
  CrisCharacter,
  MilenaCharacter,
  BrendaCharacter,
  JonathanCharacter,
} from '../../assets/img/characters'
import CrisIcon from '../../assets/img/characters/Cris.svg'
import BrendaIcon from '../../assets/img/characters/Brenda.svg'
import MilenaIcon from '../../assets/img/characters/Milena.svg'
import JonathanIcon from '../../assets/img/characters/Jonathan.svg'

export const characters = [
  {
    image: () => <CrisCharacter height="200" />,
    name: 'Cris',
    icon: CrisIcon,
    details: 'characters.cris.description',
  },
  {
    image: () => <MilenaCharacter height="200" />,
    name: 'Milena',
    icon: MilenaIcon,
    details: 'characters.milena.description',
  },
  {
    image: () => <BrendaCharacter height="200" />,
    name: 'Brenda',
    icon: BrendaIcon,
    details: 'characters.brenda.description',
  },
  {
    image: () => <JonathanCharacter height="200" />,
    name: 'Jonathan',
    icon: JonathanIcon,
    details: 'characters.jonathan.description',
  },
]
