import { Api } from './index'

export async function getMedal() {
  try {
    const response = await Api.get('/medal/user')
    return response
  } catch (err) {
    return err.response
  }
}

export async function setMedal(dataRequest) {
  try {
    const response = await Api.post(`/medal/user`, dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}
