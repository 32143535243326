import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { reportPlayWithoutLogin } from '../../../../services/analytics'

import { CardWithHeader } from '../../../../components'
import { BackgroundBlur, Container } from '../../../../components/GameCards/styles'

import { ContentCard, ContainerButton } from './styles'
import { ButtonWithRadiusBorderComponent } from '../../../../components/Buttons/buttonWithRadiusBorder'

import { isMobileSize } from '../../../../common/utils'

export const CardPlayWithoutLoginCp = ({ closeCardWithoutLogin, intl }) => {
  const isMobile = useMemo(isMobileSize, [])
  function playWithoutLogin() {
    reportPlayWithoutLogin()
    closeCardWithoutLogin(true)
  }

  function back() {
    closeCardWithoutLogin(false)
  }

  return (
    <>
      <Container>
        <CardWithHeader
          titleCard={intl.formatMessage({ id: 'login.playWithoutLogin' })}
          backgroundColorHeader="white"
          isSmall
          data_test="playWithoutLoginCard">
          <ContentCard>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'login.playWithoutLogin.description' }),
              }}
            />
            <br /> <br />
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'login.playWithoutLogin.description.question',
                }),
              }}
            />
            <ContainerButton>
              <ButtonWithRadiusBorderComponent
                color="white"
                type="button"
                onClick={back}
                data_test="backLoginButton"
                text={intl.formatMessage({ id: 'return' })}
              />

              <ButtonWithRadiusBorderComponent
                withoutMarginTop={isMobile}
                data_test="goToGameWithoutLoginButton"
                onClick={playWithoutLogin}
                text={intl.formatMessage({ id: 'login.play' })}
              />
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

CardPlayWithoutLoginCp.propTypes = {
  closeCardWithoutLogin: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const CardPlayWithoutLogin = injectIntl(CardPlayWithoutLoginCp)
