export default {
  login: 'Login',
  'login.notLogin': 'Você não fez login!',
  'login.save.alert':
    'Para salvar o jogo, você precisa fazer login ou se cadastrar (se não tiver login ainda)',
  'login.save.alert.init': 'Para salvar, você precisa iniciar um novo jogo.<br/>',
  'login.progress.not': 'Nenhum jogo em progresso',
  'login.title': 'Login',
  'login.doTitle': 'Fazer Login',
  'login.invalid': 'E-mail ou senha incorreta.',
  'login.play': 'Jogar',
  'login.signUp': 'Cadastre-se',
  'login.signUp.description': 'Para salvar o jogo, faça o login ou cadastre-se.',
  'login.notAccount': 'Não tem uma conta?',
  'login.registration': 'Faça o seu cadastro',
  'login.socialMedia': 'Ou jogue usando as Redes Sociais',
  'login.google': 'Login com Google',
  'login.facebook': 'Login com Facebook',
  'login.playWithoutLogin': 'Jogar sem login',
  'login.playWithoutLogin.description':
    'Você pode jogar sem fazer login em uma conta, mas não poderá salvar o progresso da sua partida nem o histórico de jogadas.',
  'login.playWithoutLogin.description.question': 'Quer jogar mesmo assim?',
  'login.forgetPassword': 'Esqueceu sua senha?',
  'login.forgetPassword.title': 'Esqueci a senha',
  'login.forgetPassword.description':
    'Enviaremos um e-mail com instruções para redefinição de senha.',
  'login.forgetPassword.description.emailSent':
    'Você receberá um e-mail com instruções para a redefinição de senha se o seu e-mail estiver cadastrado.',
}
