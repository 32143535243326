import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { initialState } from './initialState'
import { reducer } from './reducer'
export { types } from './types'

export const AppContext = createContext()
export function AppStore({ children }) {
  const [store, dispatch] = useReducer(reducer, initialState)
  return <AppContext.Provider value={{ store, dispatch }}>{children}</AppContext.Provider>
}

AppStore.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
}
