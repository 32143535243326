import styled, { css } from 'styled-components'

export const ConfirmationButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${({ isOnlyInfo }) =>
    isOnlyInfo &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`

export const Paragraph = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.06px;
  min-width: 300px;
`

export const SimpleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  outline: inherit;

  color: black;
  font-family: MilkyNice;
  min-width: 140px;
  max-height: 50px;
  margin-top: 30px;
`

export const Flag = styled.picture`
  position: relative;
  top: -21px;
  display: flex;
  justify-content: center;
`

export const Title = styled.div`
  position: relative;
  font-weight: bold;
  top: -52px;
  display: flex;
  font-family: MilkyNice;
  font-size: 18px;
  height: 0;
  justify-content: center;
`
