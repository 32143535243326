import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 0;
`

export const BackgroundBlur = styled.div`
  background-color: #606060;
  height: 100vh;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
`

export const ContentCard = styled.div`
  font-family: Montserrat;
  padding: 20px 30px 30px;

  p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
  }

  b {
    font-weight: bold;
  }
`

export const ImageContainer = styled.figure`
  margin: 0 0 0.5rem;
`

export const QuestionParagraph = styled.p`
  p {
  }
  margin: 0 0 1.875rem;
`

export const InspirationBalloon = styled.div`
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: -0.05px;

  border-radius: 0.3rem;
  border: solid 0.2rem ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.amber};

  padding: 20px;

  position: relative;
  margin: 0.5rem 0 0;

  b {
    font-weight: bold;
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(10% - 10px);
    bottom: -16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid rgba(0, 0, 0, 1);
  }

  &::before {
    content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="20" viewBox="0 0 26 20"><defs><path id="a34ayf8eza" d="M0 0L26 0 26 20 0 20z"/></defs><g fill="none" fill-rule="evenodd"><g><g transform="translate(-73 -175) translate(73 175)"><mask id="i79axfawrb" fill="white"><use/></mask><path fill="black" d="M21.64 10.49c1.349.151 2.36.706 3.037 1.662.672.956 1.011 2.013 1.011 3.169 0 1.208-.403 2.29-1.206 3.244-.805.956-1.984 1.435-3.541 1.435-.625 0-1.274-.113-1.948-.34-.674-.227-1.296-.614-1.868-1.17-.57-.552-1.04-1.308-1.4-2.264-.363-.954-.545-2.162-.545-3.623 0-2.716.803-5.169 2.414-7.359C19.2 3.056 21.252 1.31 23.743 0L26 3.018c-1.35.707-2.557 1.65-3.62 2.831-1.064 1.183-1.779 2.655-2.142 4.414l1.402.227zm-15.178 0c1.348.151 2.36.706 3.035 1.662.674.956 1.011 2.013 1.011 3.169 0 1.208-.401 2.29-1.207 3.244C8.497 19.521 7.317 20 5.76 20c-.622 0-1.271-.113-1.946-.34-.674-.227-1.298-.614-1.869-1.17-.57-.552-1.037-1.308-1.4-2.264C.182 15.272 0 14.064 0 12.603c0-2.716.805-5.169 2.413-7.359C4.02 3.056 6.073 1.31 8.563 0l2.257 3.018c-1.348.707-2.555 1.65-3.62 2.831-1.064 1.183-1.777 2.655-2.14 4.414l1.402.227z"/></g></g></g></svg>');
    position: absolute;
    left: calc(10% - 10px);
    top: -11px;
    width: 0;
    height: 0;
  }
`

export const InspirationPersona = styled.div`
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: -0.05px;

  b {
    font-weight: bold;
  }
`

export const ContainerButton = styled.div`
  display: inline-block;
  text-align: right;
  margin: 1.875rem 0 0;
  width: 100%;
`

export const OptionCard = styled.div`
  cursor: pointer;
  display: flex;
  border: 4px solid transparent;
  border-radius: 8px;
  padding: 0.642rem;
  width: 100%;

  .checkboxQuestion {
    padding-right: 0.642rem;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: rgba(253, 130, 4, 0.1);
      border-color: ${({ theme }) => theme.colors.black};
    `};
`

export const OptionTextContainer = styled.div`
  p {
    font-weight: 500;
    padding-top: 3px;
  }

  @media screen and (min-width: 360px) {
    padding: -1rem 0 0;
  }

  @media screen and (min-width: 414px) {
    padding: -0.65rem 0 0;
  }
`

export const BadgePoints = styled.div`
  background-color: ${({ theme }) => theme.colors.pistachioGreen};
  border-radius: 0.35rem;
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
  font-family: MilkyNice;
  font-weight: bolder;
  padding: 0.5rem 1rem;

  ${({ isNegative, theme }) =>
    isNegative &&
    css`
      background: none;
      background-color: ${theme.colors.reddishOrange};
    `}
`
