export default {
  'game.inspiration.superior.questions.1':
    '<b>Lia Zakiyyah</b><br/> Emprendedora y ex alumna JA',
  'game.inspiration.superior.questions.1.option.1':
    '"Buscar una carrera es un jornada para toda la vida. No termina con el primer empleo o con la primer carrera. <b>Persigue aquello en lo que cree y que es bueno durante toda la vida</b>',

  'game.inspiration.superior.questions.2':
    '<b>Cosmin Malureanu</b><br/>Emprendedora y ex alumna de JA',
  'game.inspiration.superior.questions.2.option.1':
    '"<b>Aprende cómo aprender</b>. El mundo cambia a un <b>ritmo acelerado</b>. Tiene que evitar quedarse parado en un empleo o carrera que no tenga futuro. Desafiarse a si mismo a <b> aprender cosas nuevas  </b> y permanecer competitivo.',

  'game.inspiration.superior.questions.3':
    '<b>Joshuel Plasencia</b><br/>Cofundador de Proyecto 99 y ex alumno de JA USA',
  'game.inspiration.superior.questions.3.option.1':
    '"Dar y recibir <b>retroalimentación</b> es un don, ya que no existe la manera de ser mejor en algo si no hay perspectivas de mejora. El <b>perfeccionamiento</b> sólo es posible a través de la retroalimentación dada y recibida."',

  'game.inspiration.superior.questions.4':
    '<b>Leen Abu Baker</b><br/>Emprendedora y ex alumna de JA',
  'game.inspiration.superior.questions.4.option.1':
    '"Comiencen a buscar sus intereses aun jóvenes  y no tengan miedo de compartilos con la comunidad que los rodea."',

  'game.inspiration.superior.questions.5':
    '<b>Yesenia Cardenas</b><br/>Emprendedora y ex-alumna JA',
  'game.inspiration.superior.questions.5.option.1':
    '"Si tu tienes un <b>objetivo</b> o un <b>sueño</b> que quieres ver materializados, no importa si es convertirse en abogado, abrir un negocio o tener una familia, <b>habrá obstáculos</b> y personas que van a cuestionar sus sueños. Es muy importante <b>permanecer fiel</b> a sus objetivos y deseos, y <b> trabajar</b> duro para alcanzarlos.',

  'game.inspiration.superior.questions.6':
    '<b>Bob Coughlin</b><br/>Emprendedor y ex-alumno JA',
  'game.inspiration.superior.questions.6.option.1':
    '"Comprenda cuáles son sus fortalezas y acérquese a personas que puedan ayudarlo a superar sus debilidades."',

  'game.inspiration.superior.questions.7':
    '<b>Mario A. Escutia</b><br/>Emprendedor y ex-alumno JA',
  'game.inspiration.superior.questions.7.option.1':
    '"Mi consejo para los jóvenes es: no importa <b> de donde uno viene</b>, cuál es su clase social o su ideología, <b>sus sueños pueden volverse realidad.</b> La honestidad y el trabajo duro siempre tienen recompensa."',

  'game.inspiration.superior.questions.8':
    '<b>Joseph Fortuno</b><br/>Ex alumno de JA EUA',
  'game.inspiration.superior.questions.8.option.1':
    '"Tengo sólo un consejo: No tenga miedo de intentar, tampoco de fallar. Si yo hubiese tenido miedo, nunca hubiese llegado donde estoy hoy."',

  'game.inspiration.superior.questions.9':
    '<b>Youmn mazhzoul</b><br/>Ex alumno de JA Marrrocos',
  'game.inspiration.superior.questions.9.option.1':
    '"Vamos a pensar sobre el concepto de qué es ser exitoso. La métrica que usamos para medir el éxito muchas veces está distorsionada, porque sólo contempla el éxito financiero. Si bien es importante, la humanidad debería comprometerse más bien con tener una vida con propósito."',

  'game.inspiration.superior.questions.10': '<b>Jeff Hansberry</b><br/>Ex alumno JA EUA',
  'game.inspiration.superior.questions.10.option.1':
    '"Usted tiene que entender que éxiste una vida diferente fuera del radio de 3 o 4 cuadras alrededor de donde usted vive o fuera de las personas que conoce, o de las experiencias que ha tenido hasta ahora. Existe algo mejor para usted, más allá del mundo en el que vive hoy."',
}
