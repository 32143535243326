export default {
  'game.job.middle.questions.1':
    '{name} tinha que fazer um relatório sobre suas atividades no trabalho e enviar por e-mail. {name} acabou deixando para a última hora e esqueceu de registrar várias tarefas. Mandou mal!',
  'game.job.middle.questions.1.feedback': ' ',

  'game.job.middle.questions.2':
    '{name} mudou de função no trabalho e está com dúvidas em várias atividades, o que {name} deve fazer?',
  'game.job.middle.questions.2.option.1': 'Encarar o desafio e aprender na prática.',
  'game.job.middle.questions.2.option.1.feedback': 'Pedir ajuda aos colegas de trabalho.',
  'game.job.middle.questions.2.option.2':
    'Aprender na prática é muito importante, mas alguns erros podem ser evitados pedindo ajuda aos colegas de trabalho. Assim, todo mundo aprende!',
  'game.job.middle.questions.2.option.2.feedback':
    'Acertou demais! Conversar e pedir ajuda mostra que {name} tem vontade de aprender!',

  'game.job.middle.questions.3':
    '{name} precisa ir ao médico logo cedo, e vai se atrasar para o trabalho. E agora?',
  'game.job.middle.questions.3.option.1': 'Avisa o chefe com antecedência.',
  'game.job.middle.questions.3.option.1.feedback':
    'A equipe de {name} conseguiu se organizar para cobrir o atraso e tudo ficou bem. Xp para quem pensou na equipe.',
  'game.job.middle.questions.3.option.2': 'Pega um atestado para justificar o atraso.',
  'game.job.middle.questions.3.option.2.feedback':
    '{name} não avisou o chefe e deixou a equipe toda na mão.',

  'game.job.middle.questions.4':
    '{name} está indo muito bem e seu rendimento no trabalho é ótimo! O gerente admira seu esforço e lhe recompensará com um bônus neste mês!',
  'game.job.middle.questions.4.feedback': ' ',

  'game.job.middle.questions.5':
    'O movimento do comércio está baixo e {name} pensa em criar uma loja online para ajudar nas vendas. Mas {name} não sabe como propor sua ideia, uma vez que é responsabilidade de outro setor. O que fazer? ',
  'game.job.middle.questions.5.option.1':
    'Seguir trabalhando e não se envolver neste assunto.',
  'game.job.middle.questions.5.option.1.feedback':
    'Um colega deu a ideia de montar a loja online, que foi muito bem aceita. {name} poderia ter se destacado se tivesse acreditado em si mesmo(a). ',
  'game.job.middle.questions.5.option.2': 'Compartilhar a ideia com a equipe.',
  'game.job.middle.questions.5.option.2.feedback':
    'A liderança gostou muito da ideia e vai implementá-la. Ponto positivo para {name}, que se destacou em mais essa!',

  'game.job.middle.questions.6':
    'Novas pessoas foram contratadas e {name} vai ajudá-las durante as primeiras semanas. A equipe confia em seu trabalho!',
  'game.job.middle.questions.6.feedback': ' ',
}
