export default {
  'game.skills.junior.questions.1':
    'La profesora ha organizado un trabajo en equipos y {name} tendrá que hacerlo con algunos compañeros a los que no conoce. {name} resuelve:​​',
  'game.skills.junior.questions.1.option.1':
    'Hacer el trabajo de manera individual y enviarlo al grupo.',
  'game.skills.junior.questions.1.option.1.feedback':
    '{name} perdió una excelente oportunidad para trabajar en equipo. Esta es una  habilidad escencial para la vida y el trabajo, pero que no se aprende en los libros, sino a través de la práctica.',
  'game.skills.junior.questions.1.option.2':
    'Hacer una reunión para organizar el trabajo.',
  'game.skills.junior.questions.1.option.2.feedback':
    'Buena iniciativa. {name} aprendió mucho sobre la colaboración y la organización. A pesar de algunas dificultades iniciales, el trabajo fue muy bueno y {name} hizo nuevos amigos.​',

  'game.skills.junior.questions.2':
    'La feria de ciencias en la escuela sucederá el próximo mes. {name} resuelve...',
  'game.skills.junior.questions.2.option.1': 'Trabajar en la organización.',
  'game.skills.junior.questions.2.option.1.feedback':
    '{name} ha demostrado iniciativa y esfuerzo para organizar la feria. La lista de cosas que ha aprendido es interminable. ¡La feria fue un éxito y una experiencia para toda la vida!',
  'game.skills.junior.questions.2.option.2': 'Visitar la feria con sus amigos.',
  'game.skills.junior.questions.2.option.2.feedback':
    '{name} no quiso participar de la organización de la feria, pero aprovechó la experiencia y ha aprendido más sobre el mundo.',

  'game.skills.junior.questions.3':
    '{name} ha terminado confiado su exámen de matemáticas, pero cuando la profesora entregó los resultados descubrió que no le fue tan bien como pensaba. ¿Cómo reaccionar?',
  'game.skills.junior.questions.3.option.1':
    'Se siente triste, rompe el exámen y se olvida del asunto.',
  'game.skills.junior.questions.3.option.1.feedback':
    '{name} ha perdido una gran oportunidad de aprender de los errores. Si hubiese revisando sus respuestas hubiese percibido que su problema fue la interpretación de las preguntas. ¡Qué pena!',
  'game.skills.junior.questions.3.option.2':
    'Se siente triste pero busca a la profesora para hablar sobre el exámen.',
  'game.skills.junior.questions.3.option.2.feedback':
    'Después de una conversacion con la profesora, {name} ha comprendido que no había leído correctamente las preguntas del exámen. Ha aprendido de los errores y ya sabe lo que necesita mejorar para una próxima vez.',

  'game.skills.junior.questions.4':
    'La mascota de la casa destruyó la maqueta que {name} preparó durante un mes para la Feria de Innovación de la escuela. Faltan 24hs para la presentación ¿qué puede hacer {name}?',
  'game.skills.junior.questions.4.option.1':
    'Cancelar su participación y prepararse nuevamente para la Feria del año entrante.',
  'game.skills.junior.questions.4.option.1.feedback':
    '¡Qué pena, la idea era perfecta y {name} podría haberla presentado de una manera diferente. Flexibilidad y creatividad valen oro en el Mundo VUCA!',
  'game.skills.junior.questions.4.option.2':
    'Adaptar la presentación usando recursos diferentes.',
  'game.skills.junior.questions.4.option.2.feedback':
    '{name} usó diseños de una bella historia para presentar su idea. Flexibilidad y creatividad para resolver problemas van a ajudar a {name} a llegar al Portal Z!',

  'game.skills.junior.questions.5':
    '{name} fue elegido como representante de su clase para la escuela, pero está con dificultades para cumplir con sus tareas. Decide entonces:',
  'game.skills.junior.questions.5.option.1':
    'Respirar profundamente y esperar a que el año termine pronto.',
  'game.skills.junior.questions.5.option.1.feedback':
    'Administrar el tiempo es uno de los grandes desafíos en el Mundo VUCA. Existen muchas estrategias para esto, como por ejemplo priorizar las tareas más urgentes o pedir ayuda.',
  'game.skills.junior.questions.5.option.2':
    'Determinar las prioridades y definir plazos para cada tarea.',
  'game.skills.junior.questions.5.option.2.feedback':
    'Administrar el tiempo es uno de los grandes desafíos en el Mundo VUCA. Existen muchas estrategias para esto, como por ejemplo priorizar las tareas más urgentes o pedir ayuda.',

  'game.skills.junior.questions.6':
    '{name} tiene que escribir un redacción para la escuela, pero no sabe por donde comenzar. {name} resuelve:',
  'game.skills.junior.questions.6.option.1':
    'Investigar en internet y copiar parte de un texto de un blog.',
  'game.skills.junior.questions.6.option.1.feedback':
    '¡Ups! Copiar ideas de otras personas sin darles crédito es plagio. ¡El plagio es un delito!',
  'game.skills.junior.questions.6.option.2':
    'Investigar sobre el tema en internet para generar inspiración.',
  'game.skills.junior.questions.6.option.2.feedback':
    '¡Muy bien! {name} relevó los datos y encontró referencias para crear su propia historia',
}
