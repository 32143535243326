import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.inspiration': 'Inspiración',
  'game.inspiration.message':
    '¡Buenísimo! Esta es una casilla de Inspiración. Aqui recibirá un incentivo para seguir adelante y además algunos puntos extra de experiencia.',

  ...junior,
  ...middle,
  ...superior,
}
