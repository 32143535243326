export default {
  'account.my': 'Mi Cuenta',
  'account.medals': 'Medallas',
  'account.medals.view': 'Ver medalla',
  'account.medals.win': '¡Ganaste la medalla de {nombre}!',
  'account.medals.win.description':
    'Las medallas son recompensas por llegar a Portal Z y ganar el juego VUCA. Las medallas ganadas se guardarán y podrás consultarlas en el menú del juego si estás conectado.',
  'account.medals.my': 'Mis medallas',
  'account.medals.of': 'Medalla {name}',
  'account.password.edit': 'Cambiar contraseña',
  'account.password.success.update': 'Tu contraseña ha sido cambiada exitosamente.',
  'account.password.error.update':
    'Se produjo un error al cambiar la contraseña. Inténtalo de nuevo.',
  'account.password.current': 'Contraseña actual',
  'account.password.current.placeholder': 'Ingrese contraseña contraseña',
  'account.password.new': 'Nueva contraseña',
  'account.password.new.placeholder': 'Introduzca nueva contraseña',
  'account.password.new.confirm': 'Confirmar nueva contraseña',
  'account.password.create': 'Crear contraseña',
  'account.password.invalid': '<b>Contraseña incorrecta</>',
  'account.edit': 'Cambiar datos',
  'account.terms': 'Ver términos de uso y privacidad',
  'account.delete': 'Eliminar cuenta',
  'account.delete.info':
    'Lamentable verlo salir. <br/><br/> ¿Está seguro que desea cerrar tu cuenta en el juego VUCA? Se cancela su cuenta, no podrá más: <br/>- Guardar partidos <br/>- Acceder al historial de partidos <br/>- Acceder a las medallas recibidas <br/><br/> ¿Desea excluir su cuenta?',
  'account.deleted': 'Conta excluída',
  'account.delete.error':
    'Hubo un error al eliminar la cuenta. Por favor, inténtelo de nuevo más tarde.',
  'account.delete.success': 'Sua conta foi excluída',
}
