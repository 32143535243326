export default {
  logo: 'O Jogo das grandes decisões',
  download: 'Baixar',
  worldVuca: 'Mundo Vuca',
  email: 'E-mail',
  emailPlaceholder: 'Digite o seu e-mail',
  emailAlreadySignedUp: 'E-mail já cadastrado',
  password: 'Senha',
  remember: 'Lembrar',
  return: 'Voltar',
  doLogin: 'Fazer login',
  send: 'Enviar',
  ok: 'Ok',
  select: 'Selecione',
  yes: 'Sim',
  no: 'Não',
  edit: 'Alterar',
  play: 'Jogar',
  exit: 'Sair',
  cancel: 'Cancelar',
  loading: 'Carregando',
  newGame: 'Novo jogo',
  saveGame: 'Salvar partida',
  'saveGame.success': 'O jogo foi salvo com sucesso!',
  'saveGame.error': 'Ocorreu um erro ao salvar o jogo. Tente novamente mais tarde.',
  or: 'Ou',
  choose: 'Escolher',
  jump: 'Pular',
  start: 'Começar',
  next: 'Próximo',
  save: 'Salvar',
  seeMore: 'Ver mais',
  backToHome: 'Voltar ao início',
  delete: 'Excluir',
  deleteGame: 'Excluir jogo',
  continue: 'Continuar',
  savedGame: 'Jogo salvo',
  and: 'e',
  copy: 'Copiar',
  copied: 'Copiado!',
  turnCounter: 'Essa é a quantidade de vezes que você pode jogar o dado',
  notResult: 'Nenhum resultado',
  shared: 'Para compartir',
  resetPassword: 'Nova senha',
  portalZ: 'Esse é o Portal Z.',
  'language.pt': 'Portugués',
  'language.es': 'Espanhol',
  'language.en': 'Inglés',
  'portalZ.info':
    'Após se formar no ensino superior {name} chegará ao Portal Z e estará pronto para novas oportunidades.',
  'deleteGame.info':
    'Tem certeza que deseja excluir essa partida? <br/><br/>Após a exclusão da partida você terá que começar um novo jogo.',
  'saveGame.confirm':
    'Você já tem uma partida salva e não finalizada na sua conta. Se iniciar e salvar uma nova partida você perderá a partida anterior. <br/><br/>Deseja iniciar um novo jogo?',
}
