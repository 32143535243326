import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.challenge': 'Situação',
  'game.challenge.message':
    'Sempre que parar nessa casa você irá receber cartas com desafios de situações do dia a dia que podem impactar na vida do seu personagem.',

  ...junior,
  ...middle,
  ...superior,
}
