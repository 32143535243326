import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { isMobileSize } from '../../../../common/utils'

import { changePassword } from '../../../../services/API/user'

import { CardWithHeader, Input } from '../../../../components'

import { BackgroundBlur } from '../../../GameCards/styles'

import { Footer } from '../../../../pages/ChoiceCharacter/CharactersCard/components/cardFooter'

import {
  Container,
  ContentCard,
  ContainerButton,
  Paragraph,
  ContentIconWithParagraph,
  ContainerChangePassword,
} from './styles'
import { Password } from '../../../../pages/SignUp/SignUpCard/components/cardBody/FormSignUp/components'
import { get } from '../../../../services/storage'
import { Loading } from '../../../Loading'
import { ButtonWithRadiusBorderComponent } from '../../../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

export const ChangePasswordCardCp = ({ closeCardChangePassword, intl }) => {
  const isMobile = useMemo(isMobileSize, [])

  const successPassword = intl.formatMessage({ id: 'account.password.success.update' })
  const errorPassword = intl.formatMessage({ id: 'account.password.error.update' })
  const [showInfoPasswordDontMatch, setShowInfoPasswordDontMatch] = useState(false)
  const [hasPasswordChanged, setHasPasswordChanged] = useState(false)
  const [msgUpdateRequest, setMsgUpdateRequest] = useState(successPassword)
  const [showRequired, setShowRequired] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const currentPasswordInput = useMemo(dataOfInputs, [])

  const [dataChangePassword, setDataChangePassword] = useState({
    currentPassword: '',
    newPassword: '',
  })

  const memoizedDataOfButton = useMemo(dataOfButtons, [
    hasPasswordChanged,
    dataChangePassword,
  ])

  const changePasswordStrings = useMemo(setChangePasswordStrings, [])
  function setChangePasswordStrings() {
    return {
      newPassword: {
        label: intl.formatMessage({ id: 'account.password.new' }),
        placeholder: intl.formatMessage({ id: 'account.password.new.placeholder' }),
        dataTest: 'inputNewPassword',
      },
      confirmPassword: {
        label: intl.formatMessage({ id: 'account.password.new.confirm' }),
        placeholder: intl.formatMessage({ id: 'account.password.new.confirm' }),
        dataTest: 'inputConfirmPassword',
      },
    }
  }

  function back() {
    closeCardChangePassword(false)
  }

  function changeUserPassword() {
    const { currentPassword, newPassword } = dataChangePassword

    if (currentPassword && newPassword) {
      setShowRequired(false)
      updatePassword(dataChangePassword)
    } else {
      setShowRequired(true)
    }
  }

  async function updatePassword(data) {
    const id = get('user').id
    setShowInfoPasswordDontMatch(false)

    setShowLoading(true)
    const response = await changePassword(data, id)
    setShowLoading(false)
    finalizePasswordUpdate(response)
  }

  function hasInvalidPassword(status, msg) {
    return status === 400 && msg === 'Password does not match the registered user'
  }

  function finalizePasswordUpdate(response) {
    const { status } = response

    if (hasInvalidPassword(status, response.data.message)) {
      setShowInfoPasswordDontMatch(true)
      return
    } else if (status !== 200) {
      setMsgUpdateRequest(errorPassword)
    }

    setHasPasswordChanged(true)
  }

  function setData(key, value) {
    const inputData = { [key]: value }
    setDataChangePassword({ ...dataChangePassword, ...inputData })
  }

  function setCurrentPassword(e) {
    setData('currentPassword', e.target.value)
  }

  function setNewPassword(value) {
    setData('newPassword', value)
  }

  function dataOfInputs() {
    return {
      label: intl.formatMessage({ id: 'account.password.current' }),
      placeholder: intl.formatMessage({ id: 'account.password.current.placeholder' }),
      dataTest: 'inputCurrentPassword',
      error: intl.formatMessage({ id: 'account.password.invalid' }),
    }
  }

  function dataOfButtons() {
    return [
      {
        text: intl.formatMessage({ id: 'return' }),
        data_test: 'buttonBackChangePasswordCard',
        color: 'white',
        hide: hasPasswordChanged,
        onClick: back,
      },
      {
        text: intl.formatMessage({ id: 'edit' }),
        data_test: 'buttonEditPassowordCard',
        hide: hasPasswordChanged,
        onClick: changeUserPassword,
      },
      {
        text: intl.formatMessage({ id: 'ok' }),
        data_test: 'buttonBackToHomeCard',
        hide: !hasPasswordChanged,
        color: 'white',
        onClick: back,
      },
    ]
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container data-test="containerChangePasswordCard">
        <CardWithHeader
          isSmall
          backgroundColorHeader="pistachioGreen"
          titleCard={intl.formatMessage({ id: 'account.password.edit' })}
          data_test="ChangePasswordCard">
          <ContentCard>
            {!hasPasswordChanged && (
              <ContentIconWithParagraph>
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: msgUpdateRequest }}
                  data-test="paragraphChangePasswordCard"
                />
              </ContentIconWithParagraph>
            )}

            {!hasPasswordChanged && (
              <ContainerChangePassword>
                <Input
                  objectString={currentPasswordInput}
                  inputName="passwordLogin"
                  type="password"
                  sendValue={setCurrentPassword}
                  showRequired={showRequired || showInfoPasswordDontMatch}
                  isRequired={true}
                  isPassword={true}
                  showError={showInfoPasswordDontMatch}
                />
                <Password
                  signUpStrings={changePasswordStrings}
                  showRequired={showRequired}
                  sendPassword={setNewPassword}
                />
              </ContainerChangePassword>
            )}

            <ContainerButton>
              {memoizedDataOfButton.map(
                ({ text, data_test, hide, color, onClick }) =>
                  !hide && (
                    <ButtonWithRadiusBorderComponent
                      onClick={onClick}
                      key={text}
                      color={color || ''}
                      data_test={data_test}
                      text={text}
                    />
                  ),
              )}
            </ContainerButton>
          </ContentCard>
          <Footer />
        </CardWithHeader>
      </Container>
      {!isMobile && <BackgroundBlur />}
    </>
  )
}

ChangePasswordCardCp.propTypes = {
  closeCardChangePassword: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const ChangePasswordCard = injectIntl(ChangePasswordCardCp)
