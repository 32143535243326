export default {
  'game.inspiration.junior.questions.1':
    '<b>Eleonora Arifova</b><br/>Empreendedora e ex aluna da JA',
  'game.inspiration.junior.questions.1.option.1':
    '"O <b>empreendedorismo</b> é aplicável a qualquer caminho que se escolha na vida. A aptidão para identificar <b>oportunidades</b> e implementar ideias pode beneficiar qualquer pessoa. Portanto, <b>liberte seu talento</b> empreendedor e siga seus sonhos.”',

  'game.inspiration.junior.questions.2':
    '<b>Bill Herp</b><br/>Ex aluno da JA e fundador da Linear Air',
  'game.inspiration.junior.questions.2.option.1':
    '“Você definitivamente <b>precisa estudar</b>. Não pode achar que vai ser um empreendedor ou ter um emprego decente <b>sem instrução</b>. E isso não significa necessariamente cursar uma universidade; pode ser uma instrução <b>técnica</b> ou <b>profissionalizante</b>. O importante é que lhe permita tornar-se um especialista em uma ou múltiplas áreas.”',

  'game.inspiration.junior.questions.3':
    '<b>Donna Shalala</b><br/>Ex aluna JA e executiva',
  'game.inspiration.junior.questions.3.option.1':
    '“Minha filosofia de educação é: os estudantes não devem se preparar para o seu <b>primeiro emprego</b>, mas para seu terceiro, quarto e quinto emprego. Precisam ter a capacidade mental de assimilar <b>novas tecnologias</b> e <b>novas ideias</b>. Assim, é muito importante que os jovens se vejam como eternos aprendizes.”',

  'game.inspiration.junior.questions.4':
    '<b>Adedayo Fashanu</b><br/>Ex aluna JA e Jornalista',
  'game.inspiration.junior.questions.4.option.1':
    '“<b>Confiança</b> é a chave para o progresso, mas não baseiem sua confiança unicamente nos resultados que alcançarem, porque o que vai acontecer quando você falhar em alguma coisa? <b>Seria motivo para perder a confiança em si mesmo?</b> Não, sua confiança deve <b>ter raízes</b> na ‘causa maior’ do trabalho no qual você está imerso e no processo positivo de <b>realizar</b> esse trabalho.”',

  'game.inspiration.junior.questions.5':
    '<b>Bonnie Chiu</b><br/>Ex-aluna da JA Hong Kong',
  'game.inspiration.junior.questions.5.option.1':
    '"Comece sempre pelo “<b>por quê?</b>”. É a pergunta mais importante a ser feita na construção de <b>sua vida</b> e de <b>sua carreira:</b> por que estou <b>fazendo</b> isso? por que me <b>importo</b> com isso? por que isso é <b>relevante</b> pra mim?"',

  'game.inspiration.junior.questions.6':
    '<b>Raymon Setiadi</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.junior.questions.6.option.1':
    '“Nunca pense que você <b>sabe tudo</b> e nunca se sinta muito <b>confortável</b> com a sua situação. Porque quando você pensa dessa maneira, você não dá a si mesmo nenhuma <b>oportunidade de crescimento.</b>”',

  'game.inspiration.junior.questions.7':
    '<b>Camilla ljunggren</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.junior.questions.7.option.1':
    '”Seja lá o que <b>optar por fazer</b>, você tem que saber que vai <b>demorar</b>. Contar como construí nossa fábrica leva 10 minutos e parece ter sido muito fácil. Mas não é fácil. <b>Leva tempo.</b> No meu caso foram 16 a 17 anos.”',

  'game.inspiration.junior.questions.8':
    '<b>Murillo Sabino</b><br/>Fundador do projeto RUAS e Ex- aluno JA Brasil',
  'game.inspiration.junior.questions.8.option.1':
    'Não “busquem” o seu propósito. Cultivem um propósito. Ele será a  união entre o que atrai sua curiosidade, o que você  faz muito bem e do que o mundo está precisando para ser um lugar melhor.',

  'game.inspiration.junior.questions.9':
    '<b>Jerome Cowans</b><br/>Membro do Jamaica House Fellowship',
  'game.inspiration.junior.questions.9.option.1':
    '“O que eu aprendi e que considero crucial para todos os jovens foi: durante toda a sua vida será essencial compreender o lado prático do que está fazendo, assim como o lado teórico.”',

  'game.inspiration.junior.questions.10':
    '<b>Leen Abu Baker</b><br/>Vice-presidente do Conselho de Ex-alunos da INJAZ Al-Arab',
  'game.inspiration.junior.questions.10.option.1':
    '“Comece a buscar seus interesses ainda jovem e não tenha medo de compartilhá-los com a comunidade que o cerca.”',
}
