import React, { useEffect, useState } from 'react'
import PropTypes, { bool, func } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Label } from '../../styles'
import { Flex } from './styles'
import { Select } from '../Select'

import { Tooltip } from '../Tooltip/'

export const DateOfBirthCp = ({
  signUpStrings,
  sendCompleteDateOfBith,
  dateOfBirth,
  showRequired,
  intl,
}) => {
  const [years, setYears] = useState()
  const [date, setDate] = useState({
    day: null,
    month: null,
    monthId: null,
    year: null,
  })

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d)
  }

  useEffect(setDateOfBirth, [dateOfBirth])
  function setDateOfBirth() {
    if (dateOfBirth) {
      setDate({
        day: new Date(dateOfBirth).getDate(),
        month: new Date(dateOfBirth).getMonth(),
        year: new Date(dateOfBirth).getFullYear(),
      })
    }
  }

  useEffect(buildCompleteDate, [date])
  function buildCompleteDate() {
    if (date.day && date.monthId && date.year) {
      const dataAux = new Date(date.year, date.monthId, date.day)

      if (isValidDate(dataAux)) {
        sendCompleteDateOfBith(dataAux)
      }
    }
  }

  useEffect(getYears, [])
  function getYears() {
    const yearsAux = []
    let fullYear = new Date().getFullYear() - 5

    for (let i = 0; i < 100; i++) {
      yearsAux.push(fullYear--)
    }

    setYears(yearsAux)
  }

  function getValueOfDate(event) {
    const { name } = event.target

    if (name === 'month') {
      return {
        [name]: event.target.value,
        [`${name}Id`]: event.target.dataset?.index,
      }
    } else {
      return { [name]: event.target.value }
    }
  }

  function setFormValue(event) {
    const value = getValueOfDate(event)
    setDate({ ...date, ...value })
  }

  function getInsetValue(name, item) {
    if (name !== 'month') {
      return date[name]
    }
    const filterMonth = item.options.filter((opt) => opt.id === date[name])

    return filterMonth.length > 0
      ? intl.formatMessage({ id: filterMonth[0].label })
      : null
  }

  return (
    <div>
      <Label>
        <FormattedMessage id={signUpStrings.birthDate.label} />
        {signUpStrings.birthDate.tooltip && (
          <Tooltip tooltipMsg={signUpStrings.birthDate.tooltip} />
        )}
      </Label>

      <Flex justifyContent="space-between">
        {signUpStrings.birthDate.placeholder.map((item, index) => {
          return (
            <Select
              objectString={
                item.nameValue === 'year' ? { ...item, ...{ options: years } } : item
              }
              key={index}
              original={item.nameValue === 'year' ? years : item.options}
              withAutocomplete={true}
              inputName={item.nameValue}
              value={getInsetValue(item.nameValue, item)}
              valueNoTranslate={item.optionsNoTranslate}
              id={item.nameValue === 'month' ? date.month : null}
              sendValue={setFormValue}
              showRequired={showRequired}
              maxLength={item.maxLength}
              isRequired={true}
            />
          )
        })}
      </Flex>
    </div>
  )
}

DateOfBirthCp.propTypes = {
  signUpStrings: PropTypes.shape({
    birthDate: PropTypes.shape({
      label: PropTypes.string,
      tooltip: PropTypes.string,
      placeholder: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          nameValue: PropTypes.string.isRequired,
          maxLength: PropTypes.number,
          dataTest: PropTypes.string.isRequired,
          options: PropTypes.array,
        }),
      ),
    }).isRequired,
  }).isRequired,
  dateOfBirth: PropTypes.string,
  sendCompleteDateOfBith: func.isRequired,
  showRequired: bool,
  intl: PropTypes.object,
}

DateOfBirthCp.defaultProps = {
  showRequired: false,
}

export const DateOfBirth = injectIntl(DateOfBirthCp)
