export default {
  'game.job.junior.questions.1':
    'Today was feedback day at work. {name}’s mother spoke about {name}’s skills at work and made some suggestions for improvement.',
  'game.job.junior.questions.1.feedback':
    'Knowing their strengths and weaknesses is key for {name} to reach Portal Z!',

  'game.job.junior.questions.2':
    '{name} went on a school trip and visited a startup that develops websites. {name} learned about the company’s structure and found out about careers {name} did not know existed. Yahoo!',
  'game.job.junior.questions.2.feedback': '{name} took the chance and gained more XP.',

  'game.job.junior.questions.3':
    "{name}'s mother must prepare a big batch of candy and she will need extra help this weekend. But {name} has homework to finish. What will {name} do?",
  'game.job.junior.questions.3.option.1':
    'Finish the homework quickly in order to have some free time.',
  'game.job.junior.questions.3.option.1.feedback':
    'Finding a balance between work and study is a big challenge. {name} carelessly completed the homework and did not learn anything. If {name} wants to reach Portal Z, {name} must improve their organization skills.',
  'game.job.junior.questions.3.option.2':
    'Organize {name}’s schedule to finish school homework during the week.',
  'game.job.junior.questions.3.option.2.feedback':
    '{name} cut back on TV and phone time during the week and focused on finishing homework. It was tough week, but {name} managed to help their mother and ended with a positive feeling of fulfillment!',

  'game.job.junior.questions.4':
    '{name} did not pay attention to mom’s recipe and messed up a batch of candy.',
  'game.job.junior.questions.4.option.1': 'Pay more attention next time!',

  'game.job.junior.questions.5':
    'One of the customers did not pay on time because he had no money. What now?',
  'game.job.junior.questions.5.option.1':
    'Accept this setback, but never sell to that client again.',
  'game.job.junior.questions.5.option.1.feedback':
    'It is a shame, but it can happen to anyone. {name} could have tried negotiating before severing ties with the customer.',
  'game.job.junior.questions.5.option.2':
    'Negotiate with the customer to receive the money in several payments.',
  'game.job.junior.questions.5.option.2.feedback':
    'Your initiative helped obtain the order and keep a good relationship with the client.',

  'game.job.junior.questions.6':
    'A schoolmate has also decided to start selling candy and now {name} has a competitor. How does {name} face the situation?',
  'game.job.junior.questions.6.option.1':
    'Come to an agreement with the schoolmate to sell different kinds of candy.',
  'game.job.junior.questions.6.option.1.feedback':
    'Good call! Now the whole school has more options to buy and {name} can keep their customers.',
  'game.job.junior.questions.6.option.2':
    'Spread wrongful and negative comments about the schoolmate’s candy.',
  'game.job.junior.questions.6.option.2.feedback':
    "That is awful! People found out this was not true and {name}'s sales have dropped.",

  'game.job.junior.questions.7':
    'Not working. Take the opportunity to study and enjoy the challenges of this phase!',
}
