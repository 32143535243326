import React from 'react'
import PropTypes from 'prop-types'

export const Sound = ({ mp3Sound, loop }) => {
  return (
    <audio autoPlay={true} loop={loop}>
      <source src={mp3Sound} type="audio/mpeg" />
    </audio>
  )
}

Sound.propTypes = {
  mp3Sound: PropTypes.string.isRequired,
  loop: PropTypes.bool,
}

Sound.defaultProps = {
  loop: false,
}
