import styled from 'styled-components'

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 30px 36px;
  font-family: Montserrat;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};

  p {
    font-family: Montserrat;
    font-size: 1rem;
  }

  span {
    font-weight: bolder;
  }

  @media screen and (max-width: 1024px) {
    padding: 20px;
  }

  b {
    font-weight: bolder;
  }
`

export const ContainerButton = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding-top: 30px;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    button,
    div {
      width: 100% !important;
    }
  }
`
