export default {
  terms: 'Formulario de consentimiento',
  'terms.title': 'Para el tratamiento de datos personales',
  'terms.description.1':
    'Este documento tiene como objetivo registrar la manifestación libre, informada e inequívoca por la cual ' +
    'el Titular acepta el tratamiento y puesta en común de sus datos personales de forma anónima para una ' +
    'finalidad específica, de conformidad con la Ley N ° 13.709 - Ley General de Protección de Datos ' +
    'Personales (LGPD).',

  'terms.description.2': 'TITULAR',
  'terms.description.2.info':
    'Persona a la que se refieren los datos personales en tratamiento.',

  'terms.description.3':
    'Al aceptar el presente formulario, el Titular consiente y acepta que la empresa Associação Junior ' +
    'Achievement do Brasil, CNPJ nº 04.906.381.001-23, endereço comercial, Avenida Ipiranga 6681 – ' +
    'Prédio96E – sala 210 – Tecnopuc, Bairro Partenon, CEP 90.160-091, Porto Alegre- RS, en adelante ' +
    'denominada Controlador, tome decisiones sobre el tratamiento de sus datos personales, así como llevar ' +
    'a cabo el tratamiento de sus datos personales, involucrando operaciones como las relativas a la ' +
    'recaudación, producción, recepción, clasificación, uso, acceso, reproducción, transmisión, distribución, ' +
    'procesamiento, archivo, almacenamiento, supresión, evaluación o control de información, modificación, ' +
    'comunicación, transferencia, difusión o extracción.',

  'terms.description.4': 'Datos Personales',
  'terms.description.4.info':
    'El Controlador está autorizado para tomar decisiones referente al tratamiento y llevar a cabo el tratamiento de los siguientes datos personales del Titular:',

  'terms.description.4.list.1': 'Nombre completo',
  'terms.description.4.list.2': 'Fecha de nacimiento',
  'terms.description.4.list.3': 'Número e imagen de la Cédula de Identidad (DNI)',
  'terms.description.4.list.4':
    'Número e imagen del Registro de Personas Físicas (lo más parecido es el CUIL)',
  'terms.description.4.list.5':
    'Número e imagen de la Licencia Nacional de Conducir (LNC)',
  'terms.description.4.list.6': 'Estado civil',
  'terms.description.4.list.7': 'Nivel de educación o escolaridad',
  'terms.description.4.list.8': 'Dirección completa',
  'terms.description.4.list.9':
    'Números de teléfono, WhatsApp y direcciones de correo electrónico',
  'terms.description.4.list.10':
    'Nombre de usuario y contraseña específicos para utilizar los servicios del Controlador',
  'terms.description.4.list.11':
    'Comunicación, verbal y escrita, mantenida entre el Titular y el Controlador',

  'terms.description.5': 'Finalidades del Tratamiento de Datos',
  'terms.description.5.info':
    'Los datos personales enumerados en este formulario se comparten con terceros socios de forma anónima ' +
    'para que puedan seguir las actividades de Junior Achievement dentro de su propósito social la ' +
    'promoción de la educación emprendedora y tiene las siguientes finalidades:',
  'terms.description.5.list.1':
    'Permitir que el Controlador envíe o proporcione al Titular sus productos y servicios, de forma gratuita',
  'terms.description.5.list.2':
    'Permitir que el Controlador envíe comunicados, mensajes electrónicos a través de WhatsApp y correo electrónico con fines de relación institucional.',
  'terms.description.5.list.3':
    'Permitir que el Controlador identifique y contacte al Titular para propósitos de relaciones comerciales.',
  'terms.description.5.list.4':
    'Permitir que el Controlador estructure, pruebe, promocione y publicite productos y servicios, personalizados o no según el perfil del Titular.',
  'terms.description.5.list.5':
    'Permitir que el Controlador comparta estos datos con terceros socios de forma anónima para que estos terceros puedan monitorear las actividades realizadas por el Controlador.',

  'terms.description.6': 'Compartimiento de Datos',
  'terms.description.6.info':
    'El Controlador está autorizado a compartir los datos personales del Titular con otros agentes de ' +
    'tratamiento de datos, de ser necesario para los fines enumerados en este formulario, sujeto a los ' +
    'principios y garantías establecidos por la Ley N ° 13.709.',

  'terms.description.7': 'Seguridad de Datos',
  'terms.description.7.info':
    'El Controlador es el responsable por el mantenimiento de las medidas de seguridad, técnicas y ' +
    'administrativas capaces de proteger los datos personales de accesos no autorizados  y de situaciones ' +
    'accidentales o ilícitas de destrucción, pérdida, alteración, comunicación o cualquier forma de ' +
    'tratamiento indebido o ilícito.' +
    '<br />' +
    'De conformidad con el art. 48 de la Ley N ° 13.709, el Responsable informará al Titular y a la Autoridad ' +
    'Nacional de Protección de Datos (ANPD) de la ocurrencia de un incidente de seguridad que pueda ocasionar ' +
    'un riesgo o daño significativo al Titular.',

  'terms.description.8': 'Término de Tratamiento de Datos',
  'terms.description.8.info':
    'El Controlador puede mantener y procesar los datos personales del Titular durante todo el período en el ' +
    'que sean relevantes para el alcance de los fines enumerados en este término. Los Datos personales anónimos, ' +
    'sin posibilidad de asociación con el particular, podrán conservarse por tiempo indefinido.' +
    '<br />' +
    'El Titular puede solicitar por correo electrónico o correspondencia al Controlador, en cualquier momento, ' +
    'que se eliminen los datos personales no anónimos del Titular. El Titular es consciente de que puede que no ' +
    'sea factible que el Titular continúe proporcionando productos o servicios al Titular después de la ' +
    'eliminación de los datos personales.',

  'terms.description.9': 'Derechos del Titular',
  'terms.description.9.info':
    'El Titular tiene derecho a obtener del Controlador, en relación a los datos tratados por él, en cualquier ' +
    'momento y previa solicitud: I - confirmación de la existencia del tratamiento; II - acceso a los datos; ' +
    'III - corrección de datos incompletos, inexactos o desactualizados; IV - anonimato, bloqueo o eliminación ' +
    'de datos innecesarios, excesivos o tratados que no cumplan con lo establecido en la Ley N ° 13.709; ' +
    'V - portabilidad de datos a otro proveedor de servicios o productos, previa solicitud expresa y debido ' +
    'respeto a los secretos comerciales e industriales, de acuerdo con la normativa de la autoridad de control; ' +
    'V - portabilidad de datos a otro proveedor de servicios o productos, previa solicitud expresa, de acuerdo ' +
    'con la normativa de la autoridad nacional, con el debido respeto a los secretos comerciales e industriales; ' +
    'VI - eliminación de los datos personales tratados con el consentimiento del titular, salvo en los casos ' +
    'previstos en el art. 16 de la Ley N ° 13.709; VII - información de entidades públicas y privadas con ' +
    'las que el controlador del tratamiento hizo uso compartido de datos; VIII - información sobre la ' +
    'posibilidad de no dar consentimiento y sobre las consecuencias de la denegación; IX - revocación del ' +
    'consentimiento, de conformidad en los términos § 5 del art. 8 de la Ley N ° 13.709.',

  'terms.description.10': 'Derecho de Revocación del Consentimiento',
  'terms.description.10.info':
    'Este consentimiento podrá ser revocado por el Titular, en cualquier momento, previa solicitud ' +
    'por correo electrónico o correspondencia al Controlador.',
}
