import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.inspiration': 'Inspiration',
  'game.inspiration.message':
    'Well done! This is an Inspiration box. Here you will get the motivation needed to go on, as well as some additional experience points.',

  ...junior,
  ...middle,
  ...superior,
}
