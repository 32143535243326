import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { IcBrendaGoodBye } from '../../../../assets/img/myAccount'

import { isMobileSize } from '../../../../common/utils'

import { deactivate } from '../../../../services/API/user'
import { get, clear } from '../../../../services/storage'

import { CardWithHeader } from '../../../../components'

import { BackgroundBlur } from '../../../GameCards/styles'

import { Footer } from '../../../../pages/ChoiceCharacter/CharactersCard/components/cardFooter'

import {
  Container,
  ContentCard,
  ContainerButton,
  Paragraph,
  ContentIconWithParagraph,
  ErrorDelete,
} from './styles'
import { Loading } from '../../../Loading'
import { ButtonWithRadiusBorderComponent } from '../../../Buttons/buttonWithRadiusBorder'
import { injectIntl, FormattedMessage } from 'react-intl'

export const DeleteAccountCardCp = ({ closeCardDeleteAccount, intl }) => {
  const isMobile = useMemo(isMobileSize, [])

  const [hasErrorDeleteAccount, setHasErrorDeleteAccount] = useState(false)
  const [hasAccountDeleted, setHasAccountDeleted] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [textsCard, setTextsCard] = useState({
    title: intl.formatMessage({ id: 'account.delete' }),
    paragraph: intl.formatMessage({ id: 'account.delete.info' }),
  })

  const memoizedDataOfButton = useMemo(dataOfButtons, [hasAccountDeleted])

  function backToMenu() {
    closeCardDeleteAccount(false)
  }

  function backToGame() {
    closeCardDeleteAccount(true)
  }

  function clearLocalStorage() {
    clear('user')
    clear('token')
  }

  function informSucess() {
    setHasAccountDeleted(true)
    setTextsCard({
      title: intl.formatMessage({ id: 'account.deleted' }),
      paragraph: intl.formatMessage({ id: 'account.delete.success' }),
    })
  }

  async function deleteThisAccount() {
    const userId = get('user').id
    setShowLoading(true)
    const response = await deactivate(userId)
    setShowLoading(false)

    if (response) {
      informSucess()
      clearLocalStorage()
    } else {
      setHasErrorDeleteAccount(true)
    }
  }

  function dataOfButtons() {
    return [
      {
        text: intl.formatMessage({ id: 'return' }),
        data_test: 'buttonDeleteAccountCard',
        hide: hasAccountDeleted,
        onClick: backToMenu,
      },
      {
        text: intl.formatMessage({ id: 'account.delete' }),
        data_test: 'buttonDeleteAccountCard',
        hide: hasAccountDeleted,
        onClick: deleteThisAccount,
      },
      {
        text: intl.formatMessage({ id: 'backToHome' }),
        data_test: 'buttonBackToHomeCard',
        hide: !hasAccountDeleted,
        color: 'white',
        onClick: backToGame,
      },
    ]
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container data-test="containerDeleteAccountCard">
        <CardWithHeader
          isSmall
          backgroundColorHeader="pistachioGreen"
          titleCard={textsCard.title}
          data_test="deleteAccountCard">
          <ContentCard>
            <ContentIconWithParagraph>
              <IcBrendaGoodBye />

              <Paragraph
                dangerouslySetInnerHTML={{ __html: textsCard.paragraph }}
                data-test="paragraphDeleteAccountCard"
              />

              {hasErrorDeleteAccount && (
                <Paragraph>
                  <ErrorDelete>
                    <FormattedMessage id="account.delete.error" />
                  </ErrorDelete>
                </Paragraph>
              )}
            </ContentIconWithParagraph>

            <ContainerButton>
              {memoizedDataOfButton.map(
                ({ text, data_test, hide, color, onClick }) =>
                  !hide && (
                    <ButtonWithRadiusBorderComponent
                      onClick={onClick}
                      key={text}
                      color={color || ''}
                      data_test={data_test}
                      text={text}
                    />
                  ),
              )}
            </ContainerButton>
          </ContentCard>
          <Footer />
        </CardWithHeader>
      </Container>
      {!isMobile && <BackgroundBlur />}
    </>
  )
}

DeleteAccountCardCp.propTypes = {
  closeCardDeleteAccount: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const DeleteAccountCard = injectIntl(DeleteAccountCardCp)
