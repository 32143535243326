export default {
  'game.skills.superior.questions.1':
    'One of {name}’s friends is a victim of prejudice in the campus. {name} decides to:',
  'game.skills.superior.questions.1.option.1':
    'Wait for their classmates to take the first step before getting involved.',
  'game.skills.superior.questions.1.option.1.feedback':
    '{name} preferred to remain silent and lost the chance to become an agent of change and appreciate diversity.',
  'game.skills.superior.questions.1.option.2':
    'Organize a debate with {name}’s classmates.',
  'game.skills.superior.questions.1.option.2.feedback':
    'Congratulations on the initiative! {name} does their part to diminish prejudices and appreciate diversity on campus.​',

  'game.skills.superior.questions.2':
    '{name} won a research scholarship at the university! It was an amazing experience, and {name} learned in practice how data analysis can help making decisions more intelligently.',
  'game.skills.superior.questions.2.feedback':
    'Great job, {name}! This skill is priceless in Portal Z!',

  'game.skills.superior.questions.3':
    '{name} lost control in a live presentation and mistreated the host in the comments. If {name} wants to reach Portal Z, {name} will have to learn how to deal with emotions and respect different ideas.',
  'game.skills.superior.questions.3.feedback': ' ',

  'game.skills.superior.questions.4':
    '{name} created a start-up proposal for presenting ideas for a business at the university. The jury thinks this is a great idea, but they mentioned some flaws in the business model. {name} decides to:',
  'game.skills.superior.questions.4.option.1':
    'Look for an ally that has knowledge in the finance area to improve the proposal.',
  'game.skills.superior.questions.4.option.1.feedback':
    'Well done! {name} analyzed the proposal and identified what was missing to improve the proposal. Congratulations on persistence!',
  'game.skills.superior.questions.4.option.2':
    'Give up on the idea and look for a new one.',
  'game.skills.superior.questions.4.option.2.feedback':
    'Oh, no! {name}’s idea was very good and {name} lost the opportunity to improve it. Persistence and the ability to learn are key elements to reach Portal Z.',

  'game.skills.superior.questions.5':
    '{name} has a job interview and is very interested in getting the job, but {name} does not fulfill all the requirements. What can {name} do?',
  'game.skills.superior.questions.5.option.1':
    "Exaggerate their skills in {name}'s résumé to fulfill all the requirements.",
  'game.skills.superior.questions.5.option.1.feedback':
    'Oops! {name} mixed things up with the lies and lost the position.',
  'game.skills.superior.questions.5.option.2':
    'Emphasize on your skills, experiences and show motivation to learn new things.',
  'game.skills.superior.questions.5.option.2.feedback':
    '{name} had a good interview! Now, it is time to wait for a positive result.',

  'game.skills.superior.questions.6':
    "{name} has just discovered that the free admission exam to university was canceled due to a lack of volunteers. What is {name}'s reaction?",
  'game.skills.superior.questions.6.option.1':
    '{name} is worried about young people that do not have another study alternative.',
  'game.skills.superior.questions.6.option.1.feedback':
    'Empathy is important, but {name} could have taken actions and helped change the story.',
  'game.skills.superior.questions.6.option.2':
    '{name} offers to participate and campaign among friends to increase volunteer hours.',
  'game.skills.superior.questions.6.option.2.feedback':
    'Well done! {name} had empathy and initiative, and also gained more experience!',

  'game.skills.superior.questions.7':
    'This semester is getting tough. {name} cannot finish assignments, loses a good opportunity for exams and the project receives a harsh critique from the professor. How does {name} react?',
  'game.skills.superior.questions.7.option.1':
    '{name} starts having doubts, but prefers to say nothing to no one.',
  'game.skills.superior.questions.7.option.1.feedback':
    'Who has never had doubts or lost confidence in themselves? There is no unique recipe for facing these kinds of situations. Ignoring the problem will not help!',
  'game.skills.superior.questions.7.option.2':
    'Analyze your mistakes and successes, and ask for advice to your friends and teachers.',
  'game.skills.superior.questions.7.option.2.feedback':
    'Who has never had doubts or lost confidence in themselves? There is no unique recipe for facing these kinds of situations. But {name} decided to face the issue and ask for help. This is a good start!',

  'game.skills.superior.questions.8':
    '{name} has doubts about their career and decides to talk to a professional in the field, who advises {name} to think about their goals and make a list about KNOWLEDGE and important SKILLS to achieve them.',
  'game.skills.superior.questions.8.option.1':
    'Points for {name} for planning how to reach Portal Z!',
}
