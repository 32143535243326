import styled from 'styled-components'

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 353px;

  p {
    font-family: Montserrat;
    font-size: 1rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 25px;
  }
`

export const InfoLoginCard = styled.div`
  height: 39px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin-bottom: 20px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
`

export const OrText = styled.div`
  height: 22px;
  font-family: MilkyNice;
  font-size: 18px;
  letter-spacing: -0.08px;
  margin-top: -22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`
