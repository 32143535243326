import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'
import { ButtonContainer } from './styles'
import {
  BackgroundBlur,
  ContentCard,
  Container as CardContainer,
} from '../../GameCards/styles'

import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { injectIntl, FormattedMessage } from 'react-intl'

export const StartJobCardCp = ({ characterName, onOkClick, intl }) => (
  <>
    <CardContainer>
      <CardWithHeader
        isSmall
        data_test="startJob"
        titleCard={intl.formatMessage({ id: 'game.startJob.init' })}
        backgroundColorHeader="white">
        <ContentCard data-test="startJobContent">
          <FormattedMessage id="game.startJob" values={{ name: characterName }} />
          <br /> <br />
          <FormattedMessage id="game.theTilesOf" />
          <b>
            {` ${intl.formatMessage({
              id: 'game.skills',
            })} ${intl.formatMessage({
              id: 'and',
            })} ${intl.formatMessage({
              id: 'game.knowledge',
            })} `}
          </b>
          <FormattedMessage id="game.nowWorthHalfThePoints" />
        </ContentCard>
        <ButtonContainer>
          <ButtonWithRadiusBorderComponent
            data_test="startJobButton"
            onClick={onOkClick}
            text={intl.formatMessage({ id: 'ok' })}
          />
        </ButtonContainer>
      </CardWithHeader>
    </CardContainer>
    <BackgroundBlur />
  </>
)

StartJobCardCp.propTypes = {
  characterName: PropTypes.string.isRequired,
  onOkClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const StartJobCard = injectIntl(StartJobCardCp)
