import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { MyCheckBox, CheckedCheckBox } from './styles'

export function CheckBox({ retrieveCheckOrUnchecked, checked }) {
  const [isChecked, setChecked] = useState(checked)

  function handleClick() {
    setChecked(!isChecked)
    retrieveCheckOrUnchecked(isChecked)
  }

  return <MyCheckBox onClick={handleClick}>{checked && <CheckedCheckBox />}</MyCheckBox>
}

CheckBox.propTypes = {
  retrieveCheckOrUnchecked: PropTypes.func,
  checked: PropTypes.bool,
}

CheckBox.defaultProps = {
  retrieveCheckOrUnchecked: () => {},
  checked: false,
}
