import { ApiIBGE } from './index'

export async function getStates() {
  try {
    const res = await ApiIBGE.get('/estados')
    return orderByName(res.data)
  } catch {
    return undefined
  }
}

export async function getCitys(UF) {
  try {
    const res = await ApiIBGE.get(`/estados/${UF}/municipios`)
    return orderByName(res.data)
  } catch {
    return undefined
  }
}

function orderByName(data) {
  return data.sort(function (a, b) {
    return a.nome.localeCompare(b.nome)
  })
}
