export default {
  'characters.choose': 'Elija un personaje',
  'characters.cris.description':
    'Tengo 15 años me gusta hacer actividades animadas. No me identifico con ningún género y me enfrento a algunos problemas por eso. Me involucro en causas sociales porque creo en un mundo mejor.',
  'characters.milena.description':
    'Tengo 15 años y soy de tipo emprendedor. Escribo sobre mi vida y me encanta publicar todo. No me gusta levantarme temprano, pero vivo lejos de la escuela. Sueño con trabajar en mi propio negocio, ¿por qué no?',
  'characters.brenda.description':
    'Soy creativo y muy curioso. Me gusta el arte y la cultura, pero no estoy seguro de qué quiero hacer con el futuro. Después de todo, el mundo es tan difícil...',
  'characters.jonathan.description':
    'Tengo 16 años, soy tímida, pero muy organizada. La gente dice que no me gusta el cambio y es verdad. Sueño con ir a una buena universidad y tener un trabajo estable.',
}
