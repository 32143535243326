import React from 'react'
import { ThemeProvider } from 'styled-components'

import { AppStore } from './store'
import { Routes } from './routes'
import { theme, Reset, Animations } from './styles'

import Intl from './i18n'

export const App = () => (
  <AppStore>
    <Intl>
      <ThemeProvider theme={theme}>
        <Routes />
        <Reset />
        <Animations />
      </ThemeProvider>
    </Intl>
  </AppStore>
)
