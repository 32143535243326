import { Api } from './index'

export async function verifyToken(dataRequest) {
  try {
    const response = await Api.post('/account/verify_token', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function forgotPassword(dataRequest) {
  try {
    const response = await Api.post('/account/forgot_password', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}

export async function resetPassword(dataRequest) {
  try {
    const response = await Api.post('/account/reset_password', dataRequest)
    return response
  } catch (err) {
    return err.response
  }
}
