import axios from 'axios'
export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_GAME,
})

export const ApiIBGE = axios.create({
  baseURL: process.env.REACT_APP_IBGE_API,
})

export function setToken(token) {
  if (token) {
    Api.defaults.headers.authorization = 'Bearer ' + token
  }
}
