import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
`

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1.42rem;

  p {
    font-family: Montserrat;
    font-size: 1rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2.14rem;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TermsBox = styled.div`
  max-width: 800px;
  max-height: 364px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  overflow: auto;

  #box-titular {
    border: 1px solid;
    padding: 5px;
    background-color: lightgray;
  }

  center {
    font-weight: 800;
    font-size: 14px;
  }

  b {
    font-weight: bold;
  }

  p {
    text-indent: 50px;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: black transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 2px;
  }
`
