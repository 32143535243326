import styled, { css } from 'styled-components'

import { setColorOfButton } from '../validateColorForButton'

export const ButtonWithRadiusBorder = styled.button`
  ${setColorOfButton};
  ${({ theme, withoutMarginTop }) => css`
    border: solid 0.2rem ${theme.colors.black};
    border-radius: 2rem;
    box-shadow: -2.5px 3px rgb(29, 29, 27);
    color: black;
    font-family: MilkyNice;
    padding: 0.5rem;
    margin-top: ${withoutMarginTop ? `0px` : `30px`};
    text-decoration: none;

    @media screen and (min-width: 1024px) {
      padding: 0.7rem;
      min-width: 140px;
      max-height: 50px;
    }

    @media screen and (max-width: 1024px) {
      margin-top: ${withoutMarginTop ? `5px` : `20px`};
      min-width: 130px;
      max-height: 45px;
    }

    &:focus,
    &:hover {
      background: none;
      background-color: ${theme.colors.orangeButtonFocused};
    }

    &:disabled {
      background: none;
      background-color: ${theme.colors.veryLightPink};
      border: solid 0.2rem ${theme.colors.brownGrey};
      box-shadow: -2.5px 3px ${theme.colors.brownGrey};
      color: ${theme.colors.brownGrey};
    }

    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
  `}
`
