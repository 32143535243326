export default {
  'game.knowledge.middle.questions.1':
    '¿Cuál de estos items no caracteriza un sistema democrático?',
  'game.knowledge.middle.questions.1.option.1': 'Acceso a la información.',
  'game.knowledge.middle.questions.1.option.1.feedback':
    'Un sistema democrático no prohíbe manifestaciones políticas. Democracia significa libertad de expresión, elecciones libres, libertad de asociación política y acceso a la información.',
  'game.knowledge.middle.questions.1.option.2':
    'Prohibición de manifestaciones políticas',
  'game.knowledge.middle.questions.1.option.2.feedback':
    '¡Muy bien! {name} ya sabe que la democracia tiene que ver con la libertad.',

  'game.knowledge.middle.questions.2':
    '¿Cuál es la mejor estrategia para construir un argumento?',
  'game.knowledge.middle.questions.2.option.1':
    'Recurrir a datos concretos y hacer comparaciones para justificar su punto de vista.',
  'game.knowledge.middle.questions.2.option.1.feedback':
    '¡Muy bien! ¡Una buena argumentación está basada en hechos, no en gritos!',
  'game.knowledge.middle.questions.2.option.2':
    'Hablar firmemente y atacar el posición contrária.​',
  'game.knowledge.middle.questions.2.option.2.feedback':
    'No es posible convencer a otra persona con gritos y ataques. Argumentar es presentar datos e ideas que apoyen una opinión.',

  'game.knowledge.middle.questions.3':
    '¿Cuál de los items siguientes no representa una reacción química?',
  'game.knowledge.middle.questions.3.option.1': 'Freir un huevo.',
  'game.knowledge.middle.questions.3.option.1.feedback':
    '¡Ups! La respuesta correcta es la otra opción, ya que el agua y el aceite no se mezclan.',
  'game.knowledge.middle.questions.3.option.2': 'Mezclar agua con aceite.',
  'game.knowledge.middle.questions.3.option.2.feedback':
    '¡Muy bien! Mezclar esos dos elementos no representa una reacción química.',

  'game.knowledge.middle.questions.4':
    'Imagina que María gana $1,200 por mes de salario. Su presupuesto incluye $450 de alquiler, $300 de alimentación y $100 entre cuentas de luz y agua. ¿Cuánto le sobra de su presupuesto mensual para los demás gastos de la familia?',
  'game.knowledge.middle.questions.4.option.1': '$350,00',
  'game.knowledge.middle.questions.4.option.1.feedback':
    'Respuesta correcta. ¡Calcular correctamente el presupuesto familiar puede evitar un gran dolor de cabeza!',
  'game.knowledge.middle.questions.4.option.2': '$250,00',
  'game.knowledge.middle.questions.4.option.2.feedback':
    '¡Las cuentas no cierran! La respuesta correcta es $350,00. ¡Calcular correctamente el presupuesto familar puede evitar un gran dolor de cabeza!',

  'game.knowledge.middle.questions.5':
    'Los antibióticos son medicamentos para combatir infecciones causadas por...',
  'game.knowledge.middle.questions.5.option.1': 'Bacterias.',
  'game.knowledge.middle.questions.5.option.1.feedback': '¡Muy bien! Respuesta correcta.',
  'game.knowledge.middle.questions.5.option.2': 'Virus.',
  'game.knowledge.middle.questions.5.option.2.feedback':
    '¡Equivocado! Los antibióticos son medicamentos que combaten las bacterias, no los virus.',

  'game.knowledge.middle.questions.6':
    'Cinco pelotas numeradas de 1 a 5 son colocadas en grupo. La probabilidad de número par es de:',
  'game.knowledge.middle.questions.6.option.1': '20%',
  'game.knowledge.middle.questions.6.option.1.feedback':
    '¡Ups! La respuesta correcta es 40%',
  'game.knowledge.middle.questions.6.option.2': '40%',
  'game.knowledge.middle.questions.6.option.2.feedback': '¡Respuesta correcta!',

  'game.knowledge.middle.questions.7': '¿Cuál es la frase correcta?',
  'game.knowledge.middle.questions.7.option.1': 'Había muchas personas en el juego.',
  'game.knowledge.middle.questions.7.option.1.feedback':
    '¡Respuesta correcta! Cuando el verbo HABER es usado con el significado de EXISTIR, se conjuga en la 3ra persona del singular.',
  'game.knowledge.middle.questions.7.option.2': 'Habían muchas personas en el juego.',
  'game.knowledge.middle.questions.7.option.2.feedback':
    '¡Respuesta errada! Cuando el verbo HABER es usado con el significado de EXISTIR, se conjuga con la 3ra persona del singular: "Había muchas personas en el juego".',

  'game.knowledge.middle.questions.8':
    'El hindú Mahatma Gandhi fue famoso muldialmente por:',
  'game.knowledge.middle.questions.8.option.1':
    'Liderar un movimiento no violento por la independencia de la India.',
  'game.knowledge.middle.questions.8.option.1.feedback': '¡Respuesta correcta!',
  'game.knowledge.middle.questions.8.option.2': 'Iniciar una guerra mundial.',
  'game.knowledge.middle.questions.8.option.2.feedback':
    'Respuesta incorrecta. Gandhi lideró el movimiento de independencia de la India y es reconocido mundialmente por su forma de manifestación pacífica.',
}
