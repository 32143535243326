export default {
  'game.challenge.middle.questions.1':
    '{name} no fue precavido y contrajo una EST (Enfermedad de Transmisión Sexual). Por esto va a tener que hacer un largo tratamiento de salud y alejarse de la escuela por un período.',
  'game.challenge.middle.questions.1.feedback':
    'Increíble, ¿no?. ¡La prevención es cosa seria! {name} termino por perder algunos XP porque debe dar atención a este tema.',

  'game.challenge.middle.questions.2':
    'La madre de {name} volvió a trabajar y necesita ayuda para cuidar al hermano menor. {name} decide:',
  'game.challenge.middle.questions.2.option.1':
    'No ir a clases por una semana para cuidar a su hermano.',
  'game.challenge.middle.questions.2.option.1.feedback':
    '¡{name} perdió una semana de clases y ahora tendrá más dificultad para seguir el contenido!',
  'game.challenge.middle.questions.2.option.2':
    'Buscar ayuda de una vecina para no perder clases.​',
  'game.challenge.middle.questions.2.option.2.feedback':
    'Gracias a su iniciativa, {name} no perdió ninguna clase y ganó más XP. ¡Rumbo al Portal Z!',

  'game.challenge.middle.questions.3':
    '{name} necesita un celular mejor pero no tiene dinero. ¿Qué hacer?',
  'game.challenge.middle.questions.3.option.1': 'Busca un empleo en el comercio.',
  'game.challenge.middle.questions.3.option.1.feedback':
    'Muchos jóvenes abandonan la escuela para trabajar. El desafío de {name} es conciliar ambos para llegar al Portal Z.',
  'game.challenge.middle.questions.3.option.2':
    'Organiza una colecta de dinero con amigos y familiares.​',
  'game.challenge.middle.questions.3.option.2.feedback':
    'Toda decisión genera un aprendizaje, ¿verdad?. Tardará más en comprar el celular, pero {name} podrá enfocarse en los estudios y ganar muchos XP.​',

  'game.challenge.middle.questions.4':
    'Por dificultades económicas, la madre de {name} no conseguirá pagar la cuota mensual del curso de inglés. ¿Qué hacer?',
  'game.challenge.middle.questions.4.option.1':
    'Deja el curso de inglés hasta que la situación económica mejore.',
  'game.challenge.middle.questions.4.option.1.feedback':
    'Dejar el curso de inglés, sin intentar otra opción puede entorpecer los planes futuros.',
  'game.challenge.middle.questions.4.option.2':
    'Busca un curso gratuito en línea y sigue sus estudios de forma autodidacta.',
  'game.challenge.middle.questions.4.option.2.feedback':
    '{name} entiende que la adaptación puede ser complicada, pero ¡más vale intentarlo que dejar de estudiar!',

  'game.challenge.middle.questions.5':
    'Algunas clases de la escuela de {name} ahora son "en línea". Como {name} no tiene computadora, necesita seguir todo desde su celular, pero esa modalidad es muy agotadora. ¿Qué hace {name}?',
  'game.challenge.middle.questions.5.option.1':
    'Presta muy poca atención a las clases desde el celular.',
  'game.challenge.middle.questions.5.option.1.feedback':
    'Fingir que está estudiando sólo provoca atrasos.',
  'game.challenge.middle.questions.5.option.2':
    'Desactiva las notificaciones y se esfuerza por prestar atención.​',
  'game.challenge.middle.questions.5.option.2.feedback':
    '¡Felicitaciones! Gracias a su dedicación, {name} mantiene sus buenas notas.',

  'game.challenge.middle.questions.6':
    '{name} se mudó a un barrio que queda muy lejos de la escuela y el tiempo de traslado se duplicó. Aún cuando debe comenzar el día mas temprano, {name} sigue con determinación para concluir la escuela media.',
  'game.challenge.middle.questions.6.option.1': ' ',
}
