export default {
  'game.skills.middle.questions.1':
    'An Olympic athlete visits the school and the principal is looking for a student that can help with the school tour. What does {name} do?',
  'game.skills.middle.questions.1.option.1':
    '{name} applies as a candidate and is excited with this opportunity.',
  'game.skills.middle.questions.1.option.1.feedback':
    '{name} surprised the athlete with a special presentation. {name} used their creativity and developed their position and gave a speech.',
  'game.skills.middle.questions.1.option.2':
    '{name} is afraid and prefers that another classmate completes this mission.',
  'game.skills.middle.questions.1.option.2.feedback':
    '{name} did not overcome their fears and still needs to improve self-trust.​',

  'game.skills.middle.questions.2':
    'The student center’s election period is here! {name} decides to:',
  'game.skills.middle.questions.2.option.1':
    'Support the student group that has the best proposal, in their opinion.',
  'game.skills.middle.questions.2.option.1.feedback':
    '{name} is actively involved. {name} had to lead teams and convince their classmates. This experience has provided new skills and a sum of XP to reach Portal Z.',
  'game.skills.middle.questions.2.option.2': 'Not participate in the elections.',
  'game.skills.middle.questions.2.option.2.feedback':
    '{name} did not show initiative and lost the opportunity to participate in making important decisions for the school and the students.',

  'game.skills.middle.questions.3':
    'The school made an alliance with an NGO to offer an entrepreneurship class for students. What does {name} do?',
  'game.skills.middle.questions.3.option.1':
    '{name} registers for the class, even though it is not mandatory.',
  'game.skills.middle.questions.3.option.1.feedback':
    'Good initiative! {name} created a student company with other classmates and discovered skills that they did not know they had! This experience will help {name} with future decisions!',
  'game.skills.middle.questions.3.option.2':
    '{name} did not register for the class because {name} is not interested in starting a business.',
  'game.skills.middle.questions.3.option.2.feedback':
    '{name} lost the opportunity to explore personal talents and to discover new career options.',

  'game.skills.middle.questions.4':
    '{name} is organizing the ‘Dance festival’, but the team does not have experience in event organization, so the members argue about everything. What should {name} do?',
  'game.skills.middle.questions.4.option.1':
    'Give the position to someone with more experience.',
  'game.skills.middle.questions.4.option.1.feedback':
    'Not a good decision! This way, {name} loses the opportunity to practice a fundamental skill in the world of VUCA: to lead people and projects.',
  'game.skills.middle.questions.4.option.2':
    'Talk to the team to identify the interests and skills of each member.',
  'game.skills.middle.questions.4.option.2.feedback':
    'Well done! Points for {name}, who learned about leading people and coordinating projects!',

  'game.skills.middle.questions.5':
    '{name} discovered through social media that their best friend lied about the weekend activities. {name} decides to:',
  'game.skills.middle.questions.5.option.1':
    'Post a text in social media exposing their friend.',
  'game.skills.middle.questions.5.option.1.feedback':
    '{name} acted on impulse and publicly attacked their friend.  {name} lacked self-control and dialogue, which are fundamental assets in the world of VUCA.',
  'game.skills.middle.questions.5.option.2': 'Talk to their friend.',
  'game.skills.middle.questions.5.option.2.feedback':
    'Well done! {name} felt bad, but did not get carried away. Points for {name} for the initiative and self-control!',

  'game.skills.middle.questions.6':
    "{name}'s friend does not understand why the school’s library only opens once a month. She talked about it with {name}, who said:",
  'game.skills.middle.questions.6.option.1': '‘It has always been like that!’',
  'game.skills.middle.questions.6.option.1.feedback':
    'Oops! This phrase is forbidden in the world of VUCA. The fact that things are done in one way does not mean that they cannot be improved, right?',
  'game.skills.middle.questions.6.option.2':
    '‘I had never thought about it, is there something we can do?',
  'game.skills.middle.questions.6.option.2.feedback':
    'Awesome! The fact that things are done in one way does not mean that they cannot be improved, right? Most innovations (whether big or small) start this way.',
}
