export default {
  'game.challenge.superior.questions.1':
    '{name} estudou muito para passar em uma universidade pública, mas infelizmente não conseguiu. A situação abalou os ânimos de {name} que está sem gás para recomeçar.',
  'game.challenge.superior.questions.1.feedback':
    'Problemas fazem parte da vida! Foco no portal Z, {name}!',

  'game.challenge.superior.questions.2':
    '{name} divide apartamento com uma amiga. Mas a amiga conseguiu uma bolsa de estudos em outro estado e terá que se mudar. {name} não consegue pagar todas as contas. E agora, o que fazer ?',
  'game.challenge.superior.questions.2.option.1': 'Procura alguém para dividir o aluguel',
  'game.challenge.superior.questions.2.option.1.feedback':
    'Ótima ideia! E a amiga ainda vai ajudar na divulgação.',
  'game.challenge.superior.questions.2.option.2':
    'Começa a dar aulas particulares para pagar o aluguel​',
  'game.challenge.superior.questions.2.option.2.feedback':
    '{name} mostrou seu lado empreendedor, mas corre o risco de não conseguir o valor para todos os meses.​',

  'game.challenge.superior.questions.3':
    '{name} começou o Ensino Superior e percebeu que vai precisar de dinheiro para pagar suas despesas. E agora?',
  'game.challenge.superior.questions.3.option.1':
    'Procura um emprego para pagar as contas e segue estudando',
  'game.challenge.superior.questions.3.option.1.feedback':
    '{name} conseguiu um estágio de meio período. Mandou bem na faculdade e ganhou elogios dos colegas de trabalho. {name} vai para o Mercado de Trabalho e acumula Xp para chegar ao Portal Z!! Boa',
  'game.challenge.superior.questions.3.option.2':
    'Pede ajuda da família e continua 100% com foco nos estudos​',
  'game.challenge.superior.questions.3.option.2.feedback':
    '{name} estudou em dobro e se envolveu em diversos projetos. Foi um semestre e tanto, e {name} garantiu mais Xp para chegar ao portal Z. Boa!',

  'game.challenge.superior.questions.4':
    '{name} estava indo para casa à noite, após a aula, quando furtaram sua mochila com o notebook e o dinheiro para o transporte do mês. {name} terá que buscar uma renda extra para recuperar o dinheiro e conseguir comprar um novo notebook. ',
  'game.challenge.superior.questions.4.feedback':
    'Problemas fazem parte da vida! Foco no portal Z, {name}!',

  'game.challenge.superior.questions.5':
    '{name} criou uma conta no Instagram para dar dicas sobre estudo. Seus conteúdos vêm recebendo comentários positivos, porém {name} viu alguns comentários maldosos que abalaram sua autoconfiança. O que fazer?',
  'game.challenge.superior.questions.5.option.1':
    'Focar nas melhorias e nos comentários positivos.',
  'game.challenge.superior.questions.5.option.1.feedback':
    'Ótima escolha! É muito importante filtrar os comentários, focar na melhoria do conteúdo e persistir.',
  'game.challenge.superior.questions.5.option.2':
    'Parar de postar conteúdos na rede social.',
  'game.challenge.superior.questions.5.option.2.feedback':
    'Que pena! {name} teve a iniciativa de criar um ótimo projeto, mas acabou desanimando em função de alguns comentários negativos. Faltou persistência e autoconfiança para seguir.',

  'game.challenge.superior.questions.6':
    '{name} está enfrentando problemas familiares e, por isso,  sente  angústia quanto pensa  no seu futuro. O que fazer?',
  'game.challenge.superior.questions.6.option.1':
    'Segue sua rotina e procura ignorar seus sentimentos.',
  'game.challenge.superior.questions.6.option.1.feedback':
    'Ignorar os próprios sentimentos acabou agravando a situação de {name}. Reconhecer suas dificuldades e buscar ajuda seria um ótimo começo!',
  'game.challenge.superior.questions.6.option.2':
    'Busca ajuda médica para ajustar a rotina da melhor forma.',
  'game.challenge.superior.questions.6.option.2.feedback':
    'Boa! {name} reconheceu suas dificuldades e buscou ajuda! Parabéns pela iniciativa.',
}
