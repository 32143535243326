export const juniorStage = 'Ensino Fundamental'
export const middleStage = 'Ensino Médio'
export const superiorStage = 'Ensino Superior'

export const gameConstants = {
  CONDITION_TYPES: {
    DEBUFF: 'debuff',
    BUFF: 'buff',
  },
}

export const malePersona1 = 'Cris'
export const malePersona2 = 'Jonathan'
export const femalepersona1 = 'Milena'
export const femalepersona2 = 'Brenda'
