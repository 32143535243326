import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.knowledge': 'Quiz',
  'game.knowledge.message':
    'Hora de testar seus conhecimentos! Nessa casa você vai responder perguntas que podem dar eu te tirar pontos de experiência.',

  ...junior,
  ...middle,
  ...superior,
}
