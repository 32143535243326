import React from 'react'

import { ContainerTextContentDesktop, Paragraph } from '../../styles'
import { FormattedMessage } from 'react-intl'

export const ContentTextIntroDesktop = () => {
  const introParagraphsDesktop = [
    'landingpage.introParagraphs.1',
    'landingpage.introParagraphs.2',
    'landingpage.introParagraphs.3',
    'landingpage.introParagraphs.4',
  ]

  return (
    <ContainerTextContentDesktop>
      {introParagraphsDesktop.map((item, index) => (
        <Paragraph key={index}>
          <FormattedMessage id={item} />
        </Paragraph>
      ))}
    </ContainerTextContentDesktop>
  )
}
