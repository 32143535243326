import React, { memo, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import {
  IcPerson,
  IcDownload,
  IcExit,
  IcRule,
  IcLogin,
  IcSandwichMenu,
  IcX,
} from '../../assets/img'

import { Account, Select } from '..'
import { types } from '../../store'
import { logout } from '../../services/API/login'
import { get } from '../../services/storage'

import {
  MenuMobile,
  MenuMobileItem,
  MenuMobileItemText,
  MenuMobileContainerItems,
} from './styles'
import { useStore } from '../../store/hooks'
import { CircularButtonComponent } from '../Buttons/circularButton'
import { gamePropTypes } from '../../game'

const MobileMenuCp = ({
  G,
  onRulesClick,
  saveGameClick,
  showLoginCard,
  showMenuMobile,
  handleDisplayMenuMobile,
  isShowSignUpCard,
  isShowLoginCard,
  intl,
}) => {
  const { store, dispatch } = useStore()
  const [isShowSubItems, setIsShowSubItems] = useState(false)
  const { language } = store

  const listLanguage = [
    { value: 'pt', label: 'PT', description: intl.formatMessage({ id: 'language.pt' }) },
    { value: 'es', label: 'ES', description: intl.formatMessage({ id: 'language.es' }) },
    { value: 'en', label: 'EN', description: intl.formatMessage({ id: 'language.en' }) },
  ]

  const updateLanguage = (language) => {
    dispatch({ type: types.SET_LANGUAGE, payload: language })
  }
  const memoizedDataOfButton = useMemo(dataOfMenuItems, [
    isShowSignUpCard,
    isShowLoginCard,
    isShowSubItems,
    get('token'),
  ])

  function isUserLogged() {
    return !!get('token')
  }

  function closeAccount() {
    setIsShowSubItems(false)
  }

  function logoutGame() {
    logout(dispatch)
    window.location.replace('https://en.gamevuca.org')
  }

  function showAccountSubItems() {
    setIsShowSubItems(!isShowSubItems)
  }

  function saveGame(G) {
    if (G && isUserLogged()) {
      saveGameClick(G)
    }
  }

  function dataOfMenuItems() {
    return [
      {
        text: intl.formatMessage({ id: 'save' }),
        data_test: 'mobileMenuSaveButton',
        Icon: IcDownload,
        hide: false,
        onClick: () => saveGame(G),
      },
      {
        text: intl.formatMessage({ id: 'menu.login' }),
        data_test: 'mobileMenuLoginButton',
        Icon: IcLogin,
        hide: isUserLogged(),
        onClick: showLoginCard,
      },
      {
        text: intl.formatMessage({ id: 'menu.account.my' }),
        data_test: 'mobileMenuAccountButton',
        Icon: IcPerson,
        hide: !isUserLogged(),
        onClick: showAccountSubItems,
        showSubItems: isShowSubItems,
      },
      {
        text: intl.formatMessage({ id: 'menu.rules.game' }),
        data_test: 'mobileMenuRulesButton',
        Icon: IcRule,
        hide: false,
        onClick: onRulesClick,
      },
      {
        text: intl.formatMessage({ id: 'menu.exit.game' }),
        data_test: 'mobileMenuExitButton',
        Icon: IcExit,
        hide: false,
        onClick: logoutGame,
      },
    ]
  }

  const buttonMobile = (Icon) => {
    return (
      <CircularButtonComponent
        to="#"
        id="mobileMenuButton"
        color="white"
        data_test="menuButtonMobile"
        onClick={() => handleDisplayMenuMobile(!showMenuMobile)}>
        <Icon width="15" height="15" />
      </CircularButtonComponent>
    )
  }

  return (
    <>
      {showMenuMobile && (
        <MenuMobile>
          {buttonMobile(IcX)}
          <MenuMobileContainerItems>
            {memoizedDataOfButton.map(
              ({ text, data_test, Icon, hide, onClick, showSubItems }) =>
                !hide && (
                  <>
                    <MenuMobileItem
                      data-test={data_test}
                      className="menuItem"
                      type="button"
                      onClick={onClick || (() => {})}
                      key={text}>
                      <Icon width="24" height="24" />
                      <MenuMobileItemText>{text}</MenuMobileItemText>
                    </MenuMobileItem>
                    {showSubItems && <Account closeAccount={closeAccount} />}
                  </>
                ),
            )}
          </MenuMobileContainerItems>
        </MenuMobile>
      )}
      {/* {buttonMobile(IcSandwichMenu)}
      <div className="containerSelect mobile">
        <Select
          color="white"
          withoutMarginTop={false}
          value={listLanguage.filter((lang) => lang.value === language)[0]}
          options={listLanguage}
          onChange={(selected) => updateLanguage(selected.value)}
        />
      </div> */}
    </>
  )
}

MobileMenuCp.propTypes = {
  G: gamePropTypes.G,
  onRulesClick: PropTypes.func,
  showLoginCard: PropTypes.func.isRequired,
  showMenuMobile: PropTypes.bool.isRequired,
  saveGameClick: PropTypes.func.isRequired,
  handleDisplayMenuMobile: PropTypes.func.isRequired,
  isShowSignUpCard: PropTypes.bool.isRequired,
  isShowLoginCard: PropTypes.bool.isRequired,
  intl: PropTypes.object,
}
const MobileMenu = injectIntl(MobileMenuCp)
const MemoizedMobileMenu = memo(MobileMenu)

export { MemoizedMobileMenu as MobileMenu }
