import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { isMobileSize } from '../../../common/utils'

import { CardWithHeader } from '../../../components'
import { BackgroundBlur } from '../../../components/GameCards/styles'

import { Container, ContentCard, ContainerButton, TermsBox } from './styles'
import { Footer } from '../../../pages/ChoiceCharacter/CharactersCard/components'
import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { FormattedMessage, injectIntl } from 'react-intl'

export const CardTermsCp = ({ closeCardTerms, intl }) => {
  const isMobile = useMemo(isMobileSize, [])

  return (
    <div>
      <Container>
        <CardWithHeader
          titleCard={intl.formatMessage({ id: 'terms' })}
          backgroundColorHeader="pistachioGreen"
          isSmall={isMobile}
          data_test="termsCard">
          <ContentCard>
            <TermsBox>
              <center>
                <FormattedMessage id="terms.title" />
              </center>
              <br /> <br />
              <p>
                <FormattedMessage id="terms.description.1" />
              </p>
              <br /> <br />
              <div id="box-titular">
                <b>
                  <FormattedMessage id="terms.description.2" />
                </b>
                <br />
                <FormattedMessage id="terms.description.2.info" />
              </div>
              <br /> <br />
              <p>
                <FormattedMessage id="terms.description.3" />
              </p>
              <br />
              <b>
                <FormattedMessage id="terms.description.4" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.4.info" />
              </p>
              <br />
              <ul>
                <li>
                  <FormattedMessage id="terms.description.4.list.1" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.2" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.3" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.4" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.5" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.6" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.7" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.8" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.9" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.10" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.4.list.11" />
                </li>
              </ul>
              <br />
              <b>
                <FormattedMessage id="terms.description.5" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.5.info" />
              </p>
              <br />
              <ul>
                <li>
                  <FormattedMessage id="terms.description.5.list.1" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.5.list.2" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.5.list.3" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.5.list.4" />
                </li>
                <li>
                  <FormattedMessage id="terms.description.5.list.5" />
                </li>
              </ul>
              <br />
              <b>
                <FormattedMessage id="terms.description.6" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.6.info" />
              </p>
              <br />
              <b>
                <FormattedMessage id="terms.description.7" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.7.info" />
              </p>
              <br />
              <b>
                <FormattedMessage id="terms.description.8" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.8.info" />
              </p>
              <br />
              <b>
                <FormattedMessage id="terms.description.9" />
              </b>
              <br />
              <p>
                <FormattedMessage id="terms.description.9.info" />
              </p>
              <br />
            </TermsBox>

            {closeCardTerms && (
              <ContainerButton>
                <ButtonWithRadiusBorderComponent
                  type="button"
                  data_test="goToGame"
                  onClick={closeCardTerms}
                  text={intl.formatMessage({ id: 'ok' })}
                />
              </ContainerButton>
            )}
          </ContentCard>
          <Footer />
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </div>
  )
}

CardTermsCp.propTypes = {
  closeCardTerms: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.bool.isRequired,
  ]),
  intl: PropTypes.object,
}

export const CardTerms = injectIntl(CardTermsCp)
