export default {
  'game.challenge.junior.questions.1':
    "{name} had a difficult year with many family issues, and ended up failing school. Hardships can affect anyone, but they are a part of life. {name} stopped gaining XP's, but {name} is ready to face a new year focused and determined!",

  'game.challenge.junior.questions.2':
    'A group of students made fun of {name}’s hairstyle, and now going to school has become a drama. What should {name} do?',
  'game.challenge.junior.questions.2.option.1':
    'Keep calm and do not complain until the school year ends.',
  'game.challenge.junior.questions.2.option.1.feedback':
    "Staying quiet did not help at all. The semester was pretty tough and {name} gained little XP's.",
  'game.challenge.junior.questions.2.option.2':
    "Tell your parents and the school's headmaster about this issue.",
  'game.challenge.junior.questions.2.option.2.feedback':
    'Congratulations for being so brave! Excellent! The school found out that {name} was not the only victim of this group of students. Thanks to {name}’s attitude, the school organized an anti-bullying campaign and the mood around school has improved for everyone.​',

  'game.challenge.junior.questions.3':
    '{name}’s parents got a divorce and {name}’s father was the one who took care of all the bills. With the divorce, {name} thinks whether to...',
  'game.challenge.junior.questions.3.option.1': 'Start selling candy with their mother.',
  'game.challenge.junior.questions.3.option.1.feedback':
    'Hard scenario, isn’t it? This will be {name}’s first work experience, and {name} will have to balance studies with new obligations in order to gain XP’s and reach Portal Z.',
  'game.challenge.junior.questions.3.option.2':
    'Focus exclusively on studies, knowing that the family will be on a tight budget.',
  'game.challenge.junior.questions.3.option.2.feedback':
    "It was a tough decision, but {name} realized that the lack of elementary education limited the family's options, so {name} has decided to focus on education in order to arrive better prepared at Portal Z.",

  'game.challenge.junior.questions.4':
    '{name} is having a hard time following up and understanding the course materials for the subjects of Math and Spanish. {name} feels the need to:',
  'game.challenge.junior.questions.4.option.1':
    'Drop out of school and try again the following year.',
  'game.challenge.junior.questions.4.option.1.feedback':
    'Giving up on education is never a good decision. {name} continues to study, but is struggling.',
  'game.challenge.junior.questions.4.option.2': 'Make an extra effort and ask for help.​',
  'game.challenge.junior.questions.4.option.2.feedback':
    'Well done! {name}’s efforts were not enough to pass Math but, on the other hand, {name} developed some resilience and that is very important for life’s future challenges.',

  'game.challenge.junior.questions.5':
    '{name} heard some friends at school talking about how school is a waste of time and {name} ended up dedicating little time to studying.',
  'game.challenge.junior.questions.5.feedback':
    'Now {name} is struggling to pass all the subjects.',

  'game.challenge.junior.questions.6':
    '{name}’s school is organizing debates on entrepreneurship and financial education as extracurricular activities.',
  'game.challenge.junior.questions.6.option.1': '{name} registers as a participant.',
  'game.challenge.junior.questions.6.option.1.feedback':
    'Despite not finding these subjects enjoyable, {name} participated on the debates and felt that it is possible to learn and enjoy new topics. Awesome!',
  'game.challenge.junior.questions.6.option.2': '{name} does not enjoy these topics.',
  'game.challenge.junior.questions.6.option.2.feedback':
    'Cheer up! These subjects can greatly contribute to {name}’s life. Who knows? Maybe {name} will participate next time, right?',
}
