import { PlayerView } from 'boardgame.io/core'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

import { GamePropTypes } from './propTypes'
import { juniorStage, middleStage, superiorStage } from '../common/strings'
import {
  rollDice,
  stepTile,
  hideCard,
  commitPoints,
  selectCharacter,
  stageProgression,
  addCondition,
  removeCondition,
  updateConcludeStep,
} from './moves'

import { cards } from './cards'
import { get } from '../services/storage'

const moves = {
  rollDice,
  stepTile,
  hideCard,
  commitPoints,
  selectCharacter,
  stageProgression,
  addCondition,
  removeCondition,
  updateConcludeStep,
}

export const gamePropTypes = {
  ...GamePropTypes,
  moves: PropTypes.shape(getMovesShape()).isRequired,
}
function getMovesShape() {
  const aux = {}
  Object.keys(moves).forEach((m) => {
    aux[m] = PropTypes.func.isRequired
  })

  return aux
}

function getCardsWithId() {
  const cardsWithIndex = cards
  for (const stage in cards) {
    const stageCategories = cards[stage]
    for (const category in stageCategories) {
      const cardsInCategory = stageCategories[category]
      if (Array.isArray(cardsInCategory)) {
        cardsWithIndex[stage][category] = cardsInCategory.map((card) => {
          return { ...card, key: uuid() }
        })
      }
    }
  }

  return cardsWithIndex
}

const isLoginFacebook = get('isLoginFacebook')
const gameStage = get('gameState')
const hasSavedGame = isLoginFacebook && gameStage && gameStage.G

const savedSetup = () => gameStage.G

const setup = () => ({
  gameStagesNames: [juniorStage, middleStage, superiorStage],
  disableUndo: true,
  boardTiles: [],
  understandedCards: [],
  cardsAlreadyExhibited: [],
  cards: getCardsWithId(),
  discardPile: [],
  showCard: false,
  progress: 0,
  currentCard: {},
  concludeStep: {
    junior: {
      done: false,
    },
    middle: {
      done: false,
    },
    superior: {
      done: false,
    },
  },
  progressLevels: {
    junior: {
      min: 0,
      max: 300,
    },
    middle: {
      min: 300,
      max: 600,
    },
    superior: {
      min: 600,
      max: 1000,
    },
  },
  gameStage: 0,
  pawnTilePosition: -1,
  lastDiceRoll: 0,
  conditions: [],
  lastPath: [],
  animatePawn: false,
  character: null,
  turnCounter: 0,
  maxTurns: 45,
  pointsCredited: 0,
  stageProgressionChecked: false,
  pointsCommitted: false,
})

export const GameJA = {
  setup: hasSavedGame ? savedSetup : setup,
  phases: {
    retriveSavedGame: {
      start: true,
      endIf: (G) => true,
      onEnd: (G) => {
        const gameState = get('gameState')
        if (gameState && gameState.G) {
          G.character = gameState.G.character
          G.cardsAlreadyExhibited = gameState.G.cardsAlreadyExhibited
          G.conditions = gameState.G.conditions
          G.currentCard = gameState.G.currentCard
          G.discardPile = gameState.G.discardPile
          G.gameStage = gameState.G.gameStage
          G.lastDiceRoll = gameState.G.lastDiceRoll
          G.lastPath = gameState.G.lastPath
          G.pawnTilePosition = gameState.G.pawnTilePosition
          G.pointsCommitted = gameState.G.pointsCommitted
          G.pointsCredited = gameState.G.pointsCredited
          G.concludeStep = gameState.G.concludeStep
          G.progress = gameState.G.progress
          G.showCard = gameState.G.showCard
          G.stageProgressionChecked = gameState.G.stageProgressionChecked
          G.turnCounter = gameState.G.turnCounter
          G.understandedCards = gameState.G.understandedCards
        }
      },
      next: 'setupCharacter',
    },
    setupCharacter: {
      endIf: (G) => G.character !== null,
      next: 'rollDice',
    },
    rollDice: {
      moves: { hideCard, rollDice, addCondition, removeCondition, updateConcludeStep },
      next: 'cleanUp',
    },
    cleanUp: {
      next: 'movePawn',
      endIf: () => true,
      onEnd: (G) => {
        G.pointsCredited = 0
        G.pointsCommitted = false
        G.stageProgressionChecked = false
      },
    },
    movePawn: {
      moves: { stepTile, updateConcludeStep },
      endIf: (G) => !G.animatePawn && G.lastPath.length === 0 && G.pawnTilePosition >= 0,
      next: 'commitPoints',
    },
    commitPoints: {
      moves: {
        hideCard,
        commitPoints,
        addCondition,
        removeCondition,
        updateConcludeStep,
      },
      endIf: (G) => G.pointsCommitted === true,
      onEnd: (G) => {
        G.turnCounter++
      },
      next: 'checkStageProgression',
    },
    checkStageProgression: {
      moves: { stageProgression },
      endIf: (G) => G.stageProgressionChecked === true,
      onBegin: (G, ctx) => {
        stageProgression(G, ctx)
      },
      onEnd: (G) => {
        G.stageProgressionChecked = false
      },
      next: 'rollDice',
    },
  },
  endIf: ({ turnCounter, turnMax, progress, progressLevels }) =>
    turnCounter === turnMax || progress >= progressLevels.superior.max,
  playerView: PlayerView.STRIP_SECRETS,
  moves,
}
