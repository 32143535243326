export const colorOfProgressItems = {
  red: '#FA3207',
  green: '#83bb00',
  yellow: '#FFCE00',
}

export function setColorInProgressBar({ color }) {
  if (color) {
    return colorOfProgressItems[color]
  }
  throw new Error('color Is Required')
}
