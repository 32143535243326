import React, { useEffect, useState } from 'react'
import PropTypes, { func, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Tooltip } from '../Tooltip/'
import {
  Label,
  RadioButton,
  LabelRadioCheckBox,
  RadioButtons,
  ContainerRadioButton,
} from './styles'

export const RadioGroup = ({ objectString, sendValue, radioGroupName, value }) => {
  const [checked, setChecked] = useState(value)

  useEffect(setCheckedValue, [value])
  function setCheckedValue() {
    if (value) {
      setChecked(value)
    }
  }

  function handleChange(e) {
    setChecked(e.target.value)
    sendValue(e)
  }

  function isChecked(forValue) {
    return forValue === checked
  }

  return (
    <div>
      <Label>
        <FormattedMessage id={objectString.label} />
        {objectString.tooltip && <Tooltip tooltipMsg={objectString.tooltip} />}
      </Label>
      <RadioButtons>
        {objectString.placeholder.map((item, index) => {
          return (
            <ContainerRadioButton key={index} data-test={objectString.dataTest}>
              <RadioButton
                id={item.for.toString()}
                value={item.for}
                name={radioGroupName}
                checked={isChecked(item.for)}
                type="radio"
                onChange={handleChange}
              />
              <LabelRadioCheckBox htmlFor={`${item.for}`}>
                <FormattedMessage id={item.label} />
              </LabelRadioCheckBox>
            </ContainerRadioButton>
          )
        })}
      </RadioButtons>
    </div>
  )
}

RadioGroup.propTypes = {
  objectString: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    placeholder: PropTypes.array.isRequired,
    dataTest: PropTypes.string.isRequired,
  }).isRequired,
  sendValue: func.isRequired,
  radioGroupName: string,
  value: PropTypes.bool,
}
