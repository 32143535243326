import React from 'react'

import { Container } from './styles'
import { SignUpCard } from './SignUpCard'
import { useHistory } from 'react-router-dom'

export const SignUp = () => {
  const history = useHistory()

  function goToLogin() {
    history.push('/')
  }

  return (
    <Container data-test="SignUpContainer">
      <SignUpCard closeSignUpCard={goToLogin} />
    </Container>
  )
}
