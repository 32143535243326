import { cards } from '../../decks'
import { checkIsCatergoryAffected } from '../../../config'
import { playerHasCondition } from '../../../helper'

export function injectContextOnCard(
  { character, gameStage, gameStagesNames, conditions },
  card,
  isSkillsOrQuiz,
) {
  if (!character) {
    throw new Error('Character is null')
  }

  const playerEmployed = playerHasCondition({ conditions: conditions }, 'employed')
  const cardAux = { ...card }

  cardAux.question = `${cardAux.question}`.split('<name>').join(character.name)
  cardAux.question = `${cardAux.question}`
    .split('<stage>')
    .join(gameStagesNames[gameStage])

  cardAux.options.forEach((option, index) => {
    cardAux.options[index].feedback = option.feedback.split('<name>').join(character.name)
    cardAux.options[index].feedback = option.feedback.split('<point>').join(option.point)
    cardAux.options[index].feedback = option.feedback
      .split('<stage>')
      .join(gameStagesNames[gameStage])

    const { point } = cardAux.options[index]
    if (point > 0 && playerEmployed && isSkillsOrQuiz) {
      cardAux.options[index].point = point / 2
    }
  })

  return cardAux
}

export function deliverCard(G, category, test = false) {
  const deck = retrieveDeckByCalculatedLevel(G, cards)

  if (deck.length === 0) {
    throw new Error('Deck retrieved does not exist')
  }
  let filteredDeck = deck[category]?.filter((item) => !G.discardPile.includes(item.key))
  if (filteredDeck.length === 0) {
    filteredDeck = reshuffleCards(G, category, deck)
  }

  if (category === 'challenge' && playerHasCondition(G, 'employed')) {
    filteredDeck = filteredDeck.filter((item) => !item.job_entry)
    if (filteredDeck.length === 0) {
      filteredDeck = reshuffleCards(G, category, deck).filter((item) => !item.job_entry)
    }
  }

  const sortedCard = filteredDeck?.sort(() => 0.5 - Math.random())[0]

  const isCatergoryAffected = checkIsCatergoryAffected(category)

  if (test) {
    return injectContextOnCard(G, sortedCard, isCatergoryAffected)
  }

  G.currentCard = { ...injectContextOnCard(G, sortedCard, isCatergoryAffected), category }
  G.discardPile = [...G.discardPile, G.currentCard.key]
}

export function retrieveDeckByCalculatedLevel({ gameStage }, myCards = cards) {
  const { junior, middle, superior } = myCards
  return [junior, middle, superior][gameStage] || []
}

export function valueBetweenInMinMaxValues(progress) {
  return (levelOfProgress) =>
    (progress >= levelOfProgress.min) & (progress <= levelOfProgress.max)
}

function reshuffleCards(G, category, deck) {
  const keysToUndoDiscard = deck[category]?.map((item) => item.key)
  G.discardPile = G.discardPile.filter((key) => !keysToUndoDiscard.includes(key))

  return deck[category]?.filter((item) => !G.discardPile.includes(item.key))
}
