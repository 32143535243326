export const theme = {
  colors: {
    white: '#fff',
    whiteOff: '#e9e9e9',
    black: '#1d1d1b',
    forestGreen: '#00763d',
    EmeraldGreen: '#1db46c',
    lemonGreen: '#8fc440',
    pistachioGreen: '#d0df5d',
    reddishOrange: '#f16022',
    ligthOrange: '#ffe3d8',
    veryLightOrange: '#ffdacb',
    orange: '#fd8204',
    orangeButtonFocused: '#de7000',
    amber: '#fdb614',
    lightGrey: '#b1b1b1',
    veryLightGrey: '#d8d8d8',
    grey: '#62636c',
    wine: '#83002c',
    turquoise: '#00a0af',
    azure: '#009ee2',
    yellow: '#fcef58',
    greenWhastapp: '#3fb223',
    veryLightBlue: '#a2ffff',
    veryLightPink: '#d3d3d3',
    pinkishGrey: '#b6b3b3',
    blueyGrey: '#9b9b9c',
    steel: '#82838e',
    brownGrey: '#a8a8a8',
    blueFacebook: '#1877f2',
    darkBlue: '#0062b5',
    lightBlue: '#4998ff',
    selectedBlue: '#0259c9',
    borderSelectedBlue: '#003e73',
    stages: {
      junior: '#fd8204',
      middle: '#009ee2',
      superior: '#1db46c',
    },
  },

  linearGradients: {
    orange: `linear-gradient(
        to bottom,
        rgba(253, 151, 49, 1) 0%,
        rgba(253, 151, 49, 1) 50%,
        rgba(253, 128, 4, 1) 51%,
        rgba(253, 128, 4, 1) 71%,
        rgba(253, 128, 4, 1) 100%)`,
  },
}
