import styled from 'styled-components'

export const CheckBox = styled.input`
  width: 1.5em;
  height: 1.5em;
  background-color: white;
  margin: 5px 7px;
  border-radius: 8px;
  vertical-align: middle;
  border: 4px solid;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.black};
  }
`

export const LabelCheckBox = styled.label`
  height: 19px;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  top: 2px;

  b {
    font-weight: bolder;
    color: #1db46c;
    cursor: pointer;
  }
`
