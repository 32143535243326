import gameChallenge from './game-challenge/'
import gameInspiration from './game-inspiration/'
import gameJob from './game-job/'
import gameKnowledge from './game-knowledge/'
import gameSkills from './game-skills/'

export default {
  'game.dice.role': 'Tira los dados para jugar',
  'game.shared': 'Comparte el juego',
  'game.shared.description':
    '¿Te gusta el juego VUCA? Comparte con tus amigos a través del enlace:',
  'game.leaveJob': 'Salir del trabajo',
  'game.leaveJob.description':
    'Solo podrá volver al trabajo cuando tenga una nueva oportunidad en la casa de situación.',
  'game.leaveJob.question': '¿Está seguro de que quiere dejar su trabajo ahora?',
  'game.leaveJob.info':
    'A partir de ahora puedes dejar de trabajar, centrarte en tus estudios y pensar en tu futuro.',
  'game.nowWorthHalfThePoints': 'ahora valen la mitad de los puntos',
  'game.startJob.init': 'Comenzó a trabajar',
  'game.startJob':
    '{name} ingresó al mercado laboral y ahora deberá conciliar su vida profesional con sus estudios.',
  'game.startJob.tooltip': '¡{name} está funcionando!',
  'game.theTilesOf': 'Las casas de',
  'game.question.empty': ' ',
  'game.success.nextStage.middle': '¡Fuiste a la escuela secundaria!',
  'game.success.nextStage.middle.message':
    '{name} completó la escuela primaria y dio su primer paso para llegar a Portal Z. ¿Está listo para continuar este viaje?',
  'game.success.nextStage.superior': '¡Has pasado a la educación superior!',
  'game.success.nextStage.superior.message':
    'La escuela secundaria es un viaje difícil lleno de altibajos y ¡{name} ha llegado tan lejos! ¡Felicidades! ¡Solo un poco más y llegarás a la meta final!',
  'game.success.nextStage.superior.finish': '¡Has terminado la educación superior!',
  'game.success.nextStage.superior.finish.message':
    '¡{name} ha completado la última etapa de tu viaje de entrenamiento! ¡Felicitaciones, ustedes fueron fundamentales en este logro y juntos llegaron aquí!',
  'game.start.newgame': '¿Empezamos un nuevo juego?',
  'game.toEnd': 'Fin',
  'game.toThank': 'Agradecemos su participación.',
  'game.playerWin': '¡{name} ha llegado al portal Z!',
  'game.playerWin.description':
    'El portal Z abrirá las puertas a grandes y nuevas oportunidades. Puedes seguir sin miedo, después del Portal Z, hay otros portales con infinitas posibilidades, tu viaje apenas comienza. ¡Disfrutar!',
  'game.player.LoserFist': '{name} no completó <br /> {maxPoints} a tiempo.',
  'game.player.LoserSecond': 'Pero no se desanime, puede intentarlo de nuevo.',
  'game.player.LoserThird': '¿Y ahí? aceptas el reto?',
  'game.player.again': 'Juega de nuevo',
  ...gameChallenge,
  ...gameInspiration,
  ...gameJob,
  ...gameKnowledge,
  ...gameSkills,
}
