import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ExpTagContainer, Tooltip } from '../../ProgressBar/styles'

import { JobButtonContainer, BandJob, LatchLeaveJob } from './styles'
import { IcJob, IcX, IcXCircle } from '../../../assets/img'

import { gamePropTypes } from '../../../game'
import { injectIntl, FormattedMessage } from 'react-intl'

export const BandLeaveJobCp = ({ G, onJobButtonClick, showLeaveJobButton, intl }) => {
  const [showTooltipEnterJob, setShowTooltipEnterJob] = useState(true)
  const [showTooltipLeaveJob, setShowTooltipLeaveJob] = useState(false)

  const tooltipEnterJob = {
    widthSize: 310,
    heightSize: 225,
    afterTop: -232,
    afterLeft: 1,
    afterTopMobile: -212,
    afterLeftMobile: 255,
    marginTop: 115,
    marginLeft: 1,
    marginTopMobile: 130,
    marginLeftMobile: -310,
  }

  const tooltipLeaveJob = {
    widthSize: 310,
    heightSize: 145,
    afterTop: -153,
    afterLeft: 1,
    afterTopMobile: -153,
    afterLeftMobile: 255,
    marginTop: 110,
    marginLeft: 10,
    marginTopMobile: 140,
    marginLeftMobile: -310,
  }

  useEffect(() => {
    setTimeout(function () {
      setShowTooltipEnterJob(false)
    }, 10000)
  }, [])

  useEffect(() => {
    if (showLeaveJobButton) {
      setShowTooltipLeaveJob(true)

      setTimeout(function () {
        setShowTooltipLeaveJob(false)
      }, 10000)
    }
  }, [showLeaveJobButton])

  return (
    <div>
      <JobButtonContainer showLeaveJobButton={showLeaveJobButton}>
        <BandJob>
          <IcJob />
        </BandJob>
        {showLeaveJobButton && (
          <LatchLeaveJob onClick={onJobButtonClick} data-test="leaveJobButton">
            <b>
              <FormattedMessage id="game.leaveJob" />
            </b>
            <IcXCircle />
          </LatchLeaveJob>
        )}
      </JobButtonContainer>

      {showTooltipEnterJob && (
        <>
          <Tooltip styles={tooltipEnterJob} withCloseButton>
            <ExpTagContainer>
              <b
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    { id: 'game.startJob.tooltip' },
                    { name: G.character.name },
                  ),
                }}
              />
              <IcX
                onClick={() => setShowTooltipEnterJob(false)}
                data-test="closeEnterJobInfo"
              />
            </ExpTagContainer>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: 'game.startJob' },
                  { name: G.character.name },
                ),
              }}
            />
            <p>
              <FormattedMessage id="game.theTilesOf" />
              <b>
                {` ${intl.formatMessage({
                  id: 'game.skills',
                })} ${intl.formatMessage({
                  id: 'and',
                })} ${intl.formatMessage({
                  id: 'game.knowledge',
                })} `}
                <FormattedMessage id="game.nowWorthHalfThePoints" />
              </b>
            </p>
          </Tooltip>
        </>
      )}

      {showTooltipLeaveJob && (
        <>
          <Tooltip styles={tooltipLeaveJob} withCloseButton>
            <ExpTagContainer>
              <b
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: 'game.leaveJob' }),
                }}
              />
              <IcX
                onClick={() => setShowTooltipLeaveJob(false)}
                data-test="closeLeaveJobInfo"
              />
            </ExpTagContainer>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'game.leaveJob.info' }),
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  )
}

BandLeaveJobCp.propTypes = {
  G: gamePropTypes.G,
  onJobButtonClick: PropTypes.func.isRequired,
  showLeaveJobButton: PropTypes.bool.isRequired,
  intl: PropTypes.object,
}

export const BandLeaveJob = injectIntl(BandLeaveJobCp)
