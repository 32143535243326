import styled from 'styled-components'

import backgroundCloudsDesktop from '../../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Container = styled.div`
  background-image: url(${backgroundCloudsMobile});
  object-fit: contain;
  height: 100vh;
  width: 100%;
  padding: 1rem;

  position: absolute;

  svg {
    font-family: MilkyNice;
  }

  @media screen and (min-width: 1000px) {
    background-image: url(${backgroundCloudsDesktop});
  }

  background-size: 100%;
  height: 100vh;
`
