export default {
  'characters.choose': 'Escolha um personagem',
  'characters.cris.description':
    'Tenho 15 anos gosto de realizar atividades animadas. Não me identifico com nenhum gênero e enfrento alguns problemas por isso. Me engajo em causas sociais pois acredito em um mundo melhor.',
  'characters.milena.description':
    'Tenho 15 anos e sou do tipo empreendedora. Escrevo sobre minha vida e amo postar tudo. Não gosto de acordar cedo, mas moro longe da escola. Sonho em trabalhar no meu próprio negócio, por que não?',
  'characters.brenda.description':
    'Sou criativa e muito curiosa. Gosto de arte e cultura, mas não sei muito bem o que quero fazer do futuro. Afinal, o mundo anda tão difícil...',
  'characters.jonathan.description':
    'Tenho 16 anos, sou tímido, mas muito organizado. As pessoas dizem que eu não gosto de mudanças e é verdade. Sonho em fazer uma boa faculdade e ter um emprego estável.',
}
