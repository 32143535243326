import React from 'react'

import { Container } from './styles'
import { CharactersCard } from './CharactersCard'

import { Menu } from '../../components'

export const ChoiceCharacter = () => {
  return (
    <>
      <Container data-test="ChoiceCharacterContainer">
        <Menu openRules />
        <CharactersCard />
      </Container>
    </>
  )
}
