export default {
  'game.inspiration.middle.questions.1':
    '<b>Bisman Deu</b><br/>Entrepreneur and former JA alumna',
  'game.inspiration.middle.questions.1.option.1':
    '‘A fundamental advice I could give young people is to create <b>opportunities</b> for themselves. We usually <b>wait</b> for opportunities to come knocking on our door, but that does not <b>really happen<b/>. I firmly believe in trying to create as many opportunities as possible.’',

  'game.inspiration.middle.questions.2':
    '<b>Alfred Bright</b><br/>Artist, entrepreneur and former JA alumnus',
  'game.inspiration.middle.questions.2.option.1':
    '‘First of all, my message is still the same: <b>maximize your potential</b>. Explore within yourself and find what you would like to do, even if you are not paid. Follow your <b> passion</b>, find yourself, and maximize your potential. Keep a positive attitude towards yourself. That is what I did. I kept a <b>positive attitude</b> and never stopped being myself.’',

  'game.inspiration.middle.questions.3':
    '<b>Kim Kaupe</b><br/>Entrepreneur and former JA alumna',
  'game.inspiration.middle.questions.3.option.1':
    '‘It does not matter whether you are an independent entrepreneur or you are working for a company: <b>it is always sensible to ask for help</b>. You will be surprised to know how many people wants you to get good results and how many of them are ready to <b>give you a hand</b>. Therefore, <b>do not</b> be <b>afraid</b> to ask for help.’',

  'game.inspiration.middle.questions.4':
    '<b>Pedro Englert</b><br/>Entrepreneur and former JA alumnus',
  'game.inspiration.middle.questions.4.option.1':
    '‘My first advice for young people is <b>do something you like</b>, because when you <b>put</b> time and energy in that, you will be successful. Do not be <b>afraid to fail</b>, but do not insist on your mistake and avoid making it again.’',

  'game.inspiration.middle.questions.5':
    '<b>Jerome Cowans</b><br/>Entrepreneur and former JA alumnus',
  'game.inspiration.middle.questions.5.option.1':
    '‘I would also like to encourage young people to <b>think about</b> <b>middle to long term</b> perspective in their professional careers. They may be starting a professional career that could be obsolete in 5 to 10 years, so they should do something that <b>they love to do</b>, but that it is also prepared to change in the <b>future</b>.',

  'game.inspiration.middle.questions.6': '<b>Amanda Cornélio</b><br/>Former JA alumna',
  'game.inspiration.middle.questions.6.option.1':
    '‘Do not limit your <b>dreams</b>: the more effort you put in them, the closer you will be to making them come true. And <b>they can happen</b> when you least expect it.’',

  'game.inspiration.middle.questions.7':
    '<b>Jim Hemak</b><br/>Entrepreneur and former JA alumnus',
  'game.inspiration.middle.questions.7.option.1':
    '‘Take advantage of the <b>opportunities</b> of getting involved in organizations, whether it is at <b>school</b> or in <b>life</b>. This can give you great <b>learning</b> for your life.’',

  'game.inspiration.middle.questions.8':
    '<b>Daneil Cheung</b><br/>Empreendedor social e ativista',
  'game.inspiration.middle.questions.8.option.1':
    '“Expanda a sua rede não apenas para receber conselhos e ajuda, mas também para se disponibilizar a dar conselhos e ajudar os outros.”',

  'game.inspiration.middle.questions.9':
    '<b>Nyi Mas Gianti Bingah Erbiana</b><br/>Chief of Legal Advice in Mitsubishi Indonesia',
  'game.inspiration.middle.questions.9.option.1':
    '‘Try to be flexible. I am not suggesting following others or not having your own identity. Young people must be able to adapt to changes of the world and reaching consensus with others.’',

  'game.inspiration.middle.questions.10': '<b> JIM HEMAK</b><br/>Former JA USA alumnus',
  'game.inspiration.middle.questions.10.option.1':
    '‘Take every opportunity of getting involved with several organizations while you are a student throughout your life. These experiences can mean a lot of learning for every kind of professional career or business that you may have.’',
}
