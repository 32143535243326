import styled from 'styled-components'

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1.42rem;

  p {
    font-family: Montserrat;
    font-size: 0.875rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2.14rem;
  }
`

export const ContainerButton = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
`
