import styled from 'styled-components'

export const Container = styled.div`
  width: 75px;
  height: 35px;
  border-radius: 19px;
  border: solid 3px ${({ theme }) => theme.colors.black};
  font-family: MilkyNice;
  font-size: 16px;
  letter-spacing: -0.07px;
  text-align: center;
  display: grid;
  place-items: center;
`
