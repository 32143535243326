import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '../../../components'
import { BackgroundBlur, Container } from '../../../components/GameCards/styles'
import { IcCopy } from '../../../assets/img'
import {
  ContentCard,
  ContainerButton,
  LinkIconContainer,
  VucaLink,
  LinkCopied,
} from './styles'

import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { reportCopiedTheShareLink } from '../../../services/analytics'
import { injectIntl, FormattedMessage } from 'react-intl'

export const ShareCardCp = ({ back, intl }) => {
  const [showCopied, setShowCopied] = useState(false)

  function copyLink() {
    reportCopiedTheShareLink()
    navigator.clipboard.writeText(process.env.REACT_APP_GAME_VUCA)
    showCopiedMessage()
  }

  function showCopiedMessage() {
    setShowCopied(true)
    const fourSecounds = 4000
    setInterval(function () {
      setShowCopied(false)
    }, fourSecounds)
  }

  return (
    <>
      <Container>
        <CardWithHeader
          titleCard={intl.formatMessage({ id: 'game.shared' })}
          backgroundColorHeader="white"
          isSmall
          data_test="shareCard">
          <ContentCard>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'game.shared.description' }),
              }}
            />
            <LinkIconContainer>
              <VucaLink>{process.env.REACT_APP_GAME_VUCA}</VucaLink>
              <IcCopy onClick={copyLink} />
            </LinkIconContainer>
            {showCopied && (
              <LinkCopied>
                <FormattedMessage id="copied" />
              </LinkCopied>
            )}
            <ContainerButton>
              <ButtonWithRadiusBorderComponent
                color="white"
                onClick={copyLink}
                data_test="copyLinkButton"
                text={intl.formatMessage({ id: 'copy' })}
              />

              <ButtonWithRadiusBorderComponent
                data_test="backButton"
                onClick={back}
                text={intl.formatMessage({ id: 'return' })}
              />
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur />
    </>
  )
}

ShareCardCp.propTypes = {
  back: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const ShareCard = injectIntl(ShareCardCp)
