export default {
  'characters.choose': 'Select a character',
  'characters.cris.description':
    "I am 15 years old I like to do lively activities. I don't identify with any gender and I face some problems for that. I engage in social causes because I believe in a better world.",
  'characters.milena.description':
    "I'm 15 years old and I'm an entrepreneurial type. I write about my life and I love to post everything. I don't like to wake up early, but I live far from school. I dream of working in my own business, why not?",
  'characters.brenda.description':
    "I am creative and very curious. I like art and culture, but I'm not sure what I want to do with the future. After all, the world is so difficult ...",
  'characters.jonathan.description':
    "I am 16 years old, I am shy, but very organized. People say that I don't like change and it's true. I dream of going to a good college and having a stable job.",
}
