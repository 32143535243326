import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.skills': 'Habilidades',
  'game.skills.message':
    'Você está em um casa de Habilidades, aqui você vai receber desafios que ajudarão a desenvolver habilidades nos personagens ou não!',

  ...junior,
  ...middle,
  ...superior,
}
