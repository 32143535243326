export default {
  'game.knowledge.junior.questions.0':
    'Cuando Cristobal Colón salió de España en 1492, ¿qué esperaba encontrar?​',
  'game.knowledge.junior.questions.0.option.1': 'India',
  'game.knowledge.junior.questions.0.option.1.feedback':
    '¡Muy bien! Colón descubrió América en 1492, pero estaba en busca del camino hacia India, la tierra del oro y las especias.',
  'game.knowledge.junior.questions.0.option.2': 'América',
  'game.knowledge.junior.questions.0.option.2.feedback':
    '¡Ups, respuesta incorrecta! Colón descubrió América en 1492, pero estaba en busca del camino hacia India, la tierra del oro y las especias.',

  'game.knowledge.junior.questions.1': '¿Qué hizo la princesa Isabel?​',
  'game.knowledge.junior.questions.1.option.1': 'Decretó el fin de la esclavitud.',
  'game.knowledge.junior.questions.1.option.1.feedback':
    '¡Respuesta Correcta! La princesa Isabel firmó la Ley Aurea en 1888.',
  'game.knowledge.junior.questions.1.option.2': 'Declaró la guerra a Portugal.',
  'game.knowledge.junior.questions.1.option.2.feedback':
    '¡Ups! Respuesta Incorrecta. La princesa Isabel decretpo el fin de la esclavitud en 1888.​',

  'game.knowledge.junior.questions.2': '¿Cuál es el antónimo de artificial?​​',
  'game.knowledge.junior.questions.2.option.1': 'Natural',
  'game.knowledge.junior.questions.2.option.1.feedback':
    '¡Respuesta Correcta! ¡Felicitaciones! Saber usar las palabras de forma eficiente es fundamental para comunicarse correctamente.',
  'game.knowledge.junior.questions.2.option.2': 'Simple',
  'game.knowledge.junior.questions.2.option.2.feedback':
    '¡Ups! Equivocado. El antónimo (lo contrario) de artificial es natural. Aprender a usar las palabras de manera eficiente es fundamental para comunicarse correctamente.',

  'game.knowledge.junior.questions.3':
    '¿Cuáles son los elementos químicos más presentes en el cuerpo humano?',
  'game.knowledge.junior.questions.3.option.1': 'Oxígeno, carbono e hidrógeno.',
  'game.knowledge.junior.questions.3.option.1.feedback':
    '¡Felicitaciones! Es importante entender cómo funciona nuestro organismo.',
  'game.knowledge.junior.questions.3.option.2': 'Oxígeno, carbono y sodio.',
  'game.knowledge.junior.questions.3.option.2.feedback':
    '¡Equivocado! Las personas también tienen sodio en el cuerpo, pero en proporciones mucho menores, la respuesta correcta es hidrógeno.',

  'game.knowledge.junior.questions.4':
    'El bosque es para el árbol, lo que el árbol es para...',
  'game.knowledge.junior.questions.4.option.1': 'si mismo.',
  'game.knowledge.junior.questions.4.option.1.feedback':
    'Esta es una cuestión de lógica. El bosque es para el árbol, lo que el árbol es para la hoja.',
  'game.knowledge.junior.questions.4.option.2': 'la hoja.',
  'game.knowledge.junior.questions.4.option.2.feedback':
    'Felicitaciones, ¡Ha usado la lógica para resolver esta pregunta!',

  'game.knowledge.junior.questions.5': 'Al analizar un sitio de internet, tu debes:',
  'game.knowledge.junior.questions.5.option.1':
    'Confíe en cualquier sitio, siempre que aparezca en Google.',
  'game.knowledge.junior.questions.5.option.1.feedback':
    '¡Ten cuidado! El hecho de que un texto aparece en Google no significa que está correcto o presenta una versión imparcial sobre el asunto en cuestión. Es importante analizar de manera crítica tanto el contenido como la fuente de la información',
  'game.knowledge.junior.questions.5.option.2':
    'Analizar de manera crítica tanto el contenido como la fuente de la información.',
  'game.knowledge.junior.questions.5.option.2.feedback':
    '¡Felicitaciones! Tu sabes que cualquier persona puede publicar en la internet, es importante verificar tanto la fuente de la información como analizar el contenido de manera crítica.',

  'game.knowledge.junior.questions.6': '¿Cuál es la capital de Ceará?',
  'game.knowledge.junior.questions.6.option.1': 'Recife',
  'game.knowledge.junior.questions.6.option.1.feedback':
    '¡No es correcta esta vez! La capital de Ceará es Fortaleza',
  'game.knowledge.junior.questions.6.option.2': 'Fortaleza',
  'game.knowledge.junior.questions.6.option.2.feedback':
    '¡Esa sí! La capital de Ceará es Fortaleza',

  'game.knowledge.junior.questions.7':
    '¿Cuál es el valore de resultado de la siguiente ecuación <br/> 8x + 16 = 24?',
  'game.knowledge.junior.questions.7.option.1': 'x=3',
  'game.knowledge.junior.questions.7.option.1.feedback':
    '¡Respuesta equivocada! La X en esta ecuación es igual a 1.',
  'game.knowledge.junior.questions.7.option.2': 'x=1',
  'game.knowledge.junior.questions.7.option.2.feedback': '¡Respuesta correcta!',
}
