import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { gamePropTypes } from '../../../../game'
import { config } from '../../../../game/config'
import { Board } from '../../../../assets/img'
import { Svg } from '../../styles'
import { Pawn } from '../../../Pawn'
import { Dot } from '../Dot/style'

export const GameBoard = ({ G, onPawnStepAnimationEnd }) => {
  const [boardViewBox, setBoardViewBox] = useState()
  const { showCentroidDots } = config.board
  useEffect(getDataTiles, [])
  function getDataTiles() {
    const $tiles = document.querySelectorAll('polygon[data-type=tile]')
    const $boardSvg = document.querySelector('svg[data-type=board]')
    if ($boardSvg) {
      const boardView = $boardSvg.viewBox.baseVal
      setBoardViewBox(
        `${boardView.x} ${boardView.y} ${boardView.width} ${boardView.height}`,
      )
    }
    getTilesCentroids($tiles)
  }

  function getTilesCentroids(tiles) {
    tiles.forEach((tile) => {
      const { x, y, width, height } = tile.getBBox()
      const { index } = tile.dataset
      const tileObj = {
        index,
        x,
        y,
        centroid: [x + width / 2, y + height / 2],
      }
      G.boardTiles.push(tileObj)
    })
  }

  return (
    <Svg data-test="GameBoard">
      <Board width="100%" />
      {boardViewBox && (
        <Svg viewBox={boardViewBox} xmlns="http://www.w3.org/2000/svg">
          <g id={'pawn'}>
            <Pawn
              G={G}
              animateToTile={G.pawnTilePosition}
              onAnimationStepEnd={onPawnStepAnimationEnd}
            />
          </g>

          {G.boardTiles.length > 0 &&
            showCentroidDots &&
            G.boardTiles.map((t, key) => {
              return (
                <Svg key={key}>
                  <g>
                    <Dot r={6} cx={t.centroid[0]} cy={t.centroid[1]} />
                  </g>
                </Svg>
              )
            })}
        </Svg>
      )}
    </Svg>
  )
}

const { G } = gamePropTypes
GameBoard.propTypes = {
  G,
  onPawnStepAnimationEnd: PropTypes.func.isRequired,
}
