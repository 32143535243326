export default {
  'game.knowledge.junior.questions.0':
    'Quando Cristóvão Colombo saiu da Espanha, em 1492,  o que ele esperava encontrar?​',
  'game.knowledge.junior.questions.0.option.1': 'Índia',
  'game.knowledge.junior.questions.0.option.1.feedback':
    'Boa! Colombo descobriu a América em 1492 , mas estava na verdade em busca do caminho para a Índia, a terra do ouro e das especiarias.',
  'game.knowledge.junior.questions.0.option.2': 'América',
  'game.knowledge.junior.questions.0.option.2.feedback':
    'Opa, resposta errada! Colombo descobriu a América em 1492 , mas estava na verdade em busca do caminho das Índias, a terra do ouro e das especiarias.',

  'game.knowledge.junior.questions.1': 'O que fez a princesa Isabel? ​',
  'game.knowledge.junior.questions.1.option.1': 'Decretou o fim da escravidão.',
  'game.knowledge.junior.questions.1.option.1.feedback':
    'Resposta certa! ​A princesa Isabel assinou a Lei Áurea em 1888.',
  'game.knowledge.junior.questions.1.option.2': 'Declarou guerra a Portugal.',
  'game.knowledge.junior.questions.1.option.2.feedback':
    'Ops! Resposta errada. ​A princesa Isabel decretou o fim da escravidão em 1888.​',

  'game.knowledge.junior.questions.2': 'Qual o antônimo de artificial?​​',
  'game.knowledge.junior.questions.2.option.1': 'Natural',
  'game.knowledge.junior.questions.2.option.1.feedback':
    'Resposta certa! Parabéns! Saber usar as palavras de forma eficiente é fundamental para se comunicar bem!',
  'game.knowledge.junior.questions.2.option.2': 'Simples',
  'game.knowledge.junior.questions.2.option.2.feedback':
    'Ops! não foi dessa vez! O antônimo (ou contrário) de Artificial é Natural. Aprender a usar as palavras de forma eficiente é fundamental para se comunicar bem.​',

  'game.knowledge.junior.questions.3':
    'Quais os elementos químicos mais presentes no corpo humano?',
  'game.knowledge.junior.questions.3.option.1': 'Oxigênio, carbono e hidrogênio',
  'game.knowledge.junior.questions.3.option.1.feedback':
    'Parabéns! Importante entender como nosso organismo funciona!',
  'game.knowledge.junior.questions.3.option.2': 'Oxigênio, carbono e sódio',
  'game.knowledge.junior.questions.3.option.2.feedback':
    'Não foi desta vez! Nós também temos sódio no corpo, mas em proporções muito menores, o correto é hidrogênio.',

  'game.knowledge.junior.questions.4':
    'A floresta está para a árvore assim como a árvore está para...',
  'game.knowledge.junior.questions.4.option.1': 'Solo.',
  'game.knowledge.junior.questions.4.option.1.feedback':
    'Esta é uma questão de lógica. A floresta está para a árvore assim como a árvore está para a folha.',
  'game.knowledge.junior.questions.4.option.2': 'Folha.',
  'game.knowledge.junior.questions.4.option.2.feedback':
    'Parabéns, você usou a lógica para resolver a questão!',

  'game.knowledge.junior.questions.5': 'Ao analisar um site na Internet você deve:',
  'game.knowledge.junior.questions.5.option.1':
    'Confiar em qualquer site, desde que apareça no Google.',
  'game.knowledge.junior.questions.5.option.1.feedback':
    'Opa! Só porque o texto aparece no Google não significa que está correto ou apresenta uma visão imparcial sobre o assunto. É importante analisar criticamente o conteúdo e a fonte!',
  'game.knowledge.junior.questions.5.option.2':
    'Analisar criticamente o conteúdo e a fonte.',
  'game.knowledge.junior.questions.5.option.2.feedback':
    'Parabéns! Você sabe que qualquer pessoa pode publicar na Internet, e é importante verificar a fonte e analisar o conteúdo criticamente.',

  'game.knowledge.junior.questions.6': 'Qual a capital do Ceará? ',
  'game.knowledge.junior.questions.6.option.1': 'Recife',
  'game.knowledge.junior.questions.6.option.1.feedback':
    'Não foi desta vez! A capital do Ceará é Fortaleza. ',
  'game.knowledge.junior.questions.6.option.2': 'Fortaleza',
  'game.knowledge.junior.questions.6.option.2.feedback':
    'Isso aí! A capital do Ceará é Fortaleza.',

  'game.knowledge.junior.questions.7': 'Qual valor de x na equação <br/> 8x + 16 = 24?',
  'game.knowledge.junior.questions.7.option.1': 'x=3',
  'game.knowledge.junior.questions.7.option.1.feedback':
    'Resposta errada! O x nesta equação é igual a 1.',
  'game.knowledge.junior.questions.7.option.2': 'x=1',
  'game.knowledge.junior.questions.7.option.2.feedback': 'Resposta correta!',
}
