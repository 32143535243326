import React, { useEffect, useState } from 'react'
import PropTypes, { array, bool, func, string } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Tooltip } from '../Tooltip'
import {
  Label,
  InputText,
  ArrowSelect,
  SelectContainer,
  Option,
  Container,
} from './styles'

export const SelectCp = ({
  objectString,
  sendValue,
  original,
  withAutocomplete,
  inputName,
  value,
  id,
  isRequired,
  showRequired,
  dataValue,
  clearSelect,
  valueNoTranslate,
  intl,
}) => {
  const [showRequiredBorder, setShowRequiredBorder] = useState(false)
  const [elementId, setElementId] = useState('')
  const [selectingOption, setSelectingOption] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [options, setOptions] = useState(objectString.options)
  const [lastState, setLastState] = useState('')
  const [isSetedState, setIsSetedState] = useState(false)

  function buildTargetObject({ valueTarget, idTarget }) {
    return {
      target: {
        value: valueTarget,
        name: inputName,
        id:
          idTarget ||
          options.find((item) => (item.label || item.nome || item) === valueTarget)?.id,
      },
    }
  }

  useEffect(setFakeTargetForValueUpdate, [value])
  function setFakeTargetForValueUpdate() {
    if (value && !!options.length && !selectedOption) {
      const fakeTarget = buildTargetObject({ valueTarget: value })
      setSelected(fakeTarget)
    }
  }

  useEffect(setFakeTargetForIdUpdate, [id])
  function setFakeTargetForIdUpdate() {
    if (id && !!options.length && !selectedOption) {
      const option = options.find((item) => item.id === id)
      if (option) {
        const fakeTarget = buildTargetObject({
          valueTarget: intl.formatMessage({ id: option.label }),
          idTarget: option.id,
        })
        setSelected(fakeTarget)
      }
    }
  }

  useEffect(setCurrentOptions, [original])
  function setCurrentOptions() {
    setOptions(original)
  }

  useEffect(clearCityValue, [dataValue])
  function clearCityValue() {
    if (inputName === 'city') {
      const hasState = dataValue && dataValue.state
      if (!isSetedState && hasState) {
        setLastState(dataValue.state)
        setIsSetedState(true)
      }

      const hasChangedState = isSetedState && hasState && dataValue.state !== lastState

      if (hasChangedState) {
        setLastState(dataValue.state)
        setSelectedOption('')
        clearSelect('city')
      }
    }

    if (!valueNoTranslate && dataValue && options.length > 0) {
      const selected = options.filter((opt) => opt.id === dataValue[inputName])[0]
      if (selected) {
        setSelectedOption(
          intl.formatMessage({ id: selected.label }, { val: selected.val }),
        )
      }
    }
  }

  function selectOption() {
    setOptions(objectString.options)
    setElementId('')
    setSelectingOption(!selectingOption)
  }

  function setSelected(event) {
    setSelectingOption(false)
    setElementId(event.target.id)
    setSelectedOption(event.target.value)
    sendValue(event)
  }

  function isntElementOnOptions() {
    return !options.some(
      (item) => (item.label || item.nome || item) === selectedOption && elementId,
    )
  }

  function onBlurSelect(event) {
    if (isntElementOnOptions()) {
      setSelectedOption('')
      sendValue(buildTargetObject(''))
    }

    setTimeout(() => {
      setSelectingOption(false)
    }, 400)
  }

  function findElement(event) {
    const { value } = event.target

    setSelectedOption(value)
    const fileList = original.filter((item) => {
      return returnIndexOf(item.nome || item, value)
    })

    setOptions(fileList)
  }

  function returnIndexOf(element, value) {
    return element.toString().toLowerCase().indexOf(value.toString().toLowerCase()) === 0
  }

  useEffect(checkRequiredValue, [showRequired])
  function checkRequiredValue() {
    if (showRequired && !selectedOption) {
      setShowRequiredBorder(true)
    } else {
      setShowRequiredBorder(false)
    }
  }

  return (
    <Container onBlur={onBlurSelect}>
      {objectString.placeholder && (
        <Label>
          <FormattedMessage id={objectString.label} />
          {objectString.tooltip && <Tooltip tooltipMsg={objectString.tooltip} />}
        </Label>
      )}

      <InputText
        readOnly={!withAutocomplete}
        placeholder={intl.formatMessage({
          id: objectString.placeholder || objectString.label,
        })}
        onClick={selectOption}
        onChange={findElement}
        showRequiredBorder={showRequiredBorder}
        data-test={objectString.dataTest}
        maxLength={objectString?.maxLength?.toString()}
        value={selectedOption}
        required={isRequired}
        autoComplete="off"
      />
      <ArrowSelect />

      {selectingOption && (
        <SelectContainer data-test={objectString.selectDataTest}>
          {!!options?.length &&
            options.map((item, index) => {
              const itemValue = item.label || item.nome || item
              return (
                <Option
                  onClick={setSelected}
                  key={index}
                  data-index={index}
                  id={item.id}
                  name={inputName}
                  value={
                    valueNoTranslate
                      ? itemValue
                      : intl.formatMessage({ id: itemValue }, { val: item.val })
                  }
                />
              )
            })}
          {!options?.length && (
            <Option defaultValue={intl.formatMessage({ id: 'notResult' })} />
          )}
        </SelectContainer>
      )}
    </Container>
  )
}

SelectCp.propTypes = {
  objectString: PropTypes.shape({
    options: PropTypes.array,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    dataTest: PropTypes.string,
    maxLength: PropTypes.number,
    selectDataTest: PropTypes.string,
  }).isRequired,
  sendValue: func.isRequired,
  clearSelect: func,
  withAutocomplete: bool,
  inputName: string.isRequired,
  original: array,
  dataValue: PropTypes.object,
  isRequired: bool,
  showRequired: bool,
  value: string,
  id: string,
  valueNoTranslate: bool,
  intl: PropTypes.object,
}

SelectCp.defaultProps = {
  withAutocomplete: false,
  isRequired: false,
  showRequired: false,
  dataValue: null,
  valueNoTranslate: false,
  original: [],
  value: '',
  id: '',
}

export const Select = injectIntl(SelectCp)
