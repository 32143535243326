export default {
  'game.inspiration.junior.questions.1':
    '<b> Eleonora Arifova </b><br/> Emprendedora y ex alumna de JA',
  'game.inspiration.junior.questions.1.option.1':
    '"El <b>emprendedurismo</b> es aplicable a cualquier camino que uno escoja en la vida. La aptitud para identificar <b>oportunidades</b> e implementar ideas puede beneficiar a cualquier persona. Por lo tanto, <b>libera su talento</b> emprendedor y sigue sus sueños."',

  'game.inspiration.junior.questions.2':
    '<b>Bill Herp</b><br/> ex alumno de JA y fundador de Linear Air',
  'game.inspiration.junior.questions.2.option.1':
    '"Usted <b>debe estudiar</b>, definitivamente. No puedrá creer que va a ser un emprendedor o tener un buen empleo <b>sin preparación</b>. Y eso, no significa necesariamente cursar en la universidad; puede ser una instrucción <b>técnica</b> o una <b>especialización</b>. Lo importante es que le permita convertirse en un especialista en una o en múltiples áreas."',

  'game.inspiration.junior.questions.3':
    '<b>Donna Shalala</b><br/> ex alumna de JA y ejecutiva',
  'game.inspiration.junior.questions.3.option.1':
    '"Mi filosofía de educación es: los estudiantes no deben prepararse para su <b>primer empleo</b>, sino para su tercero, cuarto o quinto empleo. Necesitan tener la capacidad mental de asimilar <b>nuevas tecnologías</b> y <b>nuevas ideas</b>. Es por este motivo, que es muy importante que los jóvenes se vean a si mismos como eternos aprendices."',

  'game.inspiration.junior.questions.4':
    '<b>Adedayo Fashanu</b><br/>Ex alumna de JA y periodista',
  'game.inspiration.junior.questions.4.option.1':
    '“<b>Confianza</b> es la clave para el progreso, pero no basen su confianza únicamente en los resultados alcanzados porque, ¿qué pasaría cuando fallen en alguna cosa? <b>¿Sería motivo para perder la confianza en si mismos?</b> No, su confianza debe <b>tener raíces</b> en una "causa mayor" que el trabajo en el que están inmersos y en el proceso positivo de  <b>realizar</b> ese trabajo.”',

  'game.inspiration.junior.questions.5':
    '<b>Bonnie Chiu</b><br/>Ex alumna de JA Hong Kong',
  'game.inspiration.junior.questions.5.option.1':
    '"Comience por el “<b>¿por qué?</b>”. Esa es la pregunta más importante para ser realizada en la construccion de <b>sus vidas</b> y de <b>sus carreras:</b> ¿por qué estoy<b>haciendo</b> esto? ¿por qué me <b>involucro</b> con esto? ¿por qué esto es <b>importante</b> para mi?"',

  'game.inspiration.junior.questions.6':
    '<b>Raymon Setiadi</b><br/>Emprendedor y ex alumno de JA',
  'game.inspiration.junior.questions.6.option.1':
    '“Nunca pienses que <b>sabes todo</b> y nunca te sientas muy <b>cómodo</b> con tu situación. Porque cuando piensas de esa manera, no te das a ti mismo ninguna  <b>oportunidad de crecimiento.</b>”',

  'game.inspiration.junior.questions.7':
    '<b>Camilla ljunggren</b><br/>Emprendedora y ex alumna de JA',
  'game.inspiration.junior.questions.7.option.1':
    '”Aquello que <b>decidas hacer</b>, tienes que saber que va a <b>demorar</b>. Explicar como construí nuestra fábrica lleva 10 minutos y parece haber sido muy fácil. Pero no es fácil. <b>Lleva tiempo.</b> En mi caso, fueron 16 a 17 años de trabajo.”',

  'game.inspiration.junior.questions.8':
    '<b>Murillo Sabino</b><br/> Fundador del proyecto RUAS y ex alumno de JA Brasil',
  'game.inspiration.junior.questions.8.option.1':
    'No "busquen" su propósito. Cultiven un propósito. El será la unión entre lo que les despierta su curiosad, aquello que hace muy bien y lo que el mundo necesita para ser un lugar mejor.',

  'game.inspiration.junior.questions.9':
    '<b>Jerome Cowans</b><br/> Miembro de Jamaica House Fellowship',
  'game.inspiration.junior.questions.9.option.1':
    '"Lo que aprendí y considero crucial para todos los jóvenes fue: durante toda su vida será esencial comprender el lado práctico de lo que están haciendo, así como su lado teórico."',

  'game.inspiration.junior.questions.10':
    '<b> Leen Abu Baker</b><br/> Vice presidente del consejo de ex alumnos de INJAZ Al-Arab',
  'game.inspiration.junior.questions.10.option.1':
    '"Comiencen a buscar sus intereses aún jóvenes y no tengan miedo de compartirlos con la comunidad que los rodea."',
}
