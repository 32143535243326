export default {
  'game.knowledge.junior.questions.0':
    'What did Christopher Columbus expect to find when he set out from Spain in 1492?',
  'game.knowledge.junior.questions.0.option.1': 'India',
  'game.knowledge.junior.questions.0.option.1.feedback':
    'Well done! Columbus discovered America in 1492, but he was looking for a route to India, the land of gold and spices.',
  'game.knowledge.junior.questions.0.option.2': 'America',
  'game.knowledge.junior.questions.0.option.2.feedback':
    'Oops! That is incorrect. Columbus discovered America in 1492, but he was looking for a route to India, the land of gold and spices.',

  'game.knowledge.junior.questions.1': 'What did princess Isabel do? ​',
  'game.knowledge.junior.questions.1.option.1': 'She issued a decree to abolish slavery.',
  'game.knowledge.junior.questions.1.option.1.feedback':
    'Correct! Princess Isabel signed the Golden Law in 1888.',
  'game.knowledge.junior.questions.1.option.2': 'She declared war against Portugal.',
  'game.knowledge.junior.questions.1.option.2.feedback':
    'Oops! Wrong answer. Princess Isabel decreed the abolition of slavery in 1888.​',

  'game.knowledge.junior.questions.2': 'What is the opposite of artificial?',
  'game.knowledge.junior.questions.2.option.1': 'Natural',
  'game.knowledge.junior.questions.2.option.1.feedback':
    'Correct! Congratulations! Being able to use words efficiently is key for maintaining an adequate communication.',
  'game.knowledge.junior.questions.2.option.2': 'Simple',
  'game.knowledge.junior.questions.2.option.2.feedback':
    'Oops! That is not correct. The opposite (antonym) of ‘artificial’ is ‘natural’. Learning how to use words efficiently is key for maintaining an adequate communication.',

  'game.knowledge.junior.questions.3':
    'What are the most abundant chemical elements in the human body?',
  'game.knowledge.junior.questions.3.option.1': 'Oxygen, carbon and hydrogen.',
  'game.knowledge.junior.questions.3.option.1.feedback':
    'Congratulations! It is very important to know how our body works.',
  'game.knowledge.junior.questions.3.option.2': 'Oxygen, carbon and sodium.',
  'game.knowledge.junior.questions.3.option.2.feedback':
    'Wrong answer. The human body contains sodium, but in minor proportions. The right answer is hydrogen.',

  'game.knowledge.junior.questions.4':
    'The forest is to the tree, what the tree is to...',
  'game.knowledge.junior.questions.4.option.1': 'itself.',
  'game.knowledge.junior.questions.4.option.1.feedback':
    'It is a matter of logic. The forest is to the tree, what the tree is to the leaf.',
  'game.knowledge.junior.questions.4.option.2': 'the leaf.',
  'game.knowledge.junior.questions.4.option.2.feedback':
    'Congratulations! You used logic to answer this question!',

  'game.knowledge.junior.questions.5': 'When checking a website, you must:',
  'game.knowledge.junior.questions.5.option.1':
    'Trust any site, as long as it appears on Google.',
  'game.knowledge.junior.questions.5.option.1.feedback':
    'Be careful! The fact that something is on Google does not mean it is correct nor that it is an objective take on the matter. It is important to analyze the content, as well as the source of the information, in a critical manner.',
  'game.knowledge.junior.questions.5.option.2':
    'It’s important to analyze the content, as well as the source of the information, in a critical manner.',
  'game.knowledge.junior.questions.5.option.2.feedback':
    'Congratulations! You are aware that anyone can post on the Internet, so it is important to check the source and analyze the content in a critical manner.',

  'game.knowledge.junior.questions.6': 'What is the capital of Ceará?',
  'game.knowledge.junior.questions.6.option.1': 'Recife',
  'game.knowledge.junior.questions.6.option.1.feedback':
    'That is incorrect! The capital of Ceará is Fortaleza',
  'game.knowledge.junior.questions.6.option.2': 'Fortaleza',
  'game.knowledge.junior.questions.6.option.2.feedback':
    'Correct! The capital of Ceará is Fortaleza',

  'game.knowledge.junior.questions.7':
    'What is the value of X for the following equation: 8X + 16 = 24?',
  'game.knowledge.junior.questions.7.option.1': 'x=3',
  'game.knowledge.junior.questions.7.option.1.feedback':
    'Wrong answer! In this equation, X equals 1.',
  'game.knowledge.junior.questions.7.option.2': 'x=1',
  'game.knowledge.junior.questions.7.option.2.feedback': 'Correct!',
}
