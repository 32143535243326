export default {
  'account.my': 'Minha Conta',
  'account.medals': 'Medalhas',
  'account.medals.view': 'Ver Medalha',
  'account.medals.win': 'Você conquistou a medalha {name}!',
  'account.medals.win.description':
    'Medalhas são recompensas por conseguir chegar ao Portal Z e ganhar o Game VUCA. As medalhas conquistadas ficarão salvas e você pode consultá-las no menu do jogo se estiver logado.',
  'account.medals.my': 'Minhas medalhas',
  'account.medals.of': 'Medalha {name}',
  'account.password.edit': 'Alterar senha',
  'account.password.success.update': 'Sua senha foi alterada com sucesso.',
  'account.password.error.update':
    'Ocorreu um erro ao alterar a senha. Por favor, tente novamente.',
  'account.password.current': 'Senha atual',
  'account.password.current.placeholder': 'Digite a senha senha',
  'account.password.new': 'Nova senha',
  'account.password.new.placeholder': 'Digite a nova senha',
  'account.password.new.confirm': 'Confirme a nova senha',
  'account.password.create': 'Criar senha',
  'account.password.invalid': '<b>Senha incorreta</>',
  'account.edit': 'Alterar dados',
  'account.terms': 'Ver Termos de Uso e Privacidade',
  'account.delete': 'Excluir conta',
  'account.delete.info':
    'É uma pena ver você indo embora! <br/><br/> Tem certeza que deseja fechar a sua conta no Game VUCA? Se você cancelar a sua conta você não poderá mais: <br/>- Salvar jogos <br/>- Ter acesso ao histórico de partidas <br/>- Ter acesso as medalhas recebidas <br/><br/> Você deseja excluir a sua conta?',
  'account.deleted': 'Conta excluída',
  'account.delete.error':
    'Ocorreu um erro ao excluir a conta. Por favor, tente novamente mais tarde.',
  'account.delete.success': 'Sua conta foi excluída',
}
