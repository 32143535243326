import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.job': 'Trabalho',
  'game.job.message':
    'Essa é uma casa de Trabalho. Se seu personagem estiver trabalhando, aparecerão aqui alguns desafios para você resolver. Se ainda não teve a opção de começar a trabalhar, então é só seguir em frente!',

  ...junior,
  ...middle,
  ...superior,
}
