import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'

import {
  Container,
  ContentCard,
  BackgroundBlur,
  ContainerButton,
  ImageContainer,
  QuestionParagraph,
  InspirationBalloon,
  InspirationPersona,
  BadgePoints,
} from './styles'

import RightAnswer from '../../assets/music/right_answer.mp3'
import WrongAnswer from '../../assets/music/wrong_answer.mp3'

import { useLogicCard } from './useLogicCard'
import { Questions } from './questions'
import { useStore } from '../../store/hooks'
import { Sound } from '../Sound'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { injectIntl } from 'react-intl'

export function GameCardsCp({
  currentCard,
  close,
  givePoint,
  addCondition,
  removeCondition,
  intl,
  character,
  G,
}) {
  const { store } = useStore()
  const { sound } = store

  const logicCard = useLogicCard({
    currentCard,
    addCondition,
    removeCondition,
    close,
    givePoint,
  })

  const { gameStagesNames, gameStage } = G

  const translateStage = intl.formatMessage({ id: gameStagesNames[gameStage] })

  function showPoints() {
    const isNegative = logicCard.pointsDetails.isNegative
    return (
      <>
        {sound && <Sound mp3Sound={isNegative ? WrongAnswer : RightAnswer} />}
        <BadgePoints isNegative={isNegative}>{logicCard.pointsDetails.point}</BadgePoints>
      </>
    )
  }

  return (
    <>
      <Container>
        <CardWithHeader
          className="slide-in-bottom anim-delay-half"
          isSmall
          titleCard={intl.formatMessage({ id: logicCard.title })}
          data_test="card_of_game">
          <ContentCard data-test="contentCard">
            {logicCard.Icon && (
              <ImageContainer data-test="imageCard">
                <logicCard.Icon width="100%" height="7rem" />
              </ImageContainer>
            )}

            {currentCard.type_question !== 'only_good_thing' && (
              <QuestionParagraph
                data-test="QuestionParagraph"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    { id: logicCard.paragraphsContent },
                    { name: character.name, stage: translateStage },
                  ),
                }}
              />
            )}

            {!logicCard.done && currentCard.has_interaction && (
              <Questions
                options={currentCard.options}
                indexSelectedOptions={logicCard.indexSelectedOptions}
                selectOption={logicCard.selectOption}
                character={character}
                stage={translateStage || ''}
              />
            )}

            {!currentCard.has_interaction && (
              <>
                {currentCard.type_question !== 'only_good_thing' && (
                  <QuestionParagraph
                    data-test="QuestionParagraph-withoutInteraction"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: currentCard.options[0].response_text },
                        {
                          name: character.name,
                          point: currentCard.options[0].point,
                          stage: translateStage,
                        },
                      ),
                    }}
                  />
                )}

                {currentCard.type_question === 'only_good_thing' && (
                  <>
                    <QuestionParagraph>
                      <InspirationBalloon
                        data-test="InspirationBalloon"
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage(
                            { id: currentCard.options[0].response_text },
                            {
                              name: character.name,
                              point: currentCard.options[0].point,
                              stage: translateStage,
                            },
                          ),
                        }}
                      />
                    </QuestionParagraph>
                    <InspirationPersona
                      data-test="InspirationPersona"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          { id: logicCard.paragraphsContent },
                          { name: character.name, stage: translateStage },
                        ),
                      }}
                    />
                  </>
                )}

                {showPoints()}
              </>
            )}

            {logicCard.done && showPoints()}

            <ContainerButton>
              <ButtonWithRadiusBorderComponent
                data_test={`button${logicCard.dataOfButton.text}`}
                onClick={logicCard.dataOfButton.actionButton}
                disabled={logicCard.dataOfButton.disableButton}
                text={intl.formatMessage({ id: logicCard.dataOfButton.text })}
              />
            </ContainerButton>
          </ContentCard>
        </CardWithHeader>
      </Container>
      <BackgroundBlur className="fade-in-half anim-delay-half" />
    </>
  )
}

GameCardsCp.propTypes = {
  currentCard: PropTypes.shape({
    category: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    type_question: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        response_text: PropTypes.string.isRequired,
        point: PropTypes.number.isRequired,
        feedback: PropTypes.string.isRequired,
      }),
    ).isRequired,
    key: PropTypes.string.isRequired,
    has_interaction: PropTypes.bool.isRequired,
  }).isRequired,
  close: PropTypes.func.isRequired,
  givePoint: PropTypes.func.isRequired,
  addCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func.isRequired,
  intl: PropTypes.object,
  character: PropTypes.object,
  G: PropTypes.object,
}

export const GameCards = injectIntl(GameCardsCp)
