import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
`

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 12px;

  p {
    font-family: Montserrat;
    font-size: 1rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2.14rem;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;

  @media screen and (max-width: 1024px) {
    padding: 20px;
    justify-content: flex-start;
  }
`

export const MedalContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1120px) {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const TextMedal = styled.div`
  margin-top: 30px;
  font-family: MilkyNice;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  ${({ active }) =>
    !active &&
    css`
      color: ${({ theme }) => theme.colors.grey};
    `}

  @media screen and (max-width: 1024px) {
    padding-top: 0px;
    font-size: 14px;
  }
`

export const CenterFlex = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ active }) =>
    !active &&
    css`
      filter: grayscale(1);
    `}

  @media screen and (max-width: 1024px) {
    margin-top: 25px;

    button {
      display: none;
      opacity: 0;
    }

    svg {
      width: 130px;
      height: 95px;
    }
  }
`
