export default {
  'game.challenge.superior.questions.1':
    '{name} studied a lot to be admitted in a public university, but, unfortunately, {name} failed. This situation made {name} upset, because {name} do not have the energy to start again.',
  'game.challenge.superior.questions.1.feedback':
    'Problems are part of life! Focus on Portal Z, {name}!',

  'game.challenge.superior.questions.2':
    '{name} shares an apartment with a friend. But {name}’s friend got a scholarship in another state and has to move. {name} cannot pay for the bills without help. What should {name} do?',
  'game.challenge.superior.questions.2.option.1':
    'Look for another person to share rent.',
  'game.challenge.superior.questions.2.option.1.feedback':
    'Great idea! Their friend will continue helping {name} find another roommate.',
  'game.challenge.superior.questions.2.option.2':
    'Start teaching private classes to pay for rent.​',
  'game.challenge.superior.questions.2.option.2.feedback':
    '{name} showed their business side, but runs the risk of not getting each month’s value.',

  'game.challenge.superior.questions.3':
    '{name} started higher education and realized that more money would be necessary to pay for expenses. What now?',
  'game.challenge.superior.questions.3.option.1':
    'Find a job to pay the bills and continue studying',
  'game.challenge.superior.questions.3.option.1.feedback':
    '{name} got a part-time internship. {name} did well at university and received praises from their colleagues. {name} goes to the job market and accumulates XP to reach Portal Z! Well done!',
  'game.challenge.superior.questions.3.option.2':
    'Ask help from a family member and remain a 100% focused on studying.​',
  'game.challenge.superior.questions.3.option.2.feedback':
    '{name} studied for the second time and got involved in several projects. It was a great semester and {name} got additional XP to reach Portal Z. Well done!',

  'game.challenge.superior.questions.4':
    '{name} was coming back home one night after class, when someone stole {name}’s bag that with their personal computer and a month’s worth of money for transportation. {name} will have to look for additional income to get the money back and buy a new computer.',
  'game.challenge.superior.questions.4.feedback':
    'Problems are a part of life! Focus on Portal Z, {name}!!',

  'game.challenge.superior.questions.5':
    "{name} created an Instagram account to give advice on study. {name}'s contents have received positive comments; however, {name} saw some mean comments that hurt {name}'s self-confidence. What should {name} do?",
  'game.challenge.superior.questions.5.option.1':
    'Focus on points to improve and on positive comments.',
  'game.challenge.superior.questions.5.option.1.feedback':
    'Good choice! It is very important to filter comments, focus on improving the content, and moving forward.',
  'game.challenge.superior.questions.5.option.2':
    'Stop posting contents on the social network.',
  'game.challenge.superior.questions.5.option.2.feedback':
    'That is so sad! {name} had the initiative to create a good project, but ended up upset due to some negative comments. {name} lacked the perseverance and self-confidence to move forward.',

  'game.challenge.superior.questions.6':
    '{name} is facing some family problems that are making {name} feel anguished when thinking about the future. What should {name} do?',
  'game.challenge.superior.questions.6.option.1':
    'Continue with their routine and ignore negative feelings.',
  'game.challenge.superior.questions.6.option.1.feedback':
    'Ignoring personal feelings makes {name}’s situation worse. Recognizing difficulties and getting help would be a great place to start!',
  'game.challenge.superior.questions.6.option.2':
    'Get professional help to improve your routine positively.',
  'game.challenge.superior.questions.6.option.2.feedback':
    'Well done! {name} recognized their difficulties and got help. Congratulations on the initiative!',
}
