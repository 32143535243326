import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CircularButtonComponent } from '../../../../../../components/Buttons/circularButton'
import { ButtonWithRadiusBorderComponent } from '../../../../../../components/Buttons/buttonWithRadiusBorder/'

import { IcSandwichMenu, IcX } from '../../../../../../assets/img'
import { goToGameClicked } from '../../../../../../services/analytics'

import { ContainerMenuMobile, ListOptionsMenuMobile, MenuContentMobile } from './styles'
import { injectIntl } from 'react-intl'

export const MenuMobileCp = ({ history, dataItemsMenu, intl }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  function setVisibilityOfMenu(item) {
    setMenuIsOpen((currentState) => !currentState)

    if (item && item.hashLink) {
      const { offsetTop } = item.route.current

      window.scrollTo({
        top: offsetTop - 100,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  function goToGame() {
    goToGameClicked('go-to-game-topBar')
    history.push('/login')
  }

  const buttonMobile = (Icon) => {
    return (
      <CircularButtonComponent
        to="#"
        id="mobileMenuButton"
        color="white"
        data_test="menuButtonMobile"
        onClick={setVisibilityOfMenu}>
        <Icon width="15" height="15" />
      </CircularButtonComponent>
    )
  }

  return (
    <ContainerMenuMobile data-test="ContainerMenuMobile">
      {buttonMobile(IcSandwichMenu)}

      <MenuContentMobile showMenu={menuIsOpen}>
        {buttonMobile(IcX)}

        <ListOptionsMenuMobile>
          {dataItemsMenu.map((item) => (
            <li key={item.name}>
              <a
                href={!item.hashLink ? item.route : null}
                target={item.external ? '_blank' : ''}
                data-test={`${item.name}MobileMenu`}>
                <span onClick={() => setVisibilityOfMenu(item)}>
                  {intl.formatMessage({ id: item.name })}
                </span>
              </a>
            </li>
          ))}
          <li>
            <ButtonWithRadiusBorderComponent
              data_test="goToGame"
              onClick={goToGame}
              text={intl.formatMessage({ id: 'play' })}
            />
          </li>
        </ListOptionsMenuMobile>
      </MenuContentMobile>
    </ContainerMenuMobile>
  )
}

MenuMobileCp.propTypes = {
  dataItemsMenu: PropTypes.any,
  intl: PropTypes.object,
  history: PropTypes.object,
}

export const MenuMobile = injectIntl(MenuMobileCp)
