import { checkIsCatergoryAffected, config } from './config'
import {
  getTilePath,
  getCharacterByIndex,
  getStageProgressLevels,
  getConditionKeys,
  playerHasCondition,
} from './helper'
import { deliverCard, injectContextOnCard } from './cards/logics'
import { cards } from './cards'
import { applyConditionEffect } from './effects'

import { reportSelectCharacter } from '../services/analytics'

function applyConditions(conditionsKeys, effect, args) {
  conditionsKeys.forEach((conditionKey) =>
    applyConditionEffect(effect, conditionKey, args),
  )
}

export const rollDice = (G, ctx, num) => {
  const path = getTilePath(G.pawnTilePosition, num, G.boardTiles.length)
  G.lastDiceRoll = num
  G.lastPath = path
  G.animatePawn = true
  ctx.events.endPhase()
}

export const stepTile = (G, ctx) => {
  const tileToGo = G.lastPath?.shift()

  if (tileToGo !== undefined) {
    G.pawnTilePosition = tileToGo
  } else if (G.animatePawn === true) {
    G.animatePawn = false
    if (G.pawnTilePosition >= 0) {
      const category = config.board.tiles[G.pawnTilePosition]
      if (category === 'job') {
        if (playerHasCondition(G, 'employed')) {
          deliverCard(G, category)
        } else {
          const isCatergoryAffected = checkIsCatergoryAffected(category)
          G.currentCard = injectContextOnCard(G, cards.defaults.job, isCatergoryAffected)
        }
      } else {
        deliverCard(G, category)
      }
      G.showCard = true
    }
  }
}

export const hideCard = (G) => {
  G.showCard = false
}

export const commitPoints = (G, ctx, number) => {
  if (number === 0) {
    G.pointsCommitted = true
    return
  }

  const playerConditionsKeys = getConditionKeys(G)
  G.pointsCredited = number

  applyConditions(playerConditionsKeys, 'onCommitPoints', { G, ctx, number })

  const projection = G.progress + G.pointsCredited
  const currentStageProgressLevels = getStageProgressLevels(G)

  if (projection > G.progressLevels.superior.max) {
    G.progress = G.progressLevels.superior.max
  } else if (projection < currentStageProgressLevels.min) {
    G.progress = currentStageProgressLevels.min
  } else {
    G.progress = projection
  }

  G.pointsCommitted = true
}

export const stageProgression = (G) => {
  const currentStageProgressLevels = getStageProgressLevels(G)

  if (G.progress >= currentStageProgressLevels.max) {
    G.gameStage += 1
  }

  G.stageProgressionChecked = true
}

export const selectCharacter = (G, _, characterIndex) => {
  G.character = getCharacterByIndex(characterIndex)
  reportSelectCharacter(G.character?.name)
}

export const addCondition = (G, _, condition) => {
  if (!G.conditions.map((c) => c.key).includes(condition.key)) {
    G.conditions = [...G.conditions, { ...condition, turn: G.turnCounter }]
  }
}

export const removeCondition = (G, _, condition) => {
  G.conditions = G.conditions.filter((c) => c.key !== condition.key)
}

export const updateConcludeStep = (G, _, concludeStep) => {
  if (concludeStep.junior && concludeStep.middle && concludeStep.superior) {
    G.concludeStep.junior.done = concludeStep.junior.done
    G.concludeStep.middle.done = concludeStep.middle.done
    G.concludeStep.superior.done = concludeStep.superior.done
  }
}
