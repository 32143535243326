import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.skills': 'Skills',
  'game.skills.message':
    'You are over a Skills box. Here you will have to face the challenges that will help you develop your character’s skills (or not).',

  ...junior,
  ...middle,
  ...superior,
}
