export default {
  'game.challenge.middle.questions.1':
    '{name} was not careful enough and contracted an STD (sexually transmitted disease). This requires a long-term medical treatment and being away from school for a while.',
  'game.challenge.middle.questions.1.feedback':
    'Shocking, isn’t it? Prevention is a serious business! {name} lost some XP because of this, so {name} has learned to pay more attention to these issues.',

  'game.challenge.middle.questions.2':
    '{name}’s mother went back to work and needs help taking care of {name}’s younger brother. {name} decides to:',
  'game.challenge.middle.questions.2.option.1':
    'Skip class for a week to take care of their younger brother.',
  'game.challenge.middle.questions.2.option.1.feedback':
    '{name} lost a week of class and now will have a hard time catching up on the contents!',
  'game.challenge.middle.questions.2.option.2':
    'Seek help from a neighbor to avoid having to skip classes.​',
  'game.challenge.middle.questions.2.option.2.feedback':
    'Thanks to {name}’s initiative, {name} did not miss any class and earned more XP. Off to Portal Z!​',

  'game.challenge.middle.questions.3':
    '{name} needs a better cellphone, but {name} does not have any money. What should {name} do?',
  'game.challenge.middle.questions.3.option.1': 'Find a job on the market.',
  'game.challenge.middle.questions.3.option.1.feedback':
    "A lot of young people drop out of school to work. {name}'s challenge is to find a balance between both to reach Portal Z.",
  'game.challenge.middle.questions.3.option.2':
    'Organize a fundraiser with friends and family.',
  'game.challenge.middle.questions.3.option.2.feedback':
    'Every decision helps you learn something, right? It will take longer to buy the cell phone, but {name} will be able to focus on studying and gain lots of XP.',

  'game.challenge.middle.questions.4':
    "Due to economic difficulties, {name}'s mother cannot make the monthly payment for the English class. What should {name} do?",
  'game.challenge.middle.questions.4.option.1':
    'Drop the English class until the economic situation improves.',
  'game.challenge.middle.questions.4.option.1.feedback':
    'Dropping the English class without trying another option can mess with future plans.',
  'game.challenge.middle.questions.4.option.2':
    'Search for a free class online and continue studying on their own.​',
  'game.challenge.middle.questions.4.option.2.feedback':
    '{name} understands that it can be complicated to adapt, but it is best to try than to stop studying.',

  'game.challenge.middle.questions.5':
    'Some classes in {name}’s school are now online. Since {name} does not have a computer, {name} follows classes through their cell phone, but this is very exhausting. What does {name} do?',
  'game.challenge.middle.questions.5.option.1':
    '{name} pays little attention to classes from their cell phone.',
  'game.challenge.middle.questions.5.option.1.feedback':
    'Pretending to study only makes you fall behind with classes.',
  'game.challenge.middle.questions.5.option.2':
    '{name} deactivates notifications and makes an effort to pay attention.​',
  'game.challenge.middle.questions.5.option.2.feedback':
    'Congratulations! Thanks to {name}’s dedication, {name} can keep up the good grades.',

  'game.challenge.middle.questions.6':
    '{name} moved to a neighborhood that is far away from school, and the commute takes twice the time. Even if {name} has to get up earlier, {name} is till determined to finish middle school.',
  'game.challenge.middle.questions.6.option.1': ' ',
}
