import { characters } from './characters'

export const nextTile = (pos, diceRoll, tilesTotal) => (pos + diceRoll) % tilesTotal

export const getTilePath = (pos, diceRoll, tilesTotal) => {
  const path = []
  for (let i = 0; i < diceRoll; i++) {
    const tileIndex = nextTile(pos, 1, tilesTotal)
    path.push(tileIndex)
    pos = tileIndex
  }

  return path
}

export const getCharacterByIndex = (index) => characters[index] || null

export const getStageProgressLevels = ({ gameStage, progressLevels }) =>
  progressLevels[Object.keys(progressLevels)[gameStage]]

export const getConditionKeys = ({ conditions }) => conditions.map((c) => c.key)

export const playerHasCondition = ({ conditions }, conditionKey) =>
  conditions.filter((c) => c.key === conditionKey).length > 0
