export default {
  'game.job.superior.questions.1':
    '{name} was assigned the task of creating a presentation for the launch of a new product. {name} used their creativity and surprised the company. Fortunately, {name} had the skills to do the task, right?',
  'game.job.superior.questions.1.feedback':
    '{name} receives and moves forward to the next move.',

  'game.job.superior.questions.2':
    '{name} is on a trial period and receives a proposal that is outside of their interest area, but to work full-time. What does {name} do?',
  'game.job.superior.questions.2.option.1':
    'Continue on a trial period, getting only the assigned scholarship.',
  'game.job.superior.questions.2.option.1.feedback':
    'It is hard to make a decision, right? {name} prefers to continue in their area of interest and have more time to study.',
  'game.job.superior.questions.2.option.2': 'Accepts the job, getting a full salary.',
  'game.job.superior.questions.2.option.2.feedback':
    'It is hard to make a decision, right? In spite of getting a good salary, this reduces {name}’s time to study.',

  'game.job.superior.questions.3':
    '{name} needs to reduce their expenses to be able to move and live closer to the university. What can {name} do?',
  'game.job.superior.questions.3.option.1':
    'Talk to {name}’s boss about the possibility of a raise.',
  'game.job.superior.questions.3.option.1.feedback':
    '{name} tried to negotiate but, unfortunately, the boss did not have enough budget for a raise. {name} learned about negotiation and resilience.',
  'game.job.superior.questions.3.option.2':
    'Selling sandwiches for an additional income.',
  'game.job.superior.questions.3.option.2.feedback':
    'This is a good idea for an additional income, but {name} will have to make an effort to continue studying.',

  'game.job.superior.questions.4':
    'The company in which {name} works organized a raffle, and {name} won a scholarship for a specialization class!',
  'game.job.superior.questions.4.feedback':
    '{name} receives and moves forward to the next move.',

  'game.job.superior.questions.5':
    '{name} is taking several exams at the university this week and also has lots of responsibilities at the workplace. This week, {name} could not sleep or eat properly.',
  'game.job.superior.questions.5.feedback':
    '{name} receives and moves forward to the next move.',

  'game.job.superior.questions.6':
    'An international client is visiting the company this month and {name} received an invitation to be part of the meeting.  However, {name} does not feel comfortable participating, since the meeting will be held on a foreign language. What should {name} do?',
  'game.job.superior.questions.6.option.1': 'Participate in the meeting.',
  'game.job.superior.questions.6.option.1.feedback':
    '{name} talked to the leader and participated as a member of the audience in the meeting. Even though {name} did not understand 100% of the topics, that experience made {name} appreciate foreign languages courses a bit more.',
  'game.job.superior.questions.6.option.2':
    'Skip class on the day of the meeting to avoid the embarrassment.',
  'game.job.superior.questions.6.option.2.feedback':
    "Everyone noticed {name}'s absence in the meeting and their unjustified absence made the leader lose trust in {name}’s work.",
}
