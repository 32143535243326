import styled, { css } from 'styled-components'

export const BorderCard = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 0.25rem ${({ theme }) => theme.colors.black};
  border-radius: 0.5rem;
  box-shadow: -2.5px 3px rgb(29, 29, 27);
  z-index: 999;

  ${({ isSmall }) =>
    isSmall &&
    css`
      max-width: 400px;
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`
