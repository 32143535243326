import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1rem;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;

  top: 0;
  left: 0;
`

export const BackgroundBlur = styled.div`
  background-color: #606060;
  height: 100vh;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;
`
export const ContentCard = styled.div`
  padding: 20px 30px 30px;
  min-width: 316px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};

  h1,
  p {
    letter-spacing: -0.05px;
  }

  h1 {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.05px;
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    font-family: Montserrat;
    font-size: 0.9rem;
  }
`

export const ContentIconWithParagraph = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`

export const Paragraph = styled.p`
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.05px;
`

export const ContainerChangePassword = styled.div`
  display: grid;
  gap: 20px;
`
