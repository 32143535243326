import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  padding: 1.5rem 2.1rem;
  place-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
`

export const ContentCard = styled.main`
  display: flex;
  flex-direction: column;
  padding: 12px;

  p {
    font-family: Montserrat;
    font-size: 1rem;
  }

  span {
    font-weight: bolder;
  }

  figure {
    padding: 0 0 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2.14rem;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;

  @media screen and (max-width: 1024px) {
    padding: 20px;
    justify-content: flex-start;
  }
`

export const TextMedal = styled.div`
  margin: 25px 0;
  font-family: MilkyNice;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  @media screen and (max-width: 1024px) {
    padding-top: 0px;
    font-size: 14px;
  }
`

export const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin-top: 25px;

    button {
      display: none;
      opacity: 0;
    }

    svg:first-child {
      width: 130px;
      height: 95px;
    }
  }
`

export const MedalDatas = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;

  svg:first-child {
    width: 24px;
    height: 24px;
  }
`

export const MedalData = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Data = styled.div`
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
`
