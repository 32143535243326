export default {
  feedback: 'Avaliação',
  'feedback.recommend': 'Recomendaria',
  'feedback.recommend.not': 'Não recomendaria',
  'feedback.recommend.question': 'Você recomendaria o Game VUCA a um amigo?',
  'feedback.info':
    'Avalie as frases abaixo de acordo com a sua percepção após jogar o Game VUCA:',
  'feedback.disagree': 'Discordo',
  'feedback.agree': 'Concordo',
}
