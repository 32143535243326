import React from 'react'
import PropTypes from 'prop-types'

import { goToAboutJAClicked, goToGameClicked } from '../../../../../../services/analytics'
import { ButtonWithRadiusBorderComponent } from '../../../../../../components/Buttons/buttonWithRadiusBorder/'

import { ContainerMenuDesktop, ListOptionsMenuDesktop } from './styles'
import { injectIntl } from 'react-intl'

export const MenuDesktopCp = ({ history, dataItemsMenu, intl }) => {
  const goToGame = () => {
    goToGameClicked('go-to-game-topBar')
    history.push('/login')
  }

  const reportGoToAboutJAClicked = (item) => {
    item.analytics === 'goToAboutJAClicked' && goToAboutJAClicked()

    if (item.hashLink) {
      const { offsetTop } = item.route.current
      window.scrollTo({
        top: offsetTop + 160,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <ContainerMenuDesktop data-test="ContainerMenuDesktop">
      <ListOptionsMenuDesktop>
        {dataItemsMenu.map((item) => (
          <li key={item.name}>
            <div onClick={() => reportGoToAboutJAClicked(item)}>
              <a
                href={!item.hashLink ? item.route : null}
                data-test={`${item.name}DesktopMenu`}
                target={item.external ? '_blank' : ''}>
                {intl.formatMessage({ id: item.name })}
              </a>
            </div>
          </li>
        ))}

        <li>
          <ButtonWithRadiusBorderComponent
            data_test="goToGame"
            onClick={goToGame}
            text={intl.formatMessage({ id: 'play' })}
          />
        </li>
      </ListOptionsMenuDesktop>
    </ContainerMenuDesktop>
  )
}

MenuDesktopCp.propTypes = {
  dataItemsMenu: PropTypes.any,
  intl: PropTypes.object,
  history: PropTypes.object,
}

export const MenuDesktop = injectIntl(MenuDesktopCp)
