import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.knowledge': 'Quiz',
  'game.knowledge.message':
    '¡Es hora de poner a prueba sus conocimientos! En esta casilla va a responder preguntas que le darán puntos de experiencia.',

  ...junior,
  ...middle,
  ...superior,
}
