import React, { useRef, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { config } from '../../game/config'
import { gamePropTypes } from '../../game'
import { Svg } from './style'
import { getTileCentroid } from '../../common/utils'
import { MilenaPawn, JonathanPawn, BrendaPawn, CrisPawn } from '../../assets/img/pawns'
import { PawnContainer } from './containers/PawnContainer'
import { BadgePoints } from '../BadgePoints'

const pawnMap = {
  Milena: MilenaPawn,
  Jonathan: JonathanPawn,
  Brenda: BrendaPawn,
  Cris: CrisPawn,
}

export const Pawn = ({ G, animateToTile, onAnimationStepEnd }) => {
  const { pawnIconHeight, pawnIconWidth } = config.board
  const initialPawnPosition = [0, 0]

  function getAnimateToPosition(toTile) {
    const position = getTileCentroid(G.boardTiles, toTile)
    if (!position.filter((el) => el !== undefined)) {
      return initialPawnPosition
    } else {
      return [position[0] - pawnIconWidth / 2, position[1] - pawnIconHeight]
    }
  }

  const animateX = useRef()
  const animateY = useRef()
  const [animateTo, setAnimateTo] = useState(initialPawnPosition)
  const [pawnPos, setPawnPos] = useState(initialPawnPosition)

  let prePosition = {
    x: null,
    y: null,
  }

  function trySetPawnPosition() {
    if (prePosition.x && prePosition.y) {
      setPawnPos([prePosition.x, prePosition.y])
      prePosition = { x: null, y: null }
      onAnimationStepEnd()
    }
  }

  useEffect(setupsAnimateElements, [animateX, animateY, prePosition])
  function setupsAnimateElements() {
    const animateEventX = () => {
      prePosition.x = animateTo[0]
      trySetPawnPosition()
    }
    const animateEventY = () => {
      prePosition.y = animateTo[1]
      trySetPawnPosition()
    }
    if (animateX.current) {
      animateX.current.addEventListener('endEvent', animateEventX)
    }

    if (animateY.current) {
      animateY.current.addEventListener('endEvent', animateEventY)
    }

    return () => {
      if (animateY.current) {
        animateY.current.removeEventListener('endEvent', animateEventY)
      }

      if (animateX.current) {
        animateX.current.removeEventListener('endEvent', animateEventX)
      }
    }
  }

  useEffect(setAnimation, [animateToTile])
  function setAnimation() {
    const at = getAnimateToPosition(animateToTile)
    setAnimateTo(at)
  }

  useEffect(animatePawn, [animateTo])
  function animatePawn() {
    if (animateX.current && animateY.current) {
      animateX.current.beginElement()
      animateY.current.beginElement()
    }
  }

  if (!G.character) {
    return <Redirect to="/" />
  }

  return (
    <Svg data-test="Pawn" xmlns="http://www.w3.org/2000/svg">
      <BadgePoints G={G} anchorPosition={pawnPos} />
      <PawnContainer
        CharacterPawn={pawnMap[G.character?.name]}
        x={pawnPos[0]}
        y={pawnPos[1]}
        width={pawnIconWidth}
        height={pawnIconHeight}
        id="pawnComponent"
        data-characterName={G.character.name}
      />

      {animateTo && (
        <>
          <animate
            id="animateX"
            xlinkHref="#pawnComponent"
            attributeName="x"
            from={pawnPos[0]}
            to={animateTo[0]}
            dur="0.4s"
            repeatCount="1"
            begin="indefinite"
            fill="freeze"
            ref={animateX}
          />
          <animate
            xlinkHref="#pawnComponent"
            attributeName="y"
            from={pawnPos[1]}
            to={animateTo[1]}
            repeatCount="1"
            fill="freeze"
            dur="0.4s"
            begin="indefinite"
            ref={animateY}
          />
        </>
      )}
    </Svg>
  )
}

const { G } = gamePropTypes
Pawn.propTypes = {
  G,
  animateToTile: PropTypes.number,
  onAnimationStepEnd: PropTypes.func,
}
