import styled from 'styled-components'

import backgroundCloudsDesktop from '../../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Container = styled.div`
  background-image: url(${backgroundCloudsMobile});
  object-fit: contain;
  width: 100%;
  padding: 1rem;
  background-size: 101%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  z-index: 89;

  @media screen and (max-width: 1000px) {
    background-image: url(${backgroundCloudsDesktop});
    height: 100%;
  }
`

export const AccountBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  margin-top: 167px;
  margin-bottom: 47px;
  font-family: MilkyNice;
  font-size: 36px;
  letter-spacing: -0.16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`

export const ButtonAccountContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1120px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const ButtonAccount = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 186px;
  height: 187px;
  margin: 0 0 4px 4px;
  border-radius: 39px;
  box-shadow: -2.5px 3px rgb(29, 29, 27);
  border: solid 3px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  .labelButton {
    width: 136px;
    height: 40px;
    text-align: center;
    font-family: MilkyNice;
    font-size: 15px;
    letter-spacing: -0.07px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
  }

  .iconButton {
    width: 63px;
    height: 63px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`

export const SubItem = styled.span`
  font-family: MilkyNice;
  font-size: 14px;
  letter-spacing: -0.06px;
  padding-left: 113px;
  color: ${({ theme }) => theme.colors.black};
`

export const PopButton = styled.div`
  position: absolute;
  z-index: 99999;
`
