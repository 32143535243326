export default {
  'game.inspiration.superior.questions.1':
    '<b>Lia Zakiyyah</b><br/>Empreeendedora e ex aluna JA',
  'game.inspiration.superior.questions.1.option.1':
    '“Buscar uma carreira é uma jornada para toda a vida. Não termina com o primeiro emprego ou com a primeira carreira. <b>Persiga o que você acredita e o que é bom durante toda a vida</b>"',

  'game.inspiration.superior.questions.2':
    '<b>Cosmin Malureanu</b><br/>Empreeendedora e ex aluna JA',
  'game.inspiration.superior.questions.2.option.1':
    '“<b>Aprenda a como aprender</b>. O mundo está mudando num <b>ritmo acelerado</b>. Você precisa evitar ficar parado num emprego ou carreira que não tem futuro. Tem que desafiar a si mesmo a <b>aprender coisas novas</b> e permanecer competitivo.”',

  'game.inspiration.superior.questions.3':
    '<b>Joshuel Plasencia</b><br/>Cofundador do Projeto 99 e ex-aluno da JA EUA',
  'game.inspiration.superior.questions.3.option.1':
    '“Dar e receber <b>feedback</b> é uma dádiva, pois não existe essa de ser o melhor em algo sem perspectiva de melhorias. O <b>aperfeiçoamento</b> só é possível através dos feedbacks dados e recebidos."',

  'game.inspiration.superior.questions.4':
    '<b>Leen Abu Baker</b><br/>Empreendedora e ex aluna da JA',
  'game.inspiration.superior.questions.4.option.1':
    '"Comece a <b>buscar seus interesses</b> ainda jovem e não tenha medo de <b>compartilhá-los</b> com a comunidade que o cerca. Acredite sempre em si mesmo e espalhe <b>energia positiva</b> à sua volta. E lembre-se sempre de que fracassos são apenas o começo do sucesso."',

  'game.inspiration.superior.questions.5':
    '<b>Yesenia Cardenas</b><br/>Empreendedora e ex-aluna JA',
  'game.inspiration.superior.questions.5.option.1':
    '“Se você tiver um <b>objetivo</b> ou um <b>sonho</b> que quer ver realizados, não importa se é se tornar advogado, abrir um negócio ou ter uma família, <b>haverá obstáculos</b> e pessoas que vão questionar os seus sonhos. É muito importante <b>manter-se fiel</b> a seus objetivos e desejos e <b>trabalhar</b> duro para perseverar.”',

  'game.inspiration.superior.questions.6':
    '<b>Bob Coughlin</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.superior.questions.6.option.1':
    '"Entenda o seus pontos fortes e cerque-se de pessoas que podem ajudá-lo a superar suas fraquezas.”',

  'game.inspiration.superior.questions.7':
    '<b>Mario A. Escutia</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.superior.questions.7.option.1':
    '“Meu conselho para os jovens é: não importa <b>de onde você veio</b>, qual é a sua classe social ou sua ideologia, <b>seus sonhos podem sim se tornar realidade.</b> Honestidade e trabalho duro sempre são recompensados.”',

  'game.inspiration.superior.questions.8': '<b>Joseph Fortuno</b><br/>Ex-aluno JA EUA',
  'game.inspiration.superior.questions.8.option.1':
    '"Tenho apenas um conselho: Não tenha medo de tentar, nem mesmo de falhar. Se eu tivesse tido muito medo eu nunca teria chegado onde estou hoje."',

  'game.inspiration.superior.questions.9':
    '<b>Youmn mazhzoul</b><br/>Ex-aluna JA Marrrocos',
  'game.inspiration.superior.questions.9.option.1':
    '"Vamos pensar sobre o conceito do que é ser bem sucedido. A métrica que usamos para medir o sucesso muitas vezes está distorcida, pois só leva em conta o êxito financeiro. Embora seja importante, a humanidade deveria se empenhar mais em ter uma vida com significado."',

  'game.inspiration.superior.questions.10': '<b>Jeff Hansberry</b><br/>Ex-aluno JA EUA',
  'game.inspiration.superior.questions.10.option.1':
    '"Você tem que acreditar que existe uma vida diferente para  além de  um raio de três ou quatro quarteirões de onde você mora, ou além das pessoas que conhece, ou das experiências  que  vivencia agora. Existe algo melhor para você além do mundo que vive hoje."',
}
