import styled, { css } from 'styled-components'

export const ContainerButtons = styled.div`
  margin: 1.5rem 0;
  width: 100%;

  ${({ isSingle }) =>
    isSingle &&
    css`
      max-width: 25rem;
    `};
`
