export default {
  'game.inspiration.middle.questions.1':
    '<b>Bisman Deu</b><br/>Empreendedora e ex aluna da JA',
  'game.inspiration.middle.questions.1.option.1':
    '"Um conselho crucial aos jovens é criar </b>oportunidades</b> para si mesmos. Tendemos a <b>esperar</b> que a oportunidade venha bater à nossa porta, mas isso <b>não costuma acontecer</b>. Acredito firmemente em tentar criar tantas oportunidades quanto puder."',

  'game.inspiration.middle.questions.2':
    '<b>Alfred bright</b><br/>Artista, empreendedor e ex aluno da JA',
  'game.inspiration.middle.questions.2.option.1':
    '“Primeiro, minha mensagem não mudou: <b>maximize seu potencial</b>. Vá fundo dentro de si mesmo e encontre aquilo que gostaria de fazer mesmo que não fosse pago por isso. Siga sua <b>paixão</b>, encontre a si mesmo e maximize seu potencial. Mantenha uma atitude positiva em relação a você mesmo. Foi o que eu fiz. Mantive uma <b>atitude positiva</b> e nunca deixei de ser eu mesmo.”',

  'game.inspiration.middle.questions.3':
    '<b>Kim kaupe</b><br/>Empreendedora e ex aluna da JA',
  'game.inspiration.middle.questions.3.option.1':
    '“Não importa se você é um empreendedor independente ou se atua dentro de uma companhia: <b>é sempre sensato pedir ajuda</b>. Você ficará surpreso ao constatar quanta gente quer ver você se sair bem e está pronta a <b>estender a mão</b>. Portanto, <b>não</b> tenha <b>medo</b> de pedir ajuda.',

  'game.inspiration.middle.questions.4':
    '<b>Pedro Englert</b><br/>Empreendedor e ex aluno da JA',
  'game.inspiration.middle.questions.4.option.1':
    '“Meu primeiro conselho aos jovens é <b>faça algo que você gosta</b>, porque apenas <b>você</b> dedicará o tempo e a energia necessária para fazer isso ter sucesso.” “Não tenha <b>medo de errar</b>, mas não persista no erro e evite cometê-lo duas vezes”.',

  'game.inspiration.middle.questions.5':
    '<b>Jerome Cowans</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.middle.questions.5.option.1':
    '“Eu também encorajaria os jovens a <b>pensarem</b> sobre a perspectiva de <b>médio a longo prazo</b> para suas carreiras. Você poderia fazer algo agora como uma carreira que ficará obsoleta em 5 ou 10 anos, então faça algo que <b>você ame fazer</b>, mas também esteja preparado para mudá-lo no <b>futuro.</b>”',

  'game.inspiration.middle.questions.6': '<b>Amanda Cornélio</b><br/>ex-aluna JA',
  'game.inspiration.middle.questions.6.option.1':
    '"Não coloque limites para os <b>sonhos</b>, quanto mais a gente se dedica, mais próximos estamos deles! E <b>podem acontecer</b> quando a gente menos espera."',

  'game.inspiration.middle.questions.7':
    '<b>Jim hemak</b><br/>Empreendedor e ex-aluno JA',
  'game.inspiration.middle.questions.7.option.1':
    '“Aproveite <b>oportunidades</b> para se envolver em organizações, seja na <b>escola</b> ou na <b>vida</b>. Isso pode ser de grande <b>aprendizado</b> na sua vida.',

  'game.inspiration.middle.questions.8':
    '<b>Daneil Cheung</b><br/>Empreendedor social e ativista',
  'game.inspiration.middle.questions.8.option.1':
    '“Expanda a sua rede não apenas para receber conselhos e ajuda, mas também para se disponibilizar a dar conselhos e ajudar os outros.”',

  'game.inspiration.middle.questions.9':
    '<b>Nyi Mas Gianti Bingah Erbiana</b><br/>Chefe de assessoria jurídica da Mitsubishi Indonésia',
  'game.inspiration.middle.questions.9.option.1':
    '“Procure ser flexível. Não estou sugerindo ser um seguidor dos outros ou que não tenha a própria identidade. Mas o jovem deve ser capaz de se adaptar às mudanças no mundo e de entrar em consenso com outras pessoas.”',

  'game.inspiration.middle.questions.10': '<b>JIM HEMAK</b><br/>Ex-aluno JA EUA',
  'game.inspiration.middle.questions.10.option.1':
    '"Aproveite toda a oportunidade de se envolver em organizações enquanto estudante ao longo da vida. Essas experiências podem significar muito aprendizado para qualquer tipo de carreira ou negócio que venha a ter."',
}
