import React from 'react'
import { Client } from 'boardgame.io/react'

import { GameTable } from '../../components'
import { GameJA, gamePropTypes } from '../../game'

const GameComponent = (props) => <GameTable {...props} />
GameComponent.propTypes = gamePropTypes

export const Game = Client({
  game: GameJA,
  board: GameComponent,
  numPlayers: 1,
  debug: false,
})
