export default {
  'signUp.registration': 'Cadastro',
  'signUp.editAccount': 'Alterar dados',
  'signUp.personalData': 'Dados pessoais',
  'signUp.name': 'Nome Completo*',
  'signUp.namePlaceholder': 'Digite o seu nome completo',
  'signUp.email': 'E-mail*',
  'signUp.dateOfBirth': 'Data de nascimento*',
  'signUp.dateOfBirth.tooltip':
    'O Game VUCA é indicado para maiores de 11 anos, por isso armazenamos a sua data de nascimento.',
  'signUp.birthDate.day': 'Dia',
  'signUp.birthDate.mounth': 'Mês',
  'signUp.birthDate.mounth.january': 'Jan',
  'signUp.birthDate.mounth.february': 'Fev',
  'signUp.birthDate.mounth.march': 'Mar',
  'signUp.birthDate.mounth.april': 'Abr',
  'signUp.birthDate.mounth.may': 'Mai',
  'signUp.birthDate.mounth.june': 'Jun',
  'signUp.birthDate.mounth.july': 'Jul',
  'signUp.birthDate.mounth.august': 'Ago',
  'signUp.birthDate.mounth.september': 'Set',
  'signUp.birthDate.mounth.october': 'Out',
  'signUp.birthDate.mounth.november': 'Nov',
  'signUp.birthDate.mounth.december': 'Dez',
  'signUp.year': 'Ano',
  'signUp.gender': 'Gênero',
  'signUp.gender.feminine': 'Feminino',
  'signUp.gender.male': 'Masculino',
  'signUp.gender.notBinary': 'Não binário',
  'signUp.gender.other': 'Outro',
  'signUp.gender.preferNotToSay': 'Prefiro não dizer',
  'signUp.tooltip':
    'Dado opcional, não precisamos de dados sobre gênero, raça, cidade, localização e escolaridade mas pedimos estes dados para estatísticas internas.',
  'signUp.ethnicity': 'Raça',
  'signUp.ethnicity.white': 'Branco',
  'signUp.ethnicity.parde': 'Pardo',
  'signUp.ethnicity.black': 'Negro',
  'signUp.ethnicity.yellow': 'Amarelo',
  'signUp.ethnicity.indie': 'Indígeno',
  'signUp.state': 'Estado',
  'signUp.city': 'Cidade',
  'signUp.areYouStudying': 'Estuda atualmente?',
  'signUp.accountSettings': 'Configurações da conta',
  'signUp.termsSignUp': 'Termos de Uso e Privacidade',
  'signUp.agreeTerms': 'Concordo com os ',
  'signUp.acceptTerms':
    'Para prosseguir, é preciso concordar com os Termos de Uso e Privacidade do Game VUCA.',
  'signUp.schooling': 'Escolaridade',
  'signUp.schooling.juniorStage': 'Ensino Fundamental',
  'signUp.schooling.middleStage': 'Ensino Médio',
  'signUp.schooling.superiorStage': 'Ensino Superior',
  'Ensino Fundamental': 'Ensino Fundamental',
  'Ensino Médio': 'Ensino Médio',
  'Ensino Superior': 'Ensino Superior',
  'signUp.schooling.year': 'Ano letivo',
  'signUp.schooling.year.stage': '{val} ano',
  'signUp.schooling.year.period': '{val} período',
  'signUp.schooling.type': 'Tipo de escola',
  'signUp.schooling.type.public': 'Pública',
  'signUp.schooling.type.private': 'Privada',
  'signUp.password.strenght': 'Força da senha',
  'signUp.password.strenght.caracters':
    'A senha deve ter no mínimo 8 e no máximo 12 caractéres.',
  'signUp.password.strenght.upper': 'Pelo menos uma letra maiúscula.',
  'signUp.password.strenght.lower': 'Pelo menos uma letra minúscula.',
  'signUp.password.strenght.number': 'Pelo menos um número.',
  'signUp.password.strenght.special': 'Pelo menos um caractér especial (!@#$%ˆ&).',
  'signUp.password.create': 'Criar senha*',
  'signUp.password.new': 'Nova senha',
  'signUp.password.confirm': 'Confirmar a senha',
  'signUp.password.weak': 'Fraco',
  'signUp.password.moderate': 'Moderado',
  'signUp.password.strong': 'Forte',
  'signUp.confirmLeaveWithoutSave':
    'Alguns campos com informações importantes foram editados.',
  'signUp.confirmLeaveWithoutSave.description':
    'Se você não salvar as alterações, os dados editados serão cancelados e iremos considerar os dados preenchidos anteriormente.',
  'signUp.confirm.emailInfo':
    'Você está alterando seu e-mail para {email}. Tem certeza que deseja alterar o seu e-mail?',
  'signUp.account.updateError': 'Ocorreu um erro ao atualizar os dados',
  'signUp.account.updateSuccess': 'Dados alterados com sucesso.',
}
