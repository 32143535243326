import React from 'react'
import PropTypes from 'prop-types'

import { CardWithHeader } from '..'
import { ConfirmationButtonContainer } from './styles'
import {
  BackgroundBlur,
  ContentCard,
  Container as CardContainer,
} from '../../GameCards/styles'

import { ButtonWithRadiusBorderComponent } from '../../Buttons/buttonWithRadiusBorder'
import { injectIntl, FormattedMessage } from 'react-intl'

export const LeaveJobConfirmationCardCp = ({ onLeaveJobClick, onCancelClick, intl }) => {
  return (
    <>
      <CardContainer>
        <CardWithHeader
          isSmall
          data_test="leaveJobConfirmation"
          titleCard={intl.formatMessage({ id: 'game.leaveJob' })}
          backgroundColorHeader="amber">
          <ContentCard data-test="leaveJobConfirmationContent">
            <FormattedMessage id="game.leaveJob.description" />
            <br /> <br />
            <FormattedMessage id="game.leaveJob.question" />
          </ContentCard>
          <ConfirmationButtonContainer>
            <ButtonWithRadiusBorderComponent
              data_test="leaveJobExitButton"
              onClick={onLeaveJobClick}
              text={intl.formatMessage({ id: 'exit' })}
            />
            <ButtonWithRadiusBorderComponent
              data_test="leaveJobCancelButton"
              color="white"
              onClick={onCancelClick}
              text={intl.formatMessage({ id: 'cancel' })}
            />
          </ConfirmationButtonContainer>
        </CardWithHeader>
      </CardContainer>
      <BackgroundBlur />
    </>
  )
}

LeaveJobConfirmationCardCp.propTypes = {
  onLeaveJobClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const LeaveJobConfirmationCard = injectIntl(LeaveJobConfirmationCardCp)
