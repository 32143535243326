export default {
  'game.challenge.middle.questions.1':
    '{name} não se preveniu e contraiu uma DST(Doença sexualmente transmissível). Por isso vai ter que fazer um longo tratamento de saúde e ficar algum período longe da escola.',
  'game.challenge.middle.questions.1.feedback':
    'Loucura, né? Mas prevenção é coisa séria! {name} acabou perdendo alguns XP para dar atenção nesse assunto.',

  'game.challenge.middle.questions.2':
    'A mãe de {name} voltou a trabalhar fora e precisa de sua ajuda para cuidar do irmão mais novo. {name} resolve:',
  'game.challenge.middle.questions.2.option.1':
    'Não ir à aula durante a semana para ficar com o irmão.',
  'game.challenge.middle.questions.2.option.1.feedback':
    '{name} perdeu uma semana de aula e agora terá mais dificuldade para acompanhar o conteúdo!',
  'game.challenge.middle.questions.2.option.2':
    'Buscar ajuda de uma vizinha para não perder as aulas.​',
  'game.challenge.middle.questions.2.option.2.feedback':
    'Graças a sua iniciativa, {name} não perdeu nenhuma aula e ganhou mais XP. Rumo ao Portal Z!​',

  'game.challenge.middle.questions.3':
    '{name} precisa de um celular melhor mas está sem dinheiro. O que fazer?',
  'game.challenge.middle.questions.3.option.1': 'Procura um emprego no comércio.',
  'game.challenge.middle.questions.3.option.1.feedback':
    'Muitos jovens acabam deixando a escola de lado para trabalhar. O desafio de {name} será conciliar os dois para conseguir chegar ao Portal Z.',
  'game.challenge.middle.questions.3.option.2':
    'Organiza uma vaquinha com amigos e familiares.​',
  'game.challenge.middle.questions.3.option.2.feedback':
    'Toda decisão tem um aprendizado, né? Vai demorar mais para comprar o celular, mas {name} poderá focar nos estudos e ganhar muito Xp​',

  'game.challenge.middle.questions.4':
    'Por dificuldades financeiras, a mãe de {name} não conseguirá pagar a mensalidade do curso de inglês. E agora?',
  'game.challenge.middle.questions.4.option.1':
    'Desiste de aprender inglês até melhorar a situação financeira.',
  'game.challenge.middle.questions.4.option.1.feedback':
    'Desistir do curso de inglês, sem tentar uma outra opção, pode atrapalhar os planos no futuro.',
  'game.challenge.middle.questions.4.option.2':
    'Procura um curso gratuito na internet e segue seus estudos de forma autônoma.​',
  'game.challenge.middle.questions.4.option.2.feedback':
    '{name} entende que a adaptação pode ser complicada, mas vai tentar para não parar de estudar!',

  'game.challenge.middle.questions.5':
    'Algumas aulas da escola de {name} se tornaram online. Como {name} não tem computador, precisa acompanhar tudo pelo celular, mas isso se mostra muito cansativo. O que {name} faz?',
  'game.challenge.middle.questions.5.option.1':
    'Não presta muito pouca atenção nas aulas pelo celular.',
  'game.challenge.middle.questions.5.option.1.feedback':
    'Fingir que está estudando só atrasa a vida.',
  'game.challenge.middle.questions.5.option.2':
    'Desliga as notificações e se esforça para  prestar atenção.​',
  'game.challenge.middle.questions.5.option.2.feedback':
    'Parabéns! Graças à dedicação {name} manteve notas boas.',

  'game.challenge.middle.questions.6':
    '{name} se mudou para um bairro que fica muito longe da escola e o tempo de deslocamento dobrou. Mesmo tendo que acordar muito cedo, {name} segue com determinação para concluir o Ensino Médio.',
  'game.challenge.middle.questions.6.option.1': ' ',
}
