export default {
  'landingpage.menu.init': 'Start',
  'landingpage.menu.about': 'About JA',
  'landingpage.choiceYourCharacter': 'Select your character',
  'landingpage.jobXStudies': 'Work x Education',
  'landingpage.acceptChallenge': 'Will you accept the challenge?',
  'landingpage.copyright': 'VUCA Ⓒ game. All rights reserved.',
  'landingpage.welcome': 'Welcome to',
  'landingpage.youGoTo': 'You have arrived to',
  'landingpage.introParagraphs.1':
    '‘Chris, Milena, Brenda and Jonathan have reached a turning point and they need to think about their future.',
  'landingpage.introParagraphs.2':
    'Help this group graduate from school and decide on their future by reaching Portal Z: The portal of opportunities.',
  'landingpage.introParagraphs.3':
    'Face problems and setbacks! Inspire yourself to overcome this challenge.',
  'landingpage.introParagraphs.4': 'Shall we begin?',
  'landingpage.card.1':
    'THE VUCA WORLD: School, work and family all form part of the VUCA (Volatile, Uncertain, Complex and Ambiguous) world. A world full of uncertainties, where everything can change overnight and there is no good or evil. Face real challenges, test your knowledge, and try your luck. VUCA stands for volatile, uncertain, complex and ambiguous.',
  'landingpage.card.2':
    'Everyone has a story, just like the four characters in this game. They live in a world that is hyper-connected and fast, and they feel confused and uncertain about their future. Help one of them become the best version of themselves by using their power of decision, critical thinking and general knowledge. Many challenges await, welcome to VUCA world!',
  'landingpage.card.3':
    'Work now and earn money, or study and have a career for the future? Face the challenges of the labor market and make your own decisions.',
}
