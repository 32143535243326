import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.job': 'Work',
  'game.job.message':
    "This is a Work box. If your character was working, the challenges you must overcome would show up here. If you still don't have the option to start working, then move forward!",

  ...junior,
  ...middle,
  ...superior,
}
