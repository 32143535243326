import general from './general'
import login from './login'
import signUp from './signUp'
import introduction from './introduction'
import menu from './menu'
import account from './account'
import characters from './characters'
import game from './game'
import feedback from './feedback'
import terms from './terms'
import landingpage from './landingPage'

export default {
  ...general,
  ...login,
  ...signUp,
  ...introduction,
  ...menu,
  ...account,
  ...characters,
  ...game,
  ...feedback,
  ...terms,
  ...landingpage,
}
