import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { MenuContainer } from './styles'
import { MobileMenu } from './mobileMenu'
import { DesktopMenu } from './desktopMenu'
import { SignUpCard } from '../../pages/SignUp/SignUpCard'
import { LoginCard } from './components/loginCard'
import { RulesCard } from '../RulesCard'
import { CardHowToPlay } from '../CardHowToPlay'
import { setGame } from '../../services/API/game'
import { get } from '../../services/storage'
import { CardWithFlag } from '../Cards/cardWithFlag'
import { useStore } from '../../store/hooks'
import { types } from '../../store'
import { gamePropTypes } from '../../game'
import { Loading } from '../Loading'

export const MenuCp = ({ G, closeAccount, openRules, intl }) => {
  const feedBackSaveGameSuccess = intl.formatMessage({ id: 'saveGame.success' })
  const feedBackSaveGameError = intl.formatMessage({ id: 'saveGame.error' })

  const [showSavedGameMessage, setShowSavedGameMessage] = useState(false)
  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const [showHowToPlayCard, setShowHowToPlayCard] = useState(false)
  const [isShowSignUpCard, setIsShowSignUpCard] = useState(false)
  const [isShowLoginCard, setIsShowLoginCard] = useState(false)
  const [showRules, setShowRules] = useState(false)
  const [feedBackSaveGame, setFeedbackSaveGame] = useState(feedBackSaveGameSuccess)
  const [showLoading, setShowLoading] = useState(false)
  const { dispatch } = useStore()

  useEffect(() => {
    setShowRules(openRules)
  }, [openRules])

  function openHowToPlayCard() {
    setShowHowToPlayCard(true)
  }

  function closeHowToPlayCard() {
    setShowHowToPlayCard(false)
  }

  function onStartClick() {
    setShowRules(false)
  }

  function handleDisplayMenuMobile(display) {
    setShowMenuMobile(display)
  }

  function closeLoginCard() {
    setIsShowLoginCard(false)
  }

  function showLoginCard() {
    handleDisplayMenuMobile(false)
    setIsShowLoginCard(true)
  }

  function showSignUpCard() {
    closeLoginCard()
    setIsShowSignUpCard(true)
  }

  function closeSignUpCard() {
    setIsShowSignUpCard(false)
  }

  const saveGameClick = async (game) => {
    const payload = {
      user: get('user').id,
      game: game,
    }

    setShowLoading(true)
    const response = await setGame(payload)
    setShowLoading(false)

    if (response.data && response.data.user) {
      setFeedbackSaveGame(feedBackSaveGameSuccess)
      dispatch({ type: types.SET_G, payload: payload.game })
    } else {
      setFeedbackSaveGame(feedBackSaveGameError)
    }

    handleSaveGameClick()
  }

  function handleSaveGameClick() {
    setShowSavedGameMessage(!showSavedGameMessage)
  }

  return (
    <>
      {showLoading && <Loading />}

      {showSavedGameMessage && (
        <CardWithFlag
          isOnlyInfo={true}
          onConfirmClick={handleSaveGameClick}
          title={intl.formatMessage({ id: 'saveGame' })}
          paragraph={feedBackSaveGame}
          confirmText={intl.formatMessage({ id: 'ok' })}
        />
      )}

      {isShowSignUpCard && (
        <SignUpCard isSignUpInsideGame closeSignUpCard={closeSignUpCard} />
      )}

      {isShowLoginCard && (
        <LoginCard showSignUpCard={showSignUpCard} closeLoginCard={closeLoginCard} />
      )}

      {showRules && <RulesCard onStartButtonClick={onStartClick} />}

      {showHowToPlayCard && (
        <CardHowToPlay
          closeCardHowToPlay={closeHowToPlayCard}
          back={closeHowToPlayCard}
        />
      )}

      <MenuContainer data-test="Menu">
        <MobileMenu
          G={G}
          saveGameClick={saveGameClick}
          showLoginCard={showLoginCard}
          onRulesClick={openHowToPlayCard}
          handleDisplayMenuMobile={handleDisplayMenuMobile}
          showMenuMobile={showMenuMobile}
          isShowSignUpCard={isShowSignUpCard}
          isShowLoginCard={isShowLoginCard}
        />

        <DesktopMenu
          G={G}
          saveGameClick={saveGameClick}
          showLoginCard={showLoginCard}
          onRulesClick={openHowToPlayCard}
          closeAccount={closeAccount}
          isShowSignUpCard={isShowSignUpCard}
          isShowLoginCard={isShowLoginCard}
        />
      </MenuContainer>
    </>
  )
}

MenuCp.propTypes = {
  closeAccount: PropTypes.func,
  openRules: PropTypes.bool,
  G: gamePropTypes.G,
  intl: PropTypes.object,
}

MenuCp.defaultProps = {
  openRules: false,
}

export const Menu = injectIntl(MenuCp)
