import styled from 'styled-components'

export const Card = styled.article`
  background-color: #fdb614;
  border: solid 4px;
  padding: 1.25rem;
  max-width: 350px;
`

export const CardHeader = styled.div`
  display: flex;
  gap: 0.6875rem;
  margin: 0 0 1rem;
  align-items: center;

  img {
    margin-right: 10px;
  }
`

export const CardBody = styled.div``

export const CardParagraph = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.06px;
`
