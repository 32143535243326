import styled from 'styled-components'

export const ContainerMenuDesktop = styled.div`
  width: 70%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const ListOptionsMenuDesktop = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0;
  }

  a {
    color: #1d1d1b;
    font-family: MilkyNice;
    font-size: 1.125rem;
    text-decoration: none;
    -webkit-text-decoration: none;

    &:hover {
      &:after {
        content: '';
        height: 4px;
        background: #00763d;
        display: block;
        margin-top: 4px;
        border-radius: 0.25rem;
      }
    }
  }
`
