import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { StarRatingFill, StarRating } from '../../../../assets/img/endGameCards/index.'

import { RatingContainer, Rating, Levels } from './styles'

export const RatingStar = ({
  isLast,
  minText,
  maxText,
  body,
  name,
  sendValueSeletedRating,
}) => {
  const [ratings, setRatings] = useState([
    {
      nameClass: 'rating1',
      value: 1,
      checked: false,
    },
    {
      nameClass: 'rating2',
      value: 2,
      checked: false,
    },
    {
      nameClass: 'rating3',
      value: 3,
      checked: false,
    },
    {
      nameClass: 'rating4',
      value: 4,
      checked: false,
    },
    {
      nameClass: 'rating5',
      value: 5,
      checked: false,
    },
  ])

  function setNewRating(e) {
    const { rating } = e.currentTarget.dataset

    let setValue = true
    const ratingsAux = [...ratings]

    ratingsAux.forEach((item) => {
      item.checked = setValue

      if (item.nameClass === rating) {
        const value = {
          question: name,
          body: body.ptBr,
          options: item.value,
        }
        sendValueSeletedRating(value)
        setValue = false
      }
    })

    setRatings(ratingsAux)
  }

  return (
    <>
      <RatingContainer>
        {ratings.map(({ checked, nameClass }, index) => {
          return (
            <Rating
              data-rating={nameClass}
              key={index}
              checked={checked}
              data-test={'Star' + (index + 1)}
              onClick={setNewRating}>
              <figure>
                {checked ? (
                  <StarRatingFill width="26px" heigh="26px" />
                ) : (
                  <StarRating width="26px" heigh="26px" />
                )}
              </figure>
            </Rating>
          )
        })}
      </RatingContainer>
      <Levels isLast={isLast}>
        <b>{minText}</b>
        <b>{maxText}</b>
      </Levels>
    </>
  )
}

RatingStar.propTypes = {
  sendValueSeletedRating: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  body: PropTypes.object.isRequired,
  minText: PropTypes.string.isRequired,
  maxText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

RatingStar.defaultProps = {
  isLast: false,
}
