import React from 'react'

import { ContainerTextContentMobile, Paragraph } from '../../styles'
import { FormattedMessage } from 'react-intl'

export const ContentTextIntroMobile = () => {
  const introParagraphsMobile = [
    'landingpage.introParagraphs.1',
    'landingpage.introParagraphs.2',
    'landingpage.introParagraphs.3',
    'landingpage.introParagraphs.4',
  ]

  return (
    <ContainerTextContentMobile>
      {introParagraphsMobile.map((item, index) => (
        <Paragraph key={index}>
          <FormattedMessage id={item} />
        </Paragraph>
      ))}
    </ContainerTextContentMobile>
  )
}
