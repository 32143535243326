export default {
  'game.job.middle.questions.1':
    '{name} tenía que hacer un reporte sobre sus actividades laborales y enviarlo por e-mail. {name} dejó para último momento y olvidó registrar algunas tareas. ¡Qué mal!',
  'game.job.middle.questions.1.feedback': ' ',

  'game.job.middle.questions.2':
    '{name} cambió de funciones en su trabajo y tiene dudas en varias actividades, ¿qué debe hacer {name}?',
  'game.job.middle.questions.2.option.1': 'Encarar el desafío y aprender de la práctica.',
  'game.job.middle.questions.2.option.1.feedback':
    'Pedir ayuda a los compañeros de trabajo.',
  'game.job.middle.questions.2.option.2':
    'Aprender de la práctica es muy importante, pero se podrían evitar algunos errores pidiendo ayuda a los compañeros de trabajo. ¡Así, todos aprenden!',
  'game.job.middle.questions.2.option.2.feedback':
    '¡Gran acierto! Conversar y pedir ayuda demuestra que {name} tiene voluntad de aprender.',

  'game.job.middle.questions.3':
    '{name} necesita ir al médico temprano, pero va a retrasarse para llegar a tiempo al trabajo. ¿Qué hace?',
  'game.job.middle.questions.3.option.1': 'Avisa a su jefe con anterioridad.',
  'game.job.middle.questions.3.option.1.feedback':
    'El equipo de {name} consigue organizarse para cubrir su atraso y todo permanece en orden. XP para quien pensó en el equipo.',
  'game.job.middle.questions.3.option.2':
    'Pide un certificado para justificar su atraso.',
  'game.job.middle.questions.3.option.2.feedback':
    '{name} no avisó a su jefe y dejó todo en manos del equipo.',

  'game.job.middle.questions.4':
    '¡A {name} le está yendo muy bien y su rendimiento en el trabajo es óptimo! ¡El gerente admira su esfuerzo y le recompensará con un bono extra este mes!',
  'game.job.middle.questions.4.feedback': ' ',

  'game.job.middle.questions.5':
    'El movimiento comercial en su empresa está bajo y {name} piensa en crear una página online para ayudar en las ventas. Pero {name} no sabe cómo proponer su idea ya que dicha actividad es respondsabilidad de otro sector. ¿Qué hacer?',
  'game.job.middle.questions.5.option.1':
    'Seguir trabajando y no involucrarse en el asunto.',
  'game.job.middle.questions.5.option.1.feedback':
    'Un compañero comenta la idea de crear la página online, la idea fue muy bien aceptada. {name} podría haberse destacado si hubiese confiado en si mismo(a).',
  'game.job.middle.questions.5.option.2': 'Compartir la idea con el equipo.',
  'game.job.middle.questions.5.option.2.feedback':
    'Al equipo directivo le gustó mucho la idea y va a implementarla. ¡Punto positivo para {name}, que se destacó una vez más!',

  'game.job.middle.questions.6':
    'Nuevas personas fueron contratadas y {name} va a ayudarlas durante las primeras semanas. ¡El equipo confia en su trabajo!',
  'game.job.middle.questions.6.feedback': ' ',
}
