import styled from 'styled-components'

export const Label = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  height: 19px;
  margin: 0 25px 4px 25px;
  font-family: MilkyNice;
  font-size: 14px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
`

export const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const ContainerRadioButton = styled.div`
  width: 105px;
`

export const RadioButton = styled.input`
  width: 1.5em;
  height: 1.5em;
  background-color: white;
  margin: 5px 7px;
  border-radius: 50%;
  vertical-align: middle;
  border: 4px solid;
  font-size: 16px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 50%;
      position: relative;
      background-color: ${({ theme }) => theme.colors.orange};
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
    }
  }
`

export const LabelRadioCheckBox = styled.label`
  height: 19px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  top: 2px;
`
