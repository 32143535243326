export default {
  login: 'Login',
  'login.notLogin': 'You are not signed in!',
  'login.save.alert':
    'To save the game, you need to login or register (if you are not logged in yet)',
  'login.save.alert.init': 'To save, you need to start a new game. <br/>',
  'login.progress.not': 'No games in progress',
  'login.title': 'Login',
  'login.doTitle': 'Login',
  'login.invalid': 'Incorrect email or password.',
  'login.play': 'Play',
  'login.signUp': 'Register',
  'login.signUp.description': 'To save the game, login or register.',
  'login.notAccount': 'Don’t have an account?',
  'login.registration': 'Create an account',
  'login.socialMedia': 'Ou jogue usando as Redes Sociais',
  'login.google': 'Log in with Google',
  'login.facebook': 'Log in with Facebook',
  'login.playWithoutLogin': 'Play without logging in',
  'login.playWithoutLogin.description':
    'You may play without loggin in to an account, but you will not be able to save your progress or your game history.',
  'login.playWithoutLogin.description.question': 'Play anyway?',
  'login.forgetPassword': 'Forgot your password?',
  'login.forgetPassword.title': 'I forgot my password',
  'login.forgetPassword.description':
    'We will send you an email with password reset instructions.',
  'login.forgetPassword.description.emailSent':
    'You will receive an email with instructions for resetting your password if your email is registered.',
}
