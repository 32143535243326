import styled, { css } from 'styled-components'

import backgroundCloudsDesktop from '../assets/img/backgrounds/backgroundCloudsDesktop.svg'
import backgroundCloudsMobile from '../assets/img/backgrounds/backgroundCloudsMobile.svg'

export const Background = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    css`
      background-image: url(${backgroundCloudsMobile});
      background-color: ${({ theme }) => theme.colors.veryLightBlue};
      object-fit: contain;
      width: 100%;
      padding: 1rem;
      background-size: 102%;
      background-position-x: -3px;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;

      @media screen and (min-width: 1024px) {
        background-image: url(${backgroundCloudsDesktop});
        height: 100vh;
      }
    `}
`
