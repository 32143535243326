export default {
  'game.inspiration.middle.questions.1':
    '<b>Bisman Deu</b><br/>Emprendedora y ex alumna de JA',
  'game.inspiration.middle.questions.1.option.1':
    '"Un consejo crucial para los jóvenes es crear <b>oportunidades</b> para si mismos. Tendemos a <b>esperar</b> que la oportunidad venga a golpear nuestra puerta, pero eso <b>no suele suceder<b/>. Creo firmemente en intentar crear tantas oportunidades como possible."',

  'game.inspiration.middle.questions.2':
    '<b>Alfred bright</b><br/>Artista, emprendedor y ex alumno de JA',
  'game.inspiration.middle.questions.2.option.1':
    '"Primero, mi mensaje no ha cambiado: <b>maximice su potencial</b>. Explore en su interior y encuentre aquello que le gustaría hacer aunque no le paguen por ello. Siga su <b> pasión</b>, encuéntrese y maximice su portencia. Mantenga una actitud positiva en relación a si mismo. Fue lo que hice. Mantuve una <b>actitud positiva</b> y nunca dejé de ser yo mismo."',

  'game.inspiration.middle.questions.3':
    '<b>Kim kaupe</b><br/>Emprendedora y ex alumna de JA',
  'game.inspiration.middle.questions.3.option.1':
    '"No importa si es un emprendedor independiente o se actúa dentro de una compañía: <b>es siempre sensato pedir ayuda</b> Se sorprenderá al darte cuenta cuánta gente quiere verlo obtener buenos resultados y está lista para <b>tenderle una mano</b>. Por lo tanto, <b>no</b> tenga <b>miedo</b> de pedir ayuda."',

  'game.inspiration.middle.questions.4':
    '<b>Pedro Englert</b><br/>Emprendedor y ex alumno de JA',
  'game.inspiration.middle.questions.4.option.1':
    '"Mi primer consejo a los jóvenes es <b>haz algo que le guste</b>, porque en cuanto <b>le</b> dedique su tiempo y su energía a hacerlo, tendrá éxito. No tenga <b>miedo de fallar</b>, pero no insista en el error y evita volver a repetir el fallo."',

  'game.inspiration.middle.questions.5':
    '<b>Jerome Cowans</b><br/>Emprendedor y ex-alumno JA',
  'game.inspiration.middle.questions.5.option.1':
    '"Yo también alentaría a los jóvenes a <b>pensar</b> sobre la perspectiva del <b>mediano a largo plazo</b> en sus carreras profesionales. Podrá ahora estar empezando una carrera profesional que estará obsoleta en 5 o 10 años, entonces haga algo que <b>le encanté hacer</b>, pero también esté preparado para cambiar en el <b>futuro</b>.',

  'game.inspiration.middle.questions.6': '<b>Amanda Cornélio</b><br/>ex-alumna JA',
  'game.inspiration.middle.questions.6.option.1':
    '"No ponga límites a sus <b>sueños</b>, ¡cuanto más uno se dedica, más próximo está de alcanzarlos! Y <b>pueden suceder</b> cuando uno menos lo espera."',

  'game.inspiration.middle.questions.7':
    '<b>Jim hemak</b><br/>Emprendedor e ex-alumno JA',
  'game.inspiration.middle.questions.7.option.1':
    '"Aprovecha las <b>oportunidades</b> de involucrarse en organizaciones, ya sea en la <b>escuela</b> o en la <b>vida</b>. Eso puede generar un gran <b>aprendizaje</b> para su vida."',

  'game.inspiration.middle.questions.8':
    '<b>Daneil Cheung</b><br/>Emprendedor social y activista',
  'game.inspiration.middle.questions.8.option.1':
    '"Expanda su red, no sólo para recibir consejos y ayuda, sino tambien para disponibilizarse a brindar consejos y ayudar a otros."',

  'game.inspiration.middle.questions.9':
    '<b>Nyi Mas Gianti Bingah Erbiana</b><br/>Jefe de asesoría jurídica de Mitsubishi Indonesia',
  'game.inspiration.middle.questions.9.option.1':
    '"Busque ser flexible. No estoy sugiriendo ser un seguidor de otros o que no tenga su propia identidad. Los jóvenes deben ser capaces de adaptarse a los cambios en el mundo y de consensuar con otras personas."',

  'game.inspiration.middle.questions.10': '<b>JIM HEMAK</b><br/>Ex-alumno JA EUA',
  'game.inspiration.middle.questions.10.option.1':
    '"Aprovecha toda oportunidad de involucrarse con diversas organizaciones en su etapa de estudiante a lo largo de la vida. Estas experiencias pueden significar mucho aprendizaje para cualquier tipo de carrera profesional o negocio que vaya a tener."',
}
