export default {
  logo: 'El juego de las grandes decisiones',
  download: 'Descargar',
  worldVuca: 'Mundo VUCA',
  email: 'Correo electrónico',
  emailPlaceholder: 'Introduzca tu correo electrónico',
  emailAlreadySignedUp: 'Correo electrónico ya registrado',
  password: 'Contraseña',
  remember: 'Recordar',
  return: 'Volver',
  doLogin: 'Hacer login',
  send: 'Enviar',
  ok: 'Okay',
  select: 'Seleccione',
  yes: 'Sí',
  no: 'No',
  edit: 'Cambio',
  play: 'Jugar',
  exit: 'Sal',
  cancel: 'Cancelar',
  loading: 'Cargando',
  newGame: 'Nuevo juego',
  saveGame: 'Guardar partido',
  'saveGame.success': '¡El juego se guardó con éxito!',
  'saveGame.error':
    'Se produjo un error al guardar el juego. Inténtelo de nuevo más tarde.',
  or: 'O',
  choose: 'Elegir',
  jump: 'Saltar',
  start: 'Comenzar',
  next: 'Próximo',
  save: 'Guardar',
  seeMore: 'Ver más',
  backToHome: 'Voltar ao início',
  deleteGame: 'Eliminar juego',
  continue: 'Seguir',
  savedGame: 'Juego guardado',
  and: 'y',
  copy: 'Copiar',
  copied: 'Copiado!',
  turnCounter: 'Esa es la cantidad de veces que puede lanzar los dados',
  notResult: 'No hay resultados',
  shared: 'Compartilhar',
  resetPassword: 'Nueva contraseña',
  portalZ: 'Este es Portal Z.',
  'language.pt': 'Português',
  'language.es': 'Espanhol',
  'language.en': 'Inglês',
  'portalZ.info':
    'Después de graduarse de la educación superior {nombre}, llegará a Portal Z y estará listo para nuevas oportunidades.',
  'deleteGame.info':
    '¿Está seguro de que desea eliminar este partido? <br/><br/>Después de eliminar el partido, tendrás que iniciar un nuevo partido.',
  'saveGame.confirm':
    'Ya tienes un juego guardado y sin terminar en tu cuenta. Si inicia y guarda un juego nuevo, perderá el juego anterior. <br/> <br/> ¿Quieres empezar un nuevo juego?',
}
