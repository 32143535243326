import gameChallenge from './game-challenge/'
import gameInspiration from './game-inspiration/'
import gameJob from './game-job/'
import gameKnowledge from './game-knowledge/'
import gameSkills from './game-skills/'

export default {
  'game.dice.role': 'Role o dado para jogar',
  'game.shared': 'Compartilhar o jogo',
  'game.shared.description':
    'Gostou do Game VUCA? Compartilhe com os seus amigos pelo link:',
  'game.leaveJob': 'Sair do trabalho',
  'game.leaveJob.description':
    'Você só poderá voltar a trabalhar quando cair em uma nova oportunidade na casa de Situação',
  'game.leaveJob.question': 'Tem certeza que quer sair do seu trabalho agora?',
  'game.leaveJob.info':
    'A partir de agora você pode parar de trabalhar, focar nos estudos e pensar no seu futuro.',
  'game.nowWorthHalfThePoints': 'agora valem metade dos pontos',
  'game.startJob.init': 'Começou a trabalhar',
  'game.startJob':
    '{name} entrou no mercado de trabalho e agora vai precisar balancear a sua vida profissional com os estudos.',
  'game.startJob.tooltip': '{name} está trabalhando!',
  'game.theTilesOf': 'As casas de',
  'game.question.empty': ' ',
  'game.success.nextStage.middle': 'Você passou para o Ensino Médio!',
  'game.success.nextStage.middle.message':
    '{name} concluiu o ensino fundamental e deu seu primeiro passo para chegar ao Portal Z. Preparado para continuar essa jornada?',
  'game.success.nextStage.superior': 'Você passou para o Ensino Superior!',
  'game.success.nextStage.superior.message':
    'O Ensino Médio é uma jornada difícil e cheia de altos e baixos e {name} chegou até aqui!  Parabéns! Só mais um pouquinho e vocês chegarão no objetivo final!',
  'game.success.nextStage.superior.finish': 'Você terminou o Ensino Superior!',
  'game.success.nextStage.superior.finish.message':
    '{name} concluiu a última etapa da sua jornada de formação! Parabéns, você foi fundamental para essa conquista e juntos chegaram até aqui!',
  'game.start.newgame': 'Vamos começar uma nova partida?',
  'game.toEnd': 'Fim',
  'game.toThank': 'Agradecemos a sua participação.',
  'game.playerWin': '{name} chegou ao portal Z!',
  'game.playerWin.description':
    'O portal Z irá abrir portas para grandes e novas oportunidades. Você pode seguir sem medo, depois do Portal Z, há outros portais com infinitas possibilidades, sua jornada está apenas começando. Aproveite!',
  'game.player.LoserFist': '{name} não completou<br /> {maxPoints} a tempo.',
  'game.player.LoserSecond': 'Mas não desanime, você pode tentar de novo.',
  'game.player.LoserThird': 'E aí? Aceita o desafio?',
  'game.player.again': 'Jogar Novamente',
  ...gameChallenge,
  ...gameInspiration,
  ...gameJob,
  ...gameKnowledge,
  ...gameSkills,
}
