import { config } from './config'

const effects = {
  employed: {
    onCommitPoints: ({ G, number, categoriesAffected }) => {
      const { category } = G.currentCard
      if (categoriesAffected.includes(category)) {
        G.pointsCredited = number
      }
    },
  },
}

export const applyConditionEffect = (effect, condition, args) => {
  const categoriesAffected =
    config.player.conditions[condition].categoriesAffected[effect] ?? []
  const conditionEffects = effects[condition]
  if (conditionEffects !== undefined && conditionEffects[effect] !== undefined) {
    args = { ...args, categoriesAffected }
    conditionEffects[effect](args)
  }
}
