import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 2rem;
  ${({ quantityColumns }) =>
    quantityColumns &&
    css`
      grid-template-columns: repeat(${quantityColumns}, 1fr);
    `}
`
export const Character = styled.div`
  align-items: center;
  border: solid 5px transparent;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  &:hover,
  &:focus {
    border: solid 5px ${({ theme }) => theme.colors.lemonGreen};
  }

  ${({ selectedCharacter }) =>
    selectedCharacter &&
    css`
      border: solid 5px ${({ theme }) => theme.colors.lemonGreen};
    `}
`
