export default {
  'menu.login': 'Login',
  'menu.account': 'Account',
  'menu.account.my': 'My account',
  'menu.account.edit': 'Change user data',
  'menu.account.delete': 'Delete account',
  'menu.rules': 'Rules',
  'menu.rules.game': 'Game rules',
  'menu.password.edit': 'Change password',
  'menu.terms': 'Terms of use and privacy',
  'menu.exit': 'Exit',
  'menu.exit.game': 'Exit game',
  'menu.sound': 'Sounds',
  'menu.sound.on': 'On',
  'menu.sound.off': 'Off',
}
