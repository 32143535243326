export default {
  terms: 'Termos de Uso e Privacidade',
  'terms.title': 'Termo de Consentimento para Tratamento de Dados Pessoais',
  'terms.description.1':
    'Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o' +
    ' tratamento e compartilhamento de seus dados pessoais de forma anonimizada para finalidade específica, em' +
    ' conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).',

  'terms.description.2': 'TITULAR',
  'terms.description.2.info':
    'Pessoa a quem se referem os dados pessoais que são objeto de tratamento.',

  'terms.description.3':
    'Ao aceitar o presente termo, o Titular consente e concorda que a a empresa Associação Junior Achievement do Brasil,' +
    ' CNPJ nº 04.906.381.001-23, endereço comercial, Avenida Ipiranga 6681 – Prédio96E – sala 210 – Tecnopuc, Bairro' +
    ' Partenon, CEP 90.160-091, Porto Alegre- RS, doravante denominada Controlador, tome decisões referentes ao tratamento' +
    ' de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se' +
    ' referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,' +
    ' processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,' +
    ' comunicação, transferência, difusão ou extração.',

  'terms.description.4': 'Dados Pessoais',
  'terms.description.4.info':
    'O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes dados pessoais do Titular:',

  'terms.description.4.list.1': 'Nome completo',
  'terms.description.4.list.2': 'Data de nascimento.',
  'terms.description.4.list.3': 'Número e imagem da Carteira de Identidade (RG).',
  'terms.description.4.list.4': 'Número e imagem do Cadastro de Pessoas Físicas (CPF).',
  'terms.description.4.list.5':
    'Número e imagem da Carteira Nacional de Habilitação (CNH).',
  'terms.description.4.list.6': 'Estado civil.',
  'terms.description.4.list.7': 'Nível de instrução ou escolaridade.',
  'terms.description.4.list.8': 'Endereço completo.',
  'terms.description.4.list.9': 'Números de telefone, WhatsApp e endereços de e-mail.',
  'terms.description.4.list.10':
    'Nome de usuário e senha específicos para uso dos serviços do Controlador.',
  'terms.description.4.list.11':
    'Comunicação, verbal e escrita, mantida entre o Titular e o Controlador.',

  'terms.description.5': 'Finalidades do Tratamento dos Dados',
  'terms.description.5.info':
    'Os dados pessoais listados neste termo, são compartilhados com terceiros parceiros de forma anonimizada para' +
    ' que estes possam acompanhar as atividades da Junior Achievement dentro do seu propósito social a promoção da' +
    ' educação empreendedora e tem as seguintes finalidades:',
  'terms.description.5.list.1':
    'Possibilitar que o Controlador envie ou forneça ao Titular seus produtos e serviços, de forma gratuita.,',
  'terms.description.5.list.2':
    'Possibilitar que o Controlador envie comunicados, mensagens eletrônicas via WhatsApp e e-mail para fins de relacionamento institucional.',
  'terms.description.5.list.3':
    'Possibilitar que o Controlador identifique e entre em contato com o Titular para fins de relacionamento comercial.',
  'terms.description.5.list.4':
    'Possibilitar que o Controlador estruture, teste, promova e faça propaganda de produtos e serviços, personalizados ou não ao perfil do Titular.',
  'terms.description.5.list.5':
    'Possibilitar que o Controlador compartilhe desses dados com os terceiros parceiros de forma anonimizada para que estes terceiros possam acompanhar as atividades realizadas pelo Controlador.',

  'terms.description.6': 'Compartilhamento de Dados',
  'terms.description.6.info':
    'O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de' +
    ' tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os' +
    ' princípios e as garantias estabelecidas pela Lei nº 13.709.',

  'terms.description.7': 'Segurança dos Dados',
  'terms.description.7.info':
    'O Controlador responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas' +
    ' aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas' +
    ' de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.' +
    '<br />' +
    'Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade' +
    ' Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar' +
    ' risco ou dano relevante ao Titular.',

  'terms.description.8': 'Término do Tratamento dos Dados',
  'terms.description.8.info':
    'O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período em que eles' +
    ' forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anônimos, sem' +
    ' possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.' +
    '<br />' +
    'O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que' +
    ' sejam eliminados os dados pessoais não anônimos do Titular. O Titular fica ciente de que poderá ser' +
    ' inviável ao Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da' +
    ' eliminação dos dados pessoais.',

  'terms.description.9': 'Direitos do Titular',
  'terms.description.9.info':
    'O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer' +
    ' momento e mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados;' +
    ' III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou' +
    ' eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei' +
    ' nº 13.709; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição' +
    ' expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão' +
    ' controlador; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante' +
    ' requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos' +
    ' comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular,' +
    ' exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas' +
    ' e privadas com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a' +
    ' possibilidade de não fornecer consentimento e sobre as consequências da negativa; IX - revogação do' +
    ' consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.',

  'terms.description.10': 'Direito de Revogação do Consentimento',
  'terms.description.10.info':
    'Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via' +
    'e-mail ou correspondência ao Controlador.',
}
