export default {
  'game.knowledge.superior.questions.1':
    'Which of the following elements is an example of a metaphor?',
  'game.knowledge.superior.questions.1.option.1': 'Her life is a fairy tale.',
  'game.knowledge.superior.questions.1.option.1.feedback':
    'Good! Metaphors can be a powerful communication resource.',
  'game.knowledge.superior.questions.1.option.2': 'This flower looks like the sun.​',
  'game.knowledge.superior.questions.1.option.2.feedback':
    'A metaphor is a way of speaking that creates figurative meanings through comparisons, such as the ‘fairy tale’. Knowing how to use metaphors is more useful than you think!',

  'game.knowledge.superior.questions.2': 'What is plagiarism?',
  'game.knowledge.superior.questions.2.option.1':
    'Assuming other people’s texts or ideas without giving them due credit.',
  'game.knowledge.superior.questions.2.option.1.feedback':
    'Well done! And do not forget: plagiarism is a crime!',
  'game.knowledge.superior.questions.2.option.2':
    'Publishing someone’s picture without their permission.',
  'game.knowledge.superior.questions.2.option.2.feedback':
    'Plagiarism is the act of using other people’s texts or ideas without giving them due credit. And do not forget: plagiarism is a crime!',

  'game.knowledge.superior.questions.3':
    'Why is it so important to analyze data and information in the current world?',
  'game.knowledge.superior.questions.3.option.1':
    'Because selecting and analyzing available information helps you make decisions more efficiently.',
  'game.knowledge.superior.questions.3.option.1.feedback':
    'Well done! You already know that making decisions based just on intuition is not the most efficient way to do so. That is important for life and work!',
  'game.knowledge.superior.questions.3.option.2':
    'Because data analysis is useful for programming and developing websites.',
  'game.knowledge.superior.questions.3.option.2.feedback':
    'Data analysis is a technical term used for describing the act of selecting and analyzing available information.',

  'game.knowledge.superior.questions.4':
    'Which of the following items is not an extension of an image format?',
  'game.knowledge.superior.questions.4.option.1': '.png',
  'game.knowledge.superior.questions.4.option.1.feedback':
    'These extensions in the files’ names can seem weird, but they are part of the digital world. HTML is not a type of image.',
  'game.knowledge.superior.questions.4.option.2': '.html​',
  'game.knowledge.superior.questions.4.option.2.feedback':
    'Congratulations! {name} knows that the world is becoming more digital and {name} does not want to be left out!',

  'game.knowledge.superior.questions.5': 'What is virtual reality?',
  'game.knowledge.superior.questions.5.option.1':
    'Robots and computers controlled by machines.',
  'game.knowledge.superior.questions.5.option.1.feedback':
    'Wrong answer. Virtual reality is an environment created by a computer, in which the user can interact just like in the real world.',
  'game.knowledge.superior.questions.5.option.2':
    'An artificial and three-dimensional world created by a computer.',
  'game.knowledge.superior.questions.5.option.2.feedback':
    'Correct! Virtual reality is an environment created by a computer, in which the user can interact just like in the real world.',

  'game.knowledge.superior.questions.6':
    'Artificial intelligence is a branch of tech science that focuses on:',
  'game.knowledge.superior.questions.6.option.1':
    'Building computers and machines that can simulate intelligent behavior.',
  'game.knowledge.superior.questions.6.option.1.feedback':
    'Correct! Artificial intelligence (AI) makes it possible for machines to learn through experiences and run tasks as if they were humans.',
  'game.knowledge.superior.questions.6.option.2':
    'Democratization of knowledge about IT.',
  'game.knowledge.superior.questions.6.option.2.feedback':
    'Wrong answer! Artificial intelligence is a branch of tech science that develops machines capable of simulating intelligent behavior.',

  'game.knowledge.superior.questions.7': 'The word NETWORKING is used to describe:',
  'game.knowledge.superior.questions.7.option.1': "A person's digital skills.",
  'game.knowledge.superior.questions.7.option.1.feedback':
    'Wrong answer. Networking is the process of interacting with other people to amplify relationship networks.',
  'game.knowledge.superior.questions.7.option.2':
    'It is the process of interacting with others to exchange information and develop personal or professional contacts.',
  'game.knowledge.superior.questions.7.option.2.feedback': 'Correct!',
}
