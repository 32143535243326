export default {
  'game.skills.superior.questions.1':
    'O colega de {name} está sendo vítima de preconceito no campus onde estuda. {name} resolve:',
  'game.skills.superior.questions.1.option.1':
    'Aguardar seus colegas fazerem um movimento para poder se engajar.',
  'game.skills.superior.questions.1.option.1.feedback':
    '{name} preferiu ficar em silêncio diante da situação e perdeu a chance de ser um agente da mudança, valorizando a diversidade.',
  'game.skills.superior.questions.1.option.2':
    'Realizar um debate sobre o tema com a turma.',
  'game.skills.superior.questions.1.option.2.feedback':
    'Parabéns pela iniciativa! {name} fez sua parte para diminuir o preconceito e valorizar a diversidade no campus.​',

  'game.skills.superior.questions.2':
    '{name} ganhou uma bolsa de pesquisa na universidade! A experiência foi incrível e {name} aprendeu na prática como a análise de dados pode ajudar a tomar decisões de forma mais inteligente.',
  'game.skills.superior.questions.2.feedback':
    'Boa, {name}! Esta habilidade vale ouro no Portal Z!',

  'game.skills.superior.questions.3':
    '{name} perdeu o controle durante uma live e acabou maltratando o apresentador nos comentários. Se quiser chegar ao Portal Z, {name} vai ter que aprender a lidar com suas emoções e a respeitar ideias diferentes.',
  'game.skills.superior.questions.3.feedback': ' ',

  'game.skills.superior.questions.4':
    '{name} criou uma proposta de startup para o pitch da Universidade. Os jurados acharam a ideia genial, mas apontaram algumas falhas no modelo de negócio. {name} resolve:',
  'game.skills.superior.questions.4.option.1':
    'Procurar um parceiro com conhecimento em finanças para melhorar a proposta.',
  'game.skills.superior.questions.4.option.1.feedback':
    'Boa! {name} analisou a situação e identificou o que faltava para melhorar sua proposta. Parabéns pela persistência!',
  'game.skills.superior.questions.4.option.2': 'Desistir da ideia e partir para outra.',
  'game.skills.superior.questions.4.option.2.feedback':
    'Poxa! A ideia de {name} era muito boa e perdeu a oportunidade de melhorá-la. Persistência e capacidade de aprender são peças-chave para chegar ao Portal Z.',

  'game.skills.superior.questions.5':
    '{name} tem uma entrevista de emprego e quer muito conseguir a vaga, mas não preenche todos os requisitos. O que {name} faz?',
  'game.skills.superior.questions.5.option.1':
    'Dá uma exagerada no currículo para preencher os requisitos.',
  'game.skills.superior.questions.5.option.1.feedback':
    'Opa! {name} se enrolou com as mentiras e acabou perdendo a vaga.',
  'game.skills.superior.questions.5.option.2':
    'Destaca suas habilidades, experiências e demonstra motivação para aprender coisas novas.',
  'game.skills.superior.questions.5.option.2.feedback':
    '{name} conseguiu se sair bem na entrevista! Agora é torcer para o resultado ser positivo.',

  'game.skills.superior.questions.6':
    '{name} acaba de descobrir que o pré-vestibular gratuito está parado por falta de voluntários. Qual sua reação?',
  'game.skills.superior.questions.6.option.1':
    'Se preocupa com os jovens que não têm outra alternativa.',
  'game.skills.superior.questions.6.option.1.feedback':
    'Empatia é importante, mas {name} poderia ter partido para ação e ajudado a mudar esta história.',
  'game.skills.superior.questions.6.option.2':
    'Se oferece para participar e faz uma campanha entre os amigos para aumentar o time de voluntários.',
  'game.skills.superior.questions.6.option.2.feedback':
    'Boa! {name} teve empatia e iniciativa, e ainda ganhou mais experiência! ',

  'game.skills.superior.questions.7':
    'O semestre está sendo difícil. {name} não conseguiu terminar os trabalhos, perdeu uma ótima oportunidade de estágio e seu projeto recebeu duras críticas do professor.  Como {name} reage?',
  'game.skills.superior.questions.7.option.1':
    'Começa a duvidar de si, mas prefere não falar com ninguém.',
  'game.skills.superior.questions.7.option.1.feedback':
    'Quem nunca teve dúvidas ou perdeu a confiança em si mesmo?! Não existe uma receita única para enfrentar esta situação. Mas ignorar o  problema certamente não ajuda!',
  'game.skills.superior.questions.7.option.2':
    'Analisa seus erros e acertos, e pede conselhos a amigos e professores.',
  'game.skills.superior.questions.7.option.2.feedback':
    'Quem nunca teve dúvidas ou perdeu a confiança em si mesmo?! Não existe uma receita única para enfrentar esta situação. Mas {name} decidiu encarar de frente e pedir ajuda, o que é um ótimo começo! ',

  'game.skills.superior.questions.8':
    '{name} está com dúvidas sobre a carreira, e resolveu conversar com um profissional da área que aconselhou {name} a pensar nos seus objetivos e fazer uma lista de CONHECIMENTOS e HABILIDADES importantes para conquistá-los. ',
  'game.skills.superior.questions.8.option.1':
    'Pontos para {name} que está se planejando para chegar ao Portal Z!',
}
