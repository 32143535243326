import PropTypes from 'prop-types'

const responseProp = PropTypes.shape({
  response_text: PropTypes.string,
  point: PropTypes.number,
  feedback: PropTypes.string,
})

const cardProp = PropTypes.shape({
  level: PropTypes.string,
  question: PropTypes.string,
  key: PropTypes.string,
  type_question: PropTypes.string,
  options: PropTypes.arrayOf(responseProp),
  has_interaction: PropTypes.bool,
})

const deckProp = PropTypes.shape({
  challenge: PropTypes.arrayOf(cardProp),
  knowledge: PropTypes.arrayOf(cardProp),
  inspiration: PropTypes.arrayOf(cardProp),
  job: PropTypes.arrayOf(cardProp),
  skills: PropTypes.arrayOf(cardProp),
})

export const propTypesProgressLevels = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}).isRequired

export const GamePropTypes = {
  ctx: PropTypes.shape({
    activePlayer: PropTypes.number,
    currentPlayer: PropTypes.string,
    numMoves: PropTypes.number,
    numPlayers: PropTypes.number,
    phase: PropTypes.string,
    playOrder: PropTypes.arrayOf(PropTypes.string),
    playOrderPos: PropTypes.number,
    turn: PropTypes.number,
  }),
  G: PropTypes.shape({
    boardTiles: PropTypes.array.isRequired,
    cards: PropTypes.shape({
      junior: PropTypes.shape(deckProp),
      middle: PropTypes.shape(deckProp),
      superior: PropTypes.shape(deckProp),
      generic: PropTypes.shape(deckProp),
    }),
    progress: PropTypes.number.isRequired,
    progressLevels: PropTypes.shape({
      junior: propTypesProgressLevels,
      middle: propTypesProgressLevels,
      superior: propTypesProgressLevels,
    }).isRequired,
    gameStage: PropTypes.number.isRequired,
    pawnTilePosition: PropTypes.number.isRequired,
    lastDiceRoll: PropTypes.number.isRequired,
    conditions: PropTypes.arrayOf(PropTypes.number),
    lastPath: PropTypes.arrayOf(PropTypes.number),
    animatePawn: PropTypes.bool.isRequired,
    turnCounter: PropTypes.number.isRequired,
    maxTurns: PropTypes.number.isRequired,
    character: PropTypes.shape({
      name: PropTypes.string,
    }),
    currentCard: PropTypes.object,
    discardPile: PropTypes.arrayOf(PropTypes.number),
  }),
}
