export default {
  'game.job.superior.questions.1':
    '{name} recebeu a tarefa de criar uma apresentação para o lançamento do novo produto. Usou a criatividade e surpreendeu a empresa! Ainda bem tinha as habilidades para fazer a tarefa, né?',
  'game.job.superior.questions.1.feedback': '{name} recebe e vamos para a próxima carta!',

  'game.job.superior.questions.2':
    '{name} está no estágio e recebe uma proposta fora da sua área de interesse para trabalhar em horário integral. O que {name} vai fazer?',
  'game.job.superior.questions.2.option.1':
    'Continuar no estágio, ganhando apenas a bolsa auxílio',
  'game.job.superior.questions.2.option.1.feedback':
    'Difícil essa, né? {name} preferiu investir na sua área de interesse e ter mais tempo para o estudo.',
  'game.job.superior.questions.2.option.2':
    'Aceitar o emprego, ganhando salário integral.',
  'game.job.superior.questions.2.option.2.feedback':
    'Difícil essa, né? Apesar de ganhar bem, o trabalho diminuiu o tempo de estudo.',

  'game.job.superior.questions.3':
    '{name} precisa melhorar sua renda para morar mais perto da faculdade. O que fazer?',
  'game.job.superior.questions.3.option.1':
    'Conversar com o seu chefe sobre a possibilidade de um aumento',
  'game.job.superior.questions.3.option.1.feedback':
    '{name} tentou negociar, mas infelizmente o chefe não tinha orçamento. {name} aprendeu sobre negociação e resiliência.',
  'game.job.superior.questions.3.option.2': 'Vender sanduíches para ter uma renda extra.',
  'game.job.superior.questions.3.option.2.feedback':
    'Essa é uma boa ideia para ter uma renda extra, mas {name} terá que se esforçar para não prejudicar os estudos.',

  'game.job.superior.questions.4':
    'A empresa que {name} trabalha fez um sorteio e {name] ganhou uma bolsa para um curso de especialização!',
  'game.job.superior.questions.4.feedback': '{name} recebe e vamos para a próxima carta!',

  'game.job.superior.questions.5':
    '{name} está em semana de provas na faculdade e também está com muitas demandas no trabalho. Essa semana {name} não conseguiu dormir, nem se alimentar direito.',
  'game.job.superior.questions.5.feedback': '{name} recebe e vamos para a próxima carta!',

  'game.job.superior.questions.6':
    'Um cliente internacional irá visitar a empresa neste mês e {name} recebeu o convite para acompanhar a reunião. Porém, não se sente confortável para participar em outro idioma. O que fazer?',
  'game.job.superior.questions.6.option.1': 'Participar da reunião.',
  'game.job.superior.questions.6.option.1.feedback':
    '{name} conversou com sua liderança e participou como ouvinte da reunião. Mesmo não compreendendo 100% do assunto, essa experiência fez {name} valorizar mais as aulas de idiomas estrangeiros.',
  'game.job.superior.questions.6.option.2':
    'Faltar no dia da reunião para não passar vexame.',
  'game.job.superior.questions.6.option.2.feedback':
    'Todos sentiram falta de {name} na reunião e sua ausência injustificada fez com que a liderança perdesse confiança no seu trabalho. ',
}
