import styled, { css } from 'styled-components'

export const HeaderCard = styled.div`
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  border-bottom: 0.2rem solid;
  border-bottom-color: ${({ theme }) => theme.colors.black};
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ withoutButton }) =>
    !withoutButton &&
    css`
      padding: 20px 30px;
    `}

  h1 {
    font-family: MilkyNice;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.08px;
    color: ${({ fontColor, theme }) => theme.colors[fontColor]};
  }
`
