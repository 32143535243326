import styled from 'styled-components'

export const MyCheckBox = styled.div`
  border: 3px solid;
  border-radius: 50vh;
  cursor: pointer;
  padding: 6px;
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  overflow: hidden;
`
export const CheckedCheckBox = styled.span`
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50vh;
  border: 3px solid white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
