import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { isMobileSize } from '../../common/utils'

import {
  Container,
  ContainerHasntSavedGame,
  ButtonContainer,
  ContainerHasSavedGame,
  CardSavedGame,
  ButtonSavedGameContainer,
  ContainerButtonNewGame,
  Body,
} from './styles'

import {
  CardWithHeader,
  CircularButtonComponent,
  Menu,
  ProgressBar,
  Logo,
} from '../../components'
import { useHistory } from 'react-router-dom'
import { ButtonWithRadiusBorderComponent } from '../../components/Buttons/buttonWithRadiusBorder/'
import { useStore } from '../../store/hooks'
import { IcPlay } from '../../assets/img/buttonIcons'

import { getGame } from '../../services/API/game'
import { types } from '../../store'
import { ConfirmInfoCard } from '../../components/Cards/ConfirmInfoCard'
import { clear, set } from '../../services/storage'
import { Loading } from '../../components/Loading'
import { reportContinueGameProgress } from '../../services/analytics'
import { injectIntl } from 'react-intl'

const HomeCp = ({ intl }) => {
  const [showLoading, setShowLoading] = useState(false)
  const [showConfirmNewGameCard, setShowConfirmNewGameCard] = useState(false)
  const [showConfirmDeleteGame, setShowConfirmDeleteGame] = useState(false)
  const isMobile = useMemo(isMobileSize, [])
  const history = useHistory()

  const { store, dispatch } = useStore()
  const { G, auth_token } = store

  const [hasSavedGame, setHasSavedGame] = useState(false)

  useEffect(checkSavedGame, [G])
  function checkSavedGame() {
    const hasSavedProgress = G.progress < 1000 && G.turnCounter < 45
    setHasSavedGame(hasSavedProgress)
  }

  useEffect(getSavedGame, [auth_token])
  function getSavedGame() {
    const SavedGame = async () => {
      setShowLoading(true)
      const response = await getGame(store)
      setShowLoading(false)
      if (response && response.data && response.data.game) {
        dispatch({ type: types.SET_G, payload: response.data.game })
      }
    }

    SavedGame()
  }

  function goToGame() {
    if (hasSavedGame) {
      handleNewGameClick()
    } else {
      clear('gameState')
      setHasSavedGame(false)
    }

    history.push('/start')
  }

  function deleteSavedGame() {
    setHasSavedGame(false)
    setShowConfirmDeleteGame(!showConfirmDeleteGame)
  }

  function handleNewGameClick() {
    setShowConfirmNewGameCard(!showConfirmNewGameCard)
  }

  function handleDeleteGameClick() {
    setShowConfirmDeleteGame(!showConfirmDeleteGame)
  }

  function continueGame() {
    reportContinueGameProgress()
    set('gameState', { G: G })
    history.push('/game')
  }

  return (
    <>
      {showLoading && <Loading />}
      <Container data-test="HomeContainer" hasSavedGame={hasSavedGame}>
        <Menu />
        {!hasSavedGame && (
          <ContainerHasntSavedGame>
            {isMobile ? (
              <Logo width="236px" height="70px" hiddenText />
            ) : (
              <Logo width="453px" height="149px" />
            )}
            <ButtonContainer>
              <ButtonWithRadiusBorderComponent
                onClick={goToGame}
                data_test="buttonHomeNewGame"
                text={intl.formatMessage({ id: 'newGame' })}
              />
            </ButtonContainer>
          </ContainerHasntSavedGame>
        )}

        {hasSavedGame && G.maxTurns && (
          <ContainerHasSavedGame>
            <figure>
              {isMobile ? (
                <Logo width="236px" height="70px" hiddenText />
              ) : (
                <Logo width="600px" height="180px" />
              )}
            </figure>
            <CardSavedGame>
              <CardWithHeader
                backgroundColorHeader="pistachioGreen"
                titleCard="savedGame"
                data_test="deleteAccountCard">
                <Body>
                  <ProgressBar isHome G={G} />
                  <ButtonSavedGameContainer>
                    <CircularButtonComponent
                      data_test="menuButtonMobile"
                      onClick={continueGame}>
                      <IcPlay width="16" height="16" />
                    </CircularButtonComponent>
                    {/* <CircularButtonComponent
                      color="white"
                      data_test="menuButtonMobile"
                      onClick={handleDeleteGameClick}>
                      <IcDelete width="16" height="16" />
                    </CircularButtonComponent> */}
                  </ButtonSavedGameContainer>
                </Body>
              </CardWithHeader>
              <ContainerButtonNewGame>
                <ButtonWithRadiusBorderComponent
                  onClick={handleNewGameClick}
                  color="white"
                  data_test="startNewGameButton"
                  text={intl.formatMessage({ id: 'newGame' })}
                />
              </ContainerButtonNewGame>
            </CardSavedGame>
          </ContainerHasSavedGame>
        )}
      </Container>
      {showConfirmNewGameCard && (
        <ConfirmInfoCard
          onConfirmClick={goToGame}
          onCancelClick={handleNewGameClick}
          title={intl.formatMessage({ id: 'savedGame' })}
          paragraph={intl.formatMessage({ id: 'saveGame.confirm' })}
          confirmText={intl.formatMessage({ id: 'newGame' })}
          cancelText={intl.formatMessage({ id: 'cancel' })}
        />
      )}

      {showConfirmDeleteGame && (
        <ConfirmInfoCard
          onConfirmClick={deleteSavedGame}
          onCancelClick={handleDeleteGameClick}
          title={intl.formatMessage({ id: 'deleteGame' })}
          paragraph={intl.formatMessage({ id: 'deleteGame.info' })}
          confirmText={intl.formatMessage({ id: 'delete' })}
          cancelText={intl.formatMessage({ id: 'cancel' })}
        />
      )}
    </>
  )
}

HomeCp.propTypes = {
  intl: PropTypes.object,
}

export const Home = injectIntl(HomeCp)
