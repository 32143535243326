export default {
  'game.skills.junior.questions.1':
    'The teacher assigned a team project and {name} will have to work with some classmates that {name} does not know. {name} decides to:​​',
  'game.skills.junior.questions.1.option.1':
    'Complete the assignment individually and then send it to the group.',
  'game.skills.junior.questions.1.option.1.feedback':
    '{name} lost a great chance to learn about teamwork. This skill is essential for life and work, but you cannot learn it from books, only through practice.',
  'game.skills.junior.questions.1.option.2': 'Set a meeting to organize the work.',
  'game.skills.junior.questions.1.option.2.feedback':
    'Good initiative. {name} learned a lot about collaboration and organization. Despite some initial difficulties, they did very well on the assignment and {name} made some new friends.​',

  'game.skills.junior.questions.2':
    'The school’s Science Fair is taking place next month. {name} decides...',
  'game.skills.junior.questions.2.option.1': 'To work in the organization.',
  'game.skills.junior.questions.2.option.1.feedback':
    '{name} has shown initiative and effort when organizing the fair. The list of things {name} has learned is endless. The fair was a huge success and a lifetime experience!',
  'game.skills.junior.questions.2.option.2': 'Visit the fair with friends.',
  'game.skills.junior.questions.2.option.2.feedback':
    '{name} did not choose to participate in the organization of the fair, but benefited from the experience and learned more about the world.',

  'game.skills.junior.questions.3':
    '{name} finished a Math test feeling pretty confident, but when the teacher handed out the results, they were not as good as {name} thought. How do you react?',
  'game.skills.junior.questions.3.option.1':
    '{name} feels sad, tears the exam and forgets about it.',
  'game.skills.junior.questions.3.option.1.feedback':
    '{name} has lost a good opportunity to learn from mistakes. If {name} had checked the answers, {name} would have realized that the problem lied in the understanding of the questions. That is so sad!',
  'game.skills.junior.questions.3.option.2':
    '{name} feels sad, but {name} seeks out the teacher to discuss the exam.',
  'game.skills.junior.questions.3.option.2.feedback':
    '​After discussing the exam with the teacher, {name} understood that {name} did not read the exam questions correctly. {name} has learned from their mistakes and knows what needs to improve for next time.',

  'game.skills.junior.questions.4':
    'The family pet has destroyed the diorama that {name} spent a month preparing for the Innovation Fair at school. The presentation is in 24 hours, what can {name} do?',
  'game.skills.junior.questions.4.option.1':
    "Cancel {name}'s presentation and prepare for next year’s fair.",
  'game.skills.junior.questions.4.option.1.feedback':
    'That is too bad! It was a perfect idea and {name} had an innovative way of presenting it. Flexibility and creativity are highly valuable in the world of VUCA!',
  'game.skills.junior.questions.4.option.2':
    'Adapt the presentation using other resources.',
  'game.skills.junior.questions.4.option.2.feedback':
    '{name} used a beautiful story design to present the idea. Flexibility and creativity to solve problems will help {name} reach Portal Z!',

  'game.skills.junior.questions.5':
    '{name} was elected as class delegate, but {name} is struggling to keep up with their duties. {name} decides to:',
  'game.skills.junior.questions.5.option.1':
    'Take a deep breath and hope the year ends soon.',
  'game.skills.junior.questions.5.option.1.feedback':
    'Time management is one of the biggest challenges of the world of VUCA. There are many strategies to accomplish this, such as prioritizing urgent tasks or asking for help.',
  'game.skills.junior.questions.5.option.2':
    'Identify priorities and set deadlines for every task.',
  'game.skills.junior.questions.5.option.2.feedback':
    'Time management is one of the biggest challenges of the world of VUCA. There are many strategies to accomplish this, such as prioritizing urgent tasks or asking for help.',

  'game.skills.junior.questions.6':
    '{name} has to write an essay for school, but {name} has no clue where to start. {name} decides to:',
  'game.skills.junior.questions.6.option.1':
    'Do an Internet research and copy a part of some blog’s text.',
  'game.skills.junior.questions.6.option.1.feedback':
    'Oops! Copying other people’s ideas without crediting them is plagiarism. Plagiarism is a crime!',
  'game.skills.junior.questions.6.option.2': 'Do an Internet research to get inspired.',
  'game.skills.junior.questions.6.option.2.feedback':
    'Well done! {name} studied the material and found enough references to create their own story',
}
