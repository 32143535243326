export default {
  'introduction.title': 'Você chegou ao mundo VUCA',
  'introduction.firstDescription':
    '<span>Cris</span>, <span>Milena</span>, <span>Brenda</span> e <span>Jonatan</span> estão passando por um período decisivo: <span>o Ensino Fundamental</span> vai começar e os novos desafios do <span>Mundo VUCA</span> também. Um lugar imprevisível e cheio de surpresas.',
  'introduction.secondDescription':
    'Ajude essa galera a passar pelas três etapas: <span>Ensino Fundamental, Médio e Superior</span> para conseguir 1000 pontos de experiência e chegar ao <span>Portal Z: o portal das oportunidades</span>, onde poderão conseguir a carreira dos sonhos!',
  'introduction.thirdDescription':
    'Mas não pense que é fácil. <span>A qualquer momento você pode ter um imprevisto em casa, precisar contar com a sorte e também com os seus conhecimentos</span> para conseguir mais pontos de experiência. Vamos lá?',
  'introduction.init': 'Como jogar',
  'introduction.init.description':
    'Jogue o dado para começar. Você irá andar o número de casas que tirar e poderá cair em uma das cinco casas: <span>Trabalho, Quiz, Habilidades, Situação e Inspiração.</span>',
}
