import styled, { css } from 'styled-components'

const removeOutline = '&:focus {outline: none; }'
const passwordValidation = [
  'newPassword',
  'confirmPassword',
  'emailLogin',
  'passwordLogin',
]

function returnPasswordBorderColor(theme, isValid) {
  const { lemonGreen, reddishOrange } = theme.colors
  if (typeof isValid === 'boolean') {
    return isValid ? lemonGreen : reddishOrange
  }
}

export const Label = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  height: 19px;
  margin: 0 25px 4px 25px;
  font-family: MilkyNice;
  font-size: 14px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
`

export const InputText = styled.input`
  width: 100%;
  height: 38px;
  border-radius: 19px;
  padding-left: 20px;
  font-family: Montserrat;
  border: solid 4px ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueyGrey};
  }

  &:focus,
  &:hover {
    border: solid 4px ${({ theme }) => theme.colors.azure};
  }

  ${removeOutline}

  ${({ borderColor }) =>
    borderColor &&
    `
    border: solid 4px ${borderColor};
  `}

  ${({ matchPassword, name, theme }) =>
    passwordValidation.includes(name) &&
    css`
      border: solid 4px ${returnPasswordBorderColor(theme, matchPassword)};
    `}

  ${({ showRequiredBorder, theme }) =>
    showRequiredBorder &&
    css`
      border: solid 4px ${theme.colors.reddishOrange};
    `}

  ${({ type, value }) =>
    type === 'password' &&
    value &&
    css`
      -webkit-text-security: square;
      letter-spacing: 15px;
    `}
`

export const Info = styled.div`
  font-family: Montserrat;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.67;
  letter-spacing: -0.05px;

  b {
    color: ${({ theme }) => theme.colors.reddishOrange};
    font-weight: bold;
    position: absolute;
    width: 100%;
  }
`

export const DisplayFlex = styled.div`
  display: flex;
  gap: ${({ gap }) => gap};
`

export const EyeClose = styled.figure`
  position: relative;
  padding-top: 10px !important;
  margin-left: -40px;
  cursor: pointer;
`

export const EyeOpen = styled.figure`
  position: relative;
  padding-top: 6px !important;
  margin-left: -40px;
  cursor: pointer;
`
