export default {
  'game.inspiration.junior.questions.1':
    '<b> Eleonora Arifova </b><br/> Entrepreneur and former JA alumna',
  'game.inspiration.junior.questions.1.option.1':
    '‘<b>Entrepreneurship</b> is applicable, no matter the path you take in life. The ability to identify <b>opportunities</b> and put ideas into practice can benefit anyone. Thus, <b>unlock your talent</b> for entrepreneurship and follow your dreams.’',

  'game.inspiration.junior.questions.2':
    '<b>Bill Herp</b><br/>Former JA alumnus and Linear Air founder',
  'game.inspiration.junior.questions.2.option.1':
    '‘You <b>have to study</b>, definitively. You cannot expect to become an entrepreneur or get a good job <b>without training</b>. That does not necessarily mean going to college, it may be some <b>technical</b> training or <b>specialization</b>. What is important is that it must help you become a specialist in one or multiple fields.’',

  'game.inspiration.junior.questions.3':
    '<b>Donna Shalala</b><br/> Former JA alumna and manager',
  'game.inspiration.junior.questions.3.option.1':
    '‘My philosophy for education is this: students should not train for their <b>first job</b>, but for their third, fourth or fifth job. They must have the mental capacity to assimilate <b>new technologies</b> and <b>new ideas</b>. This is why it is very important for young people to perceive themselves as lifelong learners.’',

  'game.inspiration.junior.questions.4':
    '<b>Adedayo Fashanu</b><br/> Former JA alumna and journalist',
  'game.inspiration.junior.questions.4.option.1':
    '‘<b>Confidence</b> is key for progress, but do not base your confidence only on your achievements, because what will happen when you fail at something? <b>Would that be a reason to lose confidence in yourself?</b>No. Your confidence must <b>be rooted</b> in a cause greater than the job you are doing and in the positive process of <b>fulfilling</b> that job.’',

  'game.inspiration.junior.questions.5':
    '<b>Bonnie Chiu</b><br/> Former JA Hong Kong alumna',
  'game.inspiration.junior.questions.5.option.1':
    '‘Start with the “<b>why?</b>”. That is the most important question to make when building <b>your life</b> and <b>your career:</b> why am I<b>doing</b> this? Why do I get <b>involved</b> in this? Why is this <b>important</b> for me?’',

  'game.inspiration.junior.questions.6':
    '<b>Raymon Setiadi</b><br/>Entrepreneur and former student JA',
  'game.inspiration.junior.questions.6.option.1':
    "“Never think that you <b> know everything </b> and never feel very <b> comfortable </b> with your situation. Because when you think that way, you don't give yourself any <b> opportunity for growth. </b>”",

  'game.inspiration.junior.questions.7':
    '<b>Camilla Ijunggren</b><br/> Entrepreneur and former JA alumna',
  'game.inspiration.junior.questions.7.option.1':
    '‘Whatever you <b>choose to do</b>, you must know it <b>will take time</b>. Explaining how I built our factory takes 10 minutes and it may sound easy. Turns out it was not. <b>It takes time.</b> In my case it was 16-17 years of hard work.’',

  'game.inspiration.junior.questions.8':
    '<b>Murillo Sabino</b><br/> Founder of project RUAS and former alumnus of JA Brazil',
  'game.inspiration.junior.questions.8.option.1':
    'Do not ‘look’ for your purpose. Grow your own purpose. This will be the link between what awakes your curiosity, what benefits you, and what the world needs to become a better place.',

  'game.inspiration.junior.questions.9':
    '<b>Jerome Cowans</b><br/> Member of the Jamaica House Fellowship',
  'game.inspiration.junior.questions.9.option.1':
    '‘What I have learned and believe is critical for all young people is this: throughout your life it will be essential to understand the practical side of what you are doing, as well as the theoretical side.’',

  'game.inspiration.junior.questions.10':
    '<b> Leen Abu Baker</b><br/> Vice-president of the board of former alumni of INJAZ Al-Arab',
  'game.inspiration.junior.questions.10.option.1':
    '‘Start looking for fields of interest while you are still young and do not be afraid to share them with the community that surrounds you.’',
}
