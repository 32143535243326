import styled from 'styled-components'

export const Container = styled.figure`
  span {
    height: 96px;
    width: 324px;
    position: relative;

    svg {
      font-family: MilkyNice;
      width: 236px;
      height: 70px;
    }

    small {
      position: absolute;
      top: 56px;
      left: 66px;
      font-family: MilkyNice;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1024px) {
    span {
      svg {
        height: 96px;
        width: 324px;
      }

      small {
        top: 78px;
        left: 92px;
        font-size: 14px;
      }
    }
  }
`
