export default {
  'landingpage.menu.init': 'Início',
  'landingpage.menu.about': 'Sobre JA',
  'landingpage.choiceYourCharacter': 'Elige tu personaje',
  'landingpage.jobXStudies': 'Trabajo x Estudios',
  'landingpage.acceptChallenge': '¿Aceptas el reto?',
  'landingpage.copyright': 'Juego VUCA Ⓒ. Todos los derechos reservados.',
  'landingpage.welcome': '¡bienvenido al',
  'landingpage.youGoTo': 'Has chegado al',
  'landingpage.introParagraphs.1':
    'Cris, Milena, Brenda y Jonatan están en un período decisivo y necesitan pensar en el futuro.',
  'landingpage.introParagraphs.2':
    'Ayuda a este grupo a graduarse de la escuela y decidir su futuro llegando al Portal Z: El portal de oportunidades.',
  'landingpage.introParagraphs.3':
    'Enfrenta dilemas e adversidades! Cuenta con un poco de inspiración para vencer este desafío.',
  'landingpage.introParagraphs.4': '¿Vamos?',
  'landingpage.card.1':
    'Escuela, trabajo, familia, todo esto forma parte del mundo VUCA (volátil, incierto, complejo y ambiguo). Un mundo lleno de incertidumbres en el que todo puede cambiar de la noche a la mañana y no hay bien ni mal. Enfrenta desafíos reales, prueba tus conocimientos y tu suerte. VUCA significa volátil, incierto, complejo y ambiguo.',
  'landingpage.card.2':
    'Cada persona tiene su historia, así como los cuatro personajes de ese juego. Ellos viven en un mundo hiperconectado, acelerado y se sienten confundidos e inseguros sobre su futuro. Ayude a uno de ellos a alcanzar su mejor versión utilizando su poder de decisión, pensamiento crítico y conocimiento general. Vendrán muchos desafíos, ¡bienvenido al mundo VUCA!',
  'landingpage.card.3':
    '¿Trabajar y ganar dinero ahora o estudiar y tener una profesión en el futuro? Enfrenta los desafíos del mercado laboral y toma tus propias decisiones.',
}
