export const getLoginStrings = () => {
  return {
    username: {
      label: 'email',
      placeholder: 'emailPlaceholder',
      dataTest: 'inputUsernameLogin',
    },
    password: {
      label: 'password',
      placeholder: 'password',
      dataTest: 'inputPasswordLogin',
    },
    rememberPassword: {
      dataTest: 'checkboxRememberPassword',
      label: 'remember',
    },
    forgetPassword: {
      dataTest: 'linkDidYouForgetPassarword',
      label: 'login.forgetPassword',
    },
    loginButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'orange',
      },
      dataTest: 'buttonLogin',
      value: 'login.play',
    },
    signUpButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonSignUp',
      value: 'login.signUp',
    },
    facebookButton: {
      colors: {
        borderColor: 'darkBlue',
        colorText: 'white',
        hoverColor: 'lightBlue',
        bgColor: 'blueFacebook',
      },
      dataTest: 'buttonLoginWithFacebook',
      value: 'login.facebook',
    },
    googleButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonLoginWithGoogle',
      value: 'login.google',
    },
    withoutLoginButton: {
      colors: {
        borderColor: 'black',
        colorText: 'black',
        bgColor: 'white',
      },
      dataTest: 'buttonPlayWithoutLogin',
      value: 'login.playWithoutLogin',
    },
    labelDontHaveAccount: {
      label: 'login.notAccount',
      link: 'login.registration',
    },
    labelInvalidLogin: {
      label: 'login.invalid',
    },
  }
}
