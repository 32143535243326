import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  TopBar,
  CardDetails,
  Footer,
  ContainerButtonsOfPlayNDownload,
  ContentTextIntroDesktop,
  ContentTextIntroMobile,
} from './components'

import { JonathanThinking, AllCharacters } from '../../assets/img/characters'

import icGlobe from '../../assets/img/icGlobe.svg'
import icStar from '../../assets/img/icStar.svg'
import icQuestion from '../../assets/img/icQuestion.svg'

import {
  Container,
  InfoGameSection,
  ChallengeBlock,
  JonathanFigure,
  MainSection,
  VucaTitle,
  WelcomeTitleDesktop,
  WelcomeTitleMobile,
  CardContainer,
  WrapperFirstLineCards,
  WrapperSecondLineCards,
  AllCharactersMobile,
  SectionVucaTexts,
} from './styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Logo, CardTerms } from '../../components'

export const LandingPageCp = ({ intl, history }) => {
  const [showTerms, setshowTerms] = useState(false)
  const containerVucaWorld = useRef()

  const openTerms = () => {
    setshowTerms(!showTerms)
  }

  const dataItemsMenu = [
    {
      route: '/',
      name: 'landingpage.menu.init',
    },
    {
      route: containerVucaWorld,
      hashLink: true,
      name: 'worldVuca',
    },
    {
      route: 'https://site.jaamericas.org/',
      external: true,
      name: 'landingpage.menu.about',
      analytics: 'goToAboutJAClicked',
    },
  ]

  return (
    <>
      {showTerms && <CardTerms closeCardTerms={openTerms} />}
      <Container>
        <TopBar history={history} dataItemsMenu={dataItemsMenu} />
        <section className="container container--withMarginTop" data-section="one">
          <Logo className="logo" />

          <MainSection>
            <article>
              <ContentTextIntroMobile />
              <ContentTextIntroDesktop />

              <ContainerButtonsOfPlayNDownload
                buttonPlayDescrition="go-to-game-after-Descriptions"
                history={history}
              />
            </article>

            <JonathanFigure>
              <JonathanThinking />
            </JonathanFigure>
          </MainSection>
        </section>

        <SectionVucaTexts ref={containerVucaWorld} id="vucaWorld">
          <div className="container">
            <WelcomeTitleMobile>
              <FormattedMessage id="landingpage.welcome" />
            </WelcomeTitleMobile>
            <WelcomeTitleDesktop>
              <FormattedMessage id="landingpage.youGoTo" />
            </WelcomeTitleDesktop>

            <VucaTitle>
              <FormattedMessage id="worldVuca" /> !
            </VucaTitle>
          </div>
        </SectionVucaTexts>

        <InfoGameSection>
          <div className="container" data-section="two">
            <CardContainer>
              <WrapperFirstLineCards>
                <CardDetails
                  iconName={icGlobe}
                  title={intl.formatMessage({ id: 'worldVuca' })}
                  textContent={intl.formatMessage({ id: 'landingpage.card.1' })}
                />
                <CardDetails
                  iconName={icStar}
                  title={intl.formatMessage({ id: 'landingpage.choiceYourCharacter' })}
                  textContent={intl.formatMessage({ id: 'landingpage.card.2' })}
                />
              </WrapperFirstLineCards>

              <WrapperSecondLineCards>
                <CardDetails
                  iconName={icQuestion}
                  title={intl.formatMessage({ id: 'landingpage.jobXStudies' })}
                  textContent={intl.formatMessage({ id: 'landingpage.card.3' })}
                />
              </WrapperSecondLineCards>
            </CardContainer>
          </div>
        </InfoGameSection>

        <AllCharactersMobile data-section="allCharactersMobile">
          <AllCharacters width="100%" />
        </AllCharactersMobile>

        <ChallengeBlock data-section="three">
          <div>
            <h4>
              <FormattedMessage id="landingpage.acceptChallenge" />
            </h4>
            <ContainerButtonsOfPlayNDownload
              isSingle
              buttonPlayDescrition="go-to-game-topBar-before-footer"
              history={history}
            />
          </div>
        </ChallengeBlock>

        <Footer onRulesClick={openTerms} />
      </Container>
    </>
  )
}

LandingPageCp.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
}

export const LandingPage = injectIntl(LandingPageCp)
