export default {
  'game.inspiration.superior.questions.1':
    '<b>Lia Zakiyyah</b><br/> Entrepreneur and former JA alumni',
  'game.inspiration.superior.questions.1.option.1':
    '‘Looking for a career is an activity for the rest of your life. It does not end with the first job or with the first career. <b>Look for something you believe in and that is good for your entire life.</b>',

  'game.inspiration.superior.questions.2':
    '<b>Cosmin Malureanu</b><br/>Entrepreneur and former JA alumna',
  'game.inspiration.superior.questions.2.option.1':
    '‘<b>Learn how to learn</b>. The world changes at a <b>fast pace</b>. You need to avoid getting stuck at a job or career that has no future. Challenge yourself to <b>learn new things </b> and remain competitive.',

  'game.inspiration.superior.questions.3':
    '<b>Joshuel Plasencia</b><br/>Cofounder of Proyecto 99 and former alumnus of JA USA.',
  'game.inspiration.superior.questions.3.option.1':
    '‘Giving and receiving <b>feedback</b> is a gift, since there is no way to be better at something if there are no perspectives of improvement. <b>Perfectionism</b> is only possible through giving and receiving feedback.’',

  'game.inspiration.superior.questions.4':
    '<b>Leen Abu Baker</b><br/>Entrepreneur and former JA alumna',
  'game.inspiration.superior.questions.4.option.1':
    '‘Start looking for fields of interest while you are still young and do not be afraid to share them with the community that surrounds you.’',

  'game.inspiration.superior.questions.5':
    '<b>Yesenia Cardenas</b><br/>Entrepreneur and former JA alumna',
  'game.inspiration.superior.questions.5.option.1':
    '‘If you have a <b>goal</b> or a <b>dream</b> that you want to materialize, it does not matter if it is becoming a lawyer, opening a business or having a family, <b>there will be obstacles</b> and people that will question your dreams. It is very important to be <b>faithful</b> to your goals and wishes, and to <b>work</b> hard to reach them.',

  'game.inspiration.superior.questions.6':
    '<b>Bob Coughlin</b><br/>Entrepreneur and former JA alumnus',
  'game.inspiration.superior.questions.6.option.1':
    '‘Understand what are your strengths and reach out to people that can help you overcome your weaknesses.’',

  'game.inspiration.superior.questions.7':
    '<b>Mario A. Escutia</b><br/>Entrepreneur and former JA alumnus',
  'game.inspiration.superior.questions.7.option.1':
    '‘My advice for young people is: it does not matter <b>where you come from</b>, your social class or your ideology: <b>your dreams can come true.</b> Honesty and hard work always get rewarded.’',

  'game.inspiration.superior.questions.8':
    '<b>Joseph Fortuno</b><br/>Former JA USA alumnus',
  'game.inspiration.superior.questions.8.option.1':
    '‘I have one advice: Do not be afraid of trying; do not be afraid of failing. If I had been afraid, I would have never gotten to where I am today.’',

  'game.inspiration.superior.questions.9':
    '<b> Young mazharul </b> <br/> Former student JA Morocco',
  'game.inspiration.superior.questions.9.option.1':
    '‘Let’s think about the concept of being successful. The metric we use to measure success is usually distorted, because it only contemplates financial success. While this is important, humanity should commit to having a life with a purpose.’',

  'game.inspiration.superior.questions.10':
    '<b>Jeff Hansberry</b><br/>Former JA USA alumnus',
  'game.inspiration.superior.questions.10.option.1':
    '‘You need to understand that there is a different life outside of the 3 or 4 blocks that surround you, or outside of the experiences you have had so far. There is something better for you, beyond the world you live in today.’',
}
