import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.job': 'Trabajo',
  'game.job.message':
    'Esta es una casilla de Trabajo. Si su personaje estuviera trabajando, aparecerían aqui algunos desafíos para resolver. Si aún no tuviera una opción para comenzar a trabajar, entonces ¡Sigue adelante!',

  ...junior,
  ...middle,
  ...superior,
}
