export const getSignUpStrings = () => {
  return {
    name: {
      label: 'signUp.name',
      placeholder: 'signUp.namePlaceholder',
      dataTest: 'inputSignUpName',
      error: 'signUp.name',
    },
    email: {
      label: 'signUp.email',
      placeholder: 'emailPlaceholder',
      dataTest: 'inputSignUpEmail',
      error: 'emailAlreadySignedUp',
    },
    birthDate: {
      label: 'signUp.dateOfBirth',
      tooltip: 'signUp.dateOfBirth.tooltip',
      placeholder: [
        {
          label: 'signUp.birthDate.day',
          nameValue: 'day',
          maxLength: 2,
          dataTest: 'selectSignUpDayOfBirth',
          selectDataTest: 'containerSelectSignUpDayOfBirth',
          optionsNoTranslate: true,
          options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ],
        },
        {
          label: 'signUp.birthDate.mounth',
          nameValue: 'month',
          maxLength: 3,
          dataTest: 'selectSignUpMonthOfBirth',
          selectDataTest: 'containerSelectSignUpMonthOfBirth',
          options: [
            { label: 'signUp.birthDate.mounth.january', id: 0 },
            { label: 'signUp.birthDate.mounth.february', id: 1 },
            { label: 'signUp.birthDate.mounth.march', id: 2 },
            { label: 'signUp.birthDate.mounth.april', id: 3 },
            { label: 'signUp.birthDate.mounth.may', id: 4 },
            { label: 'signUp.birthDate.mounth.june', id: 5 },
            { label: 'signUp.birthDate.mounth.july', id: 6 },
            { label: 'signUp.birthDate.mounth.august', id: 7 },
            { label: 'signUp.birthDate.mounth.september', id: 8 },
            { label: 'signUp.birthDate.mounth.october', id: 9 },
            { label: 'signUp.birthDate.mounth.november', id: 10 },
            { label: 'signUp.birthDate.mounth.december', id: 11 },
          ],
        },
        {
          label: 'signUp.year',
          nameValue: 'year',
          maxLength: 4,
          dataTest: 'selectSignUpYearOfBirth',
          selectDataTest: 'containerSelectSignUpYearOfBirth',
          optionsNoTranslate: true,
          options: [],
        },
      ],
    },
    gender: {
      label: 'signUp.gender',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      dataTest: 'selectSignUpEthnicity',
      selectDataTest: 'containerSelectSignUpEthnicity',
      options: [
        { label: 'signUp.gender.feminine', id: 'feminino' },
        { label: 'signUp.gender.male', id: 'masculino' },
        { label: 'signUp.gender.notBinary', id: 'noBinario' },
        { label: 'signUp.gender.other', id: 'other' },
        { label: 'signUp.gender.preferNotToSay', id: 'noSaid' },
      ],
    },
    ethnicity: {
      label: 'signUp.ethnicity',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      dataTest: 'selectSignUpEthnicity',
      selectDataTest: 'containerSelectSignUpEthnicity',
      options: [
        { label: 'signUp.ethnicity.white', id: 'branco' },
        { label: 'signUp.ethnicity.parde', id: 'pardo' },
        { label: 'signUp.ethnicity.black', id: 'negro' },
        { label: 'signUp.ethnicity.yellow', id: 'amarelo' },
        { label: 'signUp.ethnicity.indie', id: 'indigeno' },
      ],
    },
    state: {
      label: 'signUp.state',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      dataTest: 'selectSignUpState',
      selectDataTest: 'containerSelectSignUpState',
      options: [{}],
    },
    city: {
      label: 'signUp.city',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      selectDataTest: 'containerSelectSignUpCity',
      dataTest: 'selectSignUpCity',
      options: [{ nome: '' }],
    },
    you_are_studying: {
      label: 'signUp.areYouStudying',
      tooltip: 'signUp.tooltip',
      dataTest: 'radioSignUpAreYouStudying',
      placeholder: [
        { label: 'yes', for: 'true' },
        { label: 'no', for: 'false' },
      ],
    },
    schooling: {
      label: 'signUp.schooling',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      dataTest: 'selectSignUpschooling',
      selectDataTest: 'containerSelectSignUpSchooling',
      options: [
        { label: 'signUp.schooling.juniorStage', id: 'ensFundamental' },
        { label: 'signUp.schooling.middleStage', id: 'ensMedio' },
        { label: 'signUp.schooling.superiorStage', id: 'ensSuperior' },
      ],
    },
    school_year: {
      label: 'signUp.schooling.year',
      tooltip: 'signUp.tooltip',
      placeholder: 'select',
      dataTest: 'selectSignUpSchoolYear',
      selectDataTest: 'containerSelectSignUpSchoolYear',
      options: [],
    },
    type_institution: {
      label: 'signUp.schooling.type',
      tooltip: 'signUp.tooltip',
      dataTest: 'radioSignUpTypeOfInstitution',
      placeholder: [
        { label: 'signUp.schooling.type.public', for: 'publica' },
        { label: 'signUp.schooling.type.private', for: 'privada' },
      ],
    },
    newPassword: {
      label: 'signUp.password.create',
      placeholder: 'signUp.password.new',
      dataTest: 'inputSignUpPassword',
    },
    confirmPassword: {
      label: 'signUp.password.confirm',
      placeholder: 'signUp.password.confirm',
      dataTest: 'inputSignUpConfirmPassword',
    },
    terms: {
      label: 'signUp.termsSignUp',
      dataTest: 'checkboxSignUpTextTerm',
    },
    aceite: {
      dataTest: 'checkboxSignUpAcceptTerm',
      label: 'signUp.agreeTerms',
      link: 'signUp.termsSignUp',
      alert: 'signUp.acceptTerms',
    },
    labels: {
      accountConfiguration: 'signUp.accountSettings',
      terms: 'signUp.termsSignUp',
      pessoalData: 'signUp.personalData',
    },
  }
}
