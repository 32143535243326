export default {
  'game.knowledge.middle.questions.1':
    'Which of these items does not characterize a democratic system?',
  'game.knowledge.middle.questions.1.option.1': 'Access to information.',
  'game.knowledge.middle.questions.1.option.1.feedback':
    'A democratic system does not forbid political demonstrations. Democracy means freedom of expression, free elections, freedom of political association, and access to information.',
  'game.knowledge.middle.questions.1.option.2':
    'Prohibition of political demonstrations.​',
  'game.knowledge.middle.questions.1.option.2.feedback':
    'Well done! {name} already knows that democracy is related to freedom.',

  'game.knowledge.middle.questions.2': 'What is the best strategy to build an argument?',
  'game.knowledge.middle.questions.2.option.1':
    'Resort to concrete data and make comparisons to justify your point of view.',
  'game.knowledge.middle.questions.2.option.1.feedback':
    'Well done! A good argument is based on facts, not on screaming contests.',
  'game.knowledge.middle.questions.2.option.2':
    'Speak firmly and attack the opposite side.​',
  'game.knowledge.middle.questions.2.option.2.feedback':
    'It is not possible to convince someone by screaming and attacking them. To argue is to present data and ideas that support an opinion.',

  'game.knowledge.middle.questions.3':
    'Which of the following items does not represent a chemical reaction?',
  'game.knowledge.middle.questions.3.option.1': 'Frying an egg.',
  'game.knowledge.middle.questions.3.option.1.feedback':
    'Oops! The correct answer is the other option, since water and oil do not mix.',
  'game.knowledge.middle.questions.3.option.2': 'Mixing water with oil.',
  'game.knowledge.middle.questions.3.option.2.feedback':
    'Well done! Mixing these two elements does not represent a chemical reaction.',

  'game.knowledge.middle.questions.4':
    "Imagine that Mary wins $1.200 a month. Her budget includes $450 for rent, $300 for food and $100 for utility services. How much of her monthly budget remains for the rest of the family's expenses?",
  'game.knowledge.middle.questions.4.option.1': 'R$ 350,00',
  'game.knowledge.middle.questions.4.option.1.feedback':
    'Correct! Calculating the family budget correctly can save you a huge headache.',
  'game.knowledge.middle.questions.4.option.2': 'R$ 250,00',
  'game.knowledge.middle.questions.4.option.2.feedback':
    'Numbers do not add up! The correct answer is $350. Calculating the family budget correctly can save you a huge headache.',

  'game.knowledge.middle.questions.5':
    'Antibiotics are medication used to fight infections caused by:',
  'game.knowledge.middle.questions.5.option.1': 'Bacteria.',
  'game.knowledge.middle.questions.5.option.1.feedback': 'Well done! Correct!',
  'game.knowledge.middle.questions.5.option.2': 'Viruses.',
  'game.knowledge.middle.questions.5.option.2.feedback':
    'That is not correct. Antibiotics are a type of medication that fights bacteria, not viruses.',

  'game.knowledge.middle.questions.6':
    'Five balls numbered from 1 to 5 are placed in a group. The probability of getting an even number is:',
  'game.knowledge.middle.questions.6.option.1': '20%',
  'game.knowledge.middle.questions.6.option.1.feedback':
    'Oops! The correct answer is 40%',
  'game.knowledge.middle.questions.6.option.2': '40%',
  'game.knowledge.middle.questions.6.option.2.feedback': 'Correct!',

  'game.knowledge.middle.questions.7': 'What is the correct phrase?',
  'game.knowledge.middle.questions.7.option.1': 'There were a lot of people in the game.',
  'game.knowledge.middle.questions.7.option.1.feedback':
    'Correct! When the expression THERE + BE is used with the meaning of EXIST, it is conjugated in third person singular or plural, depending on the subject.',
  'game.knowledge.middle.questions.7.option.2': 'There was a lot of people in the game.',
  'game.knowledge.middle.questions.7.option.2.feedback':
    'Wrong answer! When the expression THERE + BE is used with the meaning of EXIST, it is conjugated in third person singular or plural, depending on the subject: ‘There were a lot of people in the game.’',

  'game.knowledge.middle.questions.8':
    'Mahatma Gandhi was a famous Hindu known in the world for: ',
  'game.knowledge.middle.questions.8.option.1':
    'Leading a non-violent movement for India’s independence.',
  'game.knowledge.middle.questions.8.option.1.feedback': 'Correct!',
  'game.knowledge.middle.questions.8.option.2': 'Starting a world war.',
  'game.knowledge.middle.questions.8.option.2.feedback':
    'Wrong answer. Gandhi led India’s independence movement and is famous for his pacific demonstrations.',
}
