import { types } from './types'

const actionMap = {
  [types.SET_AUTH_TOKEN]: (state, payload) => ({ ...state, auth_token: payload }),
  [types.SET_USER_DETAILS]: (state, payload) => ({ ...state, user_details: payload }),
  [types.SET_SOUND]: (state, payload) => ({ ...state, sound: payload }),
  [types.SET_G]: (state, payload) => ({ ...state, G: payload }),
  [types.SET_LANGUAGE]: (state, payload) => ({ ...state, language: payload }),
}

export const reducer = (state, action) =>
  actionMap[action.type] ? actionMap[action.type](state, action.payload) : state
