import styled, { css } from 'styled-components'

export const RatingContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
`

export const Rating = styled.div`
  ${({ checked }) =>
    checked &&
    css`
      color: yellow;
    `}

  cursor: pointer;
`

export const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.04px;
  padding-bottom: 20px;

  ${({ isLast }) =>
    isLast &&
    css`
      padding-bottom: 0px;
    `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
