import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { IcArrowRight, PurpleFlag } from '../../assets/img'
import {
  JonathanOnRulesScreen,
  allCharactersRulesScreen,
} from '../../assets/img/characters'

import {
  MobileContainer,
  ContainerButtonNFigures,
  CharactersFigure,
  RulesText,
  Paragraphs,
  FooterButtons,
  ContainerMarkStepRule,
} from './styles'
import { ButtonWithRadiusBorderComponent } from '../Buttons/buttonWithRadiusBorder'
import { CircularButtonComponent } from '../Buttons/circularButton'

const MobileRulesCp = ({ onStartButtonClick, intl }) => {
  const [indexCurrentRules, setIndexCurrentRules] = useState(0)

  const paragraphs = [
    intl.formatMessage({ id: 'introduction.firstDescription' }),
    intl.formatMessage({ id: 'introduction.secondDescription' }),
    intl.formatMessage({ id: 'introduction.thirdDescription' }),
  ]

  const pageRules = {
    previous: {
      isDisabled: indexCurrentRules <= 0,
      handleClick() {
        setIndexCurrentRules((current) => current - 1)
      },
    },
    next: {
      isDisabled: indexCurrentRules === paragraphs.length - 1,
      handleClick() {
        setIndexCurrentRules((current) => current + 1)
      },
    },
  }

  const CurrentImage = useMemo(
    () =>
      [allCharactersRulesScreen, PurpleFlag, JonathanOnRulesScreen][indexCurrentRules],
    [indexCurrentRules],
  )

  return (
    <MobileContainer>
      <CharactersFigure>
        <CurrentImage width="100%" />
      </CharactersFigure>

      <RulesText>
        <Paragraphs
          isLast
          dangerouslySetInnerHTML={{ __html: paragraphs[indexCurrentRules] }}
        />
      </RulesText>

      <ContainerButtonNFigures>
        <CircularButtonComponent
          color="lemonGreen"
          rotateImg
          disabled={pageRules.previous.isDisabled}
          onClick={pageRules.previous.handleClick}>
          <IcArrowRight width="18" height="18" />
        </CircularButtonComponent>

        <ContainerMarkStepRule currentMarkIndex={indexCurrentRules}>
          {paragraphs.map((_, index) => (
            <span key={index} />
          ))}
        </ContainerMarkStepRule>

        <CircularButtonComponent
          color="lemonGreen"
          disabled={pageRules.next.isDisabled}
          onClick={pageRules.next.handleClick}>
          <IcArrowRight width="18" height="18" />
        </CircularButtonComponent>
      </ContainerButtonNFigures>

      <FooterButtons>
        {paragraphs.length !== indexCurrentRules + 1 ? (
          <>
            <ButtonWithRadiusBorderComponent
              data_test="passRulesMobile"
              color="white"
              onClick={onStartButtonClick}
              text={intl.formatMessage({ id: 'jump' })}
            />
            <ButtonWithRadiusBorderComponent
              withoutMarginTop
              data_test="nextRuleMobile"
              onClick={pageRules.next.handleClick}
              text={intl.formatMessage({ id: 'next' })}
            />
          </>
        ) : (
          <>
            <div />
            <ButtonWithRadiusBorderComponent
              data_test="doneRulesMobile"
              onClick={onStartButtonClick}
              text={intl.formatMessage({ id: 'start' })}
            />
          </>
        )}
      </FooterButtons>
    </MobileContainer>
  )
}

MobileRulesCp.propTypes = {
  onStartButtonClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
}

export const MobileRules = injectIntl(MobileRulesCp)
