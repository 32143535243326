import { memo } from 'react'
import styled, { css } from 'styled-components'

import backgroundBoardScreenJuniorDesktop from '../../assets/img/backgrounds/backgroundBoardScreenJuniorDesktop.svg'
import backgroundBoardScreenMiddleDesktop from '../../assets/img/backgrounds/backgroundBoardScreenMiddleDesktop.svg'
import backgroundBoardScreenSuperiorDesktop from '../../assets/img/backgrounds/backgroundBoardScreenSuperiorDesktop.svg'

import backgroundBoardScreenJuniorMobile from '../../assets/img/backgrounds/backgroundBoardScreenJuniorMobile.svg'
import backgroundBoardScreenMiddleMobile from '../../assets/img/backgrounds/backgroundBoardScreenMiddleMobile.svg'
import backgroundBoardScreenSuperiorMobile from '../../assets/img/backgrounds/backgroundBoardScreenSuperiorMobile.svg'

const backGroundDesktop = [
  backgroundBoardScreenJuniorDesktop,
  backgroundBoardScreenMiddleDesktop,
  backgroundBoardScreenSuperiorDesktop,
]
const backGroundMobile = [
  backgroundBoardScreenJuniorMobile,
  backgroundBoardScreenMiddleMobile,
  backgroundBoardScreenSuperiorMobile,
]

export const BoardWrapper = memo(styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  ${({ gameStage }) => css`
    background-image: url(${backGroundMobile[gameStage]});
  `}

  .containerHeader {
    padding: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gameSpace {
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 1000px) {
    ${({ gameStage }) => css`
      background-image: url(${backGroundDesktop[gameStage]});
    `}

    .containerHeader {
      display: block;
    }
  }

  background-size: 100%;
  height: 100vh;
`)

export const DiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  place-items: center;

  @media screen and (min-width: 1024px) {
    padding: 0 1.4rem;
    position: absolute;
    bottom: 2rem;
  }
`

export const ContainerSvgBoard = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`

export const Svg = styled.svg`
  height: 45vh;
  width: 100%;

  @media screen and (min-width: 320px) {
    height: 50vh;
  }

  @media screen and (min-width: 360px) {
    height: 55vh;
  }

  @media screen and (min-width: 375px) {
    height: 57vh;
  }

  @media screen and (min-width: 375px) and (min-height: 812px) {
    height: 65vh;
  }

  @media screen and (min-width: 411px) {
    height: 61vh;
  }

  @media screen and (min-width: 410px) and (min-height: 823px) {
    height: 65vh;
  }

  @media screen and (min-width: 768px) {
    height: 72vh;
  }

  @media screen and (min-width: 1024px) {
    height: 70vh;
  }

  @media screen and (min-width: 1024px) and (min-height: 600px) {
    height: 65vh;
  }
`
