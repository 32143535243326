import React from 'react'
import PropTypes from 'prop-types'

import { MenuDesktop, MenuMobile } from './components'
import { TopBarContainer, TopBarContentWrapper } from './styles'
import { Logo, Select } from '../../../../components'
import { types } from '../../../../store'
import { useStore } from '../../../../store/hooks'

import { injectIntl } from 'react-intl'

export const TopBarCp = ({ intl, dataItemsMenu, ...props }) => {
  const { store, dispatch } = useStore()
  const { language } = store

  // const listLanguage = [
  //   { value: 'pt', label: 'PT', description: intl.formatMessage({ id: 'language.pt' }) },
  //   { value: 'es', label: 'ES', description: intl.formatMessage({ id: 'language.es' }) },
  //   { value: 'en', label: 'EN', description: intl.formatMessage({ id: 'language.en' }) },
  // ]

  const updateLanguage = (language) => {
    dispatch({ type: types.SET_LANGUAGE, payload: language })
  }

  return (
    <TopBarContainer data-test="TopBarContainer">
      <TopBarContentWrapper className="container">
        <Logo className="logo" width="150" height="43" hiddenText />
        <MenuDesktop dataItemsMenu={dataItemsMenu} {...props} />
        <MenuMobile dataItemsMenu={dataItemsMenu} {...props} />
        {/* <div className="containerSelect">
          {listLanguage.length > 0 && (
            <Select
              withoutMarginTop={true}
              value={listLanguage.filter((lang) => lang.value === language)[0]}
              options={listLanguage}
              onChange={(selected) => updateLanguage(selected.value)}
            />
          )}
        </div> */}
      </TopBarContentWrapper>
    </TopBarContainer>
  )
}

TopBarCp.propTypes = {
  intl: PropTypes.object,
  dataItemsMenu: PropTypes.any,
}

export const TopBar = injectIntl(TopBarCp)
