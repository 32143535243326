import React from 'react'

export default function useWindowSize() {
  const isSSR = typeof window !== 'undefined'
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export const isMobileSize = () => {
  const WIDTH_SCREEN = window.innerWidth
  const MIN_WIDTH_DESKTOP = 1024
  return WIDTH_SCREEN < MIN_WIDTH_DESKTOP
}

export const replaceDataTagFromString = (string, data, tag) => {
  return string.replace(tag, data)
}

export const getTileCentroid = (boardTiles, tilePos) => {
  const filtered = boardTiles.filter((t) => t.index === `${tilePos}`)
  return filtered.length > 0 ? filtered[0].centroid : []
}

export const validateEmail = (email) => {
  const regularExpressionEmail = /\S+@\S+\.\S+/
  return regularExpressionEmail.test(email)
}

export const replaceKeyToText = (editableText, keyToReplace, replaceToText) =>
  editableText.split(keyToReplace).join(replaceToText)
