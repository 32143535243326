import junior from './junior'
import middle from './middle'
import superior from './superior'

export default {
  'game.challenge': 'Situación',
  'game.challenge.message':
    'Siempre que pare en esta casilla va a recibir cartas con desafíos de situaciones del día a día que pueden impactar la vida de su personaje.',

  ...junior,
  ...middle,
  ...superior,
}
